import React, { useEffect } from "react";
import _ from "lodash";
import { startOfYear } from "date-fns";
import styles from "./AddPlanDialog.module.scss";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import useForm from "../../hooks/useForm";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import Loading from "../Loading/Loading";
import PlanPill from "../PlanPill/PlanPill";

const initForm = {
  departmentName: null,
  quarterNumber: null,
  theme: null,
  shortName: undefined,
  color: undefined,
};

const AddPlanDialog = ({ dialog, setDialog, requestFetch, params, snack }) => {
  let initErrorForm = {
    departmentName: ["required"],
  };

  const { form, formErrors, handleChange, handleChangeDate, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  // const {
  //   loading: queryLoading,
  //   data,
  //   refetch,
  // } = useQuery(GET_THREE_YEAR_CORPORATE_PLAN, {
  //   variables: { id: params.org },
  //   notifyOnNetworkStatusChange: true,
  //   onCompleted: (queryData) => {
  //     handleChangeDate("targetDate")(new Date(parseInt(queryData.plan.targetDate)));
  //   },
  // });

  const [createPlan, { loading: mutationLoading }] = useMutation(CREATE_PLAN);

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const { departmentName, quarterNumber, theme, shortName, color } = form;

    try {
      const ok = await createPlan({
        variables: {
          organization: params.org,
          departmentName: departmentName.trim(),
          quarterNumber,
          theme,
          shortName,
          color,
        },
      });

      if (ok.data.createPlan) {
        snack(`Created ${departmentName} department`);
        requestFetch();
        resetForm();
        handleClose();
      }
    } catch (err) {
      snack("Failed to create new department", "error");
    }
  };

  const handleClose = () => {
    setDialog({ ...dialog, addPlanDialog: false });
  };

  // useEffect(() => {
  //   refetch();
  // }, [dialog.addPlanDialog]);

  return (
    <Dialog
      open={dialog.addPlanDialog}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
    >
      <DialogTitle>Create New Department</DialogTitle>
      <>
        <DialogContent>
          <TextField
            label="Department Name"
            name="departmentName"
            fullWidth
            margin="normal"
            variant="outlined"
            value={form.departmentName || ""}
            onChange={handleChange}
            helperText={formErrors.departmentName}
            error={Boolean(formErrors.departmentName)}
          />
          <TextField
            label="Plan Theme"
            name="theme"
            fullWidth
            margin="normal"
            variant="outlined"
            value={form.theme || ""}
            onChange={handleChange}
            helperText={formErrors.theme}
            error={Boolean(formErrors.theme)}
          />
          {/* <DatePicker
            views={["year"]}
            fullWidth
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="yyyy"
            margin="normal"
            label="Plan Year (for 1 Year Dept.)"
            value={form.year || ""}
            onChange={handleChangeDate("year")}
          />
          <DatePicker
            fullWidth
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            margin="normal"
            label="Target Date (for 3 Year Dept.)"
            value={form.targetDate || ""}
            onChange={handleChangeDate("targetDate")}
          /> */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="Short Name"
              name="shortName"
              variant="outlined"
              margin="normal"
              value={(form.shortName || "").toUpperCase()}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              type="color"
              onChange={handleChange}
              value={form.color || "#000000"}
              name="color"
              helperText={formErrors.color}
              error={Boolean(formErrors.color)}
              label="Color"
              margin="normal"
              variant="outlined"
              fullWidth
              style={{ marginLeft: "0.5rem" }}
            />
            <PlanPill plan={{ departmentName: form.departmentName, color: form.color, shortName: form.shortName }} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary" variant="contained" disabled={mutationLoading} className={styles.button}>
            {mutationLoading ? <Loading size={24} color="#fff" /> : "Create Department"}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default AddPlanDialog;

// const GET_THREE_YEAR_CORPORATE_PLAN = gql`
//   query AddPlanDialog_GetThreeYearCorporatePlan($id: ID!) {
//     plan(organization: $id, category: "3 year", departmentName: "Corporate") {
//       id
//       targetDate
//     }
//   }
// `;

const CREATE_PLAN = gql`
  mutation ($organization: ID!, $departmentName: String, $quarterNumber: Int, $theme: String, $shortName: String, $color: String) {
    createPlan(
      organization: $organization
      departmentName: $departmentName
      quarterNumber: $quarterNumber
      theme: $theme
      shortName: $shortName
      color: $color
    )
  }
`;
