import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { Button, Typography } from "@material-ui/core";
import styles from "./Payment.module.scss";
import _ from "lodash";

const Payment = ({ user, subscribed }) => {
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage("Order canceled");
    }
  }, [sessionId]);

  useEffect(() => {
    const { sessionId } = user;
    if (subscribed === true) {
      setSuccess(true);
      setSessionId(sessionId);
    } else {
      setSuccess(false);
      setSessionId("");
    }
  }, [user, subscribed]);

  if (!success && message === "") {
    return <ProductDisplay user={user} />;
  } else if (success && sessionId !== "") {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
};

export default Payment;

const ProductDisplay = ({ user }) => {
  const [createCheckoutSession, { loading, error }] = useMutation(CREATE_CHECKOUT_SESSION);

  const handleCheckout = async () => {
    try {
      const { data } = await createCheckoutSession({
        variables: { userId: user.id, priceId: process.env.REACT_APP_PRICE_ID },
      });
      window.location.href = data.createCheckoutSession.url;
    } catch (e) {
      console.error("Error creating checkout session:", e.message);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  return (
    <section>
      <div className={styles.priceContainer}>
        <div>
          <Typography variant="body1">Vantage90 Seat</Typography>
          <Typography variant="caption">Starting at $15.00 / month</Typography>
        </div>
        <Button variant="contained" color="primary" onClick={handleCheckout} disabled={loading} className={styles.mainBtn}>
          Checkout
        </Button>
      </div>
    </section>
  );
};

const SuccessDisplay = ({ sessionId }) => {
  const [customerPortal, { data, loading, error }] = useMutation(CUSTOMER_PORTAL);

  const handleManageBilling = async () => {
    try {
      const { data } = await customerPortal({
        variables: { sessionId },
      });
      window.location.href = data.customerPortal;
    } catch (e) {
      console.error("Error creating customer portal session:", e.message);
    }
  };
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <section>
      <div className="product Box-root">
        <div className="description Box-root">
          <h3>Subscribed!</h3>
        </div>
      </div>
      {!_.isNil(sessionId) ? (
        <Button variant="contained" color="primary" onClick={handleManageBilling} className={styles.mainBtn}>
          Manage your billing information
        </Button>
      ) : (
        <div className={styles.mainBtn}>Session expired</div>
      )}
    </section>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

const CREATE_CHECKOUT_SESSION = gql`
  mutation CreateCheckoutSession($userId: ID!, $priceId: String!) {
    createCheckoutSession(userId: $userId, priceId: $priceId) {
      sessionId
      url
    }
  }
`;

const CUSTOMER_PORTAL = gql`
  mutation CustomerPortal($sessionId: String!) {
    customerPortal(sessionId: $sessionId)
  }
`;
