import React, { useEffect } from "react";
import _ from "lodash";
import { TextField, MenuItem, Checkbox, ListItemText, Typography } from "@material-ui/core";
import PlanPill from "../PlanPill/PlanPill";

const NewSelectRock = ({ name, rocks = [], sharedPlanId, formik, helperText, disabled = false }) => {
  const { values: formikValues, errors, touched, getFieldProps } = formik;

  const isTouched = _.get(touched, name);
  const error = _.get(errors, name);

  const hasError = Boolean(isTouched && error);
  const helperTxt = hasError ? error : helperText;

  const filteredRocks = rocks.filter(({ plan }) => (_.isNil(sharedPlanId) ? true : _.get(plan, "sharedPlanId") === sharedPlanId));

  return (
    <TextField
      select
      fullWidth
      variant="outlined"
      margin="normal"
      label={`Tied In Rock`}
      {...getFieldProps(name)}
      error={hasError}
      helperText={helperTxt}
      disabled={disabled}
    >
      {filteredRocks.map(({ id, value: rockValue, plan = null }) => {
        return (
          <MenuItem key={id} value={id}>
            <ListItemText>
              <PlanPill plan={plan} />
              {rockValue}
            </ListItemText>
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default NewSelectRock;
