import { Card, CardContent, CardHeader, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@material-ui/core";
import { mdiContentCopy, mdiDelete, mdiDotsHorizontal, mdiOpenInApp, mdiPencilBox, mdiPin, mdiPinOff } from "@mdi/js";
import Icon from "@mdi/react";
import { format, isToday } from "date-fns";
import React from "react";
import { useNavigate } from "react-router-dom";
import PlanPill from "../../components/PlanPill/PlanPill";
import styles from "./AccountabilityChartList.module.scss";

const ChartCard = ({
  id,
  label,
  name,
  plan,
  isCompanyChart,
  updatedAt,
  linkPath,
  handleDuplicate,
  handleDelete,
  handlePinAsCompanyChart,
  handleOpenDialog,
  isAdmin,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleView = (e) => {
    e.stopPropagation();
    navigate(linkPath);
  };

  const handleTogglePin = (e) => {
    e.stopPropagation();
    isCompanyChart
      ? handleOpenDialog({
          title: "UNPIN AS COMPANY",
          message: `Are you sure you want to unpin ${name} as the Company R&R Chart? The members in your organization will no longer be able to access it.`,
          callback: handlePinAsCompanyChart,
          confirmButtonText: "Unpin",
        })()
      : handleOpenDialog({
          title: "PIN AS COMPANY",
          message: `Are you sure you want to pin ${name} as the Company R&R Chart? This will replace the existing Company R&R Chart.`,
          callback: handlePinAsCompanyChart,
          confirmButtonText: "Pin",
        })();
  };

  const statusColor = label === "final" ? "#009b72" : label === "draft" ? "#2589bd" : "#ff7900";
  const dateString = getDateString(updatedAt);
  return (
    <Card variant="outlined" className={styles.listCard} onClick={handleView}>
      <CardHeader
        subheader={
          <Typography variant="button" style={{ color: statusColor, fontWeight: "bold" }}>
            {label} <PlanPill plan={plan} />
          </Typography>
        }
        action={
          <>
            <IconButton onClick={handleOpenMenu} id="card-icon">
              <Icon path={mdiDotsHorizontal} size={1} />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
              {isAdmin ? (
                [
                  <MenuItem onClick={handleView} key={1}>
                    <ListItemIcon>
                      <Icon path={mdiPencilBox} size={1} />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </MenuItem>,
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDuplicate();
                    }}
                    key={2}
                  >
                    <ListItemIcon>
                      <Icon path={mdiContentCopy} size={1} />
                    </ListItemIcon>
                    <ListItemText>Duplicate</ListItemText>
                  </MenuItem>,
                  <MenuItem key={3} onClick={handleTogglePin}>
                    <ListItemIcon>{isCompanyChart ? <Icon path={mdiPinOff} size={1} /> : <Icon path={mdiPin} size={1} />}</ListItemIcon>
                    <ListItemText>{isCompanyChart ? `Unpin` : "Pin"} as company</ListItemText>
                  </MenuItem>,
                  <MenuItem
                    key={4}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete();
                    }}
                  >
                    <ListItemIcon>
                      <Icon path={mdiDelete} size={1} />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>,
                ]
              ) : (
                <MenuItem onClick={handleView}>
                  <ListItemIcon>
                    <Icon path={mdiOpenInApp} size={1} />
                  </ListItemIcon>
                  <ListItemText>View</ListItemText>
                </MenuItem>
              )}
            </Menu>
          </>
        }
        style={{ paddingBottom: 0 }}
      />
      <CardContent style={{ paddingTop: 0 }}>
        <Typography variant="h6" style={{ fontWeight: "bold", textTransform: "uppercase" }}>
          {name}
        </Typography>
        <Typography style={{ color: "#a1a1a1" }}>Last edited {dateString}</Typography>
      </CardContent>
    </Card>
  );
};

export default ChartCard;

const getDateString = (date) => {
  const dateObj = new Date(parseInt(date));
  const dateIsToday = isToday(dateObj);
  const formattedDate = format(dateObj, "MMMM dd, yyyy");

  return dateIsToday ? "today" : `on ${formattedDate}`;
};
