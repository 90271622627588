import React, { useContext, useState } from "react";
import styles from "./Signup.module.scss";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Typography, TextField, Button } from "@material-ui/core";
import { SnackbarContext } from "../../context/snackbarContext";
import { useForm, useSearch } from "../../hooks";
import Loading from "../../components/Loading/Loading";
import Version from "../../components/Version/Version";

import Splash from "../../components/Splash/Splash";

const initForm = {
  email: null,
  firstName: null,
  lastName: null,
  password: null,
  position: null,
};

const Signup = () => {
  const navigate = useNavigate();
  const search = useSearch();
  const org = search.get("org");
  const { snack } = useContext(SnackbarContext);
  // const [errorMesasge, setErrorMessage] = useState();
  const [registerUser] = useMutation(REGISTER_USER);

  const initErrorForm = {
    firstName: ["required"],
    lastName: ["required"],
    position: ["required"],
    email: [
      {
        type: "custom",
        callback: (form, value) => !_.isNil(value) && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
        errorMessage: "Email is not valid",
      },
    ],
    password: [
      {
        type: "custom",
        callback: (form, value) => !_.isNil(value) && value.length > 8 && /\d/.test(value) && /[!@#$%^&*(),.?":{}|<>]/.test(value),
        errorMessage: "Password must be at least 8 characters long, contain at least one number and one special character",
      },
    ],
  };
  const { form, formErrors, validateForm, handleChange } = useForm({
    initForm,
    initErrorForm,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    const { email, firstName, lastName, password, position } = form;

    try {
      const { data } = await registerUser({
        variables: { email, firstName, lastName, password, position },
      });

      if (data.registerUser) {
        snack("Registration successful");
        navigate("/");
      }
    } catch (err) {
      snack(_.get(err, "message", "User signup failed"), "error");
    }
  };

  return (
    <Splash>
      <Typography variant="h2" gutterBottom>
        Sign Up
      </Typography>

      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="First Name"
          name="firstName"
          value={form.firstName || ""}
          onChange={handleChange}
          error={formErrors.firstName}
          helperText={formErrors.firstName}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Last Name"
          name="lastName"
          value={form.lastName || ""}
          onChange={handleChange}
          error={formErrors.lastName}
          helperText={formErrors.lastName}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Email Address"
          name="email"
          autoFocus
          value={form.email || ""}
          onChange={handleChange}
          error={formErrors.email}
          helperText={formErrors.email}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Position"
          name="position"
          value={form.position || ""}
          onChange={handleChange}
          error={formErrors.position}
          helperText={formErrors.position}
        />
        <TextField
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          label="Password"
          name="password"
          value={form.password || ""}
          onChange={handleChange}
          error={formErrors.password}
          helperText={formErrors.password}
        />
        {/* {errorMesasge && (
            <Typography variant="body1" className={styles.error} paragraph>
              {errorMesasge}
            </Typography>
          )} */}

        <Button type="submit" margin="normal" fullWidth variant="contained" color="primary">
          Sign up
        </Button>
      </form>
      <Version className={styles.version} />
    </Splash>
  );
};

export default Signup;

const REGISTER_USER = gql`
  mutation Register($firstName: String!, $lastName: String!, $email: String!, $position: String!, $password: String!) {
    registerUser(firstName: $firstName, lastName: $lastName, email: $email, position: $position, password: $password)
  }
`;
