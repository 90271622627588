import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import _ from "lodash";

const useThreeYearCorpPlan = ({ orgId, refetch }) => {
  const [corpForSelectedThreeYear, setCorpForSelectedThreeYear] = useState({});

  const {
    data,
    loading,
    refetch: refetchData,
  } = useQuery(GET_THREE_YEAR_CORP_PLANS, {
    variables: {
      organization: orgId,
    },
  });

  const currentThreeYearCorpPlan = _.sortBy(_.get(data, "corporateThreeYearPlans", []), (p) => parseInt(-p.targetDate))[0];

  useEffect(() => {
    // the following sets the default to the current three corporate plan
    if (data) {
      if (_.isEmpty(corpForSelectedThreeYear)) {
        setCorpForSelectedThreeYear(currentThreeYearCorpPlan);
      } else {
        // when data is updated
        const selectedPlan = _.find(_.get(data, "corporateThreeYearPlans", []), (p) => p.id === corpForSelectedThreeYear.id);
        setCorpForSelectedThreeYear(selectedPlan);
      }
    }
  }, [data]);

  useEffect(() => {
    refetchData();
  }, [refetch]);

  return {
    corpForSelectedThreeYear,
    setCorpForSelectedThreeYear,
    threeYearCorpPlans: _.get(data, "corporateThreeYearPlans", []),
    currentCorpPlan: currentThreeYearCorpPlan,
    loading,
  };
};

export default useThreeYearCorpPlan;

const GET_THREE_YEAR_CORP_PLANS = gql`
  query GetThreeYearCorpPlans($organization: ID!) {
    corporateThreeYearPlans: plans(organization: $organization, departmentName: "Corporate", category: "3 year") {
      id
      targetDate
      locked
      theme
    }
  }
`;
