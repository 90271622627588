import React, { useState, useContext } from "react";
import useForm from "../../hooks/useForm";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SelectDepartment from "../../components/SelectDepartment/SelectDepartment";
import { getCoreDisplayName } from "../../utils/misc";

const initForm = {
  plan: null,
  purpose: [],
  value: [],
  process: [],
  market: [],
  offering: [],
  valueProposition: [],
  differentiation: [],
  competency: [],
};

const AddCoreDialog = ({ id, open, handleClose, plans, name, info }) => {
  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const [coreCounter, setCoreCounter] = useState({
    [name]: 1,
  });
  const [createCore] = useMutation(CREATE_CORE);

  const { form, handleChange, resetForm } = useForm({
    initForm,
  });

  const fs = useMediaQuery("(max-width: 600px)");

  const handleDeleteEntry = (entryName, value) => () => {
    const clonedForm = _.cloneDeep(form);
    const index = clonedForm[entryName].indexOf(value);

    if (index > -1) {
      clonedForm[entryName].splice(index, 1);
    }

    resetForm(clonedForm);

    if (coreCounter[entryName] > 1) {
      setCoreCounter({ ...coreCounter, [entryName]: coreCounter[entryName] - 1 });
    }
  };

  const handleSubmit = async () => {
    try {
      const ok = await createCore({ variables: { id, core: form } });
      if (ok.data.createCore) {
        snack("Created new department core");
        requestFetch();
        resetForm();
        handleClose();
      }
    } catch (err) {
      snack("Failed to create new department core", "error");
    }
  };

  const displayName = getCoreDisplayName(name);

  return (
    <Dialog
      open={open}
      fullWidth
      fullScreen={fs}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <DialogTitle>Create New Department Core {_.startCase(displayName)}</DialogTitle>
      <DialogContent>
        <SelectDepartment
          plans={plans}
          name="plan"
          handleChange={handleChange}
          value={form.plan}
          helperText="Which department does this core belong to?"
          showAll
        />
        {Array.from(Array(coreCounter[name]), (a, i) => (
          <TextField
            key={i}
            label={`Core ${_.startCase(displayName)} #${i + 1}`}
            name={`${name}[${i}]`}
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            value={form[name][i] || ""}
            onChange={handleChange}
            helperText={i === coreCounter[name] - 1 ? _.get(info, `${name}.1`) : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleDeleteEntry(name, form.purpose[i])}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
        <IconButton onClick={() => setCoreCounter({ ...coreCounter, [name]: coreCounter[name] + 1 })}>
          <AddIcon />
        </IconButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCoreDialog;

const CREATE_CORE = gql`
  mutation ($id: ID!, $core: CoreInput) {
    createCore(id: $id, core: $core)
  }
`;
