import React, { useState } from "react";
import _ from "lodash";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import styles from "./Metrics.module.scss";
import PlanPill from "../PlanPill/PlanPill";
import Distribution from "./Distribution";

function ThreeYearMetricsList({ open, onClose, metrics, onSubmit, formMetric, selectedYear, updateMeasurables, snack }) {
  const [selectedMetricId, setSelectedMetricId] = useState(formMetric ? formMetric : null);
  const [distributionOpen, setDistributionOpen] = useState(false);
  const metricObj = _.find(metrics, (m) => m.id === selectedMetricId);
  const currentYearIndex = _.get(metricObj, "measurables", []).findIndex((year) => _.get(year, "0.value") === "Y1");

  const handleListItemClick = (metric) => () => {
    setSelectedMetricId((prev) => (metric.id === prev ? null : metric.id));
    if (metric.id !== selectedMetricId) {
      setDistributionOpen(true);
    }
  };

  const handleSubmit = () => {
    onSubmit(selectedMetricId);
  };

  const handleSubmitDistribution = async (distribution) => {
    if (_.isNil(metricObj)) {
      return;
    }
    // remove typeName
    const measurablesClone = _.cloneDeep(_.get(metricObj, ["measurables"])).map((array) =>
      array.map((obj) => ({
        value: obj.value,
        notes: obj.notes,
        reference: _.get(obj, "reference.id"),
        distribution: obj.distribution,
      }))
    );
    const newMeasurables = _.set(measurablesClone, [`${currentYearIndex}`, "1", "distribution"], distribution);
    try {
      const ok = await updateMeasurables({
        variables: {
          id: selectedMetricId,
          measurables: newMeasurables,
        },
      });

      if (ok.data.updateMetricMeasurables) {
        snack(`Updated metric distribution`);
        setDistributionOpen(false);
      }
    } catch (err) {
      snack(`Error updating metric distribution`, "error");
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>3 Year Metrics</DialogTitle>
        <DialogContent>
          <List>
            {_.isEmpty(metrics) ? (
              <Typography>No metrics available</Typography>
            ) : (
              metrics.map((metric, idx) => (
                <ListItem
                  key={metric.id}
                  button
                  selected={selectedMetricId === metric.id}
                  onClick={handleListItemClick(metric)}
                  className={styles.metricListItem}
                >
                  <ListItemText>
                    {!_.isNil(metric.plan?.departmentName) ? <PlanPill plan={metric.plan} /> : <PlanPill plan={null} />}
                    {metric.number}. {metric.value}
                  </ListItemText>
                </ListItem>
              ))
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {distributionOpen && (
        <Distribution
          open={distributionOpen}
          onClose={() => setDistributionOpen(false)}
          selectedYear={selectedYear}
          handleSubmit={handleSubmitDistribution}
          distribution={_.get(metricObj, ["measurables", currentYearIndex.toString(), "1", "distribution"])}
        />
      )}
    </>
  );
}

export default ThreeYearMetricsList;
