import React, { useEffect, useState, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import styles from "./OneYear.module.scss";
import useForm from "../../hooks/useForm";
import _ from "lodash";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { FetchContext } from "../../context/fetchContext";
import { quarterDates, formatAs, addToDate } from "../../utils/dates";
import { getQuarters } from "../../utils/misc";
import NumberFormatCustom from "../../components/NumberFormat/NumberFormat";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Switch,
  Divider,
  FormControlLabel,
  Checkbox,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  TableContainer,
  IconButton,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  DialogContentText,
} from "@material-ui/core";

import { mdiChevronDown, mdiChevronUp } from "@mdi/js";

import { useAuth } from "../../context/authContext";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
import Loading from "../../components/Loading/Loading";
import PlanPill from "../../components/PlanPill/PlanPill";
import Status from "../../components/Status/Status";
import Menu from "../../components/Menu/Menu";
import Icon from "@mdi/react";
import SuccessCriteria from "../../components/SuccessCriteria/SuccessCriteria";
import UserAvatars from "../../components/UserAvatars/UserAvatars";
import ConfirmDialog from "./ConfirmDialog";
import NotesButton from "../../components/Notes/NotesButton";

const initForm = {
  oneYearObjectiveUpdates: [],
  oneYearMetricUpdates: [],
  threeYearObjectiveUpdates: [],
  rockUpdates: [],
  quarterGrade: {
    topMiss: null,
    topHit: null,
    grade: null,
  },
  // issueUpdates: [],
  // todoUpdates: [],
};

const initErrorForm = {
  "quarterGrade.topHit": ["required"],
  "quarterGrade.topMiss": ["required"],
  "quarterGrade.grade": ["required"],
};

function getSteps() {
  return ["Confirmation", "1 Year Objectives", "1 Year Metrics", "3 Year Objectives", "Clean Up Rocks", "Grade Quarter"];
}

const CloseQuarterDialog = ({
  open,
  handleClose,
  oneYearObjectives,
  oneYearMetrics,
  plansOrder,
  plansInReview,
  quarterInReview,
  oneYearCorpPlan,
  organizationId,
  fiscalYear,
  snack,
}) => {
  const { fetch, requestFetch } = useContext(FetchContext);
  const params = useParams();
  const { auth } = useAuth();
  const { departmentFilter } = useDepartmentFilter();

  const deptFilterSpid = _.get(departmentFilter, "sharedPlanId");

  // confirm dialog states
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogFunc, setConfirmDialogFunc] = useState(() => () => {});
  const [confirmDialogMsg, setConfirmDialogMsg] = useState("");

  const [oneYearMtrcs, setOneYearMtrcs] = useState([]);
  const [threeYearObjs, setThreeYearObjs] = useState([]);
  const [oneYearObjs, setOneYearObjs] = useState([]);
  const [rocks, setRocks] = useState([]);

  // const [users, setUsers] = useState([]);
  // const [todos, setTodos] = useState([]);
  // const [issues, setIssues] = useState([]);

  const [loading, setLoading] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const spidsInReview = plansInReview.map((plan) => _.get(plan, "sharedPlanId"));

  const { form, formErrors, handleChangeManual, handleChange, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const [closeQuarter] = useMutation(CLOSE_QUARTER);

  const {
    data: threeYearObjsData,
    refetch: threeYearObjsRefetch,
    loading: threeYearObjsLoading,
  } = useQuery(GET_THREE_YEAR_OBJS, {
    variables: { organization: params.org, sharedPlanId: deptFilterSpid },
    fetchPolicy: "network-only", // Used for first execution
    nextFetchPolicy: "cache-first", // Used for subsequent executions
  });

  // const [getTodosIssues, { data: todosIssuesData, loading: todosIssuesLoading, refetch: todosIssuesRefetch }] =
  //   useLazyQuery(GET_TODOS_ISSUES);

  const updatesNameDict = {
    objective: "oneYearObjectiveUpdates",
    metric: "oneYearMetricUpdates",
    rock: "rockUpdates",
  };

  const handleCopyOver = (category) => (id, planId) => (e) => {
    const targetUpdatesName = updatesNameDict[category];
    const copyOver = e.target.checked;

    let targetUpdatesCopy = _.cloneDeep(_.get(form, targetUpdatesName));
    let existingUpdateIdx = _.findIndex(targetUpdatesCopy, { id });

    if (existingUpdateIdx > -1) {
      let existingUpdate = _.get(targetUpdatesCopy, [existingUpdateIdx.toString()]);

      if (copyOver) {
        _.set(existingUpdate, "copyOver", copyOver);

        if (category === "rock") {
          // to uncheck the complete checkbox if copy to next year is checked
          _.unset(existingUpdate, "complete");
        }
      } else {
        if (_.difference(Object.keys(existingUpdate), ["id", "copyOver"]).length > 0) {
          _.unset(existingUpdate, "copyOver");
        } else {
          targetUpdatesCopy.splice(existingUpdateIdx, 1);
        }
      }
    } else {
      targetUpdatesCopy.push({ id, planId, copyOver });
    }

    handleChangeManual({ name: targetUpdatesName, value: targetUpdatesCopy });
  };

  const handleChangeObjective = (category) => (objId, planId, status) => () => {
    const isOneYear = category === "1 year";
    const setObjFunc = isOneYear ? setOneYearObjs : setThreeYearObjs;
    setObjFunc((prev) => prev.map((obj) => (obj.id === objId ? { ...obj, status } : obj)));

    const objUpdatesName = isOneYear ? "oneYearObjectiveUpdates" : "threeYearObjectiveUpdates";
    let objUpdatesCopy = _.cloneDeep(_.get(form, objUpdatesName));
    let existingUpdate = _.find(objUpdatesCopy, { id: objId });
    if (existingUpdate) {
      _.set(existingUpdate, "status", status);
    } else {
      objUpdatesCopy.push({ id: objId, planId, status });
    }

    handleChangeManual({ name: objUpdatesName, value: objUpdatesCopy });
  };

  const handleChangeMetric = (mtrcId, planId) => (e) => {
    const actual = e.target.value;

    setOneYearMtrcs((prev) => {
      let copy = _.cloneDeep(prev);
      let metricToUpdate = _.find(copy, ["id", mtrcId]);
      _.set(metricToUpdate, ["measurables", (quarterInReview - 1).toString(), "2", "value"], actual);
      return copy;
    });

    let mtrcUpdatesCopy = _.cloneDeep(_.get(form, "oneYearMetricUpdates"));
    let existingUpdate = _.find(mtrcUpdatesCopy, { id: mtrcId });
    if (existingUpdate) {
      _.set(existingUpdate, "actual", actual);
    } else {
      mtrcUpdatesCopy.push({ id: mtrcId, planId, actual });
    }

    handleChangeManual({ name: "oneYearMetricUpdates", value: mtrcUpdatesCopy });
  };

  const handleChangeRock = (rockId, planId) => (e) => {
    const complete = e.target.checked;

    // // Required if going to be working with different status types for rocks
    // setRocks((prev) => prev.map((rock) => (rock.id === rockId ? { ...rock, status: complete ? "complete" : "on-track" } : rock)));

    let rockUpdatesCopy = _.cloneDeep(_.get(form, "rockUpdates"));
    let existingUpdateIdx = _.findIndex(rockUpdatesCopy, { id: rockId });

    if (existingUpdateIdx > -1) {
      let existingUpdate = _.get(rockUpdatesCopy, [existingUpdateIdx.toString()]);

      if (complete) {
        _.set(existingUpdate, "complete", complete);
        _.unset(existingUpdate, "copyOver"); // to uncheck copy to next year checkbox when complete is checked
      } else {
        if (_.get(existingUpdate, "copyOver")) {
          _.unset(existingUpdate, "complete");
        } else {
          rockUpdatesCopy.splice(existingUpdateIdx, 1);
        }
      }
    } else {
      rockUpdatesCopy.push({ id: rockId, planId, complete });
    }

    handleChangeManual({ name: "rockUpdates", value: rockUpdatesCopy });
  };

  const handleChangeIssueTodo = (category) => (id) => (e) => {
    const checked = e.target.checked;
    const isTodo = category === "todo";

    // // Required if going to be working with complete and incomplete todos
    // const setFunc = isTodo ? setTodos : setIssues;
    // setFunc((prev) => prev.map((item) => (item.id === id ? { ...item, done: checked } : item)));

    const updatesArrName = isTodo ? "todoUpdates" : "issueUpdates";
    let todosIssuesUpdatesCopy = _.cloneDeep(_.get(form, updatesArrName));

    const removedArr = _.remove(todosIssuesUpdatesCopy, (item) => _.get(item, "id") === id);

    // if the array of removed items is empty, that means the user has checked the todo/issue to be closed
    if (_.isEmpty(removedArr)) {
      todosIssuesUpdatesCopy.push({ id, close: checked });
    }

    handleChangeManual({ name: updatesArrName, value: todosIssuesUpdatesCopy });
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };
  const onExitConfirmDialog = () => {
    setConfirmDialogFunc(() => () => {});
    setConfirmDialogMsg("");
  };

  const handleNext = () => () => {
    // let canProceed = true;
    // Comment out the following block when want to test without having to fill out all actuals in metrics step
    if (activeStep === 2) {
      // If the current step is the 1 year metrics, can only proceed if there are no empty actual fields
      const emptyFields = _.filter(oneYearMtrcs, (mtrc) =>
        _.isEmpty(_.get(mtrc, ["measurables", (quarterInReview - 1).toString(), "2", "value"], ""))
      );
      if (emptyFields.length > 0) {
        setConfirmDialogOpen(true);
        setConfirmDialogMsg(
          `There are ${emptyFields.length} empty fields, they can also be filled later in 1 year metrics table, skip now and go to the next?`
        );
        setConfirmDialogFunc(() => () => {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          handleCloseConfirmDialog();
        });
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // if (canProceed) {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      const res = await closeQuarter({
        variables: {
          organization: organizationId,
          oneYearCorpPlan,
          plans: plansInReview.map(({ id }) => id),
          quarter: quarterInReview,
          closeQuarterData: form,
        },
      });
      if (res.data.closeQuarter) {
        setLoading(false);
        snack(`Closed quarter ${quarterInReview}`);
        requestFetch();
        handleClose();
      }
    } catch (err) {
      snack("Failed to close quarter", "error");
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 1:
        return (
          <ObjectivesList
            objectives={oneYearObjs}
            isOneYear={true}
            quarterNumber={quarterInReview}
            handleUpdateStatus={handleChangeObjective("1 year")}
            handleCopyOver={handleCopyOver("objective")}
            updatesArr={_.get(form, "oneYearObjectiveUpdates")}
          />
        );
      case 2:
        return (
          <OneYearMetricsList
            metrics={oneYearMtrcs}
            quarterNumber={quarterInReview}
            handleUpdateActual={handleChangeMetric}
            handleCopyOver={handleCopyOver("metric")}
            updatesArr={_.get(form, "oneYearMetricUpdates")}
            oneYearCorpPlan={oneYearCorpPlan}
          />
        );
      case 3:
        return (
          <ObjectivesList
            objectives={threeYearObjs}
            loading={threeYearObjsLoading}
            quarterNumber={quarterInReview}
            handleUpdateStatus={handleChangeObjective("3 year")}
          />
        );
      case 4:
        return (
          <QuarterRocksList
            rocks={rocks}
            quarterNumber={quarterInReview}
            handleUpdateStatus={handleChangeRock}
            handleCopyOver={handleCopyOver("rock")}
            updatesArr={_.get(form, "rockUpdates")}
            corpPlanId={oneYearCorpPlan}
          />
        );
      case 5:
        return <QuarterGrading gradeObj={form.quarterGrade} handleChange={handleChange} formErrors={formErrors} />;
      // case 6:
      //   return (
      //     <TodosIssuesList
      //       items={todos}
      //       loading={todosIssuesLoading}
      //       handleUpdateStatus={handleChangeIssueTodo("todo")}
      //       updatesArr={_.get(form, "todoUpdates")}
      //       rockUpdatesArr={_.get(form, "rockUpdates")}
      //     />
      //   );
      default:
        return;
    }
  };

  const sortByPlansOrder = ({ arrayToSort = [], beforeIteratees = [], afterIteratees = [] } = {}) => {
    return _.sortBy(arrayToSort, [
      ...beforeIteratees,
      function (el) {
        return plansOrder.indexOf(_.get(el, "plan.sharedPlanId"));
      },
      ...afterIteratees,
    ]);
  };

  const filterBySpid = (arrayToFilter = []) => {
    return arrayToFilter.filter((el) => spidsInReview.includes(_.get(el, "plan.sharedPlanId", "")));
  };

  useEffect(() => {
    setOneYearMtrcs(
      sortByPlansOrder({
        arrayToSort: filterBySpid(oneYearMetrics),
        afterIteratees: ["number"],
      })
    );
  }, [oneYearMetrics, plansOrder]);

  useEffect(() => {
    const filteredOneYearObjs = filterBySpid(oneYearObjectives);

    setOneYearObjs(
      sortByPlansOrder({
        arrayToSort: filteredOneYearObjs,
        afterIteratees: ["number"],
      })
    );

    const currQuarterRocks = _(filteredOneYearObjs.map((obj) => _.get(obj, "rocks", [])))
      .flatten()
      .filter((rock) => rock.index === quarterInReview && rock.status !== "complete")
      .value();

    setRocks(
      sortByPlansOrder({
        arrayToSort: currQuarterRocks,
        beforeIteratees: [
          function (rock) {
            return _.get(rock, ["users", "0", "name", "last"]);
          },
        ],
        afterIteratees: ["value"],
      })
    );

    // const currQuarterUsers = _(currQuarterRocks.map((rock) => _.get(rock, "users", [])))
    //   .flatten()
    //   .uniqBy("id")
    //   .value();

    // setUsers(currQuarterUsers.map((user) => _.get(user, "id")));
  }, [oneYearObjectives, plansOrder]);

  useEffect(() => {
    if (!_.isNil(threeYearObjsData)) {
      const filteredThreeYearObjs = filterBySpid(_.get(threeYearObjsData, "threeYearObjectives", []));
      setThreeYearObjs(sortByPlansOrder({ arrayToSort: filteredThreeYearObjs, afterIteratees: ["number"] }));
    }
  }, [threeYearObjsData, plansOrder]);

  useEffect(() => {
    threeYearObjsRefetch();
  }, [fetch]);

  // useEffect(() => {
  //   if (!_.isEmpty(users)) {
  //     if (todosIssuesData) {
  //       todosIssuesRefetch({ organization: params.org, sharedPlanId, oneYearCorpPlan, users });
  //     } else {
  //       getTodosIssues({
  //         variables: {
  //           organization: params.org,
  //           sharedPlanId,
  //           oneYearCorpPlan,
  //           users,
  //         },
  //       });
  //     }
  //   }
  // }, [users, fetch]);

  // useEffect(() => {
  //   function sortTodosIssues(arr) {
  //     return sortByPlansOrder({
  //       arrayToSort: arr,
  //       beforeIteratees: [
  //         function (item) {
  //           return _.get(item, ["user", "name", "last"]);
  //         },
  //       ],
  //       afterIteratees: ["value"],
  //     });
  //   }

  //   if (!_.isNil(todosIssuesData)) {
  //     setTodos(sortTodosIssues(filterBySpid(_.get(todosIssuesData, "todos", []))));
  //     setIssues(sortTodosIssues(filterBySpid(_.get(todosIssuesData, "issues", []))));
  //   }
  // }, [todosIssuesData, plansOrder]);

  return (
    <>
      <Dialog
        maxWidth={"md"}
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
      >
        <DialogTitle>
          <div className={styles.flexBetween}>
            <span>{steps[activeStep]}</span>
            <span>
              Close Quarter {quarterInReview} {getQuarters(fiscalYear, quarterInReview)}
              {plansInReview.map((plan, idx) => idx < 2 && <PlanPill key={idx} plan={plan} noIndent={idx > 0} />)}
              {plansInReview.length > 2 && (
                <Tooltip
                  interactive
                  title={
                    <List className={styles.tooltipList}>
                      {plansInReview.map((plan, idx) => (
                        <ListItem key={idx}>
                          <PlanPill plan={plan} noMargin />
                        </ListItem>
                      ))}
                    </List>
                  }
                >
                  <span>
                    <PlanPill noIndent noPlanVal={`${plansInReview.length - 2} MORE`} />
                  </span>
                </Tooltip>
              )}
            </span>
          </div>
        </DialogTitle>
        <DialogContent className={styles.wizardDialogContent}>
          <Stepper activeStep={activeStep} className={styles.stepper}>
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === 0 ? (
            <>
              <Typography className={styles.wizardMessage}>
                You are about to close out Q{quarterInReview} {getQuarters(fiscalYear, quarterInReview)} for the following departments:
              </Typography>
              <List className={styles.closeDialogDeptsList}>
                {plansInReview.map((plan, idx) => {
                  const { departmentName } = plan;
                  return (
                    <ListItem key={idx}>
                      <ListItemText>
                        {"\u2022"} <PlanPill plan={plan}></PlanPill>
                        {departmentName}
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </>
          ) : (
            getStepContent(activeStep)
          )}
        </DialogContent>
        <DialogActions>
          <div style={{ marginRight: "auto", display: "flex", gap: 8 }}>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={activeStep === steps.length - 1 ? handleSubmit() : handleNext()}
              disabled={loading}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </div>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={confirmDialogOpen}
        handleClose={handleCloseConfirmDialog}
        handleConfirm={confirmDialogFunc}
        message={confirmDialogMsg}
        onExited={onExitConfirmDialog}
      />
    </>
  );
};

function ObjectivesList({ objectives, isOneYear, quarterNumber, loading, handleUpdateStatus, handleCopyOver, updatesArr = [] }) {
  const updates = updatesArr.reduce((obj, item) => ((obj[item.id] = _.pick(item, ["copyOver"])), obj), {});
  const [showDescription, setShowDescription] = useState(new Array(objectives.length).fill(false));

  const handleShowDescription = (idx) => () => {
    setShowDescription((prev) => {
      let copy = [...prev];
      copy[idx] = !copy[idx];
      return copy;
    });
  };

  const isOneYearCloseOut = isOneYear && quarterNumber === 4;

  return (
    <TableContainer className={styles.wizardTableContainer}>
      {!isOneYear && loading ? (
        <Loading />
      ) : _.isEmpty(objectives) ? (
        <Typography className={styles.wizardMessage}>No objectives to show, please go to next step.</Typography>
      ) : (
        <Table stickyHeader size="small">
          <colgroup>
            <col style={{ width: "50%" }} />
            <col style={{ width: isOneYearCloseOut ? "15%" : "20%" }} />
            <col style={{ width: isOneYearCloseOut ? "15%" : "20%" }} />
            {isOneYearCloseOut && <col style={{ width: "15%" }} />}
            <col style={{ width: isOneYearCloseOut ? "5%" : "10%" }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">Accountable</TableCell>
              <TableCell align="center">Status</TableCell>
              {isOneYearCloseOut && <TableCell align="center">Copy to Next Year</TableCell>}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {objectives.map(({ id, value, description, number, users, status, statusHistory, plan }, idx) => {
              const planId = _.get(plan, "id");

              // true as long as the last 4 statusHistory elements are kept to be the current year's 4 quarters
              const modifiedStatusHistory = isOneYear ? statusHistory : _.takeRight(statusHistory, 4);

              return (
                <React.Fragment key={idx}>
                  <TableRow className={styles.rockMainRow}>
                    <TableCell style={{ maxWidth: 500 }}>
                      <PlanPill plan={plan} noIndent />
                      {number}. {value}
                    </TableCell>
                    <TableCell align="center">
                      <UserAvatars users={users} />
                    </TableCell>
                    <TableCell align="center">
                      <div className={styles.flexCenter}>
                        <Status status={status} />
                        <Menu icon="arrow">
                          {isOneYear && (
                            <MenuItem onClick={handleUpdateStatus(id, planId, "complete")} disabled={status === "complete"}>
                              Mark as complete
                            </MenuItem>
                          )}
                          <MenuItem onClick={handleUpdateStatus(id, planId, "on track")} disabled={status === "on track"}>
                            Mark as on track
                          </MenuItem>
                          <MenuItem onClick={handleUpdateStatus(id, planId, "off track")} disabled={status === "off track"}>
                            Mark as off track
                          </MenuItem>
                        </Menu>
                      </div>
                    </TableCell>
                    {isOneYearCloseOut && (
                      <TableCell align="center">
                        <Checkbox color="primary" onChange={handleCopyOver(id, planId)} checked={_.get(updates, [id, "copyOver"], false)} />
                      </TableCell>
                    )}
                    <TableCell align="right">
                      <IconButton onClick={handleShowDescription(idx)}>
                        <Icon path={showDescription[idx] ? mdiChevronUp : mdiChevronDown} size={1} color="rgba(0, 0, 0, 0.54)" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan="100%" style={{ paddingBottom: showDescription[idx] ? 6 : 0, paddingTop: 0 }}>
                      <Collapse in={showDescription[idx]} timeout="auto" unmountOnExit>
                        <Typography variant="body2">{description || "No description available"}</Typography>
                        {modifiedStatusHistory && !_.isEmpty(modifiedStatusHistory) && (
                          <>
                            <Typography variant="body2" style={{ margin: "12px 0px" }}>
                              Status History:
                            </Typography>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              {modifiedStatusHistory.map(({ period, status }, shIdx) => {
                                const isCurrentQuarter = shIdx + 1 === quarterNumber;
                                return (
                                  <div key={shIdx} className={styles.flex} style={{ gap: 3, opacity: shIdx + 1 > quarterNumber ? 0.5 : 1 }}>
                                    <span
                                      style={{
                                        color: isCurrentQuarter ? "#ffa726" : "black",
                                        fontWeight: isCurrentQuarter ? "bold" : "normal",
                                      }}
                                    >
                                      {isOneYear ? period : period.split(".")[1]}:
                                    </span>
                                    <Status status={status} />
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}

function OneYearMetricsList({ metrics, quarterNumber, handleUpdateActual, handleCopyOver, updatesArr = [], oneYearCorpPlan }) {
  const updates = updatesArr.reduce((obj, item) => ((obj[item.id] = _.pick(item, ["copyOver"])), obj), {});

  return (
    <TableContainer className={styles.wizardTableContainer}>
      {_.isEmpty(metrics) ? (
        <Typography className={styles.wizardMessage}>No metrics to show, please go to next step.</Typography>
      ) : (
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">Accountable</TableCell>
              <TableCell align="center">Q{quarterNumber} Projected Value</TableCell>
              <TableCell align="center">Q{quarterNumber} Actual Value</TableCell>
              {quarterNumber === 4 && <TableCell align="center">Copy to Next Year</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {metrics.map((metric, idx) => {
              const { id, value, number, plan, users, unit, measurables } = metric;

              const currQuarterActual = _.get(measurables, [(quarterNumber - 1).toString(), "2", "value"]);
              const currQuarterProjected = _.get(measurables, [(quarterNumber - 1).toString(), "1", "value"]);
              const planId = _.get(plan, "id");
              const formattedUnit = (unit || "").trim();

              return (
                <TableRow key={id}>
                  <TableCell style={{ maxWidth: 500 }}>
                    <div className={styles.flex}>
                      <PlanPill plan={plan} noIndent />
                      {number}. {value}
                      <NotesButton
                        id={id}
                        model="metric"
                        value={value.trim()}
                        user={users[0] ? users[0].id : null}
                        doc={metric}
                        tabs={["notes", "issues", "todos"]}
                        canEditTodo={true}
                        planId={oneYearCorpPlan}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <UserAvatars users={users} />
                  </TableCell>
                  <TableCell align="center">
                    {currQuarterProjected && (
                      <>
                        {formattedUnit === "$" && `${formattedUnit}`}
                        {parseFloat(currQuarterProjected).toLocaleString()}
                        {formattedUnit !== "$" && `${formattedUnit === "%" ? "" : " "}${formattedUnit}`}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      style={{ marginTop: 8 }}
                      name="value"
                      margin="normal"
                      variant="outlined"
                      value={currQuarterActual}
                      onChange={handleUpdateActual(id, planId)}
                      helperText={_.isEmpty(currQuarterActual) ? "Please enter actual" : ""}
                      error={_.isEmpty(currQuarterActual)}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      inputProps={{
                        prefix: formattedUnit === "$" ? "$" : "",
                        suffix: formattedUnit !== "$" ? (formattedUnit === "%" ? formattedUnit : ` ${formattedUnit}`) : "",
                      }}
                    ></TextField>
                  </TableCell>
                  {quarterNumber === 4 && (
                    <TableCell align="center">
                      <Checkbox color="primary" onChange={handleCopyOver(id, planId)} checked={_.get(updates, [id, "copyOver"], false)} />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}

function QuarterRocksList({ rocks, quarterNumber, handleUpdateStatus, handleCopyOver, updatesArr = [], corpPlanId }) {
  const [showSc, setShowSc] = useState(new Array(rocks.length).fill(false));

  const handleShowSc = (idx) => () => {
    setShowSc((prev) => {
      let copy = [...prev];
      copy[idx] = !copy[idx];
      return copy;
    });
  };

  const updates = updatesArr.reduce((obj, item) => ((obj[item.id] = _.pick(item, ["complete", "copyOver"])), obj), {});

  return (
    <TableContainer className={styles.wizardTableContainer}>
      {_.isEmpty(rocks) ? (
        <Typography className={styles.wizardMessage}>No incomplete rocks for this quarter, please go to next step.</Typography>
      ) : (
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">Accountable</TableCell>
              <TableCell align="center">Complete</TableCell>
              <TableCell align="center">Copy to Next {quarterNumber < 4 ? "Quarter" : "Year"}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rocks.map(({ id, value, plan, users, successCriterias }, idx) => {
              const planId = _.get(plan, "id");
              return (
                <React.Fragment key={id}>
                  <TableRow className={styles.rockMainRow}>
                    <TableCell style={{ maxWidth: 500 }}>
                      <PlanPill plan={plan} noIndent />
                      {value}
                    </TableCell>
                    <TableCell align="center">
                      <UserAvatars users={users} />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        color="primary"
                        checked={_.get(updates, [id, "complete"], false)}
                        onChange={handleUpdateStatus(id, planId)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip
                        title={
                          quarterNumber === 4
                            ? "Note: this will also make a copy of the objective associated with this rock in the following year"
                            : ""
                        }
                      >
                        <Checkbox color="primary" onChange={handleCopyOver(id, planId)} checked={_.get(updates, [id, "copyOver"], false)} />
                      </Tooltip>
                    </TableCell>

                    <TableCell align="right">
                      <IconButton onClick={handleShowSc(idx)}>
                        <Icon path={showSc[idx] ? mdiChevronUp : mdiChevronDown} size={1} color="rgba(0, 0, 0, 0.54)" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan="5" style={{ paddingBottom: showSc[idx] ? 6 : 0, paddingTop: 0 }}>
                      <Collapse in={showSc[idx]} timeout="auto" unmountOnExit>
                        {_.isEmpty(successCriterias) ? (
                          <Typography variant="body2" className={styles.noSc}>
                            No success criteria.
                          </Typography>
                        ) : (
                          successCriterias.map((sc) => (
                            <SuccessCriteria key={sc.id} sc={sc} previewOnly={true} corpPlanId={corpPlanId} rockId={id} />
                          ))
                        )}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}

function TodosIssuesList({ items, loading, handleUpdateStatus, updatesArr = [], rockUpdatesArr = [] }) {
  const updates = updatesArr.reduce((obj, item) => ((obj[item.id] = _.pick(item, ["close"])), obj), {});
  const rockUpdates = rockUpdatesArr.reduce((obj, item) => ((obj[item.id] = _.pick(item, ["complete"])), obj), {});

  const category = _.get(_.first(items), "category", "item");

  return (
    <TableContainer className={styles.wizardTableContainer}>
      {loading ? (
        <Loading />
      ) : _.isEmpty(items) ? (
        <Typography className={styles.wizardMessage}>No unclosed items remaining, please go to next step.</Typography>
      ) : (
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">Accountable</TableCell>
              <TableCell align="center">Close {_.upperFirst(category)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(({ id, value, plan, user, referenceId }) => {
              const toBeClosedFromRock = _.get(rockUpdates, [referenceId, "complete"], false);
              return (
                <TableRow key={id}>
                  <TableCell style={{ maxWidth: 500 }}>
                    <PlanPill plan={plan} noIndent />
                    {value}
                  </TableCell>
                  <TableCell align="center">
                    <UserAvatars users={[user]} />
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip
                      title={
                        toBeClosedFromRock
                          ? `This ${category} will automatically be closed from the completion of its associated rock in step 4`
                          : ""
                      }
                    >
                      <div>
                        <Checkbox
                          color="primary"
                          checked={_.get(updates, [id, "close"], false) || toBeClosedFromRock}
                          onChange={handleUpdateStatus(id)}
                          disabled={toBeClosedFromRock}
                        />
                      </div>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}

function QuarterGrading({ handleChange, gradeObj = {}, formErrors = {} }) {
  return (
    <div className={styles.gradeContainer}>
      <TextField
        label="Top Hit"
        name="quarterGrade.topHit"
        variant="outlined"
        value={gradeObj.topHit || ""}
        onChange={handleChange}
        margin="none"
        className={styles.gradeTextField}
        helperText={_.get(formErrors, "quarterGrade.topHit")}
        error={Boolean(_.get(formErrors, "quarterGrade.topHit"))}
      />
      <TextField
        label="Top Miss"
        name="quarterGrade.topMiss"
        variant="outlined"
        value={gradeObj.topMiss || ""}
        onChange={handleChange}
        margin="none"
        className={styles.gradeTextField}
        helperText={_.get(formErrors, "quarterGrade.topMiss")}
        error={Boolean(_.get(formErrors, "quarterGrade.topMiss"))}
      />
      <TextField
        label="Grade"
        name="quarterGrade.grade"
        variant="outlined"
        value={gradeObj.grade || ""}
        onChange={handleChange}
        margin="none"
        className={styles.gradeTextField}
        helperText={_.get(formErrors, "quarterGrade.grade")}
        error={Boolean(_.get(formErrors, "quarterGrade.grade"))}
      />
    </div>
  );
}

export default CloseQuarterDialog;

const GET_THREE_YEAR_OBJS = gql`
  query CloseQuarterDialog_GetThreeYearObjs($organization: ID!, $sharedPlanId: ID) {
    threeYearObjectives: objectives(organization: $organization, category: "3 year", sharedPlanId: $sharedPlanId) {
      id
      value
      status
      statusHistory {
        period
        status
      }
      number
      description
      users {
        id
        profilePicture
        name {
          first
          last
        }
      }
      plan {
        id
        departmentName
        color
        shortName
        sharedPlanId
      }
    }
  }
`;

// const GET_TODOS_ISSUES = gql`
//   query ($organization: ID!, $sharedPlanId: ID, $oneYearCorpPlan: ID!, $users: [ID!]) {
//     todos(
//       organization: $organization
//       category: "todo"
//       sharedPlanId: $sharedPlanId
//       oneYearCorpPlan: $oneYearCorpPlan
//       users: $users
//       done: false
//     ) {
//       id: _id
//       value
//       category
//       done
//       referenceId
//       user {
//         ...userFields
//       }
//       plan {
//         ...planFields
//       }
//     }

//     issues: todos(
//       organization: $organization
//       category: "issue"
//       sharedPlanId: $sharedPlanId
//       oneYearCorpPlan: $oneYearCorpPlan
//       users: $users
//       done: false
//     ) {
//       id: _id
//       value
//       category
//       done
//       referenceId
//       user {
//         ...userFields
//       }
//       plan {
//         ...planFields
//       }
//     }
//   }

//   fragment userFields on User {
//     id
//     profilePicture
//     name {
//       first
//       last
//     }
//   }

//   fragment planFields on Plan {
//     id
//     departmentName
//     color
//     shortName
//     sharedPlanId
//   }
// `;

const CLOSE_QUARTER = gql`
  mutation ($organization: ID!, $oneYearCorpPlan: ID!, $plans: [ID!], $quarter: Int!, $closeQuarterData: CloseQuarterData) {
    closeQuarter(
      organization: $organization
      oneYearCorpPlan: $oneYearCorpPlan
      plans: $plans
      quarter: $quarter
      closeQuarterData: $closeQuarterData
    )
  }
`;
