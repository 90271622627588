import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "typeface-montserrat";
import client from "./apolloClient";

import App from "./App";
import { AuthContextProvider } from "./context/authContext";
import { DialogContextProvider } from "./context/dialogContext";
import { FetchContextProvider } from "./context/fetchContext";
import { NotesContextProvider } from "./context/notesContext";
import { LoadingContextProvider } from "./context/loadingContext";
import { UserContextProvider } from "./context/userContext";
import { DeptFilterContextProvider } from "./context/departmentFilterContext";

render(
  <ApolloProvider client={client}>
    <AuthContextProvider>
      <UserContextProvider>
        <DeptFilterContextProvider>
          <BrowserRouter>
            <DialogContextProvider>
              <FetchContextProvider>
                <LoadingContextProvider>
                  <NotesContextProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <App />
                    </MuiPickersUtilsProvider>
                  </NotesContextProvider>
                </LoadingContextProvider>
              </FetchContextProvider>
            </DialogContextProvider>
          </BrowserRouter>
        </DeptFilterContextProvider>
      </UserContextProvider>
    </AuthContextProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
