import React, { useState, useEffect, useContext } from "react";
import styles from "./QuarterRock.module.scss";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useAuth } from "../../context/authContext";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
import { FetchContext } from "../../context/fetchContext";
import { Container, Grid, Typography } from "@material-ui/core";
import Loading from "../../components/Loading/Loading";
import Rocks from "../../components/Rocks/Rocks";
import { formatAs, getCurrentQuarter, quarterDates, addToDate } from "../../utils/dates";
import SelectCorpYear from "../../components/SelectCorpYear/SelectCorpYear";
import useCorpPlan from "../../hooks/useCorpPlan";
import { GET_QUARTER_ROCKS } from "../../utils/query";
import { isAuthed } from "../../utils/authorization";

const QuarterRocks = ({ params, org }) => {
  const { auth } = useAuth();
  const { departmentFilter } = useDepartmentFilter();
  const { fetch } = useContext(FetchContext);

  const fiscalYear = _.get(org, "fiscalYear");
  const { corpForSelectedYear, setCorpForSelectedYear, corpPlans } = useCorpPlan({ orgId: params.org, fiscalYear });

  const variables = {
    organization: params.org,
    sharedPlanId: departmentFilter.sharedPlanId,
    oneYearCorpPlan: corpForSelectedYear.id,
  };

  const { loading, data, refetch } = useQuery(GET_QUARTER_ROCKS, {
    variables,
  });

  useEffect(() => {
    refetch();
  }, [fetch]);

  if (loading) return <Loading />;
  const { rocks } = data;

  const quarter = getCurrentQuarter(fiscalYear);
  const [start, end] = quarterDates(addToDate(fiscalYear, { days: 1 }), quarter);
  const currentQuarter = { quarter, start, end };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        {currentQuarter && (
          <Grid item xs={12}>
            <Typography variant="h5">
              <span className={styles.label}>Current Quarter: </span>
              {currentQuarter.quarter} ({formatAs(currentQuarter.start, "MMM d")} - {formatAs(currentQuarter.end, "MMM d")})
            </Typography>
            <SelectCorpYear
              corporatePlans={corpPlans}
              fiscalYear={fiscalYear}
              corpForSelectedYear={corpForSelectedYear}
              setCorpForSelectedYear={setCorpForSelectedYear}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Rocks
            rocks={rocks}
            fiscalYear={fiscalYear}
            planId={corpForSelectedYear.id}
            variables={variables}
            canEdit={isAuthed(auth, "department facilitator")}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default QuarterRocks;
