import React, { useState, useEffect, useContext } from "react";
import { useAuth } from "../../context/authContext";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
import styles from "./AddEditRockDialog.module.scss";
import _ from "lodash";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";

import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";

import {
  Button,
  ButtonGroup,
  Menu as MuiMenu,
  MenuItem,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  Typography,
  DialogContentText,
  useTheme,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { mdiBrain, mdiTypewriter, mdiUndoVariant } from "@mdi/js";
import CloseIcon from "@material-ui/icons/Close";
import Icon from "@mdi/react";

import Loading from "../Loading/Loading";
import SelectQuarter from "../SelectQuarter/SelectQuarter";
import Menu from "../Menu/Menu";
import SuccessCriteriaList from "../Rock/SuccessCriteriaList";
import { ROCK_FIELDS } from "../../utils/fragments";
import { GET_QUARTER_ROCKS } from "../../utils/query";
import { recursiveMergeCustomizerWithSkipRetain, toTitleCase } from "../../utils/misc";
import ConfirmDeletionDialog from "../ConfirmDeletionDialog/ConfirmDeletionDialog";
import useConfirmDelete from "../../hooks/useConfirmDelete";
import { isAuthed } from "../../utils/authorization";
import NewSelectObjectives from "../SelectObjectives/NewSelectObjectives";
import NewSelectDepartment from "../SelectDepartment/NewSelectDepartment";
import NewSelectUsers from "../SelectUsers/NewSelectUsers";
import useLLM from "../../hooks/useLLM";
import LLMSuggestionsListDialog from "../LLMDialogs/LLMSuggestionsListDialog";
import LLMSettingsDialog from "../LLMDialogs/LLMSettingsDialog";

// const initForm = {
//   value: null,
//   index: 1,
//   users: [],
//   objective: null,
//   plan: null,
//   successCriterias: [],
// };

// const initErrorForm = {
//   value: ["required"],
//   // objective: ["required"],
// };

const SUBMIT_OPTIONS = ["Create", "Create & Add Another", "Create & Add Success Criteria"];
const CONFIRM_DEL_ITEM_TYPE = "success criteria";

const AddEditRockDialog = ({ dialog, setDialog, params, org, requestFetch, snack }) => {
  const theme = useTheme();
  const fs = useMediaQuery("(max-width: 600px)");

  const { auth } = useAuth();
  const isAdmin = isAuthed(auth, "vantage facilitator");

  const { departmentFilter } = useDepartmentFilter();
  const deptFilterSpid = _.get(departmentFilter, "sharedPlanId");

  const rockDialog = _.get(dialog, "addRockDialog", {});
  const {
    variables, // if undefined then rock is being created from QuarterRocks page otherwise it is being created from the OneYear page
    planId,
    department: departmentName, // doesn't seem to be ever set
    open,
    duplicate,
    rock,
    objective,
  } = rockDialog;

  const isCreate = _.isNil(rock);
  const hasObjectiveId = !_.isNil(objective);

  const [close, setClose] = useState(true);
  const [selectedOption, setSelectedOption] = useState(SUBMIT_OPTIONS[0]);
  const [scToBeDeleted, setScToBeDeleted] = useState([]);

  const [prevValue, setPrevValue] = useState("");

  const { confirmOpen, handleConfirmOpen } = useConfirmDelete({
    id: _.get(rock, "id"),
    value: scToBeDeleted.length,
    itemType: CONFIRM_DEL_ITEM_TYPE,
  });

  const [createRock, { loading }] = useMutation(CREATE_ROCK, {
    update(cache, { data: { createRock } }) {
      try {
        if (!_.isNil(variables)) {
          const existingData = cache.readQuery({
            query: GET_QUARTER_ROCKS,
            variables,
            // returnPartialData: true, // use cache.diff() instead of cache.readQuery() to investigate the actual error
          });
          cache.writeQuery({
            query: GET_QUARTER_ROCKS,
            variables,
            data: {
              rocks: [...existingData.rocks, createRock.newRock],
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
  });
  const [updateRock] = useMutation(UPDATE_ROCK);

  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery(GET_USERS_PLANS_AND_OBJECTIVES, {
    variables: { organization: params.org, sharedPlanId: deptFilterSpid, corpPlan: planId || null },
  });

  const [getRockSuggestions, { data: rockSuggestionsData, loading: rockSuggestionsLoading, error: rockSuggestionsError }] = useLazyQuery(
    GET_ROCK_SUGGESTIONS,
    {
      fetchPolicy: "network-only", // Ensures data is always fetched from the server
    }
  );

  const {
    anchorEl,
    llmSuggestions,
    setLlmSuggestions,
    llmSuggestionsListOpen,
    setLlmSuggestionsListOpen,
    llmSettingsOpen,
    setLlmSettingsOpen,
    canUndo,
    setCanUndo,
    abortControllerRef,
    rephraseText,
    rephraseTextData,
    rephraseTextLoading,
    llmSettingsByPurpose,
    handleOpenAdminMenu,
    handleCloseAdminMenu,
    handleCancelBrainstorm,
    handleSaveLLMSettings,
  } = useLLM({ open, snack, suggestionsLoading: rockSuggestionsLoading });

  const handleClose = (newRock) => {
    setDialog({
      ...dialog,
      addRockDialog: { open: false, quarter: 1, value: null, objective: null, duplicate: null },
      ...(!_.isNil(newRock) && selectedOption === SUBMIT_OPTIONS[2] ? { addSuccessCriteriaDialog: { open: true, rock: newRock.id } } : {}),
    });
  };

  // FORMIK START
  const schema = Yup.object().shape({
    value: Yup.string().required("Rock description is required"),
    index: Yup.number(),
    users: Yup.array().of(Yup.string().required("User is required")),
    objective: Yup.string().nullable(),
    plan: Yup.string().nullable(),
    successCriterias: Yup.array(),
    status: Yup.string().required(),
  });

  const getInitialValues = (rock, dialog) => {
    let initVals = {
      value: _.get(dialog, "value", ""),
      index: _.get(dialog, "quarter", 1),
      users: _.get(dialog, "users", []),
      objective: _.get(dialog, "objective", null),
      successCriterias: _.get(dialog, "successCriterias", []),
      plan: null,
      status: "on track",
    };

    if (!_.isNil(rock)) {
      initVals = _.mergeWith({}, initVals, rock, (objVal, srcVal, key) =>
        recursiveMergeCustomizerWithSkipRetain({ srcVal, fieldName: key, skipFields: ["successCriterias"] })
      );
    }

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues(rock, rockDialog),
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const { value, index, users, objective, plan, successCriterias, status } = values;

      try {
        let res;
        if (isCreate) {
          res = await createRock({
            variables: {
              organization: params.org,
              objective,
              value,
              index,
              users,
              plan,
              successCriteria: successCriterias.map((sc) => _.pick(sc, ["id", "value", "targetDate", "done"])),
            },
          });
        } else {
          res = await updateRock({
            variables: {
              id: rock.id,
              value,
              users,
              index,
              objective,
              plan,
              status,
              successCriteria: successCriterias.map((sc) => _.pick(sc, ["id", "value", "targetDate", "done"])),
            },
          });
        }

        if (res) {
          snack(`${isCreate ? "Created" : "Updated"} "${value}" rock`);
          if (close) {
            handleClose(_.get(res, "data.createRock.newRock"));
          } else {
            resetForm();
          }

          requestFetch();

          if (!isCreate) {
            if (confirmOpen) {
              handleConfirmOpen(false)();
            }
            setScToBeDeleted([]);
          }
        }
        setSubmitting(false);
      } catch (error) {
        snack(`Failed to ${isCreate ? "create" : "update"} "${value}" objective`, "error");
      }
    },
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit: handleSubmitFormik,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setValues,
    resetForm,
  } = formik;

  const handleSubmit = () => {
    if (!isCreate || _.at(SUBMIT_OPTIONS, ["0", "2"]).includes(selectedOption)) {
      setClose(true);
    } else {
      setClose(false);
    }

    if (_.isEmpty(scToBeDeleted)) {
      handleSubmitFormik();
    } else if (_.isEmpty(errors)) {
      handleConfirmOpen(true)();
    }
  };

  const handleSelectedOption = (option) => {
    setSelectedOption(option);
  };

  const handleScToBeDeleted = (scToDelete) => {
    setScToBeDeleted((prev) => [...prev, scToDelete]);
  };

  const handleCancel = () => {
    setScToBeDeleted([]);
    handleClose();
  };

  const handleGenerateSuggestions = (feedbackPrompts) => {
    // Abort any ongoing request before starting a new one
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the new request
    abortControllerRef.current = new AbortController();

    getRockSuggestions({
      variables: { organization: params.org, user: auth.id, objective: values.objective, feedbackPrompts },
      context: {
        fetchOptions: { signal: abortControllerRef.current.signal },
      },
    });
  };

  const handleRephraseText = () => {
    setPrevValue(_.get(values, "value", ""));
    setCanUndo(true);

    rephraseText({
      variables: { text: values.value },
    });
  };

  const handleSelectSuggestion = (suggestion = {}) => {
    setPrevValue(_.get(values, "value", ""));
    setCanUndo(true);

    const { value } = suggestion;
    setValues({ ...values, value });
    setLlmSuggestionsListOpen(false);
  };

  const handleUndo = () => {
    setValues({ ...values, value: prevValue });
    setCanUndo(false);
  };

  // const handleSubmit = async () => {
  //   if (!validateForm() && !hasObjectiveId) return;

  //   const { value, index, users, objective, duplicate, plan, successCriterias } = form;
  //   try {
  //     const ok = await createRock({
  //       variables: {
  //         organization: params.org,
  //         objective: hasObjectiveId ? open : objective,
  //         value,
  //         index,
  //         users,
  //         duplicate,
  //         plan,
  //         successCriteria: successCriterias.map((sc) => _.pick(sc, ["id", "value", "targetDate", "done"])),
  //       },
  //     });

  //     if (ok.data.createRock) {
  //       snack(`Created "${value}" rock`);
  //       resetForm({ ...initForm, index });
  //       if (selectedOption !== "Create & Add Another") {
  //         handleClose();

  //         if (selectedOption === "Create & Add Success Criteria") {
  //           setDialog({ ...dialog, addSuccessCriteriaDialog: { open: true, rock: ok.data.createRock} });
  //         }
  //       }
  //     }
  //   } catch (err) {
  //     snack("Failed to create rock", "error");
  //   }
  // };

  const handleAddIssueDialog =
    (referenceId = null, referenceModel = null, value = null, user = null) =>
    () => {
      setDialog({
        ...dialog,
        addTodoDialog: {
          open: true,
          category: "issue",
          referenceId,
          referenceModel,
          value,
          user,
          planId,
        },
      });
    };

  useEffect(() => {
    if (open) {
      refetch();
    } else {
      resetForm();
    }
  }, [open, refetch]);

  useEffect(() => {
    if (isCreate && data) {
      const objs = _.get(data, "objectives", []);
      const selectedObj = _.find(objs, ["id", values.objective]);

      setFieldValue("plan", _.get(selectedObj, "plan.id", null));
    }
  }, [values.objective, isCreate, data]);

  useEffect(() => {
    if (rockSuggestionsData) {
      setLlmSuggestions(_.get(rockSuggestionsData, "rockSuggestions", []));
    }
  }, [rockSuggestionsData]);

  useEffect(() => {
    if (rephraseTextData) {
      setFieldValue("value", _.get(rephraseTextData, "rephrase", ""));
    }
  }, [rephraseTextData]);

  const llmLoading = rockSuggestionsLoading || rephraseTextLoading;

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        fullWidth
        fullScreen={fs}
      >
        <DialogTitle>
          <div className={styles.title}>
            <div style={{ display: "flex", gap: theme.spacing(2), alignItems: "center" }}>
              {isCreate ? "Create" : "Edit"} Rock
              {isAdmin && (
                <Button color="primary" variant="outlined" onClick={(e) => handleOpenAdminMenu(e)}>
                  Settings
                </Button>
              )}
            </div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <FormikProvider value={formik}>
            <NewSelectObjectives
              name="objective"
              objectives={_.get(data, "objectives", null)}
              category="rock"
              plansOrder={_.get(data, "organization.plansOrder")}
              formik={formik}
              showEmpty={true}
              disabled={hasObjectiveId}
            />
            <TextField
              autoFocus
              label="Rock Description"
              fullWidth
              variant="outlined"
              margin="normal"
              multiline
              {...getFieldProps("value")}
              error={Boolean(touched.value && errors.value)}
              helperText={touched.value && errors.value}
              InputLabelProps={{
                shrink: !_.isEmpty(values.value),
              }}
              InputProps={{
                endAdornment: (
                  <>
                    <Tooltip title="Brainstorm">
                      <div>
                        <IconButton onClick={(e) => setLlmSuggestionsListOpen(true)} disabled={llmLoading} className={styles.llmButton}>
                          <Icon path={mdiBrain} size={1} />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title="Rephrase">
                      <div>
                        <IconButton
                          onClick={(e) => handleRephraseText()}
                          disabled={_.isEmpty(values.value) || llmLoading}
                          className={styles.llmButton}
                        >
                          {rephraseTextLoading ? <CircularProgress size={20} color="inherit" /> : <Icon path={mdiTypewriter} size={1} />}
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title="Undo">
                      <div>
                        <IconButton onClick={handleUndo} disabled={!canUndo || llmLoading} className={styles.llmButton}>
                          <Icon path={mdiUndoVariant} size={1} />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </>
                ),
              }}
            />
            <SelectQuarter
              name="index"
              value={values.index}
              handleChange={(e) => setFieldValue("index", e.target.value)}
              helperText="Which quarter is this rock for?"
            />

            {duplicate && (
              <NewSelectDepartment showAll name="plan" plans={_.get(data, "plans")} formik={formik} disabled={!_.isNil(deptFilterSpid)} />
            )}
            <NewSelectUsers
              name="users"
              multiple={true}
              users={_.get(data, "users")}
              handleResetField={() => setFieldValue("users", [])}
              helperText="Who is responsible for this rock?"
              plan={values.plan}
              allPlans={_.get(data, "plans")}
              formik={formik}
            />
            <SuccessCriteriaList
              rockToEdit={rock}
              successCriterias={values.successCriterias}
              onChange={(newScs) => setFieldValue("successCriterias", newScs)}
              handleAddIssueDialog={handleAddIssueDialog}
              handleToBeDeleted={handleScToBeDeleted}
              dialog={dialog}
              setDialog={setDialog}
              corpPlanId={planId}
            />
            {departmentName && (
              <Typography>
                <span className={styles.label}>Creating Rock from Department:</span> <b>{departmentName}</b>
              </Typography>
            )}
          </FormikProvider>
        </DialogContent>
        <DialogActions>
          {isCreate ? (
            <>
              <Button onClick={handleCancel}>Cancel</Button>
              <ButtonGroup variant="contained" color="primary">
                <Button onClick={handleSubmit}>{loading ? <Loading size={24} color="#fff" /> : selectedOption}</Button>
                <Button color="primary" size="small">
                  <Menu icon="arrow" color="#fff">
                    {SUBMIT_OPTIONS.map((option) => (
                      <MenuItem key={option} onClick={() => handleSelectedOption(option)}>
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </Button>
              </ButtonGroup>
            </>
          ) : (
            <>
              <Button
                onClick={async () => {
                  const newStatus = values.status === "complete" ? "on track" : "complete";
                  await setFieldValue("status", newStatus);
                  handleSubmit();
                }}
                color="primary"
                style={{ marginRight: "auto" }}
              >
                {loading ? <Loading size={24} color="#fff" /> : `Mark as ${values.status === "complete" ? "Incomplete" : "Complete"}`}
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button color="primary" onClick={handleSubmit} variant="contained">
                {isSubmitting || loading ? <Loading size={24} color="#fff" /> : "Save"}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <ConfirmDeletionDialog
        itemType={CONFIRM_DEL_ITEM_TYPE}
        confirmOpen={confirmOpen}
        handleConfirmOpen={handleConfirmOpen}
        handleDeletion={handleSubmitFormik}
        deleteLoading={isSubmitting}
      >
        {!_.isEmpty(scToBeDeleted) &&
          scToBeDeleted.map((sc) => {
            return <DialogContentText>&#x25cf; {sc.value}</DialogContentText>;
          })}
      </ConfirmDeletionDialog>
      <MuiMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClick={handleCloseAdminMenu}
        onClose={handleCloseAdminMenu}
      >
        <MenuItem onClick={() => setLlmSettingsOpen("brainstorm")}>Brainstorm</MenuItem>
        <MenuItem onClick={() => setLlmSettingsOpen("rephrase")}>Rephrase</MenuItem>
      </MuiMenu>
      <LLMSuggestionsListDialog
        open={llmSuggestionsListOpen}
        handleClose={() => {
          setLlmSuggestionsListOpen(false);
          handleCancelBrainstorm();
        }}
        title="Rock Suggestions"
        suggestionGroups={llmSuggestions}
        loading={rockSuggestionsLoading}
        error={rockSuggestionsError}
        handleRefresh={handleGenerateSuggestions}
        handleSelect={handleSelectSuggestion}
        snack={snack}
        org={org}
      />
      <LLMSettingsDialog
        open={!_.isEmpty(llmSettingsOpen)}
        onClose={() => setLlmSettingsOpen("")}
        title={`LLM System Settings (${_.capitalize(llmSettingsOpen)})`}
        llmSettings={_.get(llmSettingsByPurpose, llmSettingsOpen, {})}
        systemDescMoreInfo={`Describes what the model is supposed to do and how it should generally behave and respond.${
          llmSettingsOpen === "brainstorm" ? 'Use {{type}} as the placeholder for the document type, which in this case is "rock".' : ""
        }`}
        userPromptMoreInfo={
          {
            brainstorm:
              'Information about the organization in addition to its rocks and its related, todos, etc. precedes this prompt hence the use of wording such as "this information". Use {{type}} as the placeholder for the document type, which in this case is the "rock"',
            rephrase: "The current text to be rephrased follows this prompt",
          }[llmSettingsOpen]
        }
        handleSave={handleSaveLLMSettings}
      />
    </>
  );
};

export default AddEditRockDialog;

const GET_USERS_PLANS_AND_OBJECTIVES = gql`
  query AddRockDialog_GetUsersPlansObjectives($organization: ID!, $sharedPlanId: ID, $corpPlan: ID) {
    organization(id: $organization) {
      id
      plansOrder
    }

    users(organization: $organization) {
      name {
        first
        last
      }
      profilePicture
      id
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    objectives(organization: $organization, sharedPlanId: $sharedPlanId, corpPlan: $corpPlan) {
      id
      value
      category
      number
      plan {
        id
        departmentName
        color
        sharedPlanId
        shortName
      }
    }

    plans(organization: $organization, category: "1 year", oneYearCorpPlan: $corpPlan) {
      id
      departmentName
      sharedPlanId
    }
  }
`;

const CREATE_ROCK = gql`
  ${ROCK_FIELDS}
  mutation CreateQuarterRock(
    $value: String!
    $index: Int!
    $organization: ID!
    $objective: ID
    $users: [ID!]
    $plan: ID
    $successCriteria: [SuccessCriterionInput!]
  ) {
    createRock(
      value: $value
      index: $index
      organization: $organization
      objective: $objective
      users: $users
      plan: $plan
      successCriteria: $successCriteria
    ) {
      newRock {
        ...RockFields
        objective {
          id
          value
        }
      }
      updatedObjective {
        id
        rocks {
          ...RockFields
        }
      }
      updatedPlan {
        id
        rocks {
          id
        }
      }
    }
  }
`;

const UPDATE_ROCK = gql`
  mutation RocksEditDialog_UpdateRock(
    $id: ID!
    $value: String!
    $users: [ID!]
    $index: Int!
    $objective: ID
    $plan: ID
    $status: String!
    $successCriteria: [SuccessCriterionInput!]
  ) {
    updateRock(
      id: $id
      value: $value
      users: $users
      index: $index
      objective: $objective
      plan: $plan
      status: $status
      successCriteria: $successCriteria
    )
  }
`;

const GET_ROCK_SUGGESTIONS = gql`
  query ($organization: ID!, $user: ID!, $objective: ID, $feedbackPrompts: [ID!]) {
    rockSuggestions(organization: $organization, user: $user, objective: $objective, feedbackPrompts: $feedbackPrompts) {
      feedbackPrompt {
        id
        title
      }
      suggestions {
        value
      }
      systemText
      userText
    }
  }
`;
