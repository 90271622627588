import React from "react";
import _ from "lodash";
import { MenuItem, TextField } from "@material-ui/core";

const NewSelectDepartment = ({ plans = [], name, showAll, multiple, disabled = false, helperText, formik }) => {
  const { values, errors, touched, getFieldProps } = formik;

  const isTouched = _.get(touched, name);
  const error = _.get(errors, name);

  const hasError = Boolean(isTouched && error);
  const helperTxt = hasError ? error : helperText;

  return (
    <TextField
      select
      fullWidth
      label="Department"
      variant="outlined"
      margin="normal"
      {...getFieldProps(name)}
      SelectProps={{
        multiple,
      }}
      InputLabelProps={{
        shrink: !_.isEmpty(values[name]),
      }}
      error={hasError}
      helperText={helperTxt}
      disabled={disabled}
    >
      {showAll && <MenuItem value={null}>No Department</MenuItem>}
      {!_.isEmpty(plans) &&
        plans.map(({ id, departmentName }) => (
          <MenuItem key={id} value={id}>
            {departmentName}
          </MenuItem>
        ))}
    </TextField>
  );
};

export default NewSelectDepartment;
