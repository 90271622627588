import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import _ from "lodash";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, useTheme, Collapse } from "@material-ui/core";
import { useUser } from "../../context/userContext";
import NewSelectDepartment from "../SelectDepartment/NewSelectDepartment";

const AddEditFeedbackPromptDialog = ({ open, feedbackPrompt, handleSubmit, handleClose, handleDelete, user, plans, orgId }) => {
  const isCreate = _.isNil(feedbackPrompt);

  const schema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    prompt: Yup.string().required("Prompt is required"),
    access: Yup.object({
      level: Yup.string().required("Access level is required"),
      id: Yup.string().when("level", {
        is: (val) => val === "department",
        then: (schema) => schema.required("Department is required"),
        otherwise: (schema) => schema.required(),
      }),
    }),
  });

  const getInitialValues = (currVals) => {
    let initVals = {
      title: "",
      prompt: "",
      access: {
        level: "user",
        id: user.id,
      },
    };

    if (!_.isNil(currVals)) {
      const filteredCurrVals = _.pick(currVals, Object.keys(initVals));
      initVals = _.merge({}, initVals, filteredCurrVals);
    }

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues(feedbackPrompt),
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting }) => {
      handleSubmit(values);
      setSubmitting(false);
    },
    enableReinitialize: true,
  });

  const {
    values,
    dirty,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    handleSubmit: handleSubmitFormik,
    isSubmitting,
    getFieldProps,
    resetForm,
  } = formik;

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>{isCreate ? "Create" : "Edit"} Feedback Prompt</DialogTitle>
      <DialogContent>
        <FormikProvider>
          <TextField
            label="Title"
            {...getFieldProps("title")}
            fullWidth
            variant="outlined"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
          />
          <TextField
            label="Prompt"
            {...getFieldProps("prompt")}
            fullWidth
            variant="outlined"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            error={Boolean(touched.prompt && errors.prompt)}
            helperText={touched.prompt && errors.prompt}
          />
          <TextField
            select
            fullWidth
            label="Access Level"
            variant="outlined"
            margin="normal"
            {...getFieldProps("access.level")}
            onChange={(e) => {
              const accessLevel = e.target.value;
              setFieldValue("access.level", accessLevel);

              // let accessId;
              // switch (accessLevel) {
              //   case "organization":
              //     accessId = org;
              //     break;
              //   case "user":
              //     accessId = user.id;
              //     break;
              //   default:
              // }
              // setFieldValue("access.id", accessId);

              setFieldValue("access.id", accessLevel === "organization" ? orgId : accessLevel === "user" ? user.id : "");
              if (accessLevel === "department") {
                setFieldTouched("access.id", false);
              }
            }}
            error={Boolean(_.get(touched, "access.level") && _.get(errors, "access.level"))}
            helperText={_.get(touched, "access.level") && _.get(errors, "access.level")}
          >
            {["organization", "department", "user"].map((level, idx) => (
              <MenuItem key={level} value={level}>
                {_.capitalize(level)}
                {level === "user" ? " (Self)" : level === "organization" && " (Current)"}
              </MenuItem>
            ))}
          </TextField>
          <Collapse timeout="auto" in={_.get(values, "access.level") === "department"}>
            <NewSelectDepartment name="access.id" plans={plans} formik={formik} />
          </Collapse>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        {!isCreate && (
          <Button style={{ marginRight: "auto" }} onClick={handleDelete}>
            Delete
          </Button>
        )}
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmitFormik} color="primary" variant="contained" disabled={isSubmitting || !dirty}>
          {isCreate ? "Create" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditFeedbackPromptDialog;
