import React from "react";
import _ from "lodash";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import { isCompanyAdmin } from "../../utils/authorization";

const RequireOnboard = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const authenticated = !_.isNil(auth);

  if (!authenticated) {
    return <Navigate to={`/signin`} state={{ from: location }} replace />;
  }

  const companyAdmin = isCompanyAdmin(auth);

  if (!companyAdmin) {
    return <Navigate to={`/`} state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default RequireOnboard;
