import React, { useState, useContext } from "react";
import _ from "lodash";
import { DialogContext } from "../../context/dialogContext";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { mdiDelete, mdiDotsHorizontal, mdiPencilBox } from "@mdi/js";
import Icon from "@mdi/react";
import { format, isToday } from "date-fns";
import { useNavigate } from "react-router-dom";
import PlanPill from "../../components/PlanPill/PlanPill";
import styles from "./Meetings.module.scss";

const MeetingCard = ({ meeting, org, handleDelete, isAdmin, currentCorpPlan }) => {
  const { id, status, title, plan, startTime } = meeting;

  const { dialog, setDialog } = useContext(DialogContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const getDateString = (date) => {
    const dateObj = new Date(parseInt(date));
    const dateIsToday = isToday(dateObj);
    const formattedDate = format(dateObj, "MMM dd, yyyy");
    const formattedTime = format(dateObj, "h:mm a");

    return `${dateIsToday ? "today" : formattedDate} @ ${formattedTime}`;
  };

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleJoinMeeting = (e) => {
    e.stopPropagation();
    navigate(`/${org}/meeting/${id}`);
  };

  const handleEditMeeting = (e) => {
    e.stopPropagation();
    setDialog({
      ...dialog,
      addMeetingDialog: { open: true, planId: _.get(currentCorpPlan, "id"), meeting },
    });
  };

  const handleDeleteMeeting = (e) => {
    e.stopPropagation();
    handleDelete();
  };

  const statusColor = status === "finished" ? "#009b72" : status === "scheduled" ? "#2589bd" : "#ff7900";
  const dateString = getDateString(startTime);
  return (
    <Card variant="outlined" className={styles.listCard}>
      <CardHeader
        title={
          <Typography variant="button" style={{ color: statusColor, fontWeight: "bold" }}>
            {status} <PlanPill plan={plan} />
          </Typography>
        }
        subheader={
          <div style={{ marginTop: 8 }}>
            <Typography variant="h6" style={{ fontWeight: "bold", textTransform: "uppercase" }}>
              {title}
            </Typography>
            <Typography style={{ color: "#a1a1a1" }}>{dateString}</Typography>
          </div>
        }
        action={
          <>
            <IconButton onClick={handleOpenMenu} id="card-icon">
              <Icon path={mdiDotsHorizontal} size={1} />
            </IconButton>
            {isAdmin && (
              <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
                <MenuItem key={4} onClick={handleEditMeeting}>
                  <ListItemIcon>
                    <Icon path={mdiPencilBox} size={1} />
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDeleteMeeting} key={2}>
                  <ListItemIcon>
                    <Icon path={mdiDelete} size={1} />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>
              </Menu>
            )}
          </>
        }
      />
      <CardContent style={{ borderTop: "1px solid rgb(209 213 219)" }}>
        <div style={{ display: "flex", justifyContent: "flex-end", gap: 8 }}>
          <Button color="primary" variant="contained" onClick={handleJoinMeeting}>
            Join
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default MeetingCard;
