import React, { useEffect } from "react";
import _ from "lodash";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, useTheme, Tooltip } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";

const LLMSettingsDialog = ({ open, onClose, title, llmSettings = {}, systemDescMoreInfo, userPromptMoreInfo, handleSave }) => {
  const theme = useTheme();

  const schema = Yup.object().shape({
    systemDescription: Yup.string().required("System description is required"),
    userPrompt: Yup.string().required("User prompt is required"),
    // meta: Yup.object()
  });

  const getInitialValues = (currVals) => {
    let initVals = {
      systemDescription: "",
      userPrompt: "",
      // meta: {}
    };

    if (!_.isNil(currVals)) {
      const filteredCurrVals = _.pick(currVals, Object.keys(initVals));
      initVals = _.merge({}, initVals, filteredCurrVals);
    }

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues(llmSettings),
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting }) => {
      handleSave(values);
      setSubmitting(false);
    },
    enableReinitialize: true,
  });

  const { dirty, handleSubmit, isSubmitting, getFieldProps, resetForm } = formik;

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FormikProvider value={formik}>
          <TextField
            label="System Description"
            {...getFieldProps("systemDescription")}
            fullWidth
            multiline
            variant="outlined"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            helperText={
              systemDescMoreInfo && (
                <Tooltip title={systemDescMoreInfo} placement="right">
                  <span style={{ color: theme.palette.info.main }}>More info</span>
                </Tooltip>
              )
            }
          />
          <TextField
            label="User Prompt"
            {...getFieldProps("userPrompt")}
            fullWidth
            multiline
            variant="outlined"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            helperText={
              userPromptMoreInfo && (
                <Tooltip title={userPromptMoreInfo} placement="right">
                  <span style={{ color: theme.palette.info.main }}>More info</span>
                </Tooltip>
              )
            }
          />
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary" disabled={isSubmitting || !dirty}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LLMSettingsDialog;
