import React from "react";
import _ from "lodash";
import { Navigate, useLocation } from "react-router-dom";
import { isAuthed } from "../../utils/authorization";
import { useAuth } from "../../context/authContext";

const AuthRedirect = () => {
  const { auth } = useAuth();
  const location = useLocation();

  // if (_.isNil(auth)) {
  //   // If no user, redirect to sign-in
  //   return <Navigate to="/signin" state={{ from: location }} replace />;
  // }

  if (isAuthed(auth, "vantage facilitator")) {
    // If permission level is vantage facilitator or higher, navigate to organization select
    return <Navigate to={`/organizations`} state={{ from: location }} replace />;
  } else {
    // Otherwise the user will be navigated to their corresponding organization
    return <Navigate to={`/${auth.organization}`} state={{ from: location }} replace />;
  }
};

export default AuthRedirect;
