import React, { useState } from "react";
import useForm from "../../hooks/useForm";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from "@material-ui/core";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const initForm = { email: null };

const ForgotPasswordDialog = ({ open, handleClose }) => {
  const { form, handleChange } = useForm({ initForm });
  const [message, setMessage] = useState("");

  const [sendPasswordResetEmail, { loading }] = useMutation(SEND_PASSWORD_RESET_EMAIL);

  const handleSubmit = async () => {
    const { email } = form;

    try {
      await sendPasswordResetEmail({ variables: { email } });
      setMessage("If there is an account with an associated email, a password reset link will be sent shortly.");
    } catch (err) {
      setMessage("Something went wrong, please try again.");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
    >
      <DialogTitle>Forgot Password</DialogTitle>
      <DialogContent>
        <DialogContentText>To reset your password, enter your email</DialogContentText>
        <TextField
          autoFocus
          fullWidth
          margin="normal"
          label="Email"
          variant="outlined"
          name="email"
          value={form.email || ""}
          onChange={handleChange}
        />
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained" disabled={!form.email || loading}>
          Reset
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ForgotPasswordDialog;

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation SendPasswordResetEmail($email: String, $_id: ID) {
    sendPasswordResetEmail(email: $email, _id: $_id)
  }
`;
