import React, { useMemo } from "react";
import _ from "lodash";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";

const SelectTimezone = ({ label = "Timezone", name = "timezone", value, handleChange, additionalTimezones = [] }) => {
  const formattedAdditionalTimezones = useMemo(() => {
    return additionalTimezones.reduce((obj, tz) => {
      const tzLabel = _.last(tz.split("/"));
      obj[tz] = tzLabel;
      return obj;
    }, {});
  }, [additionalTimezones]);

  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle: "original",
    timezones: { ...allTimezones, ...formattedAdditionalTimezones },
  });

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel variant="outlined">{label}</InputLabel>
      <Select
        variant="outlined"
        label={label}
        name={name}
        value={value}
        onChange={(e) => handleChange({ name, value: parseTimezone(e.target.value).value })}
      >
        {options.map((option, idx) => (
          <MenuItem key={idx} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectTimezone;
