import React, { createContext, useEffect, useState, useContext } from "react";
import _ from "lodash";
import { getUser } from "../utils/graphql";
import { useAuth } from "./authContext";
import { gql, useSubscription } from "@apollo/client";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const { auth, setAuth } = useAuth();
  const [user, setUser] = useState({});
  const [subscribed, setSubscribed] = useState(false);
  const { data: subData, loading: subloading } = useSubscription(SUBSCRIPTION_SUB, {
    variables: { orgId: _.get(auth, "organization") },
  });

  const refreshUser = async (id) => {
    const userRes = await getUser(id);
    const userDoc = _.get(userRes, "data.user");
    setUser(userDoc);
  };

  useEffect(() => {
    if (!_.isNil(auth)) {
      refreshUser(auth.id);
    } else {
      setUser({});
    }
  }, [auth]);

  useEffect(() => {
    if (
      !_.isEmpty(user) &&
      (auth.isVerified !== user.isVerified || auth.organization !== user.organization || auth.isSubscribed !== subscribed)
    ) {
      setAuth((prev) => ({ ...prev, isSubscribed: subscribed, isVerified: user.isVerified, organization: user.organization }));
    }
  }, [user, subscribed]);

  useEffect(() => {
    if (!_.isNil(subData)) {
      const subscriptions = _.get(subData, "subscriptionSub.subscriptions") || [];
      const isSubscribed = !_.isEmpty(subscriptions);

      setSubscribed(isSubscribed);
    }
  }, [subData]);

  return <UserContext.Provider value={{ user, setUser, refreshUser, subscribed }}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  return useContext(UserContext);
};

const SUBSCRIPTION_SUB = gql`
  subscription ($orgId: ID) {
    subscriptionSub(orgId: $orgId) {
      orgId
      subscriptions {
        quantity
        subscription
      }
    }
  }
`;
