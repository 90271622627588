import React, { useContext, useEffect, useState } from "react";
import styles from "./OnBoard.module.scss";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { Typography, Card, CardContent, Stepper, Step, StepLabel, StepContent, Button } from "@material-ui/core";

import { useUser } from "../../context/userContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { DialogContext } from "../../context/dialogContext";
import { FetchContext } from "../../context/fetchContext";

import AddOrgDialog from "../../components/AddOrgDialog/AddOrgDialog";
import Payment from "../../components/Payment/Payment";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import { useAuth } from "../../context/authContext";
import Splash from "../../components/Splash/Splash";

const STEPS = ["Verify Email", "Create Organization", "Make Subscription"];

const OnBoard = () => {
  const { auth, handleSignout } = useAuth();
  const { user, refreshUser, subscribed } = useUser();
  const { snack } = useContext(SnackbarContext);
  const { dialog, setDialog } = useContext(DialogContext);
  const { fetch, requestFetch } = useContext(FetchContext);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(new Array(STEPS.length).fill(false));

  const navigate = useNavigate();

  const handleAddOrg = () => {
    setDialog({ ...dialog, addOrgDialog: { open: true, userId: auth.id } });
  };

  const handleNext = () => {
    if (activeStep === STEPS.length - 1) {
      navigate(`/${auth.organization}`);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    refreshUser(auth.id);
  }, [fetch]);

  useEffect(() => {
    // update fields
    let newCompleted = [...completed];
    let newStep = 0;
    if (auth.isVerified) {
      newCompleted[0] = true;
      newStep = 1;
    }

    if (auth.organization) {
      newCompleted[1] = true;
      newStep = 2;
    }

    if (subscribed === true) {
      newCompleted[2] = true;
    }

    setActiveStep(newStep);
    setCompleted(newCompleted);
  }, [user, auth]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography variant="body2">Email Verification</Typography>
            {_.isNil(auth.isVerified) ? (
              <Typography className={styles.successText}>Please check email for verification link</Typography>
            ) : (
              <Typography className={styles.successText}>Email Verified</Typography>
            )}
          </>
        );
      case 1:
        return (
          <>
            <Typography variant="body2">Enter your organization's details to get started.</Typography>
            {_.isNil(auth.organization) ? (
              <Button onClick={handleAddOrg} variant="contained" color="primary" className={styles.createBtn}>
                Add organization
              </Button>
            ) : (
              <Typography className={styles.successText}>Organization Added</Typography>
            )}
          </>
        );
      case 2:
        return (
          <>
            <Typography>Choose your subscription plan and complete the payment to access all features.</Typography>
            <Payment user={user} subscribed={subscribed} />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Splash isWide={true}>
        <div className={styles.contentWrapper}>
          <div className={styles.cardContainer}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {STEPS.map((label, index) => {
                return (
                  <Step key={label}>
                    <StepLabel icon={getStepIcon(index, completed)}>{label}</StepLabel>
                    <StepContent>
                      {getStepContent(index)}
                      <div className={styles.actionsContainer}>
                        <div>
                          <Button size="small" disabled={activeStep === 0} onClick={handleBack} className={styles.button}>
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleNext}
                            disabled={
                              (activeStep === 1 && !completed[1]) || (activeStep === STEPS.length - 1 && completed.some((v) => v === false))
                            }
                            className={styles.button}
                          >
                            {activeStep === STEPS.length - 1 ? "Finish" : "Next"}
                          </Button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </div>

        <div className={styles.flex}>
          <div className={styles.cardContainer}>
            <Card className={styles.card} onClick={handleSignout}>
              <CardContent className={styles.logoutBtn}>
                <Typography variant="h6" align="center">
                  Log out
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </Splash>

      <AddOrgDialog dialog={dialog} setDialog={setDialog} user={user} requestFetch={requestFetch} snack={snack} />
    </>
  );
};

export default OnBoard;

function getStepIcon(step, completed) {
  return completed[step] ? <CheckCircleIcon style={{ color: "green" }} fontSize="large" /> : <ErrorIcon color="error" fontSize="large" />;
}
