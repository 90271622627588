import React from "react";
import styles from "../Meetings.module.scss";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

const MeetingEndScreen = ({ org }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/${org.id}/weekly-meeting`);
  };
  return (
    <div className={styles.notificationBanner}>
      <p style={{ fontSize: 24, fontWeight: "bold" }}>The meeting has ended.</p>
      <p>
        <Button variant="outlined" color="primary" onClick={handleClick}>
          Return to Select Meeting Page
        </Button>
      </p>
    </div>
  );
};

export default MeetingEndScreen;
