import { useState, useEffect, useContext, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import _ from "lodash";
import { useQuery, useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import gql from "graphql-tag";
import { addMinutes, differenceInSeconds } from "date-fns";
import { quarterDates, addToDate, getCurrentQuarter, diffInDays } from "../../utils/dates";
import { isAuthed } from "../../utils/authorization";
import { useAuth } from "../../context/authContext";
import { DialogContext } from "../../context/dialogContext";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { MEETING_FIELDS, TODO_FIELDS, USER_FIELDS, WEEKLY_TARGET_FIELDS } from "../../utils/fragments";

const useMeeting = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { auth } = useAuth();
  const { dialog, setDialog } = useContext(DialogContext);
  const { snack } = useContext(SnackbarContext);

  // const [isPolling, setIsPolling] = useState(false);
  const [inMeeting, setInMeeting] = useState(false);
  const { data, loading } = useQuery(GET_MEETING, { variables: { id: params.meeting_id }, fetchPolicy: "network-only" });
  const { data: currentSessionSubscription, loading: subloading } = useSubscription(SUBSCRIBE_TO_CURRENT_SESSION, {
    variables: { id: params.meeting_id },
  });

  const [getUserData, { data: userData, loading: userLoading, startPolling, subscribeToMore }] = useLazyQuery(GET_DATA_FOR_USERS);
  const [updateMeeting] = useMutation(UPDATE_MEETING);
  const [updateCurrentSession] = useMutation(UPDATE_CURRENT_SESSION);
  const [exitMeeting] = useMutation(EXIT_MEETING);
  const [updateSuccessCriteria] = useMutation(UPDATE_SUCCESS_CRITERIA);
  const [completeMeeting] = useMutation(COMPLETE_MEETING);
  // const [rateMeeting] = useMutation(RATE_MEETING);
  // const [updateRockStatus] = useMutation(UPDATE_ROCK_STATUS);

  // const quarterInfo = useMemo(() => {
  //   if (_.has(userData, "organization.fiscalYear")) {
  //     const { fiscalYear } = userData.organization;

  //     const quarter = getCurrentQuarter(fiscalYear);

  //     const [, end] = quarterDates(addToDate(fiscalYear, { days: 1 }), quarter);
  //     const daysRemaining = diffInDays(end, new Date());

  //     return { quarter, daysRemaining };
  //   }

  //   return { quarter: null, daysRemaining: null };
  // }, [userData]);

  // const updateCurrentRock = async (id) => {
  //   const currentSession = {
  //     currentRock: id,
  //   };
  //   await updateCurrentSession({ variables: { id: params.meeting_id, currentSession } });
  // };

  const isAdmin = isAuthed(auth, "department admin") || auth.id === _.get(data, "meeting.owner.id");

  const isTempAdmin = auth.id === _.get(currentSessionSubscription, "meetingSub.session.temporaryAdmin");
  const handleChangeStep = (index) => async () => {
    //only meeting owner updates steps
    const nextStep = _.get(data, ["meeting", "steps", index.toString()]);
    let session = _.get(currentSessionSubscription, "meetingSub.session");
    const currentSessionCopy = _.cloneDeep(session);
    const currentCompletedStepIndex = _.get(currentSessionCopy, "completedStepIndex", null);

    // record time spent in previous step
    const prevStepIndex = _.get(currentSessionCopy, "currentStepIndex") || 0;
    const prevStepStartTIme = _.get(currentSessionCopy, `stepTime.${prevStepIndex}.stepStartTime`);
    const prevStepTimeSpentInSeconds = _.get(currentSessionCopy, `stepTime.${prevStepIndex}.timeSpentInSeconds`, 0);
    const newTimeSpentInSeconds = prevStepTimeSpentInSeconds + differenceInSeconds(new Date(), new Date(prevStepStartTIme));

    _.set(currentSessionCopy, `stepTime.${prevStepIndex}.timeSpentInSeconds`, newTimeSpentInSeconds);
    _.set(currentSessionCopy, `stepTime.${prevStepIndex}.stepStartTime`, null);
    _.set(currentSessionCopy, `stepTime.${index}.stepStartTime`, new Date());

    const currentSession = {
      ...currentSessionCopy,
      currentStepIndex: index,
      currentStepEndTime: addMinutes(new Date(), nextStep.duration),
      completedStepIndex: index >= _.get(currentSessionCopy, "completedStepIndex", 0) ? index : currentCompletedStepIndex,
    };

    await updateCurrentSession({ variables: { id: params.meeting_id, currentSession } });
  };

  const handleStepButton = (inc) => () => {
    const indexOfCurr = currentSessionSubscription.meetingSub.session.currentStepIndex;
    handleChangeStep(indexOfCurr + inc)();
  };

  // const handleAddDialog =
  //   (category, referenceModel = null, referenceId = null) =>
  //   () => {
  //     setDialog({ ...dialog, addTodoDialog: { open: true, category, referenceId, referenceModel } });
  //   };

  const handleAddScDialog = (id) => () => {
    setDialog({ ...dialog, addSuccessCriteriaDialog: { open: true, rock: id } });
  };

  const handleStart = async () => {
    let session = _.get(currentSessionSubscription, "meetingSub.session");
    session = {
      sessionStartTime: new Date(),
      currentStepIndex: 0,
      currentStepEndTime: addMinutes(new Date(), data.meeting.steps[0].duration),
      completedStepIndex: null,
      currentRock: null,
      users: [...(_.get(session, "users") || []), auth.id],
      stepTime: data.meeting.steps.map((step, idx) => {
        return { stepStartTime: idx === 0 ? new Date() : null, timeSpentInSeconds: 0 };
      }),
    };

    await updateMeeting({ variables: { id: params.meeting_id, status: "in progress" } });
    await updateCurrentSession({ variables: { id: params.meeting_id, currentSession: session } });
  };

  const handleJoinMeeting = async () => {
    let session = _.get(currentSessionSubscription, "meetingSub.session", {});
    const status = _.get(data, "meeting.status");
    // if session is killed due to server restart, revive the timer
    if (status === "in progress" && _.isNil(session.sessionStartTime) && _.isNil(_.get(data, "meeting.endTime"))) {
      session.sessionStartTime = new Date();
    }
    if (status === "in progress" && _.isNil(session.stepTime)) {
      session.stepTime = data.meeting.steps.map((step, idx) => {
        return { stepStartTime: idx === 0 ? new Date() : null, timeSpentInSeconds: 0 };
      });
    }

    const currentSessionCopy = _.cloneDeep(session);
    if ((currentSessionCopy.users || []).includes(auth.id)) {
      return;
    }
    const newUsers = [...(currentSessionCopy.users || []), auth.id];
    _.set(currentSessionCopy, "users", newUsers);
    await updateCurrentSession({ variables: { id: params.meeting_id, currentSession: currentSessionCopy } });
  };

  const handleExitMeeting = async () => {
    await exitMeeting({ variables: { id: params.meeting_id, userId: auth.id } });
  };

  const handlePassAdminControl = async (userId) => {
    let session = _.get(currentSessionSubscription, "meetingSub.session", {});

    const currentSessionCopy = _.omit(_.cloneDeep(session), ["__typename"]);

    _.set(currentSessionCopy, "temporaryAdmin", userId);
    await updateCurrentSession({ variables: { id: params.meeting_id, currentSession: currentSessionCopy } });
  };

  const handleRevokeAdminControl = async () => {
    let session = _.get(currentSessionSubscription, "meetingSub.session", {});

    const currentSessionCopy = _.omit(_.cloneDeep(session), ["__typename"]);

    _.set(currentSessionCopy, "temporaryAdmin", null);
    await updateCurrentSession({ variables: { id: params.meeting_id, currentSession: currentSessionCopy } });
  };

  useEffect(() => {
    // closing browser
    window.addEventListener("beforeunload", handleTabClosing);
    // switch url (doesn't seem to work)
    window.addEventListener("popstate", handleTabClosing);
    // lose internet connection
    window.addEventListener("offline", handleTabClosing);
    // regain internet connection
    window.addEventListener("online", handleJoinMeeting);
    return () => {
      window.removeEventListener("beforeunload", handleTabClosing);
      window.removeEventListener("popstate", handleTabClosing);
      window.removeEventListener("offline", handleTabClosing);
      window.removeEventListener("online", handleJoinMeeting);
    };
  });

  const handleTabClosing = () => {
    handleExitMeeting();
  };

  const handleFinishMeeting = async () => {
    // await rateMeeting({ variables: { id: params.meeting_id, rating } });
    const { issues, rocks, todos, weeklyTargets } = userData;
    const issueIds = (issues || []).map((todo) => todo.id),
      todoIds = (rocks || []).map((todo) => todo.id),
      rockIds = (todos || []).map((rock) => rock.id),
      weeklyTargetIds = (weeklyTargets || []).map((wt) => wt.id);

    await completeMeeting({
      variables: { id: params.meeting_id, issues: issueIds, todos: todoIds, rocks: rockIds, weeklyTargets: weeklyTargetIds },
    });
  };

  const handleUpdateSuccessCriteria = (id, done) => {
    updateSuccessCriteria({ variables: { id, done } });
  };

  const verifyUsers = (meeting, userId) => {
    return _.some(meeting.users, ["id", userId]) || meeting.owner.id === userId;
  };

  useEffect(() => {
    // Data relavent to the meeting is added
    if (data) {
      const { status, steps, users, owner } = data.meeting;

      const userIds = users.map(({ id }) => id).concat([owner.id]);

      getUserData({ variables: { organization: params.org, users: userIds } });
    }
  }, [data]);

  useEffect(() => {
    return () => {
      // switch url exit user session
      handleTabClosing();
    };
  }, []);

  useEffect(() => {
    if (data && !inMeeting && !subloading) {
      if (!verifyUsers(data.meeting, auth.id)) {
        navigate(`/${params.org}`);
        snack("You need permission to access the meeting", "info");
        return;
      }

      if (_.get(data, "meeting.status") === "scheduled" && isAdmin) {
        handleStart();
        setInMeeting(true);
      } else {
        handleJoinMeeting();
        setInMeeting(true);
      }
    }
  }, [subloading, data]);

  return {
    loading,
    userLoading,
    subloading,
    isAdmin,
    isTempAdmin,
    data,
    userData,
    currentStepIndex: _.get(currentSessionSubscription, "meetingSub.session.currentStepIndex") || 0,
    completedStepIndex: _.get(currentSessionSubscription, "meetingSub.session.completedStepIndex", null),
    currentSessionSubscription,
    snack,
    handleChangeStep,
    handleStepButton,
    handleAddScDialog,
    handleStart,
    handleUpdateSuccessCriteria,
    handleFinishMeeting,
    handleExitMeeting,
    handlePassAdminControl,
    handleRevokeAdminControl,
  };
};

export default useMeeting;

const GET_MEETING = gql`
  ${MEETING_FIELDS}
  query UseMeeting_GetMeeting($id: ID!) {
    meeting(id: $id) {
      ...MeetingFields
    }
  }
`;

const SUBSCRIBE_TO_CURRENT_SESSION = gql`
  subscription ($id: ID!) {
    meetingSub(id: $id) {
      session {
        sessionStartTime
        currentStepIndex
        currentStepEndTime
        completedStepIndex
        currentRock
        users
        temporaryAdmin
        sessionEndTime
        stepTime {
          stepStartTime
          timeSpentInSeconds
        }
      }
      meetingId
    }
  }
`;

const GET_DATA_FOR_USERS = gql`
  query UseMeeting_GetDataForUsers($organization: ID!, $users: [ID!], $plan: ID) {
    organization(id: $organization) {
      id
      fiscalYear
    }

    projects(organization: $organization, plan: $plan) {
      id
      value
      plan {
        id
        departmentName
        shortName
        color
      }
      notes {
        id
        date
      }
    }

    weeklyTargets(organization: $organization) {
      ...WeeklyTargetFields
    }

    rocks(organization: $organization, users: $users) {
      id
      value
      index
      status
      notes {
        id
        user {
          name {
            first
            last
          }
        }
        text
        url
        filename
        type
        date
      }
      objective {
        id
        value
      }
      users {
        ...UserFields
      }
      successCriterias {
        id
        value
        done
      }
      plan {
        id
        departmentName
        shortName
        color
        year
        plan {
          id
          year
        }
      }
      todos {
        id: _id
        createdAt
      }
    }

    todos(organization: $organization, users: $users, category: "todo", plan: $plan) {
      ...TodoFields
    }

    issues: todos(organization: $organization, users: $users, category: "issue", plan: $plan) {
      ...TodoFields
    }
  }
  ${WEEKLY_TARGET_FIELDS}
  ${USER_FIELDS}
  ${TODO_FIELDS}
`;

const UPDATE_MEETING = gql`
  ${MEETING_FIELDS}
  mutation UseMeeting_UpdateMeeting($id: ID!, $status: String!) {
    updateMeeting(id: $id, status: $status) {
      ...MeetingFields
    }
  }
`;

const UPDATE_CURRENT_SESSION = gql`
  mutation UseMeeting_UpdateCurrSession($id: ID!, $currentSession: InputCurrentSession!) {
    updateCurrentSession(id: $id, currentSession: $currentSession)
  }
`;

const EXIT_MEETING = gql`
  mutation UseMeeting_ExitMeeting($id: ID!, $userId: ID!) {
    exitMeeting(id: $id, userId: $userId)
  }
`;

const UPDATE_SUCCESS_CRITERIA = gql`
  mutation UseMeeting_UpdateSc($id: ID!, $done: Boolean!) {
    updateSuccessCriteria(id: $id, done: $done)
  }
`;

// const RATE_MEETING = gql`
//   mutation UseMeeting_RateMeeting($id: ID!, $rating: [Float!]) {
//     rateMeeting(id: $id, rating: $rating)
//   }
// `;

const COMPLETE_MEETING = gql`
  mutation UseMeeting_CompleteMeeting($id: ID!, $weeklyTargets: [ID!], $todos: [ID!], $issues: [ID!], $rocks: [ID!]) {
    completeMeeting(id: $id, weeklyTargets: $weeklyTargets, todos: $todos, issues: $issues, rocks: $rocks)
  }
`;

// const UPDATE_ROCK_STATUS = gql`
//   mutation UseMeeting_UpdateRockStatus($id: ID!, $status: String) {
//     updateRock(id: $id, status: $status)
//   }
// `;
