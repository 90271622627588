import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import styles from "./QuarterSummary.module.scss";
import {
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import RichText from "../RichText/RichText";
import { getContrastText, getDeptShortName, notesSectionIsNotEmpty } from "../../utils/misc";
import useForm from "../../hooks/useForm";

const StyledChip = styled(Chip)(({ pillcolor, textcolor }) => ({
  backgroundColor: pillcolor ? pillcolor : "black",
  color: textcolor ? textcolor : "white",
  "& .MuiChip-deleteIcon": {
    color: textcolor ? textcolor : "white",
  },
}));

const initErrorForm = {
  text: [
    {
      type: "custom",
      callback: (form, value) => {
        let retBoolArr = [];
        for (const section of value) {
          if (_.endsWith(section.type, "list")) {
            const listItems = _.get(section, "children", []);
            retBoolArr.push(_.some(listItems, notesSectionIsNotEmpty));
          } else {
            retBoolArr.push(notesSectionIsNotEmpty(section));
          }
        }
        return _.some(retBoolArr);
      },
      errorMessage: "This field is required",
    },
  ],
  plans: ["required"],
};

const QSNotesDialog = ({
  editMode,
  planOptions,
  notePlans = [],
  quarterIndex = 0,
  handleCreate,
  handleUpdate,
  handleChange,
  handleClose,
}) => {
  const [submit, setSubmit] = useState("");

  const { open, id, text } = editMode;

  const initForm = {
    text,
    plans: notePlans,
    quarter: quarterIndex,
  };

  const {
    form,
    formErrors,
    handleChange: handleChangeForm,
    handleChangeManual,
    validateForm,
    resetForm,
  } = useForm({
    initForm,
    initErrorForm,
  });

  const plansDict = useMemo(() => {
    return _.isEmpty(planOptions) ? {} : _.keyBy(planOptions, "id");
  }, [planOptions]);

  const handleChangeText = (value) => {
    handleChangeManual({ name: "text", value });
  };

  const handleAddPlan = (event, plans) => {
    handleChangeManual({ name: "plans", value: plans });
  };

  const handleCreateUpdate = (type) => {
    const { plans, quarter } = form;

    switch (type) {
      case "create":
        handleCreate({
          referenceIds: plans,
          model: "plan",
          additionalProps: { path: `periodData.${quarter}` },
        })();
        break;
      case "update":
        handleUpdate({
          origReferenceIds: initForm.plans,
          newReferenceIds: plans,
          model: "plan",
          origAdditionalProps: { path: `periodData.${initForm.quarter}` },
          newAdditionalProps: { path: `periodData.${quarter}` },
        })();
        break;
    }
  };

  const handleSubmit = (type) => () => {
    if (!validateForm()) return;

    setSubmit(type);

    const { text } = form;
    if (_.isEqual(text, initForm.text)) {
      handleCreateUpdate(type);
      setSubmit("");
    } else {
      handleChange({ target: { name: "text", value: text } });
    }
  };

  useEffect(() => {
    // this is triggered after the handleChange function prop is called and asynchronously modifies editMode.text
    if (submit) {
      handleCreateUpdate(submit);
      setSubmit("");
    }
  }, [text]);

  useEffect(() => {
    // this resets the form whenever the user tries to edit different notes
    if (open) {
      setSubmit("");
      resetForm({ text, plans: notePlans, quarter: quarterIndex });
    }
  }, [open]);

  return (
    <Dialog
      open={Boolean(open)}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{_.isNil(id) ? "Add" : "Edit"} Note</DialogTitle>
      <DialogContent style={{ overflowX: "hidden" }}>
        <RichText
          value={form.text}
          readOnly={false}
          onChange={handleChangeText}
          error={Boolean(formErrors.text)}
          helperText={formErrors.text}
        />
        <div style={{ display: "flex", gap: 16, marginTop: 16, width: "calc(100% - 6px)" }}>
          <Autocomplete
            multiple
            disableCloseOnSelect
            name="plans"
            onChange={handleAddPlan}
            options={Object.keys(plansDict)}
            value={form.plans}
            style={{ flex: 1 }}
            getOptionLabel={(plan) => _.get(plansDict, [plan, "departmentName"], "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Departments"
                name="plans"
                variant="outlined"
                error={Boolean(formErrors.plans)}
                helperText={formErrors.plans}
              />
            )}
            renderTags={(plans, getTagProps) =>
              plans.map((plan, index) => {
                const { departmentName, shortName, color } = _.get(plansDict, [plan], {});
                const deptShortName = getDeptShortName(departmentName, shortName);
                return (
                  <StyledChip
                    variant="outlined"
                    label={deptShortName}
                    {...getTagProps({ index })}
                    pillcolor={color}
                    textcolor={getContrastText(color)}
                  />
                );
              })
            }
          />
          <FormControl variant="outlined">
            <InputLabel>Quarter</InputLabel>
            <Select name="quarter" value={form.quarter} onChange={handleChangeForm} label="Quarter">
              {Array.from({ length: 4 }, (_, idx) => (
                <MenuItem key={idx} value={idx}>
                  {idx + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ display: "flex", gap: 16, justifyContent: "space-between", width: "100%" }}>
          <div>
            <label htmlFor="file-upload" style={{ display: "inline-block" }}>
              <Button color="primary" variant="contained" component="span">
                Upload File
              </Button>
            </label>
            <input
              name="file"
              onChange={handleChange}
              accept="image/*, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf"
              id="file-upload"
              type="file"
              className={styles.input}
            />
            <Typography variant="subtitle2" display="inline" className={styles.filename}>
              {_.get(editMode, "file.name", _.get(editMode, "filename"))}
            </Typography>
          </div>
          <div>
            {_.isNil(id) ? (
              <Button variant="contained" onClick={handleSubmit("create")} color="primary" disabled={Boolean(submit)}>
                Create
              </Button>
            ) : (
              <Button variant="contained" onClick={handleSubmit("update")} color="primary" disabled={Boolean(submit)}>
                Update
              </Button>
            )}
            <Button onClick={handleClose}>Close</Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default QSNotesDialog;
