import React, { useContext, useState, useEffect } from "react";
import styles from "./Settings.module.scss";
import _ from "lodash";
import { v4 } from "uuid";
import imageCompression from "browser-image-compression";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { Container, Grid, Typography, Hidden, TextField, Avatar, Button, MenuItem, Switch, Select } from "@material-ui/core";
import { SnackbarContext } from "../../context/snackbarContext";
import Menu from "../../components/Menu/Menu";
import AvatarEditDialog from "../../components/AvatarEditDialog/AvatarEditDialog";
import useForm from "../../hooks/useForm";
import { removeTypenameDeep } from "../../utils/misc";
import { NOTIFICATION_FREQUENCY } from "../../utils/const";
import { useUser } from "../../context/userContext";
import { deleteFileFromS3, uploadFileToS3 } from "../../utils/aws";

const initErrorForm = {
  firstName: ["required"],
  lastName: ["required"],
  email: ["required"],
};

const Settings = ({ params }) => {
  const { snack } = useContext(SnackbarContext);
  const { user, refreshUser } = useUser();

  const [avatarEditDialog, setAvatarEditDialog] = useState(null);
  const [updateUser] = useMutation(UPDATE_USER);
  const [getSignedUrlS3] = useMutation(GET_SIGNED_URL_S3);

  const { id, name, email, position, profilePicture, llmPersonalPrompt, notificationSettings } = user || {};
  const notificationsWithoutTypename = removeTypenameDeep(notificationSettings);

  const getInitForm = () => {
    return {
      firstName: _.get(name, "first", ""),
      lastName: _.get(name, "last", ""),
      email,
      position,
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      llmPersonalPrompt,
      notificationSettings: notificationsWithoutTypename,
    };
  };

  const { form, formErrors, handleChange, handleToggleCheckBox, resetForm, validateForm } = useForm({
    initForm: getInitForm(),
    initErrorForm,
  });

  const handleUpdateUser = async () => {
    if (!validateForm()) return;

    const { firstName, lastName, email, position } = form;

    try {
      const ok = await updateUser({ variables: { id, firstName, lastName, position, email } });

      if (ok.data.updateUser) {
        snack("Updated user information");
        refreshUser(id);
      }
    } catch (err) {
      snack("Failed to update user information", "error");
    }
  };

  const handleUpdateNotification = async () => {
    const { notificationSettings } = form;

    try {
      const ok = await updateUser({ variables: { id, notificationSettings } });

      if (ok.data.updateUser) {
        snack("Updated notification settings");
        refreshUser(id);
      }
    } catch (err) {
      snack("Failed to update notification settings", "error");
    }
  };

  const handleUpdateLLMPrompt = async () => {
    const { llmPersonalPrompt } = form;

    try {
      const ok = await updateUser({ variables: { id, llmPersonalPrompt } });

      if (ok.data.updateUser) {
        snack("Updated Personal Prompt");
        refreshUser(id);
      }
    } catch (err) {
      snack("Failed to update personal prompt", "error");
    }
  };

  const handleUpdatePassword = async () => {
    const { currentPassword, newPassword } = form;

    try {
      const ok = await updateUser({ variables: { id, currentPassword, newPassword } });

      if (ok.data.updateUser) {
        snack("Updated password");
        refreshUser(id);
      }
    } catch (err) {
      snack("Failed to update password", "error");
    }
  };

  const handleUploadImage = async (e) => {
    if (!_.isEmpty(e.target.files) && e.target.validity.valid) {
      const file = e.target.files[0];
      setAvatarEditDialog(file);
    }
  };

  const handleSaveImage = (ref) => async () => {
    const canvas = ref.current.getImage();
    canvas.toBlob(async (blob) => {
      const compressionOptions = { maxSizeMB: 1 };
      const compressedFile = await imageCompression(blob, compressionOptions);
      const fileType = compressedFile.type;
      const extension = _.last(fileType.split("/"));

      try {
        const profilePicUrl = await uploadFileToS3({
          snack,
          typeStr: "profile picture",
          file: compressedFile,
          fileName: `organizations/${params.org}/public/${v4()}.${extension}`,
          fileType,
          getSignedUrlAsync: async (variables) =>
            getSignedUrlS3({
              variables,
            }),
        });

        if (!profilePicUrl) return;

        const ok = await updateUser({
          variables: {
            id,
            profilePicture: profilePicUrl,
          },
        });

        if (ok.data.updateUser) {
          snack("Updated profile picture");

          if (profilePicture) await deleteImage("previous profile picture");

          refreshUser(id);
          setAvatarEditDialog(null);
        }
      } catch (err) {
        snack("Failed to update profile picture", "error");
      }
    });
  };

  const handleDeleteImage = async () => {
    try {
      const ok = await updateUser({ variables: { id, profilePicture: "" } });

      if (ok.data.updateUser) {
        snack("Deleted profile picture");
        await deleteImage();

        refreshUser(id);
      }
    } catch (err) {
      snack("Failed to delete profile picture", "error");
    }
  };

  const deleteImage = async (typeStr = "profile picture") => {
    return await deleteFileFromS3({
      snack,
      typeStr,
      objectUrl: profilePicture,
      getSignedUrlAsync: async (variables) =>
        getSignedUrlS3({
          variables,
        }),
    });
  };

  const handleAvatarEditDialog = (open) => () => {
    setAvatarEditDialog(open);
  };

  useEffect(() => {
    if (!_.isEmpty(user)) {
      resetForm(getInitForm());
    }
  }, [user]);

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Hidden xsDown>
            <Grid item xs={12} className={styles.marginBottom}>
              <Typography variant="h6" className={styles.label}>
                Settings
              </Typography>
            </Grid>
          </Hidden>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <Typography variant="h4" className={styles.label}>
                  User
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <div className={styles.avatarWrapper}>
                  <Avatar className={styles.avatar} src={profilePicture}>
                    {`${_.get(form, "firstName.0", "")}${_.get(form, "lastName.0", "")}`}
                  </Avatar>
                  <Menu className={styles.editAvatar} button="Edit">
                    <input
                      name="file"
                      onChange={handleUploadImage}
                      accept="image/*"
                      id="file-upload"
                      type="file"
                      className={styles.input}
                    />
                    <label htmlFor="file-upload">
                      <MenuItem component="span">Upload New</MenuItem>
                    </label>
                    {profilePicture && (
                      <MenuItem className={styles.delete} onClick={handleDeleteImage}>
                        Delete
                      </MenuItem>
                    )}
                  </Menu>
                </div>

                <Typography variant="h6" className={styles.label} paragraph>
                  Name
                </Typography>
                <TextField
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  name="firstName"
                  value={form.firstName || ""}
                  onChange={handleChange}
                  helperText={formErrors.firstName}
                  error={Boolean(formErrors.firstName)}
                  className={styles.textField}
                />
                <TextField
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  name="lastName"
                  value={form.lastName || ""}
                  onChange={handleChange}
                  helperText={formErrors.lastName}
                  error={Boolean(formErrors.lastName)}
                  className={styles.textField}
                />

                <Typography variant="h6" className={styles.label} paragraph>
                  Position
                </Typography>

                <TextField
                  label="Position"
                  variant="outlined"
                  fullWidth
                  name="position"
                  value={form.position || ""}
                  onChange={handleChange}
                  className={styles.textField}
                />

                <Typography variant="h6" className={styles.label} paragraph>
                  Email
                </Typography>

                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  name="email"
                  value={form.email || ""}
                  onChange={handleChange}
                  helperText={formErrors.email}
                  error={Boolean(formErrors.email)}
                  className={styles.textField}
                />

                <Button variant="contained" color="primary" onClick={handleUpdateUser} className={styles.saveButton}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <Typography variant="h4" className={styles.label}>
                  Authentication
                </Typography>
              </Grid>

              <Grid item xs={12} sm={9}>
                <Typography variant="h6" className={styles.label} paragraph>
                  Change Password
                </Typography>

                <div>
                  <TextField
                    type="password"
                    label="Current Password"
                    variant="outlined"
                    fullWidth
                    name="currentPassword"
                    value={form.currentPassword || ""}
                    onChange={handleChange}
                    className={styles.textField}
                  />
                </div>

                <TextField
                  type="password"
                  label="New Password"
                  variant="outlined"
                  fullWidth
                  name="newPassword"
                  value={form.newPassword || ""}
                  onChange={handleChange}
                  className={styles.textField}
                />
                <TextField
                  type="password"
                  label="Confirm New Password"
                  variant="outlined"
                  fullWidth
                  name="confirmNewPassword"
                  value={form.confirmNewPassword || ""}
                  onChange={handleChange}
                  helperText={form.newPassword !== form.confirmNewPassword ? "Passwords do not match" : null}
                  error={form.newPassword !== form.confirmNewPassword}
                  className={styles.textField}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdatePassword}
                  disabled={form.newPassword === "" || form.newPassword !== form.confirmNewPassword}
                  className={styles.saveButton}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <Typography variant="h4" className={styles.label}>
                  LLM
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  label="Personal Prompt"
                  variant="outlined"
                  fullWidth
                  name="llmPersonalPrompt"
                  value={form.llmPersonalPrompt || ""}
                  onChange={handleChange}
                  className={styles.textField}
                />
                <Button variant="contained" color="primary" onClick={handleUpdateLLMPrompt} className={styles.saveButton}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <Typography variant="h4" className={styles.label}>
                  Notification
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                {Object.keys(form.notificationSettings).map((setting, idx) => (
                  <div className={styles.notifications} key={idx}>
                    <Typography variant="subtitle1">{_.startCase(setting)}</Typography>
                    {_.get(form, `notificationSettings.${setting}.frequency`, "N/A") !== "N/A" && (
                      <Select
                        value={_.get(form, `notificationSettings.${setting}.frequency`, "N/A")}
                        onChange={handleChange}
                        name={`notificationSettings.${setting}.frequency`}
                        label={"Frequency"}
                      >
                        {NOTIFICATION_FREQUENCY.map((value) => {
                          return (
                            <MenuItem key={value} value={value}>
                              {_.startCase(value)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                    <Switch
                      checked={_.get(form, `notificationSettings.${setting}.enabled`, false)}
                      onChange={handleToggleCheckBox}
                      name={`notificationSettings.${setting}.enabled`}
                    />
                  </div>
                ))}

                <Button variant="contained" color="primary" onClick={handleUpdateNotification} className={styles.saveButton}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <AvatarEditDialog
        open={Boolean(avatarEditDialog)}
        handleClose={handleAvatarEditDialog(false)}
        handleSave={handleSaveImage}
        src={avatarEditDialog}
      />
    </>
  );
};

export default Settings;

const UPDATE_USER = gql`
  mutation (
    $id: ID!
    $firstName: String
    $lastName: String
    $position: String
    $email: String
    $currentPassword: String
    $newPassword: String
    $profilePicture: String
    $llmPersonalPrompt: String
    $notificationSettings: NotificationsInput
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      position: $position
      email: $email
      currentPassword: $currentPassword
      newPassword: $newPassword
      profilePicture: $profilePicture
      llmPersonalPrompt: $llmPersonalPrompt
      notificationSettings: $notificationSettings
    )
  }
`;

const GET_SIGNED_URL_S3 = gql`
  mutation ($fileName: String!, $fileType: String, $operation: String!) {
    generateSignedUrlS3(fileName: $fileName, fileType: $fileType, operation: $operation)
  }
`;
