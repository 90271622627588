import React, { useEffect, useRef, useState } from "react";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import _ from "lodash";
import gql from "graphql-tag";

import { Paper, TextField, Button, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import { mdiContentCopy, mdiMessageText, mdiPaperclip, mdiRobot, mdiVolumeHigh } from "@mdi/js";
import Icon from "@mdi/react";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  floatingButton: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  chatWidgetContainer: {
    position: "fixed",
    bottom: theme.spacing(10),
    right: theme.spacing(2),
    width: 400,
    height: 500,
    display: "flex",
    flexDirection: "column",
    zIndex: 1000,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    overflow: "hidden",
  },
  chatHeader: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    padding: theme.spacing(1),
    textAlign: "center",
  },
  chatContent: {
    flexGrow: 1,
    overflowY: "auto",
    padding: theme.spacing(1),
    backgroundColor: "#f9f9f9",
  },
  chatInput: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    borderTop: "1px solid #ddd",
  },
  sendButton: {
    marginLeft: theme.spacing(1),
  },
  presetMessages: {
    display: "flex",
    justifyContent: "start",
    padding: theme.spacing(1),
    gap: 10,
  },
}));

const ChatComponent = ({ userId }) => {
  const classes = useStyles();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const chatEndRef = useRef(null);

  const [sendMessage] = useMutation(SEND_MESSAGE);

  const { loading, error, data } = useQuery(GET_MESSAGES, {
    variables: { userId },
  });

  useSubscription(ON_MESSAGE_ADDED, {
    variables: { userId },
    onData: ({ data }) => {
      const { content, sender, createdAt } = data?.data?.messageAdded || {};
      if (sender && content) {
        const newMessage = {
          position: sender === "ai" ? "left" : "right",
          type: "text",
          text: content,
          date: new Date(Number(createdAt)),
        };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
    },
  });

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNewUserMessage = (newMessage) => {
    sendMessage({ variables: { content: newMessage, userId } })
      .then(({ data }) => {
        console.log("Message sent:", data.sendMessage);
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });

    setInput("");
  };

  const handleFileAttachment = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileMessage = {
        position: "right",
        type: "file",
        text: file.name,
        date: new Date(),
        data: { file },
      };
      setMessages((prevMessages) => [...prevMessages, fileMessage]);
    }
  };

  const handleTextToSpeech = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(utterance);
  };

  const handleCopyResponse = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, isOpen]);

  useEffect(() => {
    if (!loading && data) {
      const loadedMessages = data.messages
        .map((message) => ({
          position: message.sender === "user" ? "right" : "left",
          type: "text",
          text: message.content,
          date: new Date(Number(message.createdAt)),
        }))
        .reverse();
      setMessages(loadedMessages);

      if (_.isEmpty(data.messages)) {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            position: "left",
            type: "text",
            text: "Welcome to this Awesome chat!",
            date: new Date(),
          },
        ]);
      }
    }

    if (error) {
      console.error("Error fetching messages:", error);
    }
  }, [loading, data, error]);

  return (
    <>
      <Fab className={classes.floatingButton} onClick={() => setIsOpen(!isOpen)}>
        <Icon path={mdiMessageText} size={1.5} />
      </Fab>

      {isOpen && (
        <Paper className={classes.chatWidgetContainer} elevation={3}>
          <div className={classes.chatHeader}>
            <Typography variant="h6">AI Chat Assistant</Typography>
          </div>

          <div className={classes.chatContent}>
            {messages.map((message, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: message.position === "right" ? "flex-end" : "flex-start",
                  marginBottom: "10px",
                  maxWidth: "100%",
                }}
              >
                {message.position === "left" && (
                  <Avatar style={{ marginRight: "10px" }}>
                    <Icon path={mdiRobot} size={1} />
                  </Avatar>
                )}
                <Paper
                  style={{
                    padding: "10px",
                    position: "relative",
                    maxWidth: "80%",
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                  }}
                >
                  <Typography variant="body1">{message.text}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    {message.date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
                  </Typography>

                  {message.position === "left" && (
                    <div style={{ position: "relative" }}>
                      <IconButton onClick={() => handleTextToSpeech(message.text)} size="small">
                        <Icon path={mdiVolumeHigh} size={0.5} />
                      </IconButton>
                      <IconButton onClick={() => handleCopyResponse(message.text)} size="small">
                        <Icon path={mdiContentCopy} size={0.5} />
                      </IconButton>
                    </div>
                  )}
                </Paper>
              </div>
            ))}
            <div ref={chatEndRef} />
          </div>

          <div className={classes.chatInput}>
            <IconButton component="label">
              <input type="file" hidden onChange={handleFileAttachment} />
              <Icon path={mdiPaperclip} size={1} />
            </IconButton>

            <TextField
              placeholder="Type a message..."
              fullWidth
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && input.trim()) {
                  handleNewUserMessage(input);
                  e.preventDefault();
                }
              }}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.sendButton}
              onClick={() => handleNewUserMessage(input)}
              disabled={!input.trim()}
            >
              Send
            </Button>
          </div>

          <div className={classes.presetMessages}>
            <Button variant="outlined" size="small" onClick={() => handleNewUserMessage("Hello, how can you help me?")}>
              Greeting
            </Button>
            <Button variant="outlined" size="small" onClick={() => handleNewUserMessage("Tell me more about your services.")}>
              Services
            </Button>
            <Button variant="outlined" size="small" onClick={() => handleNewUserMessage("What is your pricing?")}>
              Pricing
            </Button>
          </div>
        </Paper>
      )}
    </>
  );
};

export default ChatComponent;

const ON_MESSAGE_ADDED = gql`
  subscription OnMessageAdded($userId: ID!) {
    messageAdded(userId: $userId) {
      id
      content
      sender
      createdAt
    }
  }
`;

const SEND_MESSAGE = gql`
  mutation SendMessage($userId: ID!, $content: String!) {
    sendMessage(userId: $userId, content: $content) {
      id
      content
      sender
      createdAt
    }
  }
`;

const GET_MESSAGES = gql`
  query GetMessages($userId: ID!) {
    messages(userId: $userId) {
      id
      content
      sender
      createdAt
    }
  }
`;
