import React, { useState, useEffect } from "react";
import _ from "lodash";
import styles from "./Announcement.module.scss";
import { format } from "date-fns";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, Card } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiAlertDecagram, mdiCheckboxMarked, mdiRhombus } from "@mdi/js";

const Announcement = ({ open, handleClose, data, user }) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [carousel, setCarousel] = useState([]);
  const [carouselIndex, setCarouselIndex] = useState(0);

  useEffect(() => {
    setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
  }, []);

  useEffect(() => {
    if (_.get(data, "organization.core")) {
      let carouselData = [];

      _.forEach(data.organization.core, (value, key) => {
        if (_.isArray(value)) {
          carouselData = carouselData.concat(value.map((val) => `Core ${_.startCase(key)}: ${val}`));
        }
      });

      setCarousel(carouselData);

      setInterval(() => {
        setCarouselIndex((prev) => (prev + 1) % carouselData.length);
      }, 3000);
    }
  }, [data]);

  const { name } = user;
  const rockNum = _.get(data, "rocks", []).length;
  const todoNum = _.get(data, "todos", []).length;
  const issueNum = _.get(data, "issues", []).length;

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        Welcome back {name.first} {name.last[0]}
      </DialogTitle>
      <DialogContent className={styles.text}>
        <Typography align="center">{format(currentTime, "EEEE, MMMM do yyyy")}</Typography>
        <Typography align="center" variant="h4" paragraph>
          {format(currentTime, "h:mm a")}
        </Typography>

        <div className={styles.carousel}>
          <div className={styles.wrapper} style={{ marginTop: -90 * carouselIndex }}>
            {carousel.map((val) => {
              return (
                <span key={val}>
                  <Typography align="center" variant="h5">
                    {val}
                  </Typography>
                </span>
              );
            })}
          </div>
        </div>

        <div className={styles.cards}>
          <Card className={styles.cardPurple} raised>
            <Icon path={mdiCheckboxMarked} size={5} color="#fff" className={styles.cardIcon} />
            <Typography variant="h2">{todoNum}</Typography>
            <Typography variant="h4">Todo{todoNum === 1 ? "" : "s"}</Typography>
          </Card>
          <Card className={styles.cardRed} raised>
            <Icon path={mdiAlertDecagram} size={5} color="#fff" className={styles.cardIcon} />
            <Typography variant="h2">{issueNum}</Typography>
            <Typography variant="h4">Issue{issueNum === 1 ? "" : "s"}</Typography>
          </Card>
          <Card className={styles.cardBlue} raised>
            <Icon path={mdiRhombus} size={5} color="#fff" className={styles.cardIcon} />
            <Typography variant="h2">{rockNum}</Typography>
            <Typography variant="h4">Rock{rockNum === 1 ? "" : "s"}</Typography>
          </Card>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Announcement;
