import React, { useState, useContext, useEffect } from "react";
import styles from "./Users.module.scss";
import _ from "lodash";
import { v4 } from "uuid";
import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Card,
  CardContent,
  CardActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Hidden,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import CardTitle from "../../components/CardTitle/CardTitle";
import { useForm } from "../../hooks";
import Loading from "../../components/Loading/Loading";
import User from "./User";
import imageCompression from "browser-image-compression";
import EditDialog from "./EditDialog";
import useTableFilters from "../../hooks/useTableFilters";
import { useAuth } from "../../context/authContext";
import { deleteFileFromS3, uploadFileToS3 } from "../../utils/aws";

const initForm = {
  firstName: null,
  lastName: null,
  email: null,
  position: null,
  plan: [],
  notificationSettings: {
    weeklyReminder: {
      frequency: "N/A",
      enabled: true,
    },
    meetingReminder: {
      frequency: "N/A",
      enabled: true,
    },
  },
  auth: "department employee",
  profilePicture: null,
};

const initErrorForm = {
  firstName: ["required"],
  lastName: ["required"],
  email: ["required"],
  position: ["required"],
};

const Users = ({ params, org }) => {
  // const url = `${process.env.REACT_APP_URL}/signup?org=${params.org}`;
  const { fetch: fetchCtx, requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const { auth } = useAuth();
  // dialog
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  // pagination
  const { page, rowsPerPage, searchTerm, debouncedSearchTerm, handleChangePage, handleRowsPerPage, setSearchTerm } = useTableFilters({
    initialValue: {},
  });
  const [getUsers, { data, loading, refetch }] = useLazyQuery(GET_USERS_AND_DEPARTMENTS);
  const [createUser] = useMutation(CREATE_USER);
  const [updateUser] = useMutation(UPDATE_USER);
  const [deleteUser] = useMutation(DELETE_USER);

  const [getSignedUrlS3] = useMutation(GET_SIGNED_URL_S3);

  const [getUpcomingInvoice, { data: upcomingInvoiceData, loading: invoiceDataLoading }] = useLazyQuery(GET_UPCOMING_INVOICE, {
    fetchPolicy: "network-only",
  });

  const { form, formErrors, handleChange, resetForm, validateForm, handleToggleCheckBox } = useForm({
    initForm,
    initErrorForm,
  });

  const handleDialog =
    (open, user = null) =>
    () => {
      setSelectedUser(user);
      setOpen(open);
    };

  // const handleClick = () => {
  //   const str = document.getElementById("inputid");

  //   str.select();
  //   str.setSelectionRange(0, 99999); // For mobile

  //   document.execCommand("copy");
  // };

  const uploadImage = async (image) => {
    const fileType = image.type;
    const extension = _.last(fileType.split("/"));

    return await uploadFileToS3({
      snack,
      typeStr: "profile picture",
      file: image,
      fileName: `organizations/${params.org}/public/${v4()}.${extension}`,
      fileType,
      getSignedUrlAsync: async (variables) =>
        getSignedUrlS3({
          variables,
        }),
    });
  };

  const handleCreateSubmit = async (e) => {
    if (!validateForm()) return;
    e.preventDefault();

    const { email, firstName, lastName, position, plan, notificationSettings, profilePicture, auth } = form;

    try {
      let profilePictureUrl = profilePicture,
        proceedCreate = true;
      if (profilePicture) {
        profilePictureUrl = await uploadImage(profilePicture);
        proceedCreate = !_.isEmpty(profilePictureUrl);
      }

      if (!proceedCreate) return;

      const { data } = await createUser({
        variables: {
          organization: params.org,
          email,
          firstName,
          lastName,
          position,
          plan,
          notificationSettings,
          ...(profilePictureUrl ? { profilePicture: profilePictureUrl } : {}),
          auth,
        },
      });

      if (data.createUser) {
        snack(`Created user: ${firstName} ${lastName}`);
        resetForm();
        requestFetch();
        setOpen(false);
      }
    } catch (err) {
      snack("Failed to create user", "error");
    }
  };

  const handleEditSubmit = async (e) => {
    if (!validateForm()) return;
    e.preventDefault();

    const { firstName, lastName, position, email, plan, id, notificationSettings, profilePicture, auth } = form;

    let profilePictureUrl = profilePicture,
      proceedUpdate = true,
      uploadedProfilePic = false;
    if (profilePicture instanceof Blob) {
      // if the profilePicture is found to be Blob then user has updated it and needs to be uploaded
      profilePictureUrl = await uploadImage(profilePicture);
      proceedUpdate = !_.isEmpty(profilePictureUrl);
      uploadedProfilePic = true;
    }

    if (!proceedUpdate) return;

    try {
      const ok = await updateUser({
        variables: {
          id,
          firstName,
          lastName,
          position,
          email,
          plan,
          notificationSettings,
          auth,
          ...(profilePictureUrl ? { profilePicture: profilePictureUrl } : {}),
        },
      });

      if (ok.data.updateUser) {
        snack(`Updated user: ${firstName} ${lastName}`);

        // Delete the previous profile picture if it exists
        const prevProfilePicture = _.get(selectedUser, "profilePicture");
        if (uploadedProfilePic && prevProfilePicture) await deleteImage(prevProfilePicture, "previous profile picture");

        requestFetch();
        setOpen(false);
      }
    } catch (err) {
      snack("Failed to update user", "error");
    }
  };

  // const handleChangeAuth = (user) => async () => {
  //   const { id, name, auth } = user;
  //   const ok = await updateUser({ variables: { id, auth } });

  //   if (ok) {
  //     snack(`Changed ${name.first} ${name.last}'s authorization level to ${auth}`);
  //     requestFetch();
  //   }
  // };

  const deleteImage = async (profilePicture, typeStr = "profile picture") => {
    return await deleteFileFromS3({
      snack,
      typeStr,
      objectUrl: profilePicture,
      getSignedUrlAsync: async (variables) =>
        getSignedUrlS3({
          variables,
        }),
    });
  };

  // removing existing user image
  const handleDeleteImage = async (id, profilePicture) => {
    if (profilePicture) {
      try {
        const ok = await updateUser({
          variables: { id, profilePicture: "" },
        });

        if (ok.data.updateUser) {
          snack("Profile picture deleted");
          await deleteImage(profilePicture);

          requestFetch();
        }
      } catch (err) {
        snack("Failed to delete profile picture", "error");
      }
    } else {
      snack("Profile image already removed");
    }
  };

  const handleSelectPicture = async (e, profilePicture) => {
    let ret = {};
    if (!_.isEmpty(_.get(e, "target.files")) && _.get(e, "target.validity.valid")) {
      const file = e.target.files[0];

      const compressionOptions = { maxSizeMB: 1 };
      const compressedFile = await imageCompression(file, compressionOptions);

      ret = { url: URL.createObjectURL(compressedFile), file: compressedFile };
    }
    return ret;
  };

  const handleCreateUserCancel = (form) => {
    setSelectedUser(null);
    setOpen(false);
  };

  const handleDelete = async (user) => {
    const { id, name, profilePicture } = user;

    try {
      const ok = await deleteUser({ variables: { id } });

      if (ok) {
        snack(`Deleted user: ${name.first} ${name.last}`);
        await deleteImage(profilePicture);

        requestFetch();
        setDeleteDialogOpen(false);
        setSelectedUser(null);
      }
    } catch (err) {
      snack("Failed to delete user", "error");
    }
  };

  useEffect(() => {
    getUsers({ variables: { organization: params.org, offset: page, size: rowsPerPage, search: debouncedSearchTerm } });
  }, [page, rowsPerPage, debouncedSearchTerm]);

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [fetchCtx]);

  useEffect(() => {
    if (open) {
      getUpcomingInvoice({ variables: { payingUserId: auth.id, quantityChange: 1 } });
    } else if (deleteDialogOpen) {
      getUpcomingInvoice({ variables: { payingUserId: auth.id, quantityChange: -1 } });
    }
  }, [open, deleteDialogOpen]);

  if (loading || !data) return <Loading />;
  const users = _.get(data, "users", []);
  const fiscalYear = org.fiscalYear;
  const currentYearPlans = _.get(data, "plans", []).filter(({ year }) => year === fiscalYear);
  const invoice = _.get(upcomingInvoiceData, "getUpcomingInvoice.invoice");
  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Hidden xsDown>
            <Grid item xs={12}>
              <Typography variant="h6" className={styles.label}>
                Manage Users
              </Typography>
            </Grid>
          </Hidden>
          <Grid item xs={12}>
            <Card className={styles.card}>
              <CardTitle vertical color="teal">
                <Typography variant="h5" className={styles.title}>
                  Users
                </Typography>
              </CardTitle>
              <CardActions className={styles.cardActions}>
                <TextField
                  variant="outlined"
                  label="Search"
                  name="search"
                  value={searchTerm || ""}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  // onKeyPress={(e) => {
                  //   if (e.key === "Enter") handleSearch();
                  // }}
                  className={styles.left}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton disabled={true}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  startIcon={<AddIcon />}
                  className={styles.leftIcon}
                  onClick={handleDialog(true)}
                  variant="contained"
                  color="primary"
                >
                  New User
                </Button>
              </CardActions>
              <CardContent className={styles.cardContent}>
                <Table size="small" className={styles.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Department</TableCell>
                      <TableCell>Authorization</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users &&
                      users.map((user) => {
                        return (
                          <User
                            key={user.id}
                            user={user}
                            snack={snack}
                            handleEdit={handleDialog(true, user)}
                            handleDelete={() => {
                              setDeleteDialogOpen(true);
                              setSelectedUser(user);
                            }}
                            disableDelete={auth.id === user.id || !_.isNil(user.stripeCustomerId)}
                            handleDeleteImage={() => handleDeleteImage(user.id, user.profilePicture)}
                            fiscalYear={org.fiscalYear}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
                <TablePagination
                  page={page}
                  rowsPerPage={rowsPerPage}
                  count={data.usersCount}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleRowsPerPage}
                  component="div"
                />
              </CardContent>
            </Card>
          </Grid>

          {/* <Grid item xs={12}>
            <Typography variant="h6" className={styles.label}>
              Invite Users
            </Typography>
            <Typography variant="subtitle2" className={styles.delete}>
              This will be replaced with an email field that will email the invite link out to users. For now copy the url into your browser
            </Typography>
            <Button onClick={handleClick} className={styles.button}>
              {url}
            </Button>
            <input className={styles.input} defaultValue={url} id="inputid" />
          </Grid> */}
        </Grid>
      </Container>

      <EditDialog
        open={open}
        handleClose={handleDialog(false)}
        user={selectedUser}
        form={form}
        resetForm={resetForm}
        formErrors={formErrors}
        handleChange={handleChange}
        handleToggleCheckBox={handleToggleCheckBox}
        handleSubmit={selectedUser ? handleEditSubmit : handleCreateSubmit}
        handleSelectPicture={handleSelectPicture}
        handleCreateUserCancel={handleCreateUserCancel}
        plans={currentYearPlans}
        invoice={invoice}
        invoiceDataLoading={invoiceDataLoading}
      />

      {/* Delete Dialog */}
      {selectedUser && (
        <Dialog
          open={deleteDialogOpen}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setDeleteDialogOpen(false);
            }
          }}
        >
          <DialogTitle>Delete User</DialogTitle>
          <DialogContent>
            Are you sure you want to delete {selectedUser.name.first} {selectedUser.name.last}?
            {invoice && !invoiceDataLoading && (
              <Typography style={{ textAlign: "end", fontWeight: "bold" }}>
                Next Balance Preview: ${(invoice.total / 100).toLocaleString()} {invoice.currency.toUpperCase()}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => {
                setDeleteDialogOpen(false);
                setSelectedUser(null);
              }}
            >
              Cancel
            </Button>
            <Button color="primary" onClick={() => handleDelete(selectedUser)} variant="contained" disabled={invoiceDataLoading}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleDialog(false)();
          }
        }}
      >
        <DialogTitle>Register User</DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="First Name"
            name="name.first"
            autoFocus
            value={form.name.first || ""}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Last Name"
            name="name.last"
            value={form.name.last || ""}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Email Address"
            name="email"
            value={form.email || ""}
            onChange={handleChange}
            helperText={formErrors.email}
            error={Boolean(formErrors.email)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Title"
            name="position"
            value={form.position || ""}
            onChange={handleChange}
            helperText={formErrors.position}
            error={Boolean(formErrors.position)}
          />
          <SelectDepartment
            plans={_.get(data, "plans")}
            name="plan"
            handleChange={handleChange}
            value={form.plan}
            helperText="Which department does this user belong to?"
            multiple
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialog(false)}>Cancel</Button>
          <Button color="primary" onClick={handleSubmit} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default Users;

const GET_USERS_AND_DEPARTMENTS = gql`
  query Users_GetUsersDepts($organization: ID!, $offset: Int, $size: Int, $search: String) {
    users(organization: $organization, offset: $offset, size: $size, search: $search) {
      id
      name {
        first
        last
      }
      plan {
        id
        departmentName
        closed
        year
      }
      profilePicture
      position
      email
      auth
      notificationSettings {
        weeklyReminder {
          frequency
          enabled
        }
        meetingReminder {
          frequency
          enabled
        }
      }
      stripeCustomerId
    }

    plans(organization: $organization, category: "1 year", closed: false) {
      id
      departmentName
      year
    }

    usersCount(organization: $organization, search: $search)
  }
`;

const CREATE_USER = gql`
  mutation Users_CreateUser(
    $organization: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $position: String!
    $plan: [ID!]
    $auth: String
    $profilePicture: String
    $notificationSettings: NotificationsInput
  ) {
    createUser(
      organization: $organization
      firstName: $firstName
      lastName: $lastName
      email: $email
      position: $position
      plan: $plan
      auth: $auth
      profilePicture: $profilePicture
      notificationSettings: $notificationSettings
    )
  }
`;

const UPDATE_USER = gql`
  mutation (
    $id: ID!
    $firstName: String
    $lastName: String
    $position: String
    $email: String
    $auth: String
    $profilePicture: String
    $plan: [ID!]
    $notificationSettings: NotificationsInput
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      position: $position
      email: $email
      auth: $auth
      profilePicture: $profilePicture
      plan: $plan
      notificationSettings: $notificationSettings
    )
  }
`;

const DELETE_USER = gql`
  mutation ($id: ID!) {
    deleteUser(id: $id)
  }
`;

const GET_UPCOMING_INVOICE = gql`
  query Users_GetUpcomingInvoice($payingUserId: String!, $quantityChange: Int!) {
    getUpcomingInvoice(payingUserId: $payingUserId, quantityChange: $quantityChange) {
      invoice {
        total
        currency
      }
    }
  }
`;

const GET_SIGNED_URL_S3 = gql`
  mutation ($fileName: String!, $fileType: String, $operation: String!) {
    generateSignedUrlS3(fileName: $fileName, fileType: $fileType, operation: $operation)
  }
`;
