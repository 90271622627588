import _ from "lodash";

export const permissionDictionary = {
  "vantage admin": 1,
  "vantage facilitator": 2,
  "company admin": 3,
  "department admin": 4,
  "department facilitator": 5,
  "department employee": 6,
};

export const isVantageAdmin = (user) => {
  return user.auth === "vantage admin";
};

export const isVantageFacilitator = (user) => {
  return user.auth === "vantage facilitator";
};

export const isCompanyAdmin = (user) => {
  return user.auth === "company admin";
};

export const isDepartmentAdmin = (user) => {
  return user.auth === "department admin";
};

export const isDepartmentFacilitator = (user) => {
  return user.auth === "department facilitator";
};

export const isDepartmentEmployee = (user) => {
  return user.auth === "department employee";
};

export const getListOfAuth = (permissionInts) => {
  const arr = [];
  const permissions = _.invert(permissionDictionary);

  const str = permissionInts.toString();
  for (let char of str) {
    arr.push(permissions[char]);
  }

  return arr;
};

export const isAuthed = (user = {}, minPermission) => {
  const userInt = _.get(permissionDictionary, user.auth);
  const permissionInt = _.get(permissionDictionary, minPermission);

  if (_.isNil(userInt || _.isNil(permissionInt))) {
    return false;
  }

  return userInt <= permissionInt;
};
