import { differenceInSeconds } from "date-fns";
import React, { useEffect, useState } from "react";
import styles from "./Meetings.module.scss";
import _ from "lodash";

const StepTimeIndicator = ({ stepStartTime, timeSpentInSeconds, plannedDuration }) => {
  const [timer, setTimer] = useState(timeSpentInSeconds);

  useEffect(() => {
    let interval;
    if (!_.isNil(stepStartTime)) {
      interval = setInterval(() => setTimer(differenceInSeconds(new Date(), new Date(stepStartTime)) + timeSpentInSeconds), 1000);
    }

    return () => clearInterval(interval);
  }, [stepStartTime, timeSpentInSeconds]);

  const progress = plannedDuration * 60 > timer ? (1 - timer / (plannedDuration * 60)) * 100 : 0;
  return (
    <div className={styles.stepTimeIndicator} style={{ transform: `translateX(-${progress}%)` }} />
  );
};

export default StepTimeIndicator;
