import _ from "lodash";
import imageExtensions from "image-extensions";
import isUrl from "is-url";
import { ReactEditor } from "slate-react";
import { Editor, Transforms, Element, Path, Range } from "slate";

export const serialize = (nodes) => {
  return JSON.stringify(nodes);
};

export const deserialize = (string) => {
  try {
    const result = JSON.parse(string);
    if (_.get(result, "0.type") === undefined) {
      throw Error("not a valid JSON object");
    }
    return result;
  } catch (e) {
    // serialize old format
    const result = [
      {
        type: "paragraph",
        children: [{ text: string }],
      },
    ];
    return result;
  }
};

export const createLinkNode = (href, text) => ({
  type: "link",
  href,
  children: [{ text }],
});

export const createParagraphNode = (children = [{ text: "" }]) => ({
  type: "paragraph",
  children,
});

export const removeLink = (editor, opts = {}) => {
  Transforms.unwrapNodes(editor, {
    ...opts,
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "link",
  });
};

export const insertLink = (editor, url) => {
  if (!url) return;

  const { selection } = editor;
  const link = createLinkNode(url, "New Link");

  ReactEditor.focus(editor);

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(editor, selection.focus?.path);

    if (parentNode.type === "link") {
      // removeLink(editor);    //uncomment if want to remove an existing link in the desired spot of a new link
      return;
    }

    if (editor.isVoid(parentNode)) {
      // Insert the new link after the void node
      Transforms.insertNodes(editor, createParagraphNode([link]), {
        at: Path.next(parentPath),
        select: true,
      });
    } else if (Range.isCollapsed(selection)) {
      // Insert the new link in our last known location
      Transforms.insertNodes(editor, link, { select: true });
    } else {
      // Wrap the currently selected range of text into a Link
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: "end" });
    }
  } else {
    // Insert the new link node at the bottom of the Editor when selection
    // is falsey
    Transforms.insertNodes(editor, createParagraphNode([link]));
  }
};

export const insertImage = (editor, url) => {
  const text = { text: "" };
  const image = { type: "image", url, children: [text] };
  Transforms.insertNodes(editor, image);
  Transforms.insertNodes(editor, {
    type: "paragraph",
    children: [{ text: "" }],
  });
};

export const isImageUrl = (url) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split(".").pop();
  return imageExtensions.includes(ext);
};
