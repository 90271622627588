import React, { createContext, useEffect, useState, useContext } from "react";
import _ from "lodash";
import { useUser } from "./userContext";

export const DepartmentFilterContext = createContext();

export const DeptFilterContextProvider = ({ children }) => {
  const { user } = useUser();
  const [departmentFilter, setDepartmentFilter] = useState({});

  useEffect(() => {
    if (!_.isEmpty(user)) {
      let departmentFilterId = sessionStorage.getItem("departmentFilterId");
      let departmentFilterSharedId = sessionStorage.getItem("departmentFilterSharedId");

      let deptFilter = { id: null, sharedPlanId: null };
      if (!_.isNil(departmentFilterId)) {
        deptFilter = { id: departmentFilterId, sharedPlanId: departmentFilterSharedId };
      }

      setDepartmentFilter(deptFilter);
    } else {
      setDepartmentFilter({});
    }
  }, [user]);

  return <DepartmentFilterContext.Provider value={{ departmentFilter, setDepartmentFilter }}>{children}</DepartmentFilterContext.Provider>;
};

export const useDepartmentFilter = () => {
  return useContext(DepartmentFilterContext);
};
