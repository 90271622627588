import React, { useContext } from "react";
import _ from "lodash";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import { isAuthed, isCompanyAdmin } from "../../utils/authorization";
import AuthRedirect from "./AuthRedirect";

const RequireAuth = ({ roleAccess }) => {
  const { auth } = useAuth();
  const location = useLocation();
  const authenticated = !_.isNil(auth);

  if (!authenticated) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  const needOnboard = isCompanyAdmin(auth || {}) && (!auth.isVerified || !auth.organization || !auth.isSubscribed);
  if (needOnboard) {
    return <Navigate to="/onboard" state={{ from: location }} replace />;
  }

  // if they are department admins or lower and they are not subscribed
  if (!needOnboard && !isAuthed(auth, "vantage facilitator") && !auth.isSubscribed) {
    return <Navigate to="/error" state={{ from: location }} replace />;
  }

  if (!isAuthed(auth, roleAccess)) {
    return <AuthRedirect />;
  }

  return <Outlet />;
};

export default RequireAuth;
