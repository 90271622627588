import React, { useContext, useState } from "react";
import { Button, Container, TextField, Typography } from "@material-ui/core";
import _ from "lodash";

import styles from "./PasswordReset.module.scss";
import { useForm } from "../../hooks";
import { Link, useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { SnackbarContext } from "../../context/snackbarContext";
import Splash from "../../components/Splash/Splash";

const initForm = {
  newPassword: null,
  confirmNewPassword: null,
};
const initErrorForm = {
  newPassword: [
    {
      type: "custom",
      callback: (form, value) => !_.isEmpty(value) && value.length > 8 && /\d/.test(value) && /[!@#$%^&*(),.?":{}|<>]/.test(value),
      errorMessage: "Password must be at least 8 characters long, contain at least one number and one special character",
    },
  ],
  confirmNewPassword: [
    {
      type: "custom",
      callback: (form, value) => value === form.newPassword,
      errorMessage: "Password must match above",
    },
  ],
};

const PasswordReset = () => {
  const { token } = useParams();
  const [success, setSucess] = useState(false);
  const [linkInvalid, setLinkInvalid] = useState(false);

  const { snack } = useContext(SnackbarContext);

  const [resetPassword, { loading }] = useMutation(SET_PASSWORD);
  const [sendPasswordResetEmail, { loading: sendEmailLoading }] = useMutation(SEND_PASSWORD_RESET_EMAIL);

  const { form, formErrors, handleChange, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const res = await resetPassword({
        variables: {
          token,
          password: form.confirmNewPassword,
        },
      });

      if (res.data.setUserPassword) {
        setSucess(true);
        setLinkInvalid(false);
        snack("Password is successfully updated");
      }
    } catch (err) {
      let snackMsg = "Something went wrong, please try again later",
        snackStatus = "error";
      if (_.get(err, "cause.extensions.code") === "INVALID_TOKEN") {
        setLinkInvalid(true);
        snackMsg = "Link invalid";
        snackStatus = "info";
      }

      snack(snackMsg, snackStatus);
    }
  };

  const handleResend = async () => {
    try {
      const ok = await sendPasswordResetEmail({ variables: { expiredToken: token } });
      if (ok) {
        snack(`Updated password reset link sent to account email`);
      }
    } catch (e) {
      snack("Something went wrong, please try again later", "error");
    }
  };

  return (
    <Splash>
      {success ? (
        <Typography variant="subtitle1" color="textSecondary" align="center">
          Your password has been successfully changed! You can sign into your account with your new password.
        </Typography>
      ) : (
        <>
          <Typography variant="h6">Change password</Typography>
          <TextField
            label="New Password"
            name="newPassword"
            fullWidth
            type="password"
            variant="outlined"
            margin="normal"
            value={form.newPassword || ""}
            onChange={handleChange}
            helperText={formErrors.newPassword}
            error={Boolean(formErrors.newPassword)}
          />
          <TextField
            label="Repeat New Password"
            name="confirmNewPassword"
            fullWidth
            type="password"
            variant="outlined"
            margin="normal"
            value={form.confirmNewPassword || ""}
            onChange={handleChange}
            helperText={formErrors.confirmNewPassword}
            error={Boolean(formErrors.confirmNewPassword)}
          />
          <Button variant="contained" color="primary" disabled={loading} onClick={handleSubmit} className={styles.button}>
            Change
          </Button>

          {linkInvalid && (
            <Button onClick={handleResend} disabled={sendEmailLoading} className={styles.button} style={{ marginLeft: "16px" }}>
              Resend password reset link
            </Button>
          )}
        </>
      )}
      <Typography component={Link} to="/" variant="subtitle1" color="primary" className={styles.link}>
        Back to Sign In
      </Typography>
    </Splash>
  );
};

export default PasswordReset;

const SET_PASSWORD = gql`
  mutation ResetUserPassword($password: String!, $token: String) {
    setUserPassword(password: $password, token: $token)
  }
`;

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation SendPasswordResetEmail($expiredToken: String) {
    sendPasswordResetEmail(expiredToken: $expiredToken)
  }
`;
