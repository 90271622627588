import React, { useContext } from "react";
import useForm from "../../hooks/useForm";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, useMediaQuery } from "@material-ui/core";
import SelectDepartment from "../../components/SelectDepartment/SelectDepartment";

const initForm = {
  value: null,
  plan: null,
};

const initErrorForm = {
  value: ["required"],
};

const AddProjectDialog = ({ id, open, handleClose, plans }) => {
  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const [createProject] = useMutation(CREATE_PROJECT);

  const { form, formErrors, handleChange, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const fs = useMediaQuery("(max-width: 600px)");

  const handleSubmit = async () => {
    if (!validateForm()) return null;
    const { value, plan } = form;

    try {
      const ok = await createProject({
        variables: {
          organization: id,
          value,
          plan,
        },
      });

      if (ok.data.createProject) {
        snack("Created new project");
        requestFetch();
        resetForm();
        handleClose();
      }
    } catch (err) {
      snack("Failed to create new project", "error");
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      fullScreen={fs}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <DialogTitle>Create New Project</DialogTitle>
      <DialogContent>
        <TextField
          label="Project Name"
          name="value"
          fullWidth
          autoFocus
          margin="normal"
          variant="outlined"
          value={form.value || ""}
          onChange={handleChange}
          error={Boolean(formErrors.value)}
          helperText={formErrors.value}
        />
        <SelectDepartment
          plans={plans}
          name="plan"
          handleChange={handleChange}
          value={form.plan}
          helperText="Which department does this project belong to?"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProjectDialog;

const CREATE_PROJECT = gql`
  mutation ($organization: ID!, $value: String!, $plan: ID) {
    createProject(organization: $organization, value: $value, plan: $plan)
  }
`;
