import React, { useEffect, useContext, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
import styles from "./AddEditSuccessCriterionDialog.module.scss";
import _ from "lodash";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { SUCCESS_CRITERIA_FIELDS } from "../../utils/fragments";
import { recursiveMergeCustomizerWithSkipRetain } from "../../utils/misc";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  Menu,
  MenuItem,
  Tooltip,
  CircularProgress,
  useTheme,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import CloseIcon from "@material-ui/icons/Close";
import { mdiBrain, mdiTypewriter, mdiUndoVariant } from "@mdi/js";
import Icon from "@mdi/react";

import Loading from "../Loading/Loading";
import NewSelectRock from "../SelectRock/NewSelectRock";
import ConfirmDeletionDialog from "../ConfirmDeletionDialog/ConfirmDeletionDialog";
import useConfirmDelete from "../../hooks/useConfirmDelete";
import useLLM from "../../hooks/useLLM";
import LLMSuggestionsListDialog from "../LLMDialogs/LLMSuggestionsListDialog";
import LLMSettingsDialog from "../LLMDialogs/LLMSettingsDialog";
import { isAuthed } from "../../utils/authorization";

// const initForm = {
//   value: null,
//   rock: null,
// };

// const initErrorForm = {
//   value: ["required"],
//   rock: ["required"],
// };

const ITEM_TYPE = "success criteria";

const AddEditSuccessCriterionDialog = ({ dialog, setDialog, params, org, snack, requestFetch }) => {
  const theme = useTheme();
  const fs = useMediaQuery("(max-width: 600px)");

  const { auth } = useAuth();
  const isAdmin = isAuthed(auth, "vantage facilitator");

  const { departmentFilter } = useDepartmentFilter();
  const deptFilterSpid = _.get(departmentFilter, "sharedPlanId");

  const successCriteriaDialog = _.get(dialog, "addSuccessCriteriaDialog", {});

  const {
    open,
    rock,
    successCriterion,
    planId, // only passed when editing (i.e. when successCriterion is provided) for the issue dialog
    rockDialog,
  } = successCriteriaDialog;

  const isCreate = _.isNil(successCriterion);
  const isRockDialog = !_.isNil(rockDialog);

  const [close, setClose] = useState(true);
  const [submitAfterChange, setSubmitAfterChange] = useState(false);
  const [prevValue, setPrevValue] = useState("");

  const { data, refetch } = useQuery(GET_ROCKS, {
    variables: { organization: params.org },
  });

  const [createSuccessCriteria, { loading }] = useMutation(CREATE_SUCCESS_CRITERIA);
  const [deleteSuccessCriteria] = useMutation(DELETE_SUCCESS_CRITERIA, {
    update(cache, { data: { deleteSuccessCriteria } }) {
      const { deletedSuccessCriteria } = deleteSuccessCriteria;
      const deleteScId = cache.identify(deletedSuccessCriteria);
      cache.modify({
        fields: {
          successCriterias: (existingScs) => {
            return existingScs.filter((scRef) => {
              const scId = cache.identify(scRef);
              return scId !== deleteScId;
            });
          },
        },
      });
    },
  });
  const [updateSuccessCriteria] = useMutation(UPDATE_SUCCESS_CRITERIA);

  const [getScSuggestions, { data: scSuggestionsData, loading: scSuggestionsLoading, error: scSuggestionsError }] = useLazyQuery(
    GET_SC_SUGGESTIONS,
    {
      fetchPolicy: "network-only", // Ensures data is always fetched from the server
    }
  );

  const {
    anchorEl,
    llmSuggestions,
    setLlmSuggestions,
    llmSuggestionsListOpen,
    setLlmSuggestionsListOpen,
    llmSettingsOpen,
    setLlmSettingsOpen,
    canUndo,
    setCanUndo,
    abortControllerRef,
    rephraseText,
    rephraseTextData,
    rephraseTextLoading,
    llmSettingsByPurpose,
    handleOpenAdminMenu,
    handleCloseAdminMenu,
    handleCancelBrainstorm,
    handleSaveLLMSettings,
  } = useLLM({
    open,
    snack,
    suggestionsLoading: scSuggestionsLoading,
  });

  const handleClose = () => {
    setDialog({ ...dialog, addSuccessCriteriaDialog: { open: false } });
  };

  // FORMIK START
  const schema = (rock, isRockDialog) =>
    Yup.object().shape({
      value: Yup.string().required("Success criterion description"),
      rock: Yup.string().when([], {
        is: () => _.isNil(rock) && isRockDialog, // if we are creating a rock in the rock dialog then the rock tie-in for the sc is not required
        then: (schema) => schema,
        otherwise: (schema) => schema.required("Tied in rock is required"),
      }),
      targetDate: Yup.date().nullable(),
      done: Yup.boolean(),
    });

  const getInitialValues = (successCriterion, rockId) => {
    let initVals = {
      value: "",
      rock: rockId || "",
      targetDate: null,
      done: false,
    };

    if (!_.isNil(successCriterion)) {
      const targetDate = _.get(successCriterion, "targetDate", null);
      if (_.isString(targetDate)) {
        _.set(successCriterion, "targetDate", new Date(parseInt(targetDate)));
      }

      initVals = _.mergeWith({}, initVals, successCriterion, (objVal, srcVal, key) =>
        recursiveMergeCustomizerWithSkipRetain({ srcVal, fieldName: key, skipFields: ["targetDate"] })
      );
    }

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues(successCriterion, rock),
    validationSchema: schema(rock, isRockDialog),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const { value, rock, targetDate, done } = values;

      try {
        let res;
        if (isCreate) {
          res = await createSuccessCriteria({
            variables: {
              organization: params.org,
              rock,
              value,
              targetDate,
            },
          });
        } else {
          res = await updateSuccessCriteria({ variables: { id: _.get(successCriterion, "id"), value, targetDate, done } });
        }

        if (res) {
          snack(`${isCreate ? "Created" : "Updated"} "${value}" success criterion`);
          if (close) {
            handleClose(res.data.createRock);
          } else {
            resetForm();
          }
          requestFetch();
        }
        setSubmitting(false);
      } catch (error) {
        snack(`Failed to ${isCreate ? "create" : "update"} "${value}" success criterion`, "error");
      }
    },
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit: handleSubmitFormik,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setValues,
    resetForm,
    validateForm,
  } = formik;

  // const handleSubmit = (close) => async () => {
  //   if (!validateForm()) return;

  //   const { value, rock } = form;
  //   const rockId = dialog.addSuccessCriteriaDialog === true ? rock : dialog.addSuccessCriteriaDialog;

  //   try {
  //     const ok = await createSuccessCriteria({
  //       variables: {
  //         organization: params.org,
  //         rock: rockId,
  //         value,
  //       },
  //     });

  //     if (ok) {
  //       snack(`Created "${value}" success criteria`);
  //       // If this dialog was opened in with a rock as a reference, do not remove the selected rock from the form
  //       if (dialog.addSuccessCriteriaDialog !== true) {
  //         resetForm({ ...form, value: null });
  //       } else {
  //         resetForm();
  //       }

  //       if (close) {
  //         handleClose();
  //       }
  //     }
  //   } catch (err) {
  //     snack("Failed to create success criteria", "error");
  //   }
  // };

  const { confirmOpen, handleConfirmOpen, handleDelete } = useConfirmDelete({
    id: _.get(successCriterion, "id"),
    value: values.value,
    itemType: ITEM_TYPE,
  });

  const handleSubmit = async (close) => {
    if (isRockDialog) {
      const rockDialogHandleSubmit = _.get(rockDialog, "handleSubmit");
      const errors = await validateForm();

      if (_.isEmpty(errors)) {
        rockDialogHandleSubmit(values);
        if (close) handleClose();
      }
    } else {
      setClose(close);
      handleSubmitFormik();
    }
  };

  const handleClickDelete = () => {
    if (!isRockDialog) handleConfirmOpen(true)();
  };

  const handleConfirmDelete = async () => {
    let successfulDeletion = await handleDelete(deleteSuccessCriteria)();
    if (successfulDeletion) {
      handleClose();
    }
  };

  // only available when editing success criteria
  const handleAddIssueDialog =
    (referenceId = null, referenceModel = null, value = null, user = null) =>
    () => {
      setDialog({
        ...dialog,
        addTodoDialog: {
          open: true,
          category: "issue",
          referenceId,
          referenceModel,
          value,
          user,
          planId,
        },
      });
    };

  const handleGenerateSuggestions = (feedbackPrompts) => {
    // Abort any ongoing request before starting a new one
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the new request
    abortControllerRef.current = new AbortController();

    getScSuggestions({
      variables: { organization: params.org, user: auth.id, rock: values.rock, feedbackPrompts },
      context: {
        fetchOptions: { signal: abortControllerRef.current.signal },
      },
    });
  };

  const handleRephraseText = () => {
    setPrevValue(_.get(values, "value", ""));
    setCanUndo(true);

    rephraseText({
      variables: { text: values.value },
    });
  };

  const handleSelectSuggestion = (suggestion = {}) => {
    setPrevValue(_.get(values, "value", ""));
    setCanUndo(true);

    const { value, description } = suggestion;
    setValues({ ...values, value, description });
    setLlmSuggestionsListOpen(false);
  };

  const handleUndo = () => {
    setValues({ ...values, value: prevValue });
    setCanUndo(false);
  };

  useEffect(() => {
    if (open) {
      refetch();
    } else {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    if (submitAfterChange) {
      setSubmitAfterChange(false);
      handleSubmit(true);
    }
  }, [values.done, submitAfterChange]);

  useEffect(() => {
    if (scSuggestionsData) {
      setLlmSuggestions(_.get(scSuggestionsData, "successCriteriaSuggestions", []));
    }
  }, [scSuggestionsData]);

  useEffect(() => {
    if (rephraseTextData) {
      setFieldValue("value", _.get(rephraseTextData, "rephrase", ""));
    }
  }, [rephraseTextData]);

  const llmLoading = scSuggestionsLoading || rephraseTextLoading;
  const isCreateRockDialog = _.isNil(rock) && isRockDialog;

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        fullWidth
        fullScreen={fs}
      >
        <DialogTitle>
          <div className={styles.title}>
            <div style={{ display: "flex", gap: theme.spacing(2), alignItems: "center" }}>
              {isCreate ? "Create" : "Edit"} Success Criterion
              {isAdmin && (
                <Button color="primary" variant="outlined" onClick={(e) => handleOpenAdminMenu(e)}>
                  Settings
                </Button>
              )}
            </div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <FormikProvider>
            {isCreate && !isRockDialog && (
              <NewSelectRock name="rock" rocks={_.get(data, "rocks", null)} sharedPlanId={deptFilterSpid} formik={formik} />
            )}
            <TextField
              autoFocus
              multiline
              fullWidth
              label="Success Criterion Description"
              variant="outlined"
              margin="normal"
              {...getFieldProps("value")}
              error={Boolean(touched.value && errors.value)}
              helperText={touched.value && errors.value}
              InputProps={{
                endAdornment: (
                  <>
                    <Tooltip title={`Brainstorm${isCreateRockDialog ? " (please create rock first)" : ""}`}>
                      <div>
                        <IconButton
                          onClick={(e) => setLlmSuggestionsListOpen(true)}
                          disabled={llmLoading || isCreateRockDialog}
                          className={styles.llmButton}
                        >
                          <Icon path={mdiBrain} size={1} />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title="Rephrase">
                      <div>
                        <IconButton
                          onClick={(e) => handleRephraseText()}
                          disabled={_.isEmpty(values.value) || llmLoading}
                          className={styles.llmButton}
                        >
                          {rephraseTextLoading ? <CircularProgress size={20} color="inherit" /> : <Icon path={mdiTypewriter} size={1} />}
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title="Undo">
                      <div>
                        <IconButton onClick={handleUndo} disabled={!canUndo || llmLoading} className={styles.llmButton}>
                          <Icon path={mdiUndoVariant} size={1} />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </>
                ),
              }}
            />
            <DatePicker
              autoOk
              clearable
              fullWidth
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              label="Target Date (optional)"
              {...getFieldProps("targetDate")}
              onChange={(dateVal) => setFieldValue("targetDate", dateVal)}
            />
          </FormikProvider>
        </DialogContent>
        <DialogActions>
          {isCreate ? (
            <>
              <Button onClick={handleClose}>Cancel</Button>
              {!isRockDialog && (
                <Button onClick={() => handleSubmit(false)} color="primary" variant="outlined" disabled={loading} className={styles.button}>
                  {loading ? <Loading size={24} color="#fff" /> : "Create & Add Another"}
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                onClick={handleAddIssueDialog(_.get(successCriterion, "id"), "SuccessCriteria", values.value)}
                color="primary"
                disabled={!_.has(successCriterion, "id")}
              >
                Add Issue
              </Button>
              <Button
                onClick={() => {
                  setFieldValue("done", !values.done);
                  setSubmitAfterChange(true);
                }}
                color="primary"
                style={{ marginRight: "auto" }}
              >
                {loading ? <Loading size={24} color="primary" /> : `Mark as ${values.done ? "Incomplete" : "Complete"}`}
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
              {!isRockDialog && (
                <Button onClick={handleClickDelete} color="primary">
                  Delete
                </Button>
              )}
            </>
          )}
          <Button onClick={() => handleSubmit(true)} color="primary" variant="contained" disabled={loading} className={styles.button}>
            {isSubmitting || loading ? <Loading size={24} color="#fff" /> : isCreate ? "Create" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDeletionDialog
        itemType={ITEM_TYPE}
        value={values.value}
        confirmOpen={confirmOpen}
        handleConfirmOpen={handleConfirmOpen}
        handleDeletion={handleConfirmDelete}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClick={handleCloseAdminMenu}
        onClose={handleCloseAdminMenu}
      >
        <MenuItem onClick={() => setLlmSettingsOpen("brainstorm")}>Brainstorm</MenuItem>
        <MenuItem onClick={() => setLlmSettingsOpen("rephrase")}>Rephrase</MenuItem>
      </Menu>
      <LLMSuggestionsListDialog
        open={llmSuggestionsListOpen}
        handleClose={() => {
          setLlmSuggestionsListOpen(false);
          handleCancelBrainstorm();
        }}
        title="Success Criteria Suggestions"
        suggestionGroups={llmSuggestions}
        loading={scSuggestionsLoading}
        error={scSuggestionsError}
        handleRefresh={handleGenerateSuggestions}
        handleSelect={handleSelectSuggestion}
        snack={snack}
        org={org}
      />
      <LLMSettingsDialog
        open={!_.isEmpty(llmSettingsOpen)}
        onClose={() => setLlmSettingsOpen("")}
        title={`LLM System Settings (${_.capitalize(llmSettingsOpen)})`}
        llmSettings={_.get(llmSettingsByPurpose, llmSettingsOpen, {})}
        systemDescMoreInfo={`Describes what the model is supposed to do and how it should generally behave and respond.${
          llmSettingsOpen === "brainstorm"
            ? 'Use {{type}} as the placeholder for the document type, which in this case is "success criteria".'
            : ""
        }`}
        userPromptMoreInfo={
          {
            brainstorm:
              'Information about the organization in addition to its success criteria with all of their related rocks, todos, etc. precedes this prompt hence the use of wording such as "this information". Use {{type}} as the placeholder for the document type, which in this case is the "success criteria"',
            rephrase: "The current text to be rephrased follows this prompt",
          }[llmSettingsOpen]
        }
        handleSave={handleSaveLLMSettings}
      />
    </>
  );
};

export default AddEditSuccessCriterionDialog;

const GET_ROCKS = gql`
  query AddScDialog_GetRocks($organization: ID!) {
    rocks(organization: $organization) {
      id
      value
      plan {
        id
        departmentName
        color
        shortName
        sharedPlanId
      }
    }
  }
`;

const CREATE_SUCCESS_CRITERIA = gql`
  ${SUCCESS_CRITERIA_FIELDS}
  mutation CreateSuccessCriteria($value: String!, $rock: ID!, $organization: ID!) {
    createSuccessCriteria(value: $value, rock: $rock, organization: $organization) {
      updatedRock {
        id
        successCriterias {
          ...SuccessCriteriaFields
        }
      }
    }
  }
`;

const DELETE_SUCCESS_CRITERIA = gql`
  mutation DialogDeleteSuccessCriteria($id: ID!) {
    deleteSuccessCriteria(id: $id) {
      deletedSuccessCriteria {
        id
      }
      updatedRock {
        id
        successCriterias {
          id
        }
      }
    }
  }
`;

const UPDATE_SUCCESS_CRITERIA = gql`
  mutation ($id: ID!, $value: String, $targetDate: String, $done: Boolean) {
    updateSuccessCriteria(id: $id, value: $value, targetDate: $targetDate, done: $done)
  }
`;

const GET_SC_SUGGESTIONS = gql`
  query ($organization: ID!, $user: ID!, $rock: ID!, $feedbackPrompts: [ID!]) {
    successCriteriaSuggestions(organization: $organization, user: $user, rock: $rock, feedbackPrompts: $feedbackPrompts) {
      feedbackPrompt {
        id
        title
      }
      suggestions {
        value
      }
      systemText
      userText
    }
  }
`;
