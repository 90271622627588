import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Typography } from "@material-ui/core";
import _ from "lodash";

import styles from "./VerifyEmail.module.scss";

import { Link, useParams } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import Splash from "../../components/Splash/Splash";

const VerifyEmail = (props) => {
  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const { token } = useParams();
  const [message, setMessage] = useState("Verifying...");
  const [isValid, setIsValid] = useState(true);

  const [verifyEmail] = useMutation(VERIFY_EMAIL);

  const [sendEmailVerificationEmail, { loading }] = useMutation(SEND_VERIFICATION_EMAIL);

  const handleVerifyEmail = async () => {
    try {
      const res = await verifyEmail({
        variables: {
          verificationToken: token,
        },
      });

      if (res.data.verifyEmail) {
        requestFetch();
        setMessage("Thank you for verifying email");
        setIsValid(true);
      }
    } catch (err) {
      setMessage(err.message);
      if (_.get(err, "cause.extensions.code") === "INVALID_TOKEN") {
        setIsValid(false);
      }
    }
  };

  const handleResend = async () => {
    try {
      const ok = await sendEmailVerificationEmail({ variables: { verificationToken: token } });
      if (ok) {
        snack(`Updated password reset link sent to account email`);
      }
    } catch (e) {
      snack("Something went wrong, please try again later", "error");
    }
  };

  useEffect(() => {
    if (token) {
      handleVerifyEmail();
    }
  }, []);

  return (
    <Splash>
      <Typography variant="subtitle1" color="textSecondary" align="center">
        {message}
      </Typography>
      {!isValid && (
        <Button onClick={handleResend} disabled={loading} className={styles.button}>
          Resend Verification link
        </Button>
      )}
      <Typography component={Link} to="/" variant="subtitle1" color="primary" className={styles.link}>
        Back to Sign In
      </Typography>
    </Splash>
  );
};

export default VerifyEmail;

const VERIFY_EMAIL = gql`
  mutation VerifyEmail($verificationToken: String!) {
    verifyEmail(verificationToken: $verificationToken)
  }
`;

const SEND_VERIFICATION_EMAIL = gql`
  mutation SendVerificationEmail($verificationToken: String!) {
    sendVerificationEmail(verificationToken: $verificationToken)
  }
`;
