import React, { useContext, useEffect } from "react";
import _ from "lodash";
import useForm from "../../hooks/useForm";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { useAuth } from "../../context/authContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { isVantageAdmin } from "../../utils/authorization";

const EditAdminDialog = ({ open, handleClose, initForm, organizations }) => {
  const [updateUser] = useMutation(UPDATE_USER);
  const [createAdmin] = useMutation(CREATE_ADMIN);
  const [sendPasswordResetEmail, { loading: resetPwLoading }] = useMutation(SEND_PASSWORD_RESET_EMAIL);

  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const { auth, handleSignout } = useAuth();
  const { form, handleChange, resetForm } = useForm({
    initForm,
  });

  const isEdit = !_.isNil(form.id);

  const handleSubmit = async () => {
    if (isEdit) {
      const isUpdatingSelf = auth.id === form.id;

      try {
        const ok = await updateUser({
          variables: form,
        });

        if (ok.data.updateUser) {
          if (isUpdatingSelf) {
            snack("Updated admin. Please log in to view changes.");
            handleSignout();
          } else {
            snack("Updated admin");
            requestFetch();
            handleClose();
          }
        }
      } catch (err) {
        snack("Failed to update admin", "error");
      }
    } else {
      try {
        const { data } = await createAdmin({
          variables: form,
        });

        if (data.createAdmin) {
          snack(`Created user: ${form.firstName} ${form.lastName}`);
          requestFetch();
          handleClose();
        }
      } catch (err) {
        snack(err.message, "error");
      }
    }
  };

  const handleResetPassword = async () => {
    const { id } = form;
    try {
      const res = await sendPasswordResetEmail({ variables: { _id: id } });

      const pwResetUrl = _.get(res, "data.sendPasswordResetEmail");

      let showLink = false;
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(pwResetUrl);
      } else {
        showLink = true;
        console.warn("Clipboard API not supported");
      }

      snack(`Password reset link sent to currently configured email (${showLink ? pwResetUrl : "also copied to clipboard"})`);
    } catch (err) {
      snack(err.message, "error");
    }
  };

  useEffect(() => {
    resetForm(initForm);
  }, [initForm]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <DialogTitle>{isEdit ? "Edit" : "Create new"} Admin</DialogTitle>
      <DialogContent>
        <TextField
          label="First Name"
          name="firstName"
          fullWidth
          variant="outlined"
          margin="normal"
          value={form.firstName || ""}
          onChange={handleChange}
        />
        <TextField
          label="Last Name"
          name="lastName"
          fullWidth
          variant="outlined"
          margin="normal"
          value={form.lastName || ""}
          onChange={handleChange}
        />
        <TextField
          label="Email"
          name="email"
          fullWidth
          variant="outlined"
          margin="normal"
          value={form.email || ""}
          onChange={handleChange}
        />

        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel>Authorization</InputLabel>
          <Select name="auth" value={form.auth || ""} onChange={handleChange} label="Authorization">
            <MenuItem value="vantage admin">Vantage Admin</MenuItem>
            <MenuItem value="vantage facilitator">Vantage Facilitator</MenuItem>
          </Select>
        </FormControl>
        {form.auth === "vantage facilitator" && (
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Organizations</InputLabel>
            <Select name="organizations" value={form.organizations} multiple onChange={handleChange} label="Organizations">
              {organizations &&
                organizations.map(({ name, id }) => {
                  return (
                    <MenuItem value={id} key={id}>
                      {name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        {isVantageAdmin(auth) && isEdit && (
          <Button style={{ marginRight: "auto" }} onClick={handleResetPassword} disabled={resetPwLoading}>
            Send Reset Password Link
          </Button>
        )}
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAdminDialog;

const UPDATE_USER = gql`
  mutation ($id: ID!, $firstName: String, $lastName: String, $email: String, $auth: String, $organizations: [ID!]) {
    updateUser(id: $id, firstName: $firstName, lastName: $lastName, email: $email, auth: $auth, organizations: $organizations)
  }
`;

const CREATE_ADMIN = gql`
  mutation ($firstName: String!, $lastName: String!, $email: String!, $auth: String!, $organizations: [ID!]) {
    createAdmin(firstName: $firstName, lastName: $lastName, email: $email, auth: $auth, organizations: $organizations)
  }
`;

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation ($email: String, $_id: ID) {
    sendPasswordResetEmail(email: $email, _id: $_id)
  }
`;
