import React from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import _ from "lodash";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Avatar } from "@material-ui/core";

import styles from "./Bugs.module.scss";
import { formatAs } from "../../utils/dates";

const ActionDialog = ({ open, handleClose, snack, requestFetch, report }) => {
  const { id, title, description, author, createdAt } = report;
  const [deleteReport] = useMutation(DELETE_REPORT);

  const handleDelete = async () => {
    try {
      const ok = await deleteReport({ variables: { id } });

      if (ok) {
        snack(`Deleted report: ${title}`);
        requestFetch();
      }
    } catch (err) {
      snack("Failed to delete report", "error");
    }
  };

  return (
    <>
      <Dialog
        open={open.view}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        fullWidth
      >
        <div className={styles.author}>
          <Avatar
            style={{ width: 24, height: 24, margin: 10 }}
            src={author.profilePicture}
            alt={`${author.name.first} ${author.name.last}`}
          >
            {author.name.first[0]}
            {author.name.last[0]}
          </Avatar>
          <Typography variant="subtitle1">
            {author.name.first} {author.name.last} - {formatAs(createdAt, "MMM d")}
          </Typography>
        </div>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography>{description}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open.delete}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
      >
        <DialogTitle>Delete Report</DialogTitle>
        <DialogContent>Are you sure you want to delete the report?</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleDelete} variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActionDialog;

const DELETE_REPORT = gql`
  mutation ($id: ID!) {
    deleteReport(id: $id)
  }
`;
