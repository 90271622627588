import { useState, useContext } from "react";
import { FetchContext } from "../context/fetchContext";
import { SnackbarContext } from "../context/snackbarContext";
import { upperFirst, camelCase } from "lodash";

function useConfirmDelete({ id, value, itemType, itemTypeSnackAlias }) {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);

  const handleConfirmOpen = (open) => () => {
    setConfirmOpen(open);
  };

  function toPascalCase(str) {
    return upperFirst(camelCase(str));
  }

  const handleDelete = (deleteMutation) => async () => {
    try {
      const ok = await deleteMutation({ variables: { id } });
      let delMutationData = `delete${toPascalCase(itemType)}`;

      if (ok.data[delMutationData]) {
        snack(`Deleted "${value}" ${itemTypeSnackAlias ? itemTypeSnackAlias : itemType}`);
        //   requestFetch();
        handleConfirmOpen(false)();
        return true;
      }
    } catch (err) {
      snack(`Failed to delete ${itemTypeSnackAlias ? itemTypeSnackAlias : itemType}`, "error");
    }

    return false;
  };

  return {
    confirmOpen,
    handleConfirmOpen,
    handleDelete,
  };
}

export default useConfirmDelete;
