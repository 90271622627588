import React, { useContext, useEffect, useState } from "react";
import styles from "./AccountabilityChartList.module.scss";
import _ from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Button, CircularProgress, Container, Grid } from "@material-ui/core";
import { useAuth } from "../../context/authContext";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import Loading from "../../components/Loading/Loading";
import { v4 } from "uuid";
import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { isAuthed } from "../../utils/authorization";
import ChartCard from "./ChartCard";
import CustomConfirmDialog from "../../components/CustomConfirmDialog/CustomConfirmDialog";
import { CHART_FIELDS } from "../../utils/fragments";

const AccountabilityChartList = ({ params }) => {
  const { auth } = useAuth();
  const { departmentFilter } = useDepartmentFilter();
  const { fetch, requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const isAdmin = isAuthed(auth, "department admin");

  const [dialogData, setDialogData] = useState({ title: "", message: "", callback: () => {}, confirmButtonText: "Proceed" });
  const [dialogOpen, setDialogOpen] = useState(false);

  const { data, loading, refetch } = useQuery(GET_COMPANY_CHARTS, {
    variables: {
      organization: params.org,
      sharedPlanId: departmentFilter.sharedPlanId,
    },
  });

  const [createChart, { loading: createLoading }] = useMutation(CREATE_CHART, {
    update(cache, { data: { createChart } }) {
      cache.modify({
        fields: {
          charts(existingCharts = []) {
            const newChartRef = cache.writeFragment({
              data: createChart,
              fragment: CHART_FIELDS,
            });
            return [...existingCharts, newChartRef];
          },
        },
      });
    },
  });
  const [togglePinChart, { loading: pinLoading }] = useMutation(TOGGLE_PIN_CHART);
  const [deleteChart, { loading: deleteLoading }] = useMutation(DELETE_CHART, {
    update(cache, { data: { deleteChart } }) {
      const deletedChartId = cache.identify(deleteChart);
      cache.modify({
        fields: {
          charts: (existingCharts) => {
            return existingCharts.filter((chartRef) => {
              const chartId = cache.identify(chartRef);
              return chartId !== deletedChartId;
            });
          },
        },
      });
    },
  });

  const handleCreateChart = async () => {
    const newDefaultCards = getDefaultChart();
    const newChart = {
      id: v4(),
      createdBy: auth.id,
      isFavorite: false,
      isCompanyChart: false,
      name: "New chart",
      organization: params.org,
      plan: departmentFilter.id,
      status: "draft",
      cards: newDefaultCards,
    };

    try {
      const response = await createChart({ variables: newChart });
      const newChartId = _.get(response, "data.createChart.id");
      if (!_.isNil(newChartId)) {
        snack("New chart created");
        // requestFetch();
      }
    } catch (err) {
      snack("Failed to create new chart", "error");
    }
  };

  const handleDuplicate = (chart) => async () => {
    const { name, organization, plan, cards } = getDuplicatedChart(chart);
    const newChart = {
      id: v4(),
      createdBy: auth.id,
      isFavorite: false,
      isCompanyChart: false,
      name,
      organization,
      plan: _.get(plan, "id", null),
      status: "draft",
      cards: cards.map((card) => _.omit(card, "__typename")),
    };
    const response = await createChart({ variables: newChart });
    const newChartId = _.get(response, "data.createChart.id");
    if (!_.isNil(newChartId)) {
      snack("Chart Duplicated");
      // requestFetch();
    } else {
      snack("Something went wrong, please try again later", "error");
    }
  };

  const handlePinAsCompanyChart = (id, isCompanyChart) => async () => {
    if (pinLoading) {
      return;
    }

    try {
      const response = await togglePinChart({
        variables: {
          id,
          isCompanyChart: !isCompanyChart,
        },
      });

      const newChartId = _.get(response, "data.updateChart.chart.id");
      if (!_.isNil(newChartId)) {
        snack(`Chart ${isCompanyChart ? "Unpinned" : "Pinned"}`);
        setDialogOpen(false);
        // requestFetch();
      }
    } catch (err) {
      snack("Failed to pin chart as company chart", "error");
    }
  };

  const handleDelete = (id) => async () => {
    try {
      const response = await deleteChart({
        variables: { id },
      });
      if (response.data.deleteChart) {
        snack(`Chart deleted`);
        // requestFetch();
      }
    } catch (err) {
      snack("Failed to delete chart", "error");
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSetDialogData = (data) => () => {
    const { title, message, callback, confirmButtonText } = data;
    setDialogData({ title, message, callback, confirmButtonText });
  };

  const handleOpenDialog = (data) => () => {
    handleSetDialogData(data)();
    setDialogOpen(true);
  };

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [fetch]);

  if (loading || !data) return <Loading />;

  const companyChart = _.find(_.get(data, "charts", []), { isCompanyChart: true });
  const nonCompanyChart = _.get(data, "charts", []).filter((chart) => !chart.isCompanyChart);

  return (
    <>
      <Container maxWidth={false}>
        <div style={{ display: "flex", borderBottom: "1px solid gray" }}>
          <Button
            startIcon={createLoading ? <CircularProgress size={18} thickness={4.8} /> : <Icon path={mdiPlus} size={1} color="#fff" />}
            className={styles.icon}
            onClick={handleCreateChart}
            variant="contained"
            color="primary"
            disabled={!isAdmin || createLoading}
          >
            New Chart
          </Button>
        </div>
        {_.isNil(companyChart) && _.isEmpty(nonCompanyChart) && <div>No chart created</div>}
        <Grid container spacing={3} style={{ marginTop: "16px" }}>
          {!_.isNil(companyChart) && (
            <Grid item xs={12}>
              <ChartCard
                label="COMPANY R&R CHART"
                name={companyChart.name}
                plan={companyChart.plan}
                updatedAt={companyChart.updatedAt}
                linkPath={`/${params.org}/chart/${companyChart.id}`}
                isCompanyChart={companyChart.isCompanyChart}
                handleDuplicate={handleDuplicate(companyChart)}
                handleDelete={handleDelete(companyChart.id)}
                handlePinAsCompanyChart={handlePinAsCompanyChart(companyChart.id, companyChart.isCompanyChart)}
                handleOpenDialog={handleOpenDialog}
                isAdmin={isAdmin}
              />
            </Grid>
          )}
          {!_.isEmpty(nonCompanyChart) &&
            nonCompanyChart.map((chart, idx) => (
              <Grid item xs={12} sm={6} md={4} key={chart.id}>
                <ChartCard
                  label={chart.status}
                  name={chart.name}
                  plan={chart.plan}
                  updatedAt={chart.updatedAt}
                  isCompanyChart={chart.isCompanyChart}
                  linkPath={`/${params.org}/chart/${chart.id}`}
                  handleDuplicate={handleDuplicate(chart)}
                  handleDelete={handleDelete(chart.id)}
                  handlePinAsCompanyChart={handlePinAsCompanyChart(chart.id, chart.isCompanyChart)}
                  handleOpenDialog={handleOpenDialog}
                  isAdmin={isAdmin}
                />
              </Grid>
            ))}
          <Grid item xs={12}></Grid>
        </Grid>
      </Container>
      <CustomConfirmDialog
        dialogData={dialogData}
        open={dialogOpen}
        onClose={handleCloseDialog}
        onExited={handleSetDialogData({ title: "", message: "", callback: () => {}, confirmButtonText: "Proceed" })}
      />
    </>
  );
};

export default AccountabilityChartList;

const GET_COMPANY_CHARTS = gql`
  ${CHART_FIELDS}
  query AccountabilityChartList_GetCompanyCharts($organization: ID, $sharedPlanId: String) {
    charts(organization: $organization, sharedPlanId: $sharedPlanId) {
      ...ChartFields
    }
  }
`;

const CREATE_CHART = gql`
  ${CHART_FIELDS}
  mutation AccountabilityChartList_CreateChart(
    $id: ID
    $createdBy: String
    $cards: [CardInput!]
    $isFavorite: Boolean
    $isCompanyChart: Boolean
    $name: String
    $organization: String
    $plan: String
    $status: String
  ) {
    createChart(
      id: $id
      createdBy: $createdBy
      cards: $cards
      isFavorite: $isFavorite
      isCompanyChart: $isCompanyChart
      name: $name
      organization: $organization
      plan: $plan
      status: $status
    ) {
      ...ChartFields
    }
  }
`;

const TOGGLE_PIN_CHART = gql`
  ${CHART_FIELDS}
  mutation AccountabilityChartList_TogglePinChart($id: ID!, $isCompanyChart: Boolean) {
    updateChart(id: $id, isCompanyChart: $isCompanyChart) {
      chart {
        ...ChartFields
      }
      charts {
        ...ChartFields
      }
    }
  }
`;

const DELETE_CHART = gql`
  mutation AccountabilityChartList_DeleteChart($id: ID!) {
    deleteChart(id: $id) {
      id: _id
    }
  }
`;

const getDefaultChart = () => {
  const visionaryId = v4();
  const intergratorId = v4();
  const subChildOneId = v4();
  const subChildTwoId = v4();
  const subChildThreeId = v4();
  const defaultCards = [
    {
      cardId: visionaryId,
      parentNode: null,
      childNodes: [intergratorId],
      assistantNodes: [],
      user: null,
      name: null,
      title: null,
      roles: "Visionary",
      responsibilities: "Responsibility 1\nResponsibility 2",
    },
    {
      cardId: intergratorId,
      parentNode: visionaryId,
      childNodes: [subChildOneId, subChildTwoId, subChildThreeId],
      assistantNodes: [],
      user: null,
      name: null,
      title: null,
      roles: "Integrator",
      responsibilities: "Responsibility 1\nResponsibility 2",
    },
    {
      cardId: subChildOneId,
      parentNode: intergratorId,
      childNodes: [],
      assistantNodes: [],
      user: null,
      name: null,
      title: null,
      roles: "Sales & Marketing",
      responsibilities: "Responsibility 1\nResponsibility 2",
    },
    {
      cardId: subChildTwoId,
      parentNode: intergratorId,
      childNodes: [],
      assistantNodes: [],
      user: null,
      name: null,
      title: null,
      roles: "Operations",
      responsibilities: "Responsibility 1\nResponsibility 2",
    },
    {
      cardId: subChildThreeId,
      parentNode: intergratorId,
      childNodes: [],
      assistantNodes: [],
      user: null,
      name: null,
      title: null,
      roles: "Finance",
      responsibilities: "Responsibility 1\nResponsibility 2",
    },
  ];

  return defaultCards;
};

const getDuplicatedChart = (chart) => {
  let nodeIdReplaceMap = {}; // {key: originalId, value: replacementId}
  let chartCopy = _.cloneDeep(chart);
  let cardIds = chartCopy.cards.map((card) => card.cardId);
  cardIds.forEach((id) => {
    nodeIdReplaceMap[id] = v4();
  });

  chartCopy.cards.forEach((card) => {
    const { cardId, parentNode, childNodes, assistantNodes, user } = card;
    // replace user object with id
    if (!_.isNil(user)) {
      card.user = user.id;
    }

    // replace cardId
    card.cardId = nodeIdReplaceMap[cardId];

    // replace parentNode
    if (!_.isNil(parentNode)) {
      card.parentNode = nodeIdReplaceMap[parentNode];
    }

    // replace childNodes
    const newChildNodes = childNodes.map((childNode) => {
      return nodeIdReplaceMap[childNode];
    });
    card.childNodes = newChildNodes;
    //replace assistantNodes
    const newAssistantNodes = assistantNodes.map((assistantNode) => {
      return nodeIdReplaceMap[assistantNode];
    });
    card.assistantNodes = newAssistantNodes;
  });

  return chartCopy;
};
