import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import styles from "./ProjectsPage.module.scss";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { FetchContext } from "../../context/fetchContext";
import { useAuth } from "../../context/authContext";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
import { isAuthed } from "../../utils/authorization";
import {
  Container,
  Grid,
  Typography,
  Hidden,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import Loading from "../../components/Loading/Loading";
import Projects from "../../components/Projects/Projects";
import AddProjectDialog from "./AddProjectDialog";

const ProjectsPage = ({ params }) => {
  const { fetch } = useContext(FetchContext);
  const { auth } = useAuth();
  const { departmentFilter } = useDepartmentFilter();

  const [addProject, setAddProject] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState({ open: false, callback: null, text: "" });

  const { loading, data, refetch } = useQuery(GET_ORGANIZATION, {
    variables: { id: params.org, plan: departmentFilter.id },
  });

  const handleAddProject = (open) => () => {
    setAddProject(open);
  };

  const handleConfirmDelete =
    (open, callback = null, text = "") =>
    () => {
      setConfirmDelete({ open, callback, text });
    };

  useEffect(() => {
    refetch();
  }, [fetch, departmentFilter]);

  if (loading) return <Loading />;

  if (!data.organization) return null; //TODO remove this check
  const { fiscalYear, viewPreferences } = data.organization;
  const { plans, projects } = data;
  const currentYearPlans = _.filter(plans, (plan) => plan.year === fiscalYear && plan.category === "1 year");
  let showProjects = true;
  // TODO get view preference for Project
  const sortedView = _.sortBy(viewPreferences, "position");

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          {showProjects && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                  <Typography variant="h5" className={styles.label}>
                    Projects
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Projects projects={projects} handleConfirmDelete={handleConfirmDelete} />
                </Grid>
                <Grid item xs={12} sm={1}>
                  <div className={styles.flexEnd}>
                    <Button variant="outlined" onClick={handleAddProject(true)} disabled={!isAuthed(auth, "company admin")}>
                      New Project
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>

      <Dialog
        open={confirmDelete.open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleConfirmDelete(false)();
          }
        }}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmDelete.text}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleConfirmDelete(false)}>Dismiss</Button>
          <Button
            onClick={() => {
              confirmDelete.callback();
            }}
            className={styles.delete}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <AddProjectDialog id={params.org} open={addProject} handleClose={handleAddProject(false)} plans={currentYearPlans} />
    </>
  );
};

export default ProjectsPage;

const GET_ORGANIZATION = gql`
  query ProjectsPage_GetOrg($id: ID!, $plan: ID) {
    organization(id: $id, plan: $plan) {
      id
      name
      fiscalYear
      profilePicture
      plansOrder
      core {
        purpose
        value
        competency
        market
        offering
        valueProposition
        differentiation
        process
      }
      createdAt
      bhag {
        goal
        targetDate
      }
      viewPreferences {
        name
        position
        show
      }
      notes {
        bhag {
          id
          date
        }
        purpose {
          id
          date
        }
        value {
          id
          date
        }
        competency {
          id
          date
        }
        market {
          id
          date
        }
        offering {
          id
          date
        }
        valueProposition {
          id
          date
        }
        process {
          id
          date
        }
        differentiation {
          id
          date
        }
      }
    }

    plans(organization: $id, closed: false) {
      id
      departmentName
      theme
      year
      useDepartmentTheme
      sharedPlanId
      category
      color
      shortName
      targetDate
    }

    projects(organization: $id) {
      id
      value
      plan {
        id
        departmentName
      }
      notes {
        id
        date
      }
    }
  }
`;
