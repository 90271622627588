import { useState, useContext } from "react";
import { v4 } from "uuid";
import imageCompression from "browser-image-compression";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { getNotes, getNestedNotes, getSubArrayFieldNotes } from "../../utils/graphql";
import { deserialize, serialize } from "../RichText/functions";
import { FetchContext } from "../../context/fetchContext";
import { useParams } from "react-router-dom";
import { deleteFileFromS3, getFileFromS3, uploadFileToS3 } from "../../utils/aws";
import { S3_SIGNED_URL_OPERATIONS } from "../../utils/const";

function useNotes({ user, snack, notes, model, setRefetchId, openInEditMode, onCreateNote }) {
  const params = useParams();

  const [data, setData] = useState([]);
  const { requestFetch } = useContext(FetchContext);
  const [editMode, setEditMode] = useState({ open: false, text: deserialize(""), file: null, id: null, filename: null, url: null });

  const [createNote] = useMutation(CREATE_NOTE);
  const [updateNote] = useMutation(UPDATE_NOTE);
  const [deleteNote] = useMutation(DELETE_NOTE);
  const [getSignedUrlS3] = useMutation(GET_SIGNED_URL_S3);

  const getData = async (id, model, core, additionalProps) => {
    setData([]);
    if (core) {
      const res = await getNestedNotes(id, model, core);
      const resData = _.get(res, `data.${model}`);
      if (resData) {
        setData(resData.notes[core]);
      }
    } else if (id) {
      // Get notes for objectives, metrics, rocks, success criteria, todos, and issues
      let modelName = model;
      if (model === "issue") {
        modelName = "todo";
      }
      let res;
      let resData;
      if (_.isNil(additionalProps.path)) {
        res = await getNotes(id, modelName, additionalProps);
        resData = _.get(res, `data.${modelName}`);
      } else {
        const subArrayField = additionalProps.path.split(".")[0];
        res = await getSubArrayFieldNotes(id, modelName, subArrayField);
        resData = _.get(res, `data.${modelName}.${additionalProps.path}`);
      }

      if (_.get(resData, "notes")) {
        setData(resData.notes);
      }
    }
  };

  const handleClose = () => {
    if (notes) notes(null, model, null, undefined, null, null, {});

    setEditMode({ open: false, text: deserialize(""), file: null, id: null, filename: null, url: null });
  };

  const handleExitDialog = () => {
    notes(null, null, null, undefined, null, null, {});
  };

  const handleEditMode = (open, note) => () => {
    if (!note) {
      // create new notes
      setEditMode({ open, text: deserialize(""), file: null, id: null, filename: null, url: null });
    } else {
      // updating existing notes
      const { id, text, filename, url } = note;
      setEditMode({ open, text: deserialize(text), file: null, id, filename, url });
    }
  };

  const handleChange = (e) => {
    const { name } = e.target;
    if (name === "text") {
      setEditMode({ ...editMode, text: e.target.value });
    } else if (name === "file") {
      if (!_.isEmpty(e.target.files) && e.target.validity.valid) {
        setEditMode({ ...editMode, file: e.target.files[0] });
      }
    }
  };

  const uploadFile = async (file) => {
    const fileType = file.type;
    const extension = _.last(fileType.split("/"));

    const name = file.name.split(".").slice(0, -1);
    //Append a random string to the image to prevent name collisions
    const fileName = `organizations/${params.org}/notes/${name}-${_.first(v4().split("-"))}.${extension}`;

    const url = await uploadFileToS3({
      snack,
      typeStr: "file",
      file,
      fileName,
      fileType,
      getSignedUrlAsync: async (variables) =>
        getSignedUrlS3({
          variables,
        }),
    });

    return url && { url, filename: _.last(fileName.split("notes/")) };
  };

  const handleCreate =
    ({ referenceId, referenceIds, model, core, additionalProps }) =>
    async () => {
      const { text, file } = editMode;

      const refObj = referenceId ? { referenceId } : { referenceIds };

      let fileToUpload = file;
      if (fileToUpload) {
        const type = fileToUpload.type.includes("image") ? "img" : "doc";
        if (type === "img") {
          const compressionOptions = { maxSizeMB: 1 };
          fileToUpload = await imageCompression(file, compressionOptions);
        }

        const uploadRes = await uploadFile(fileToUpload);

        if (!uploadRes) return;

        const { url, filename } = uploadRes;
        await handleCreateNote({
          ...refObj,
          model,
          core,
          additionalProps,
          text,
          filename,
          type,
          url,
        });
      } else {
        handleCreateNote({ ...refObj, model, core, additionalProps, text, type: "doc" });
      }
    };

  const handleUpdate =
    ({ origReferenceId, newReferenceId, origReferenceIds, newReferenceIds, model, core, origAdditionalProps, newAdditionalProps }) =>
    async () => {
      const { text, file, url } = editMode;
      let fileToUpload = file;

      const refObj = origReferenceId || newReferenceId ? { origReferenceId, newReferenceId } : { origReferenceIds, newReferenceIds };

      if (fileToUpload) {
        const type = fileToUpload.type.includes("image") ? "img" : "doc";
        if (type === "img") {
          const compressionOptions = { maxSizeMB: 1 };
          fileToUpload = await imageCompression(file, compressionOptions);
        }

        const uploadRes = await uploadFile(file);

        if (!uploadRes) return;

        const { url: s3ObjectUrl, filename } = uploadRes;
        await handleUpdateNote({
          ...refObj,
          model,
          core,
          origAdditionalProps,
          newAdditionalProps,
          text: serialize(text),
          filename,
          type,
          url: s3ObjectUrl,
        });

        // Delete note's previous (image/doc) file if exists
        if (!_.isNil(url)) await deleteFile(url, "previously uploaded file");
      } else {
        handleUpdateNote({ ...refObj, model, core, origAdditionalProps, newAdditionalProps, text: serialize(text) });
      }
    };

  const handleCreateNote = async ({ referenceId, referenceIds, model, core, additionalProps, text, type, filename = null, url = null }) => {
    try {
      const res = await createNote({
        variables: {
          referenceId,
          referenceIds,
          referenceModel: model,
          additionalProps,
          user: user.id,
          text: serialize(text),
          url,
          filename,
          type,
          core,
        },
      });
      if (res.data.createNote) {
        if (onCreateNote) onCreateNote();
        snack(`Created ${filename || "note"}`);

        if (referenceId) getData(referenceId, model, core, additionalProps);

        requestFetch();
        // setRefetchId(core || id);
      }

      setEditMode({ open: false, text: deserialize(""), file: null, id: null, filename: null, url: null });
    } catch (err) {
      snack("Failed to create note", "error");
    }
  };

  const handleUpdateNote = async ({
    origReferenceId,
    newReferenceId,
    origReferenceIds,
    newReferenceIds,
    model,
    core,
    origAdditionalProps,
    newAdditionalProps,
    text,
    type,
    filename,
    url,
  }) => {
    try {
      const res = await updateNote({
        variables: {
          id: editMode.id,
          origReferenceId,
          newReferenceId,
          origReferenceIds,
          newReferenceIds,
          referenceModel: model,
          origAdditionalProps,
          newAdditionalProps,
          text,
          url,
          filename,
          type,
          core,
        },
      });
      if (res.data.updateNote) {
        snack("Updated Note");

        if (newReferenceId) getData(newReferenceId, model, core, newAdditionalProps);

        requestFetch();
        // setRefetchId(core || id);
      }

      setEditMode({ open: false, text: deserialize(""), file: null, id: null, filename: null, url: null });
    } catch (err) {
      snack("Failed to update note", "error");
    }
  };

  const deleteFile = async (fileUrl, typeStr = "uploaded file") => {
    return await deleteFileFromS3({
      snack,
      typeStr,
      objectUrl: fileUrl,
      getSignedUrlAsync: async (variables) =>
        getSignedUrlS3({
          variables,
        }),
    });
  };

  const handleDelete =
    ({ id, referenceId, referenceIds, model, filename, url, core, additionalProps }) =>
    async () => {
      try {
        const res = await deleteNote({
          variables: { id, referenceId, referenceIds, referenceModel: model, core, additionalProps },
        });
        if (res.data.deleteNote) {
          snack(`Deleted ${filename || "note"}`);
          if (!_.isEmpty(url)) await deleteFile(url);
          if (referenceId) getData(referenceId, model, core, additionalProps);

          requestFetch();
          // setRefetchId(core || referenceId);
        }
      } catch (err) {
        snack("Failed to delete note", "error");
      }
    };

  const handleRemoveImage = async (id, model, core, filename, url, noteId, additionalProps) => {
    if (filename !== null) {
      const updateArgs = { id: noteId, type: "doc", filename: null, url: null };

      try {
        const res = await updateNote({
          variables: { id: noteId, ...updateArgs },
        });

        if (res.data.updateNote) {
          snack("Removed uploaded file");
          await deleteFile(url);

          if (id) getData(id, model, core, additionalProps);

          requestFetch();
        }

        setEditMode({ open: false, text: deserialize(""), file: null, id: null, filename: null, url: null });
      } catch (err) {
        snack("Failed to remove uploaded file", "error");
      }
    }
  };

  const convertJPEGtoPNG = async (jpegBlob) => {
    try {
      // Convert the JPEG blob to a PNG blob
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();

      img.src = URL.createObjectURL(jpegBlob);

      // Use a Promise to wait for the PNG blob
      return new Promise((resolve) => {
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          canvas.toBlob((pngBlob) => {
            // Resolve the Promise with the PNG blob
            resolve(pngBlob);
          }, "image/png");
        };
      });
    } catch (error) {
      throw error; // Re-throw the error for the caller to handle
    }
  };

  const setToClipboard = async (blob) => {
    const data = [new window.ClipboardItem({ [blob.type]: blob })];
    await navigator.clipboard.write(data);
  };

  const handleCopyImageToClipboard = async (url) => {
    if (_.isEmpty(url)) return;

    try {
      let blob = await getFileFromS3({
        objectUrl: url,
        snack,
        typeStr: "uploaded file",
        getSignedUrlAsync: async (variables) =>
          getSignedUrlS3({
            variables,
          }),
      });

      if (!blob) return;

      if (blob.type === "image/jpeg") {
        blob = await convertJPEGtoPNG(blob); // ClipboardAPI only works with PNGs
      }

      await setToClipboard(blob);

      snack("Copied image to clipboard");
    } catch (error) {
      console.log(error);
      snack("Unable to copy image to clipboard", "error");
    }
  };

  const handleOpenFileInNewTab = async (url) => {
    const fileName = _.last(url.split("amazonaws.com/"));

    try {
      const res = await getSignedUrlS3({
        variables: { fileName, operation: S3_SIGNED_URL_OPERATIONS["get"] },
      });

      const signedUrl = _.get(res, "data.generateSignedUrlS3");
      if (signedUrl) {
        window.open(signedUrl, "_blank");
      }
    } catch (err) {
      snack("Failed to open uploaded file, please try again later", "error");
    }
  };

  return {
    data,
    editMode,
    getData,
    handleClose,
    handleExitDialog,
    handleEditMode,
    handleChange,
    handleCreate,
    handleUpdate,
    handleDelete,
    handleRemoveImage,
    handleCopyImageToClipboard,
    handleOpenFileInNewTab,
  };
}

export default useNotes;

const CREATE_NOTE = gql`
  mutation (
    $referenceId: ID
    $referenceIds: [ID]
    $referenceModel: String
    $user: ID!
    $text: String
    $url: String
    $filename: String
    $type: String!
    $core: String
    $additionalProps: AdditionalProps
  ) {
    createNote(
      referenceId: $referenceId
      referenceIds: $referenceIds
      referenceModel: $referenceModel
      user: $user
      text: $text
      url: $url
      filename: $filename
      type: $type
      core: $core
      additionalProps: $additionalProps
    )
  }
`;

const UPDATE_NOTE = gql`
  mutation (
    $id: ID!
    $origReferenceId: ID
    $newReferenceId: ID
    $origReferenceIds: [ID]
    $newReferenceIds: [ID]
    $referenceModel: String
    $text: String
    $url: String
    $filename: String
    $type: String
    $core: String
    $origAdditionalProps: AdditionalProps
    $newAdditionalProps: AdditionalProps
  ) {
    updateNote(
      id: $id
      origReferenceId: $origReferenceId
      newReferenceId: $newReferenceId
      origReferenceIds: $origReferenceIds
      newReferenceIds: $newReferenceIds
      referenceModel: $referenceModel
      text: $text
      url: $url
      filename: $filename
      type: $type
      core: $core
      origAdditionalProps: $origAdditionalProps
      newAdditionalProps: $newAdditionalProps
    )
  }
`;

const DELETE_NOTE = gql`
  mutation ($id: ID, $referenceId: ID, $referenceIds: [ID], $referenceModel: String, $core: String, $additionalProps: AdditionalProps) {
    deleteNote(
      id: $id
      referenceId: $referenceId
      referenceIds: $referenceIds
      referenceModel: $referenceModel
      core: $core
      additionalProps: $additionalProps
    )
  }
`;

const GET_SIGNED_URL_S3 = gql`
  mutation ($fileName: String!, $fileType: String, $operation: String!) {
    generateSignedUrlS3(fileName: $fileName, fileType: $fileType, operation: $operation)
  }
`;
