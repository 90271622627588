import { gql, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { ROCK_FIELDS } from "../../../utils/fragments";
import Loading from "../../../components/Loading/Loading";
import useCorpPlan from "../../../hooks/useCorpPlan";
import _ from "lodash";
import Rocks from "../../../components/Rocks/Rocks";

const RockStep = ({ userIds, org, canEdit }) => {
  const variables = { users: userIds, organization: org.id };
  const { subscribeToMore, data, loading } = useQuery(ROCK_STEP_QUERY, { variables });
  const { corpForSelectedYear } = useCorpPlan({ fiscalYear: org.fiscalYear, orgId: org.id });
  useEffect(
    () =>
      subscribeToMore({
        document: ROCK_STEP_SUBSCRIPTION,
        variables: { users: userIds },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const rock = subscriptionData.data.rockPayload.rockMutated;
          const action = subscriptionData.data.rockPayload.action;
          let newRocks;
          switch (action) {
            case "update":
              newRocks = prev.rocks.map((r) => (r.id === rock.id ? rock : r));
              break;
            case "delete":
              newRocks = prev.rocks.filter((r) => r.id !== rock.id);
              break;
            case "create":
              newRocks = [...prev.rocks, rock];
              break;
            default:
              break;
          }

          return Object.assign({}, prev, {
            rocks: newRocks,
          });
        },
      }),
    []
  );

  if (loading) return <Loading />;

  return (
    <div>
      <Rocks rocks={data.rocks} fiscalYear={org.fiscalYear} planId={corpForSelectedYear.id} variables={variables} canEdit={canEdit} />
    </div>
  );
};

export default RockStep;

const ROCK_STEP_QUERY = gql`
  ${ROCK_FIELDS}
  query RockStepQuery($organization: ID!, $users: [ID!]) {
    rocks(organization: $organization, users: $users) {
      ...RockFields
      objective {
        id
        value
      }
    }
  }
`;

const ROCK_STEP_SUBSCRIPTION = gql`
  ${ROCK_FIELDS}
  subscription OnRockMutation($users: [ID!]) {
    rockPayload(users: $users) {
      rockMutated {
        ...RockFields
      }
      action
    }
  }
`;
