import { Typography } from "@material-ui/core";
import { differenceInSeconds } from "date-fns";
import React, { useEffect, useState } from "react";
import styles from "./Meetings.module.scss";
import _ from "lodash";

const MeetingTimer = ({ currentSessionSubscription }) => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let interval;
    if (!_.isNil(_.get(currentSessionSubscription, "meetingSub.session.sessionStartTime"))) {
      interval = setInterval(
        () =>
          setTimer(differenceInSeconds(new Date(), new Date(currentSessionSubscription.meetingSub.session.sessionStartTime) || new Date())),
        1000
      );
    }

    return () => clearInterval(interval);
  }, [currentSessionSubscription]);

  return (
    <Typography variant="body1" align="center">
      {formatAsTime(timer)}
    </Typography>
  );
};

export default MeetingTimer;

const formatAsTime = (seconds) => {
  let time, status;
  if (seconds <= 0) {
    time = Math.abs(seconds);
    status = "error";
  } else {
    time = seconds;
    // if (seconds <= 60) {
    //   status = "warning";
    // }
  }
  const hours = Math.floor(time / 60 / 60);
  const mins = Math.floor(time / 60) - hours * 60;
  const secs = time % 60;

  if (status) {
    return (
      <span className={status === "error" ? styles.delete : undefined}>
        {seconds < 0 && "-"}
        {mins.toString().padStart(2, "0") + ":" + secs.toString().padStart(2, "0")}
      </span>
    );
  }
  return <span className={styles.timer}>{mins.toString().padStart(2, "0") + ":" + secs.toString().padStart(2, "0")}</span>;
};
