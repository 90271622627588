import React, { useEffect, useState, useRef, useMemo } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import _ from "lodash";

function useLLM({ snack, open, suggestionsLoading }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const [llmSuggestions, setLlmSuggestions] = useState([]);
  const [llmSuggestionsListOpen, setLlmSuggestionsListOpen] = useState(false);
  const [llmSettingsOpen, setLlmSettingsOpen] = useState("");

  const [canUndo, setCanUndo] = useState(false);

  const abortControllerRef = useRef(null);

  const [updateLlmSettings] = useMutation(UPDATE_LLM_SETTINGS);

  const { data: llmSettingsData, refetch: llmSettingsRefetch, loading: llmSettingsLoading } = useQuery(GET_ALL_LLM_SETTINGS);

  const [rephraseText, { data: rephraseTextData, loading: rephraseTextLoading, error: rephraseTextError }] = useLazyQuery(REPHRASE_TEXT, {
    fetchPolicy: "network-only", // Ensures data is always fetched from the server
  });

  const llmSettingsByPurpose = useMemo(() => {
    if (_.isNil(llmSettingsData)) return {};

    const llmSettings = _.get(llmSettingsData, "allLLMSettings", []);
    return _.keyBy(llmSettings, "purpose");
  }, [llmSettingsData]);

  const handleOpenAdminMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAdminMenu = () => {
    setAnchorEl(null);
  };

  const handleCancelBrainstorm = () => {
    if (suggestionsLoading) setLlmSuggestions([]);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null; // Reset the ref for future use
    }
  };

  const handleSaveLLMSettings = async (newLLMSettings) => {
    const { systemDescription, userPrompt } = newLLMSettings;

    try {
      const res = await updateLlmSettings({
        variables: { id: _.get(llmSettingsByPurpose, [llmSettingsOpen, "id"], {}), systemDescription, userPrompt },
      });

      if (res) {
        snack(`Updated LLM settings (${llmSettingsOpen})`);
        setLlmSettingsOpen(""); // close the dialog
      }
    } catch (err) {
      snack(`Failed to update LLM settings (${llmSettingsOpen})`, "error");
    }
  };

  useEffect(() => {
    if (!open) {
      setCanUndo(false);
    }
  }, [open]);

  return {
    anchorEl,
    setAnchorEl,
    llmSuggestions,
    setLlmSuggestions,
    llmSuggestionsListOpen,
    setLlmSuggestionsListOpen,
    llmSettingsOpen,
    setLlmSettingsOpen,
    canUndo,
    setCanUndo,
    abortControllerRef,
    updateLlmSettings,
    llmSettingsData,
    llmSettingsRefetch,
    llmSettingsLoading,
    rephraseText,
    rephraseTextData,
    rephraseTextLoading,
    llmSettingsByPurpose,
    handleOpenAdminMenu,
    handleCloseAdminMenu,
    handleCancelBrainstorm,
    handleSaveLLMSettings,
  };
}

export default useLLM;

const REPHRASE_TEXT = gql`
  query ($text: String!) {
    rephrase(text: $text)
  }
`;

const GET_ALL_LLM_SETTINGS = gql`
  query {
    allLLMSettings {
      id
      purpose
      systemDescription
      userPrompt
    }
  }
`;

const UPDATE_LLM_SETTINGS = gql`
  mutation ($id: ID!, $systemDescription: String!, $userPrompt: String!) {
    updateLLMSettings(id: $id, systemDescription: $systemDescription, userPrompt: $userPrompt) {
      id
      systemDescription
      userPrompt
      purpose
    }
  }
`;
