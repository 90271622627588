import React, { useEffect, useState } from "react";
import _ from "lodash";
import useForm from "../../hooks/useForm";
import styles from "./Rock.module.scss";
import { isDate, startOfToday } from "date-fns";
import { before } from "../../utils/dates";
import { formatAs } from "../../utils/dates";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { Icon as MdiIcon } from "@mdi/react";
import { mdiDrag } from "@mdi/js";

import { DatePicker } from "@material-ui/pickers";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Icon,
  Divider,
  IconButton,
  Button,
  FormControl,
} from "@material-ui/core";

function SuccessCriteriaList({ rockToEdit, successCriterias = [], onChange, error, handleToBeDeleted, dialog, setDialog, corpPlanId }) {
  const [successCriteriaList, setSuccessCriteriaList] = useState(successCriterias);

  const handleRemove = (i) => {
    let newSCs = _.cloneDeep(successCriteriaList);
    const [removedSc] = newSCs.splice(i, 1);
    if (handleToBeDeleted && _.get(removedSc, "id")) {
      handleToBeDeleted(removedSc);
    }

    setSuccessCriteriaList(newSCs);
    onChange(newSCs);
  };

  const handleAddEdit = (indexToEdit) => (values) => {
    let newSCs = _.cloneDeep(successCriteriaList);
    if (_.isNil(indexToEdit)) {
      newSCs.push(values);
    } else {
      let scToUpdate = newSCs[indexToEdit];
      _.set(newSCs, [indexToEdit], { ...scToUpdate, ...values });
    }
    setSuccessCriteriaList(newSCs);
    onChange(newSCs);
  };

  const handleEditOpen = (index) => {
    setDialog({
      ...dialog,
      addSuccessCriteriaDialog: {
        open: true,
        planId: corpPlanId,
        successCriterion: _.get(successCriterias, [index.toString()], null),
        rock: _.get(rockToEdit, "id"),
        rockDialog: {
          handleSubmit: handleAddEdit(index),
        },
      },
    });
  };

  const handleCreateOpen = () => {
    setDialog({
      ...dialog,
      addSuccessCriteriaDialog: {
        open: true,
        rock: _.get(rockToEdit, "id"),
        rockDialog: {
          handleSubmit: handleAddEdit(),
        },
      },
    });
  };

  const handleDragEnd = ({ draggableId, destination, source }) => {
    if (_.isEqual(source, destination)) return;

    let newSCs = _.cloneDeep(successCriteriaList);

    const [srcItem] = newSCs.splice(source.index, 1);
    newSCs.splice(destination.index, 0, srcItem);

    setSuccessCriteriaList(newSCs);
    onChange(newSCs);
  };

  return (
    <FormControl fullWidth variant="outlined" margin="normal" error={error} className={styles.scContainer}>
      <div className={styles.inputLabel}>Success Criteria</div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="SCF" type="successCriteriaForm">
          {(provided, snapshot) => (
            <List {...provided.droppableProps} ref={provided.innerRef}>
              {_.isEmpty(successCriteriaList) ? (
                <ListItem className={styles.scListItem}>
                  <ListItemText>No success criteria at the moment</ListItemText>
                </ListItem>
              ) : (
                successCriteriaList.map((sc, index) => {
                  const targetDate = sc.targetDate;
                  return (
                    <Draggable key={sc.value} draggableId={`SCF_${sc.id}_${sc.value}`} index={index}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} style={{ ...provided.draggableProps.style }}>
                          <ListItem className={styles.scListItem}>
                            <ListItemIcon className={styles.listIcon}>
                              <div {...provided.dragHandleProps}>
                                <MdiIcon path={mdiDrag} size={1} />
                              </div>
                            </ListItemIcon>
                            <ListItemText style={{ maxWidth: "80%" }}>
                              <div style={{ textDecoration: _.get(sc, "done", false) ? "line-through" : "none" }}>
                                {_.get(sc, `value`, "")}
                              </div>
                              <div className={before(targetDate, startOfToday()) ? styles.targetDatePast : styles.targetDate}>
                                {targetDate && `(${formatAs(targetDate, "MMM dd, yyyy")})`}
                              </div>
                            </ListItemText>
                            <ListItemSecondaryAction>
                              <IconButton onClick={() => handleEditOpen(index)}>
                                <Icon>edit</Icon>
                              </IconButton>
                              <IconButton onClick={() => handleRemove(index)}>
                                <Icon>delete</Icon>
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider />
                        </div>
                      )}
                    </Draggable>
                  );
                })
              )}
              {provided.placeholder}
              <Button variant="outlined" color="primary" style={{ width: "100%", marginTop: 8 }} onClick={handleCreateOpen}>
                <Icon>add_circle</Icon> Add
              </Button>
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </FormControl>
  );
}

export default SuccessCriteriaList;
