import React, { useEffect, useState, useContext } from "react";
import { useAuth } from "../../context/authContext";
import styles from "./AddMeetingDialog.module.scss";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import useForm from "../../hooks/useForm";
import {
  styled,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  Chip,
  Avatar,
  Checkbox,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { TimePicker, DatePicker } from "@material-ui/pickers";
import Loading from "../../components/Loading/Loading";
import { getDay, mergeDateAndTime } from "../../utils/dates";

import SelectUsers from "../SelectUsers/SelectUsers";
import SelectDepartment from "../SelectDepartment/SelectDepartment";
import SelectTimezone from "../SelectTimezone/SelectTimezone";
import MeetingStepsList from "./MeetingStepsList";
import { utcToZonedTime } from "date-fns-tz";
import { MEETING_FIELDS } from "../../utils/fragments";

const StyledChip = styled(Chip)(({ theme }) => ({
  color: "white",
  backgroundColor: theme.palette.primary.main,
  "& .MuiChip-deleteIcon": {
    color: "white",
  },
}));

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const initErrorForm = {
  title: ["required"],
};

function getDialogSteps() {
  return ["General", "Type", "Attendees"];
}

const AddMeetingDialog = ({ dialog, setDialog, requestFetch, fetch, params, snack }) => {
  const { auth } = useAuth();
  const newDate = getDefaultMeetingStartDate();
  const initForm = {
    id: null,
    title: "",
    startDate: newDate,
    startTime: newDate,
    timezone: userTimezone,
    frequency: 0,
    type: "hem",
    steps: [],
    plan: "",
    users: [],
  };

  const [activeStep, setActiveStep] = useState(0);
  const [usersById, setUsersById] = useState();

  const [createMeeting, { loading: createLoading }] = useMutation(CREATE_MEETING, {
    // update(cache, { data: { createMeeting } }) {
    //   cache.modify({
    //     fields: {
    //       meetings(existingMeetings = []) {
    //         const newMeetingRef = cache.writeFragment({
    //           data: createMeeting,
    //           fragment: MEETING_FIELDS,
    //         });
    //         return [...existingMeetings, newMeetingRef];
    //       },
    //     },
    //   });
    // },
  });
  const [updateMeeting, { loading: updateLoading }] = useMutation(UPDATE_MEETING, {
    refetchQueries: ["Meetings_GetMeetings"], // even when returning id of updated meeting, meetings page does not see changes unless refetch is made on query
  });
  const { data } = useQuery(GET_USERS_AND_DEPARTMENTS, {
    variables: { organization: params.org, oneYearCorpPlan: _.get(dialog, "addMeetingDialog.planId") },
  });

  const { form, formErrors, handleChange, handleChangeDate, handleChangeManual, resetForm, validateForm } = useForm({
    initForm: initForm,
    initErrorForm,
  });

  const fs = useMediaQuery("(max-width: 600px)");

  const dialogSteps = getDialogSteps();

  const formatExistingMeeting = (meeting = {}) => {
    if (_.isEmpty(meeting)) return meeting;

    let meetingCopy = _.cloneDeep(meeting);

    const startTime = _.get(meetingCopy, "startTime", "");
    const timezone = _.get(meetingCopy, "timezone", userTimezone);

    const startDateTime = _.isEmpty(startTime) ? newDate : new Date(parseInt(startTime));
    const zonedTime = utcToZonedTime(startDateTime, timezone);

    _.set(meetingCopy, "startTime", zonedTime);
    _.set(meetingCopy, "startDate", zonedTime);

    const users = _.get(meetingCopy, "users", []);
    _.set(
      meetingCopy,
      "users",
      users.map((user) => user.id)
    );

    const plan = _.get(meetingCopy, "plan", {});
    _.set(meetingCopy, "plan", _.get(plan, "id"));

    return meetingCopy;
  };

  const handleNext = () => () => {
    let proceed = true;
    switch (activeStep) {
      case 0:
        proceed = validateForm(["title"]);
      default:
    }

    if (proceed) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    resetForm(initForm);
    setActiveStep(0);
    setDialog({ ...dialog, addMeetingDialog: { open: false } });
  };

  const handleSubmit = () => async () => {
    if (!validateForm()) return;

    const { id, title, frequency, startDate, startTime, timezone, type, steps, plan, users } = form;
    const mergedDateTime = mergeDateAndTime(startDate, startTime, timezone);
    const isCreate = _.isNil(id);

    let ok;
    if (isCreate) {
      ok = await createMeeting({
        variables: {
          organization: params.org,
          owner: auth.id,
          title,
          frequency,
          startTime: mergedDateTime,
          timezone,
          type,
          steps,
          users,
          plan: plan === "" ? null : plan,
        },
      });
    } else {
      ok = await updateMeeting({
        variables: {
          id,
          title,
          frequency,
          startTime: mergedDateTime,
          timezone,
          type,
          steps: steps.map((step) => _.omit(step, ["__typename"])),
          users,
          plan: plan === "" ? null : plan,
        },
      });
    }

    if (_.get(ok, `data.${isCreate ? "createMeeting" : "updateMeeting"}`, false)) {
      snack(`${isCreate ? "Created" : "Updated"} "${title}" meeting`);
      // if (!isCreate) requestFetch();
      resetForm();
      handleClose();
    }
  };

  const handleAddAttendee = (event, users) => {
    handleChangeManual({ name: "users", value: users });
  };

  useEffect(() => {
    if (_.get(dialog, "addMeetingDialog.open", false)) {
      const existingMeeting = _.get(dialog, "addMeetingDialog.meeting", {});
      const meeting = _.isEmpty(existingMeeting)
        ? initForm
        : formatExistingMeeting(
            _.pick(existingMeeting, ["id", "title", "startDate", "startTime", "timezone", "frequency", "type", "steps", "plan", "users"])
          );
      resetForm(meeting);
    }
  }, [dialog.addMeetingDialog]);

  useEffect(() => {
    if (!_.isNil(usersById)) {
      const selectedUsersCopy = _.cloneDeep(form.users);

      handleChangeManual({
        name: "users",
        value: selectedUsersCopy.filter((userId) => {
          const user = usersById[userId];
          return _.isEmpty(form.plan) || _.some(user.plan, ["id", form.plan]);
        }),
      });
    }
  }, [usersById, form.plan]);

  useEffect(() => {
    if (data) {
      const users = _.get(data, "users", []);
      setUsersById(_.keyBy(users, "id"));
    }
  }, [data]);

  const getAvatar = (user) => {
    const { name, profilePicture } = user;
    return (
      <Avatar src={profilePicture} className={styles.avatar}>
        {_.get(name, ["first", "0"])}
        {_.get(name, ["last", "0"])}
      </Avatar>
    );
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <TextField
              autoFocus
              label="Name"
              name="title"
              fullWidth
              variant="outlined"
              margin="normal"
              value={form.title || ""}
              onChange={handleChange}
              helperText={formErrors.title}
              error={Boolean(formErrors.title)}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel variant="outlined">Frequency</InputLabel>
              <Select variant="outlined" label="Frequency" name="frequency" value={form.frequency} onChange={handleChange}>
                <MenuItem value={0}>One Time</MenuItem>
                <MenuItem value={1}>Weekly</MenuItem>
                <MenuItem value={2}>Bi-weekly</MenuItem>
                <MenuItem value={4}>Monthly</MenuItem>
                <MenuItem value={13}>Quarterly</MenuItem>
                <MenuItem value={52}>Annually</MenuItem>
              </Select>
            </FormControl>
            <DatePicker
              autoOk
              clearable
              value={form.startDate}
              onChange={handleChangeDate("startDate")}
              fullWidth
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              label="Start Date"
            />
            <TimePicker
              minutesStep={5}
              fullWidth
              variant="inline"
              inputVariant="outlined"
              label="Start Time"
              margin="normal"
              value={form.startTime || ""}
              onChange={handleChangeDate("startTime")}
            />
            <SelectTimezone value={form.timezone} handleChange={handleChangeManual} additionalTimezones={[userTimezone]} />
          </>
        );
      case 1:
        return (
          <>
            <FormControl fullWidth margin="normal">
              <InputLabel variant="outlined">Type</InputLabel>
              <Select variant="outlined" label="Type" name="type" value={form.type} onChange={handleChange}>
                <MenuItem value="hem">HEM</MenuItem>
                {/* <MenuItem value="quarterly">Quarterly</MenuItem>
                <MenuItem value="annual">Annual</MenuItem>
                <MenuItem value="foundation">Foundation Building</MenuItem>
                <MenuItem value="core">Core Building</MenuItem>
                <MenuItem value="leader">Leader Building</MenuItem> */}
                <MenuItem value="custom">Custom</MenuItem>
              </Select>
            </FormControl>
            <MeetingStepsList type={form.type} steps={form.steps} onChange={handleChangeManual} />
          </>
        );
      case 2:
        return (
          <>
            <SelectDepartment
              plans={_.get(data, "plans")}
              name="plan"
              handleChange={handleChange}
              value={form.plan}
              helperText={formErrors.plan}
              error={Boolean(formErrors.plan)}
              showAll
            />
            <Autocomplete
              fullWidth
              multiple
              disableCloseOnSelect
              filterSelectedOptions
              name="users"
              onChange={handleAddAttendee}
              value={form.users}
              style={{ marginTop: 16 }}
              options={_.get(data, "users", []).map((user) => user.id)}
              filterOptions={(userIds) =>
                userIds.filter((userId) => {
                  const user = usersById[userId];
                  return _.isEmpty(form.plan) || _.some(user.plan, ["id", form.plan]);
                })
              }
              getOptionLabel={(userId) => {
                const user = usersById[userId];
                return `${user.name.first} ${user.name.last}`;
              }}
              renderOption={(userId, { selected }) => {
                const user = usersById[userId];
                return (
                  <>
                    <Checkbox icon={getAvatar(user)} checkedIcon={getAvatar(user)} checked={selected} />
                    {user.name.first} {user.name.last}
                  </>
                );
              }}
              renderInput={(params) => <TextField {...params} label="Attendees" variant="outlined" />}
              renderTags={(userIds, getTagProps) =>
                userIds.map((userId, index) => {
                  const user = usersById[userId];
                  return (
                    <StyledChip
                      variant="outlined"
                      label={`${user.name.first} ${user.name.last}`}
                      {...getTagProps({ index })}
                      avatar={getAvatar(user)}
                    />
                  );
                })
              }
            />
          </>
        );
      default:
        return;
    }
  };

  return (
    <Dialog
      open={dialog.addMeetingDialog.open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
      fullScreen={fs}
    >
      <DialogTitle>
        <div className={styles.title}>
          {form.id ? "Edit" : "Create New"} Meeting
          <div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} className={styles.stepper}>
          {dialogSteps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {getStepContent(activeStep)}
      </DialogContent>
      <DialogActions>
        <div style={{ marginRight: "auto", display: "flex", gap: 8 }}>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={activeStep === dialogSteps.length - 1 ? handleSubmit() : handleNext()}
            disabled={createLoading || updateLoading}
          >
            {activeStep === dialogSteps.length - 1 ? (form.id ? "Save" : "Create") : "Next"}
          </Button>
        </div>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMeetingDialog;

const CREATE_MEETING = gql`
  ${MEETING_FIELDS}
  mutation AddMeetingDialog_CreateMeeting(
    $organization: ID!
    $owner: ID!
    $title: String!
    $frequency: Int!
    $startTime: String!
    $timezone: String!
    $type: String!
    $steps: [InputStep!]
    $users: [ID!]
    $plan: ID
  ) {
    createMeeting(
      organization: $organization
      owner: $owner
      title: $title
      frequency: $frequency
      startTime: $startTime
      timezone: $timezone
      type: $type
      steps: $steps
      users: $users
      plan: $plan
    ) {
      ...MeetingFields
    }
  }
`;

const UPDATE_MEETING = gql`
  ${MEETING_FIELDS}
  mutation AddMeetingDialog_UpdateMeeting(
    $id: ID!
    $title: String!
    $frequency: Int!
    $startTime: String!
    $timezone: String!
    $type: String!
    $steps: [InputStep!]
    $users: [ID!]
    $plan: ID
  ) {
    updateMeeting(
      id: $id
      title: $title
      frequency: $frequency
      startTime: $startTime
      timezone: $timezone
      type: $type
      steps: $steps
      users: $users
      plan: $plan
    ) {
      ...MeetingFields
    }
  }
`;

const GET_USERS_AND_DEPARTMENTS = gql`
  query AddMeetingDialog_GetUsersDepts($organization: ID!, $oneYearCorpPlan: ID) {
    users(organization: $organization) {
      name {
        first
        last
      }
      profilePicture
      id
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    plans(organization: $organization, oneYearCorpPlan: $oneYearCorpPlan, category: "1 year", closed: false) {
      id
      departmentName
      sharedPlanId
    }
  }
`;

const getDefaultMeetingStartDate = () => {
  let currentDate = new Date();
  let minutes = currentDate.getMinutes();
  let newMinutes = minutes + 5 - (minutes % 5);
  currentDate.setMinutes(newMinutes);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);
  return currentDate;
};
