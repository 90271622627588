import React, { useState, useRef, useContext, useMemo } from "react";
import _ from "lodash";
import styles from "./SelectUsers.module.scss";
import { FormControl, FormHelperText, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Avatar } from "@material-ui/core";
import { useAuth } from "../../context/authContext";
import { useUser } from "../../context/userContext";

const SelectUsers = ({
  name,
  users = [],
  handleChange,
  multiple,
  values,
  label,
  helperText,
  error,
  plan,
  allPlans = [],
  disabled = false,
}) => {
  const { auth } = useAuth();
  const { user } = useUser();
  const selectRef = useRef();

  const sharedPlanIdDict = useMemo(() => {
    return allPlans.reduce((prev, curr) => {
      return { ...prev, [curr.id]: curr.sharedPlanId };
    }, {});
  }, [allPlans]);

  const usersToShow = useMemo(() => {
    if (_.isEmpty(user)) return [];

    const planArr = Array.isArray(plan) ? plan : _.isNil(plan) ? [] : [plan];
    const sharedPlanIdArr = planArr.map((id) => sharedPlanIdDict[id]);

    // If there exists selected plan(s) then only return users that belong to said plan(s), otherwise return the logged-in user
    const assignableUsers = !_.isEmpty(planArr)
      ? users.filter((user) => user.plan.some((userPlan) => sharedPlanIdArr.includes(userPlan?.sharedPlanId)))
      : [user];

    const valuesIsArray = Array.isArray(values);

    /* The following checks if the currently selected user(s) are valid based on the selected department(s).
       In case of multiple selectable users, ALL selected users must be an assignable user from the selected department(s) otherwise validation fails */
    const selectedUserIsValid = valuesIsArray
      ? values.every((svUser) => assignableUsers.some((assignableUser) => assignableUser.id === svUser))
      : assignableUsers.some((assignableUser) => assignableUser.id === values);

    // If there there exists selected user(s) which do not belong to the selected department(s) then reset this user dropdown to an empty selection
    if (!error && !selectedUserIsValid) {
      const emptyVal = valuesIsArray ? [] : null;

      let simulatedEventObj = {
        target: {
          name,
          value: emptyVal,
        },
      };

      handleChange(simulatedEventObj);
    }

    return assignableUsers;
  }, [users, plan, values, user]);

  return (
    <FormControl fullWidth variant="outlined" margin="normal" error={error} style={{ opacity: disabled ? 0.5 : 1 }}>
      <InputLabel>{label || "Person Accountable"}</InputLabel>
      <Select
        multiple={multiple}
        value={values || ""}
        label={label || "Person Accountable"}
        onChange={handleChange}
        name={name}
        renderValue={multiple ? () => `${values.length} Users Selected` : null}
        ref={selectRef}
        disabled={disabled}
      >
        {usersToShow && usersToShow.length > 0 ? (
          usersToShow.map(({ name: username, profilePicture, id }) => {
            return (
              <MenuItem key={id} value={id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={
                        <Avatar className={styles.avatar} src={profilePicture}>
                          {/* The following are indexing 0 to grab the first letter of the first and last name */}
                          {username.first[0]}
                          {username.last[0]}
                        </Avatar>
                      }
                      checkedIcon={
                        <Avatar className={styles.avatar} src={profilePicture}>
                          {username.first[0]}
                          {username.last[0]}
                        </Avatar>
                      }
                      name={name}
                    />
                  }
                  label={`${username.first} ${username.last}`}
                  className={values && values.includes(id) ? styles.active : styles.inactive}
                  onClick={(e) => e.preventDefault()}
                />
              </MenuItem>
            );
          })
        ) : (
          <MenuItem value={""}>No users found for selected department(s)</MenuItem>
        )}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectUsers;
