import _ from "lodash";
import { S3_SIGNED_URL_OPERATIONS } from "./const";

export const deleteFileFromS3 = async ({ objectUrl, getSignedUrlAsync, snack, typeStr = "" }) => {
  if (typeStr) typeStr = " " + typeStr;

  snack(`Deleting${typeStr}...`);

  let success = false;
  const fileName = _.last(objectUrl.split("amazonaws.com/"));

  try {
    const s3SignedUrlRes = await getSignedUrlAsync({ fileName, operation: S3_SIGNED_URL_OPERATIONS["delete"] });
    const deleteUrl = _.get(s3SignedUrlRes, "data.generateSignedUrlS3");

    if (deleteUrl) {
      // Delete from S3 using returned signed URL
      const s3DeleteRes = await fetch(deleteUrl, {
        method: "DELETE",
      });

      if (s3DeleteRes.ok) {
        success = true;
      } else {
        snack(`Failed to delete${typeStr}, please try again later`, "error");
      }
    }
  } catch (err) {
    snack(`Failed to delete${typeStr}`, "error");
  } finally {
    return success;
  }
};

export const uploadFileToS3 = async ({ file, fileName, fileType, getSignedUrlAsync, snack, typeStr = "" }) => {
  if (typeStr) typeStr = " " + typeStr;

  snack(`Uploading${typeStr}...`);

  let objectUrl;
  try {
    const s3SignedUrlRes = await getSignedUrlAsync({ fileName, fileType, operation: S3_SIGNED_URL_OPERATIONS["upload"] });
    const uploadUrl = _.get(s3SignedUrlRes, "data.generateSignedUrlS3");
    if (uploadUrl) {
      // Upload to S3 using returned signed URL
      const s3UploadRes = await fetch(uploadUrl, {
        method: "PUT",
        headers: {
          "Content-Type": fileType,
        },
        body: file,
      });

      if (s3UploadRes.ok) {
        objectUrl = `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${fileName}`;
      } else {
        snack(`Failed to upload${typeStr}, please try again later`, "error");
      }
    }
  } catch (err) {
    snack(`Failed to upload${typeStr}`, "error");
  } finally {
    return objectUrl;
  }
};

export const getFileFromS3 = async ({ objectUrl, getSignedUrlAsync, snack, typeStr = "" }) => {
  if (typeStr) typeStr = " " + typeStr;

  snack(`Retrieving${typeStr}...`);

  let fileBlob;
  const fileName = _.last(objectUrl.split("amazonaws.com/"));

  try {
    const s3SignedUrlRes = await getSignedUrlAsync({ fileName, operation: S3_SIGNED_URL_OPERATIONS["get"] });
    const getUrl = _.get(s3SignedUrlRes, "data.generateSignedUrlS3");
    if (getUrl) {
      // Retrieve from S3 using returned signed URL
      const s3GetRes = await fetch(getUrl);

      if (s3GetRes.ok) {
        fileBlob = await s3GetRes.blob();
      } else {
        snack(`Failed to retrieve${typeStr}, please try again later`, "error");
      }
    }
  } catch (err) {
    snack(`Failed to retrieve${typeStr}`, "error");
  } finally {
    return fileBlob;
  }
};
