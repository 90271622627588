import React, { useEffect, useState, useContext } from "react";
import styles from "../WeeklyReview.module.scss";
import _ from "lodash";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Icon from "@mdi/react";
import {
  mdiBullseyeArrow,
  mdiChevronDown,
  mdiChevronUp,
  mdiAlertDecagram,
  mdiCheckboxMarked,
  mdiFileImage,
  mdiFileDocumentOutline,
  mdiPencil,
  mdiPlusCircle,
  mdiCheckCircle,
} from "@mdi/js";
import { NotesContext } from "../../../context/notesContext";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import UserAvatar from "../../../components/UserAvatar/UserAvatar";
import UserAvatars from "../../../components/UserAvatars/UserAvatars";
import SuccessCriteria from "../../../components/SuccessCriteria/SuccessCriteria";
import Status from "../../../components/Status/Status";
import Menu from "../../../components/Menu/Menu";
import { formatAs, quarterDates, fullDate, getCurrentQuarter, addToDate } from "../../../utils/dates";
import { DialogContext } from "../../../context/dialogContext";

const RockReview = ({
  rocks,
  currentRock,
  updateCurrentRock,
  updateRockStatus,
  filterUsers,
  fiscalYear,
  handleAddDialog,
  handleAddScDialog,
  handleUpdateSuccessCriteria,
  requestFetch,
  snack,
  completedRocks,
  corpPlanId, // need to set this as the corp plan id of the year we are working with
}) => {
  const { notes } = useContext(NotesContext);
  const { dialog, setDialog } = useContext(DialogContext);

  const [selectedRock, setSelectedRock] = useState();
  const [groupedRocks, setGroupedRocks] = useState();
  const [allRocks, setAllRocks] = useState([]);

  const getQuarter = (quarter) => {
    const [start, end] = quarterDates(addToDate(fiscalYear, { days: 1 }), quarter);
    return `(${formatAs(start, "MMM d")} - ${formatAs(end, "MMM d")})`;
  };

  const handleNavigateRock = (next) => () => {
    const i = allRocks.indexOf(selectedRock.id);
    let rock;
    if (next) {
      rock = _.find(rocks, { id: allRocks[i + 1] });
    } else {
      rock = _.find(rocks, { id: allRocks[i - 1] });
    }
    setSelectedRock(rock);
    updateCurrentRock(rock.id);
  };

  const handleSelectedRock = (id) => () => {
    const rock = _.find(rocks, { id });
    setSelectedRock(rock);
    updateCurrentRock(id);
  };

  const handleUpdateAllSc = (done) => async () => {
    if (selectedRock) {
      for (let sc of selectedRock.successCriterias) {
        // **TODO: make mutation that changes multiple success criteria within one transaction
        await handleUpdateSuccessCriteria(sc.id, done);
      }
      requestFetch();
    }
  };

  const handleNotes = () => {
    notes(selectedRock.id, "rock", null, true);
  };

  const handleEditDialog = (rock) => {
    setDialog({ ...dialog, addRockDialog: { open: true, rock, planId: corpPlanId } });
  };

  const handleUpdateStatus = (newStatus) => async () => {
    try {
      const res = await updateRockStatus({
        variables: {
          id: selectedRock.id,
          status: newStatus,
        },
      });

      if (res.data.updateRockStatus) {
        snack(`Marked as ${newStatus}`);
        requestFetch();
      }
    } catch (err) {
      snack("Failed to update status", "error");
    }
  };

  useEffect(() => {
    const currentQuarter = getCurrentQuarter(fiscalYear);
    const filteredRocks = rocks.filter((r) => filterUsers.includes(r.users[0].id) && r.index === currentQuarter);
    const groupedRocks = _.groupBy(filteredRocks, (r) => r.objective.id);
    const allRockIds = [];
    setGroupedRocks(groupedRocks);

    _.forEach(groupedRocks, (val) => {
      val.forEach(({ id }) => allRockIds.push(id));
    });
    setAllRocks(allRockIds);
    // refresh rock data
    if (selectedRock) {
      const rock = _.find(rocks, { id: selectedRock.id });
      setSelectedRock(rock);
    }
  }, [rocks]);

  useEffect(() => {
    if (selectedRock !== currentRock) {
      const rock = _.find(rocks, { id: currentRock });
      setSelectedRock(rock);
    }
  }, [currentRock]);

  useEffect(() => {
    if (!currentRock) {
      const currentQuarter = getCurrentQuarter(fiscalYear);
      const filteredRocks = rocks.filter((r) => filterUsers.includes(r.users[0].id) && r.index === currentQuarter);

      const groupedRocks = _.groupBy(filteredRocks, (r) => r.objective.id);
      setSelectedRock(_.head(groupedRocks[Object.keys(groupedRocks)[0]]));
    }
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4} className={styles.objectiveCardList}>
        {groupedRocks &&
          Object.keys(groupedRocks).map((id) => {
            return (
              <ObjectiveCard
                key={id}
                rocks={groupedRocks[id]}
                selectedRock={selectedRock}
                handleSelectedRock={handleSelectedRock}
                completedRocks={completedRocks}
              />
            );
          })}
      </Grid>
      <Grid item xs={12} sm={8}>
        {selectedRock && (
          <>
            <div className={styles.flexParagraph}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleNavigateRock(false)}
                disabled={allRocks.indexOf(selectedRock.id) === 0}
              >
                Prev
              </Button>

              <Button
                variant="outlined"
                color="primary"
                className={styles.button}
                onClick={handleNavigateRock(true)}
                disabled={allRocks.indexOf(selectedRock.id) === allRocks.length - 1}
              >
                Next
              </Button>
            </div>
            <Typography variant="h4" paragraph>
              {selectedRock.value}{" "}
              {_.get(selectedRock, "plan.departmentName") && (
                <span className={styles.labelOblique}>({selectedRock.plan.departmentName})</span>
              )}
            </Typography>

            <div className={styles.flexParagraph}>
              <Typography variant="h5">
                <span className={styles.labelMarginRight}>Person Accountable: </span>
              </Typography>
              <UserAvatar user={selectedRock.users[0]} variant="h5" />
            </div>

            <Typography variant="h5" paragraph className={styles.flex}>
              <span className={styles.labelMarginRight}>Objective: </span>
              {selectedRock.objective.value}
            </Typography>

            <Typography variant="h5" paragraph className={styles.flex}>
              <span className={styles.labelMarginRight}>Quarter: </span>
              {selectedRock.index} {getQuarter(selectedRock.index)}
            </Typography>

            <Typography variant="h5" paragraph className={styles.flex}>
              <span className={styles.labelMarginRight}>Status: </span>

              <div className={styles.flexCenter}>
                <Status status={selectedRock.status} />
                <Menu icon="arrow">
                  <MenuItem onClick={handleUpdateStatus("complete")} disabled={selectedRock.status === "complete"}>
                    Mark as complete
                  </MenuItem>
                  <MenuItem onClick={handleUpdateStatus("on track")} disabled={selectedRock.status === "on track"}>
                    Mark as on track
                  </MenuItem>
                  <MenuItem onClick={handleUpdateStatus("off track")} disabled={selectedRock.status === "off track"}>
                    Mark as off track
                  </MenuItem>
                </Menu>
              </div>
            </Typography>

            <div className={styles.flexParagraph}>
              <Typography variant="h5">
                <span className={styles.labelMarginRight}>Progress: </span>
              </Typography>
              <span>
                <ProgressBar sc={selectedRock.successCriterias} done={selectedRock.status === "complete"} />
              </span>
            </div>

            <div>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Icon path={mdiPencil} size={1} color="#fff" />}
                onClick={() => handleEditDialog(selectedRock)}
              >
                Edit
              </Button>

              <Button
                variant="contained"
                color="primary"
                className={styles.buttonRed}
                startIcon={<Icon path={mdiAlertDecagram} size={1} color="#fff" />}
                onClick={handleAddDialog("issue", "Rock", selectedRock.id)}
              >
                New Issue
              </Button>

              <Button
                variant="contained"
                color="primary"
                className={styles.buttonPurple}
                startIcon={<Icon path={mdiCheckboxMarked} size={1} color="#fff" />}
                onClick={handleAddDialog("todo", "Rock", selectedRock.id)}
              >
                New Todo
              </Button>
            </div>

            <Typography variant="h4" className={styles.labelMarginTop} gutterBottom>
              <span className={styles.flex}>
                Success Criteria
                <IconButton size="small" onClick={handleAddScDialog(selectedRock.id)}>
                  <Icon path={mdiPlusCircle} size={0.75} color="rgba(0, 0, 0, 0.54)" />
                </IconButton>
              </span>
            </Typography>

            <div className={styles.divider}>
              {selectedRock.successCriterias.map((sc) => {
                return (
                  <SuccessCriteria
                    key={sc.id}
                    sc={sc}
                    snack={snack}
                    requestFetch={requestFetch}
                    showComplete
                    corpPlanId={corpPlanId}
                    rockId={selectedRock.id}
                  />
                );
              })}
            </div>

            <div>
              <Button variant="contained" color="primary" onClick={handleUpdateAllSc(true)}>
                Mark all complete
              </Button>

              <Button variant="contained" color="primary" className={styles.button} onClick={handleUpdateAllSc(false)}>
                Mark all incomplete
              </Button>
            </div>

            <Typography variant="h4" className={styles.labelMarginTop} gutterBottom>
              <span className={styles.flex}>
                Rock Notes
                <IconButton size="small" onClick={handleNotes}>
                  <Icon path={mdiPlusCircle} size={0.75} color="rgba(0, 0, 0, 0.54)" />
                </IconButton>
              </span>
            </Typography>
            <List>
              {selectedRock.notes.map(({ user, date, text, url, filename, type }) => {
                const isImg = type === "img";
                const path = isImg ? mdiFileImage : mdiFileDocumentOutline;
                return (
                  <ListItem key={date}>
                    <ListItemText>
                      <div className={styles.flexParagraph}>
                        <Avatar className={styles.avatar}>
                          <Icon path={path} color="#fff" size={1} />
                        </Avatar>
                        <div>
                          <Typography variant="caption" display="block">
                            {fullDate(date)} | {user.name.first} {user.name.last}
                          </Typography>
                          {url !== "undefined" && (
                            <a href={url} target="__blank" download className={styles.downloadLink}>
                              {filename}
                            </a>
                          )}
                          <Typography>{text}</Typography>
                        </div>
                      </div>

                      {isImg && <img src={url} alt="" className={styles.image} />}
                    </ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default RockReview;

const ObjectiveCard = ({ rocks, selectedRock, handleSelectedRock, completedRocks }) => {
  const [expanded, setExpanded] = useState(true);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const { objective } = rocks[0];
  const length = rocks.length;
  const rockIds = rocks.map(({ id }) => id);
  const completedLength = completedRocks.reduce((sum, id) => (rockIds.includes(id) ? sum + 1 : sum), 0);
  const isActive = selectedRock.objective.id === objective.id;
  return (
    <Card className={styles.objectiveCard}>
      <CardHeader
        avatar={<Icon path={mdiBullseyeArrow} size={1.25} color="rgba(0, 0, 0, 0.5)" />}
        action={
          <IconButton onClick={handleExpand}>
            <Icon path={expanded ? mdiChevronUp : mdiChevronDown} size={1} color="rgba(0, 0, 0, 0.5)" />
          </IconButton>
        }
        title={objective.value}
        subheader={`${completedLength} / ${length} ${length === 1 ? "Rock" : "Rocks"}`}
        className={isActive ? styles.active : undefined}
      />
      <Collapse in={expanded}>
        <CardContent>
          <List component="div">
            {rocks.map(({ id, users, value, successCriterias, status }) => {
              let done = 0,
                total = 0,
                percentage = 0.0;

              if (status === "complete") {
                done = 1;
                total = 1;
              } else if (successCriterias) {
                successCriterias.forEach((s) => {
                  total++;
                  if (s.done) {
                    done++;
                  }
                });
              }

              percentage = (done / total) * 100;
              const isActive = id === _.get(selectedRock, "id");
              const isReviewed = completedRocks.includes(id);
              return (
                <ListItem button key={id} className={isActive ? styles.active : undefined} onClick={handleSelectedRock(id)}>
                  <ListItemAvatar>
                    <UserAvatars users={users} />
                  </ListItemAvatar>
                  <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                    <span className={styles.flexPaddingRight}>
                      {value}
                      <span className={styles.flex}>
                        {isReviewed && <Icon path={mdiCheckCircle} color={green[400]} size={0.75} className={styles.check} />}
                      </span>
                    </span>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <div className={styles.progressWrapper}>
                      <CircularProgress variant="static" value={percentage || 0} />
                      <span>{Math.round(percentage) || 0}%</span>
                    </div>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </CardContent>
      </Collapse>
    </Card>
  );
};
