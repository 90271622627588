import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import useInViewport from "../../hooks/useInViewport";
import handleViewport from "react-in-viewport";
import styles from "./Objectives.module.scss";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { quarterDates, formatAs, addToDate, getCurrentQuarter } from "../../utils/dates";
import { isAuthed } from "../../utils/authorization";
import { getQuarters } from "../../utils/misc";

import { TableRow, TableCell, MenuItem, Typography, IconButton, Divider, Button } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiPlusCircle, mdiChevronDown, mdiChevronUp, mdiChevronLeftCircleOutline, mdiChevronRightCircleOutline, mdiDrag } from "@mdi/js";

import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { useAuth } from "../../context/authContext";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
// import { LoadingContext } from "../../context/loadingContext";

import UserAvatars from "../UserAvatars/UserAvatars";
import Menu from "../Menu/Menu";
import ProgressBarRocks from "../ProgressBar/ProgressBarRocks";
import Status from "../Status/Status";
import StatusIcons from "../StatusIcons/StatusIcons";
import NotesButton from "../Notes/NotesButton";
import Rock from "../Rock/Rock";
import RockClone from "../Rock/RockClone";
import Skeleton from "./Skeleton";
import PlanPill from "../PlanPill/PlanPill";
import QuarterSummary from "../QuarterSummary/QuarterSummary";

const OneYearObjective = ({
  fiscalYear,
  handleOpenDialog,
  handleAddIssueDialog,
  category,
  objective,
  showAllRocks,
  showMyRocks,
  showDepartmentRocks,
  inViewport,
  forwardedRef,
  canEdit,
  objPath,
  rockScStrategy,
  plansOrder,
  closedYear,
  rockProgressQuarter,
  showAllQuarters,
  handleToggleQuarter,
  showDescription,
  corpForSelectedYear,
  currentQuarter,
  handleEditDialog,
  handleUpdateStatus,
  handleConfirmOpen,
  provided,
  nextYearPlanCreated,
  isFuturePlan,
  isCurrentPlan,
}) => {
  let { id, value, description, users, number, status, objectives, rocks, plan, category: objCategory } = objective;
  const params = useParams();
  const { fetch, requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const { auth } = useAuth();
  const { departmentFilter } = useDepartmentFilter();
  // const { updateLoading } = useContext(LoadingContext);

  const [showRocks, setShowRocks] = useState(() => {
    let sessionStorageKey = JSON.parse(sessionStorage.getItem(`oneYearObjs.${id.toString()}.showRocks`));
    return _.isNil(sessionStorageKey) ? showAllRocks : sessionStorageKey;
  });
  const [showHidden, setShowHidden] = useState([false, false, false, false]);
  const [showSummary, setShowSummary] = useState([false, false, false, false]);
  const [summaryQuarterCeiling, setSummaryQuarterCeiling] = useState(0);
  const [initialRender, setInitialRender] = useState(true);

  const {
    data,
    refetch,
    loading: oneYearObjLoading,
  } = useQuery(GET_OBJECTIVES, {
    variables: { organization: params.org, ids: objectives },
    fetchPolicy: "network-only", // Used for first execution
    nextFetchPolicy: "cache-first", // Used for subsequent executions,,
  });
  const hasRendered = useInViewport(inViewport);

  const handleShowRocks = () => {
    sessionStorage.setItem(`oneYearObjs.${id.toString()}.showRocks`, JSON.stringify(!showRocks));
    setShowRocks((prev) => !prev);
  };

  const handleHiddenRocks = (index) => () => {
    const arr = _.cloneDeep(showHidden);
    arr[index] = !arr[index];
    setShowHidden(arr);
  };

  const handleSummary = (index) => () => {
    const arr = _.cloneDeep(showSummary);
    arr[index] = !arr[index];
    sessionStorage.setItem(`oneYearObjs.${id.toString()}.${index}.showQuarterRocks`, JSON.stringify(!arr[index]));
    setShowSummary(arr);
  };

  useEffect(() => {
    setInitialRender(false);
  }, []);

  useEffect(() => {
    if (currentQuarter > 0) {
      const reviewedPeriodData = _.get(plan, "periodData", []).filter((periodDatum) => periodDatum.reviewed);
      const highestReviewedQuarter = _.get(_.maxBy(reviewedPeriodData, "periodNumber"), "periodNumber", 0);

      // const quarterCeiling =
      //   isNaN(highestReviewedQuarter) || currentQuarter > highestReviewedQuarter ? currentQuarter - 1 : highestReviewedQuarter;
      let q1 = JSON.parse(sessionStorage.getItem(`oneYearObjs.${id.toString()}.0.showQuarterRocks`));
      let q2 = JSON.parse(sessionStorage.getItem(`oneYearObjs.${id.toString()}.1.showQuarterRocks`));
      let q3 = JSON.parse(sessionStorage.getItem(`oneYearObjs.${id.toString()}.2.showQuarterRocks`));
      let q4 = JSON.parse(sessionStorage.getItem(`oneYearObjs.${id.toString()}.3.showQuarterRocks`));
      let sessionQuarters = [q1, q2, q3, q4];
      const quarterCeiling = highestReviewedQuarter;
      setSummaryQuarterCeiling(quarterCeiling);
      setShowSummary((prev) =>
        prev.map((show, quarterIdx) =>
          !_.isNil(sessionQuarters[quarterIdx]) ? !sessionQuarters[quarterIdx] : quarterIdx + 1 <= quarterCeiling ? true : false
        )
      );
    }
  }, [plan, currentQuarter]);

  useEffect(() => {
    // initialRender flag skips this on the initial render (i.e. only trigger on actual button click)
    if (!initialRender) {
      sessionStorage.setItem(`oneYearObjs.${id.toString()}.showRocks`, JSON.stringify(showAllRocks));
      setShowRocks(showAllRocks);
    }
  }, [showAllRocks]);

  // useEffect(() => {
  //   if (!_.isNil(oneYearObjLoading)) updateLoading({ loadingObj: { oneYearObjLoading }, groupKeyStr: "objectives" });
  // }, [oneYearObjLoading]);

  if (!hasRendered)
    return (
      <TableRow ref={forwardedRef} className={styles.skeletonRow}>
        <Skeleton />
      </TableRow>
    );
  const flattenedRocks = rocks.flat();
  const filteredRocks = _.filter(flattenedRocks, (rock) => {
    const userIds = (rock.users || []).map((user) => user.id);
    return showMyRocks
      ? userIds.includes(auth.id)
      : showDepartmentRocks && departmentFilter.sharedPlanId
      ? rock.plan.sharedPlanId === departmentFilter.sharedPlanId
      : true;
  });
  const totalRocksDone = filteredRocks.reduce((sum, rock) => {
    if (rock.status === "complete" || (!_.isEmpty(rock.successCriterias) && rock.successCriterias.every((sc) => sc.done))) {
      return sum;
    }
    return sum - 1;
  }, filteredRocks.length);

  const spid = _.get(plan, "sharedPlanId");

  return (
    <>
      <TableRow className={category === "1 year" ? styles.noBot : undefined} ref={forwardedRef}>
        <TableCell align="center">
          {provided ? (
            <div {...provided.dragHandleProps} style={{ opacity: canEdit ? 1 : 0.25 }}>
              <Icon path={mdiDrag} size={1} />
            </div>
          ) : (
            <div>
              <Icon path={mdiDrag} size={1} />
            </div>
          )}
        </TableCell>
        <TableCell align="center">
          <UserAvatars users={users} />
        </TableCell>
        <TableCell>
          <div className={styles.flex}>
            {!_.isNil(plan?.departmentName) ? <PlanPill plan={plan} /> : <PlanPill plan={null} />}
            <div className={status === "complete" ? styles.complete : undefined}>
              {number}. {value}
            </div>
            <NotesButton
              id={id}
              model="objective"
              value={value.trim()}
              user={_.get(users, ["0", "id"], null)}
              doc={objective}
              tabs={["notes", "issues", "todos"]}
              canEditTodo={canEdit}
              disabled={!canEdit || closedYear}
              planId={_.get(corpForSelectedYear, "id")}
            />
            {canEdit && (
              <Menu>
                {/* <MenuItem onClick={handleAddIssueDialog(id, "Objective", value.trim(), users[0] ? users[0].id : null)}>Add Issue</MenuItem> */}
                <MenuItem onClick={handleEditDialog(true, objective)}>Edit</MenuItem>
                <MenuItem onClick={handleConfirmOpen(true, objective)} className={styles.delete}>
                  Delete
                </MenuItem>
              </Menu>
            )}
          </div>
        </TableCell>
        <TableCell align="center">
          <StatusIcons
            items={_.sortBy(_.get(data, "objectives", []), [
              function (obj) {
                // this preliminary sorting step is required if the list of metrics includes multiple plans
                return plansOrder.indexOf(_.get(obj, "plan.sharedPlanId"));
              },
              "number",
            ])}
          />
        </TableCell>
        <TableCell align="center">
          <div className={styles.flexCenter}>
            {showAllQuarters ? (
              <>
                <ProgressBarRocks rocks={rocks[0]} tooltipTitle="Q1" />
                <ProgressBarRocks rocks={rocks[1]} tooltipTitle="Q2" />
                <ProgressBarRocks rocks={rocks[2]} tooltipTitle="Q3" />
                <ProgressBarRocks rocks={rocks[3]} tooltipTitle="Q4" />
                <Typography variant="body2" className={styles.rocksCompleteText}>
                  {totalRocksDone}/{filteredRocks.length}
                </Typography>
              </>
            ) : (
              <>
                <IconButton onClick={() => handleToggleQuarter(-1)} disabled={rockProgressQuarter === 0}>
                  <Icon path={mdiChevronLeftCircleOutline} size={1} />
                </IconButton>
                <ProgressBarRocks rocks={rocks[rockProgressQuarter]} tooltipTitle={`Q${rockProgressQuarter + 1}`} />
                <IconButton onClick={() => handleToggleQuarter(1)} disabled={rockProgressQuarter === 3}>
                  <Icon path={mdiChevronRightCircleOutline} size={1} />
                </IconButton>
              </>
            )}
          </div>
        </TableCell>
        <TableCell align="center">
          <div className={styles.flexCenter}>
            <Status status={status} />
            {isAuthed(auth, "department facilitator") && (
              <Menu icon="arrow">
                <MenuItem onClick={handleUpdateStatus("complete", objective)} disabled={status === "complete"}>
                  Mark as complete
                </MenuItem>
                <MenuItem onClick={handleUpdateStatus("on track", objective)} disabled={status === "on track"}>
                  Mark as on track
                </MenuItem>
                <MenuItem onClick={handleUpdateStatus("off track", objective)} disabled={status === "off track"}>
                  Mark as off track
                </MenuItem>
              </Menu>
            )}
          </div>
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={handleShowRocks}>
            <Icon path={showRocks ? mdiChevronUp : mdiChevronDown} size={1} color="rgba(0, 0, 0, 0.54)" />
          </IconButton>
        </TableCell>
      </TableRow>

      {showDescription && (
        <TableRow className={styles.noBot}>
          <TableCell colSpan="100%" className={styles.firstCellOneYear}>
            <Typography variant="body2" className={styles.departmentName}>
              {description ? <span className={styles.description}>{description}</span> : "No description"}
            </Typography>
          </TableCell>
        </TableRow>
      )}

      <TableRow>
        <TableCell colSpan="100%">
          <div className={styles.rocksList}>
            {rocks.map((quarter, quarterIdx) => {
              if (!showRocks) return null;

              const isCurrentQuarter = currentQuarter === quarterIdx + 1;
              const isCompletedQuarter = currentQuarter > quarterIdx + 1;
              const numOfHiddenRocks = quarter.reduce((sum, rock) => (rock.hide ? sum + 1 : sum), 0);
              const droppableId = `ROCK_${id}_${objPath}.rocks.${quarterIdx}`;

              return (
                <Droppable droppableId={droppableId} key={quarterIdx} type={`rock_${spid}`}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={snapshot.isDraggingOver ? styles.droppableDragging : styles.droppable}
                      >
                        <div className={styles.rocks}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            align="center"
                            className={isCurrentQuarter ? styles.quarterCurrent : isCompletedQuarter ? styles.quarterComplete : undefined}
                          >
                            Q{quarterIdx + 1}
                            <span className={styles.label}>{getQuarters(fiscalYear, quarterIdx + 1)}</span>
                            {canEdit && (
                              <IconButton size="small" onClick={handleOpenDialog(id, quarterIdx + 1)}>
                                <Icon path={mdiPlusCircle} size={1} color="rgba(0, 0, 0, .1)" />
                              </IconButton>
                            )}
                            <br />
                            <div style={{ display: "flex", gap: 4 }}>
                              <Button
                                className={styles.hidden}
                                size="small"
                                variant={showHidden[quarterIdx] ? "text" : "contained"}
                                onClick={handleHiddenRocks(quarterIdx)}
                                disableElevation
                                disabled={showSummary[quarterIdx]}
                              >
                                {showHidden[quarterIdx] ? 0 : numOfHiddenRocks} rock
                                {showHidden[quarterIdx] || numOfHiddenRocks !== 1 ? "s" : ""} hidden
                              </Button>
                              {quarterIdx + 1 <= summaryQuarterCeiling && (
                                <Button
                                  className={styles.hidden}
                                  size="small"
                                  variant="contained"
                                  onClick={handleSummary(quarterIdx)}
                                  disableElevation
                                >
                                  {showSummary[quarterIdx] ? "SHOW ROCKS" : "SHOW SUMMARY"}
                                </Button>
                              )}
                            </div>
                          </Typography>
                          {showSummary[quarterIdx] ? (
                            <QuarterSummary rocks={quarter} />
                          ) : (
                            <>
                              {!_.isEmpty(quarter) &&
                                quarter.map((rock, rockIdx) => {
                                  const userIds = (rock.users || []).reduce((arr, user) => [...arr, user.id], []);
                                  if ((rock.hide && !showHidden[quarterIdx]) || (showMyRocks && !userIds.includes(auth.id))) return null;

                                  return (
                                    <Draggable
                                      key={rock.id}
                                      draggableId={`ROCK_${rock.id}`}
                                      index={rockIdx}
                                      isDragDisabled={!isAuthed(auth, "department facilitator") || !canEdit}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <>
                                            <div
                                              {...provided.draggableProps}
                                              ref={provided.innerRef}
                                              style={{ ...provided.draggableProps.style }}
                                              className={isCompletedQuarter ? styles.quarterComplete : undefined}
                                            >
                                              <Rock
                                                isDragging={snapshot.isDragging}
                                                rock={rock}
                                                objectiveId={id}
                                                canEdit={canEdit}
                                                rockPath={`${objPath}.rocks.${quarterIdx}.${rockIdx}`}
                                                rockScStrategy={rockScStrategy}
                                                planId={_.get(corpForSelectedYear, "id")}
                                                spid={spid}
                                                provided={provided}
                                                nextYearPlanCreated={nextYearPlanCreated}
                                                isFuturePlan={isFuturePlan}
                                                isCurrentPlan={isCurrentPlan}
                                              />
                                            </div>
                                            {rockScStrategy === "copy" && snapshot.isDragging && snapshot.draggingOver !== droppableId && (
                                              <RockClone isDragging={snapshot.isDragging} rock={rock} canEdit={canEdit} />
                                            )}
                                          </>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })}
                              {snapshot.isDraggingOver && provided.placeholder}
                            </>
                          )}
                        </div>
                        {quarterIdx < 3 && <Divider orientation="vertical" flexItem className={styles.divider} />}
                      </div>
                    );
                  }}
                </Droppable>
              );
            })}
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

export default handleViewport(OneYearObjective);

const GET_OBJECTIVES = gql`
  query OneYearObj_GetObjs($organization: ID!, $ids: [ID!]) {
    objectives(organization: $organization, ids: $ids) {
      value
      status
      plan {
        id
        departmentName
        sharedPlanId
        color
        shortName
      }
      number
    }
  }
`;
