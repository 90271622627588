export const NOTIFICATION_FREQUENCY = ["daily", "weekly", "monthly", "quarterly", "yearly"];
export const isProd = process.env.REACT_APP_ENV === "PROD";

// **IMPORTANT: the keys need to be the camelCase version of their respective "value" fields
// The duration values are strings because InputStep in meetingSchema has duration as String to work with MUI textfields
// (gets converted to Number when saved in DB)
export const DEFAULT_MEETING_ACTIONS = {
  checkIn: {
    name: "Check-in",
    value: "check-in",
    duration: "5",
  },
  scorecard: {
    name: "Scorecard",
    value: "scorecard",
    duration: "5",
  },
  rocks: {
    name: "Rocks",
    value: "rocks",
    duration: "5",
  },
  textBlock: {
    name: "Text Block",
    value: "text block",
    duration: "5",
  },
  toDos: {
    name: "To-Dos",
    value: "to-dos",
    duration: "5",
  },
  issues: {
    name: "Issues",
    value: "issues",
    duration: "5",
  },
  conclude: {
    name: "Conclude",
    value: "conclude",
    duration: "5",
  },
};

export const REPEATABLE_MEETING_ACTIONS = ["textBlock"];

export const S3_SIGNED_URL_OPERATIONS = {
  upload: "putObject",
  delete: "deleteObject",
  get: "getObject",
};

export const OPENAI_SUGGESTIONS_SYSTEM_DESCS = {
  objectives: `You are an AI assistant tasked with generating new objective suggestions for an organization based on the information provided. Your primary goal is to propose innovative and unique objectives that are not duplicates of the existing ones. 

Please carefully assess the current data provided about the organization's objectives and identify any gaps or areas for potential growth. Ensure that your suggestions are entirely new and do not reuse or replicate any existing objective values or descriptions. Focus on what is missing or could be beneficial additions to help the organization achieve its goals.

Your suggestions should be:
1. Relevant to the organization’s context and needs.
2. Unique, creative, and distinct from any existing objectives provided.
3. Focused on areas of improvement, growth, or new opportunities.`,
};

export const OPENAI_SUGGESTIONS_USER_PROMPTS = {
  objectives: "Generate exactly 5 new objective suggestions based on this information.",
};

export const OPENAI_REPHRASE_SYSTEM_DESC = `You are an AI assistant tasked with improving a given piece of text. Your job is to assess the provided text for content, grammar, and composition, and then rephrase it with the following goals:
1. Correct any errors related to grammar, spelling, or punctuation.
2. Simplify the text if it is too complex, ensuring it remains clear and easy to understand.
3. Make the text more concise if it is overly lengthy or redundant.
4. Enhance the tone to sound more professional, where applicable.
5. Improve the text to make it more engaging or catchy, if appropriate.

Prioritize clarity and correctness above all else, while maintaining the original meaning and intent of the text.`;

export const OPENAI_REPHRASE_USER_PROMPT = "Rephrase the following text:";
