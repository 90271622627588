import React, { createContext, useContext, useEffect, useState } from "react";
import _ from "lodash";
import { signout } from "../utils/graphql";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [auth, setAuth] = useState();

  const handleSignout = async () => {
    await signout();
    localStorage.removeItem("accessToken");
    setAuth();
  };

  return <AuthContext.Provider value={{ auth, setAuth, handleSignout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
