import React, { useContext } from "react";
import _ from "lodash";
import styles from "./ReportDialog.module.scss";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, TextField } from "@material-ui/core";
import { useForm } from "../../hooks";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import Loading from "../Loading/Loading";
import { useLocation } from "react-router-dom";
import { FetchContext } from "../../context/fetchContext";

const initForm = {
  type: "bug", // currently only use 1 type
  title: null,
  description: null,
  author: null,
};

const initErrorForm = {
  type: ["required"],
  title: ["required"],
  description: ["required"],
};

const ReportDialog = ({ open, handleClose, user, snack }) => {
  const [createReport, { loading }] = useMutation(CREATE_REPORT);
  const location = useLocation();
  const { requestFetch } = useContext(FetchContext);

  const { form, formErrors, handleChange, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const handleSubmitReport = async () => {
    if (!validateForm()) return;

    const { type, title, description } = form;

    try {
      const res = await createReport({
        variables: {
          type,
          title,
          description,
          author: user.id,
        },
      });

      if (res.data.createReport) {
        snack("Report is sucessfully submitted");
        resetForm();
        handleClose();
        requestFetch();
      }
    } catch (err) {
      snack("Failed to submit report, please try again later", "error");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Report A Bug</DialogTitle>
      <DialogContent className={styles.text}>
        <Typography variant="subtitle2">
          Please include a description of the steps it would take to reproduce the bug, and the expected behavior.
        </Typography>
        <TextField
          autoFocus
          label={"Title"}
          name="title"
          fullWidth
          variant="outlined"
          margin="normal"
          value={form.title || ""}
          onChange={handleChange}
          helperText={formErrors.title}
          error={Boolean(formErrors.title)}
        />
        <TextField
          label={"Description"}
          name="description"
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          rows={3}
          value={form.description || ""}
          onChange={handleChange}
          helperText={formErrors.description}
          error={Boolean(formErrors.description)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmitReport} color="primary" variant="contained" disabled={loading} className={styles.button}>
          {loading ? <Loading size={24} color="#fff" /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportDialog;

const CREATE_REPORT = gql`
  mutation ($type: String!, $title: String!, $description: String!, $author: ID!) {
    createReport(type: $type, title: $title, description: $description, author: $author)
  }
`;
