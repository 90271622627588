import React from "react";
import _ from "lodash";
import styles from "./Users.module.scss";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { TableRow, TableCell, MenuItem, Tooltip } from "@material-ui/core";
import UserAvatars from "../../components/UserAvatars/UserAvatars";
import Menu from "../../components/Menu/Menu";

const User = ({ user, snack, handleEdit, handleDelete, handleDeleteImage, fiscalYear, disableDelete }) => {
  const { id, name, profilePicture, position, email, auth, plan } = user;

  const [sendPasswordResetEmail] = useMutation(SEND_PASSWORD_RESET_EMAIL);

  const handlePasswordResetEmail = async () => {
    try {
      const res = await sendPasswordResetEmail({ variables: { email } });

      const pwResetUrl = _.get(res, "data.sendPasswordResetEmail");

      let showLink = false;
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(pwResetUrl);
      } else {
        showLink = true;
        console.warn("Clipboard API not supported");
      }

      snack(`Password reset link sent to ${email} (${showLink ? pwResetUrl : "also copied to clipboard"})`);
    } catch (err) {
      snack(err.message, "error");
    }
  };

  const currentYearPlans = plan.filter((p) => p.year === fiscalYear);

  return (
    <>
      <TableRow>
        <TableCell className={styles.firstCell}>
          <UserAvatars users={[{ id, name, profilePicture }]} />
        </TableCell>
        <TableCell>
          <div className={styles.flex}>
            {name.first} {name.last}
            <Menu>
              <MenuItem onClick={handleEdit}>Edit</MenuItem>
              <MenuItem onClick={handlePasswordResetEmail}>Send Password Reset Link</MenuItem>
              {profilePicture && (
                <MenuItem onClick={handleDeleteImage} className={styles.delete}>
                  Delete Profile Picture
                </MenuItem>
              )}
              <MenuItem onClick={handleDelete} className={styles.delete} disabled={disableDelete}>
                Delete
              </MenuItem>
            </Menu>
          </div>
        </TableCell>
        <TableCell>{position}</TableCell>
        <TableCell>
          <a href={`mailto:${email}`} className={styles.email}>
            {email}
          </a>
        </TableCell>
        <TableCell>
          <Tooltip
            title={
              currentYearPlans.length === 0
                ? "No Departments"
                : currentYearPlans.map(({ departmentName }, idx) => departmentName + (idx === currentYearPlans.length - 1 ? "" : ", "))
            }
          >
            <span>
              {currentYearPlans.length} Department{plan.length === 1 ? "" : "s"}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell className={styles.auth}>
          <div className={styles.flex}>
            {auth}
            {/* <Menu icon="arrow">
              {authOptions.map((option) => {
                return (
                  <MenuItem onClick={handleChangeAuth(option)} disabled={auth === option}>
                    Change authorization to {_.startCase(option)}
                  </MenuItem>
                );
              })}
            </Menu> */}
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

export default User;

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation ($email: String, $_id: ID) {
    sendPasswordResetEmail(email: $email, _id: $_id)
  }
`;
