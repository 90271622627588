import React, { useEffect, useRef, useState, useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Checkbox, Container, IconButton, ListItemText, MenuItem, Select, Typography } from "@material-ui/core";
import { mdiPrinter, mdiRedoVariant, mdiUndoVariant } from "@mdi/js";
import { useAuth } from "../../context/authContext";
import Icon from "@mdi/react";
import { format, isToday } from "date-fns";
import gql from "graphql-tag";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import { isAuthed } from "../../utils/authorization";
import Loading from "../Loading/Loading";
import styles from "./AccountabilityChart.module.scss";
import EditableText from "./EditableText";
import TreeGraph from "./TreeGraph";
import html2canvas from "html2canvas";
import EditablePlan from "./EditablePlan";
import { CHART_FIELDS } from "../../utils/fragments";

const AccountabilityChart = ({ org }) => {
  const { auth } = useAuth();
  const params = useParams();
  const isAdmin = isAuthed(auth, "department admin");

  const [chart, setChart] = useState(undefined);
  const [chartHistory, setChartHistory] = useState([]);
  const [historyIndex, setHitoryIndex] = useState(0);
  const [users, setUsers] = useState([]);
  const [plans, setPlans] = useState([]);

  //displayOptions
  const [displayOptions, setDisplayOptions] = useState({
    avatar: true,
    name: true,
    roles: true,
    responsibilities: true,
  });

  const componentRef = useRef();
  const handleSaveTree = () => {
    // when tree is loaded
    if (componentRef.current) {
      // render a duplicated html element to screen capture in the body tag
      const container = document.querySelector(".rd3t-tree-container");
      const duplicatedContainer = container.cloneNode(true);
      document.body.prepend(duplicatedContainer);

      if (container) {
        // get dimensions in the svg tag
        const svg = duplicatedContainer.querySelector("svg");
        const g = svg.querySelector("g");

        // find how much g tag needs to be translated to the left from the root node in order to show the whole tree
        const gLeaves = g.querySelectorAll(".rd3t-leaf-node");
        let translateValue = 0;
        if (gLeaves) {
          gLeaves.forEach((e) => {
            const transformString = e.getAttribute("transform");
            const transformValue = parseInt(transformString.split(",")[0].replace("translate(", ""));
            if (transformValue < translateValue) {
              translateValue = transformValue;
            }
          });
        }
        const nodeWidth = 350;
        const width = g.getBBox().width;
        const height = g.getBBox().height;
        const translateWidth = Math.abs(translateValue) + nodeWidth / 2;

        // overwrite existing style attribute for container
        duplicatedContainer.setAttribute("style", `width: ${width}px !important; height: ${height}px !important;`);

        g.setAttribute("transform", `translate(${translateWidth},100) scale(1)`); // set view point
        html2canvas(duplicatedContainer, {
          // allowTaint: true,
          // useCORS: true,
          foreignObjectRendering: true,
          width,
          height,
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/jpg");
          const link = document.createElement("a");
          if (typeof link.download === "string") {
            link.href = imgData;
            link.download = "image.jpg";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            window.open(imgData);
          }
        });
      }
      document.body.removeChild(duplicatedContainer);
    }
  };

  const { loading } = useQuery(GET_CHART, {
    variables: { id: params.chartId },
    onCompleted: (data) => {
      if (data) {
        setChart(data.chart);
        setChartHistory([data.chart]);
      }
    },
  });

  const { data: orgUsersData, loading: userLoading } = useQuery(GET_ORGANIZATION_USERS, {
    variables: { organization: params.org },
    // onCompleted: (data) => {
    //   if (data) {
    //     setUsers(data.users);
    //   }
    // },
  });

  const { loading: planLoading } = useQuery(GET_DEPARTMENTS, {
    variables: { organization: params.org },
    onCompleted: (data) => {
      if (data) {
        setPlans(data.plans);
      }
    },
  });

  const [updateChart, { loading: updateChartLoading }] = useMutation(UPDATE_CHART);

  const handleUpdateChart = async (newChart) => {
    if (updateChartLoading) {
      return;
    }

    const { id, cards, name, status, plan } = newChart;
    const formattedCards = cards.map((card) => {
      const newCard = _.omit(card, ["__typename", "isAssistant"]);
      newCard.user = _.get(card, "user.id", null);
      return newCard;
    });
    let newUpdatedAt;
    try {
      const response = await updateChart({
        variables: {
          id,
          cards: formattedCards,
          name,
          status,
          plan: _.get(plan, "id", null),
        },
      });
      // update last saved date
      newUpdatedAt = _.get(response, "data.updateChart.chart.updatedAt");
      const newChartCopy = _.cloneDeep(newChart);
      _.set(newChartCopy, "updatedAt", newUpdatedAt);
      setChart(newChartCopy);
    } catch (e) {
      console.log("something went wrong");
    }
    return newUpdatedAt;
  };

  const handleUpdateChartAndAddHistory = (newChart) => {
    handleUpdateChart(newChart);
    // add history
    if (historyIndex === chartHistory.length - 1) {
      setChartHistory([...chartHistory, newChart]);
      setHitoryIndex(historyIndex + 1);
    } else {
      // remove future history, then add new future history
      const reducedChartHistory = chartHistory.slice(0, historyIndex + 1);
      setChartHistory([...reducedChartHistory, newChart]);
      setHitoryIndex(historyIndex + 1);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let chartCopy = _.cloneDeep(chart);
    _.set(chartCopy, name, value);
    setChart(chartCopy);
    return chartCopy;
  };

  const handleUpdatePlan = (newPlan) => {
    const chartCopy = handleChange({
      target: {
        name: "plan",
        value: newPlan,
      },
    });
    handleUpdateChartAndAddHistory(chartCopy);
  };

  // for selecting status
  const handleChangeAndUpdate = (e) => {
    const chartCopy = handleChange(e);
    handleUpdateChartAndAddHistory(chartCopy);
  };

  const handleChangeChartHistory = (jump) => {
    setHitoryIndex(historyIndex + jump);
    const currentChart = chartHistory[historyIndex + jump];
    handleUpdateChart(currentChart);
  };

  const handleDisplayOptionChange = (e) => {
    const { value } = e.target;
    setDisplayOptions({ ...displayOptions, [value]: !displayOptions[value] });
  };

  const handleChangeNode = (newNode) => {
    let chartCopy = _.cloneDeep(chart);
    let cards = chartCopy.cards;
    const nodeIndex = cards.findIndex((card) => card.cardId === newNode.cardId);
    if (nodeIndex < 0) {
      console.log("something went wrong");
      return;
    }
    const targetNode = cards[nodeIndex];
    if (newNode.parentNode !== targetNode.parentNode) {
      // TODO: additional changes need here if parent changes
    }
    _.set(chartCopy, `cards.${nodeIndex}`, newNode);
    setChart(chartCopy);
    return chartCopy;
  };

  const handleAddChildNode = (parentNode) => {
    let chartCopy = _.cloneDeep(chart);
    let cards = chartCopy.cards;

    // update parentNode
    let parentNodeCopy = _.omit(parentNode, ["children", "__rd3t"]);
    const parentNodeId = parentNodeCopy.cardId;
    const newNodeId = v4();
    const newChildNodes = [...parentNodeCopy.childNodes, newNodeId];
    _.set(parentNodeCopy, "childNodes", newChildNodes);
    const nodeIndex = cards.findIndex((card) => card.cardId === parentNodeCopy.cardId);
    if (nodeIndex < 0) {
      console.log("something went wrong");
      return;
    }
    _.set(chartCopy, `cards.${nodeIndex}`, parentNodeCopy);

    // add new node
    const newNode = {
      cardId: newNodeId,
      parentNode: parentNodeId,
      childNodes: [],
      assistantNodes: [],
      user: null,
      name: null,
      title: "",
      roles: null,
      responsibilities: "Responsibility 1\nResponsibility 2",
    };
    cards.push(newNode);
    handleUpdateChartAndAddHistory(chartCopy);
  };

  const handleAddAssistantNode = (parentNode) => {
    let chartCopy = _.cloneDeep(chart);
    let cards = chartCopy.cards;

    // update parentNode
    let parentNodeCopy = _.omit(parentNode, ["children", "__rd3t"]);
    const parentNodeId = parentNodeCopy.cardId;
    const newNodeId = v4();
    const newAssistantNodes = [...parentNodeCopy.assistantNodes, newNodeId];
    _.set(parentNodeCopy, "assistantNodes", newAssistantNodes);
    const nodeIndex = cards.findIndex((card) => card.cardId === parentNodeCopy.cardId);
    if (nodeIndex < 0) {
      console.log("something went wrong");
      return;
    }
    _.set(chartCopy, `cards.${nodeIndex}`, parentNodeCopy);

    // add new node
    const newNode = {
      cardId: newNodeId,
      parentNode: parentNodeId,
      childNodes: [],
      assistantNodes: [],
      user: null,
      name: null,
      title: "Assistant",
      roles: null,
      responsibilities: "Responsibility 1\nResponsibility 2",
    };
    cards.push(newNode);
    handleUpdateChartAndAddHistory(chartCopy);
  };

  const handleDeleteLeafNode = (removedNode) => {
    let chartCopy = _.cloneDeep(chart);
    const isAssistant = removedNode.isAssistant;
    // remove node
    _.set(
      chartCopy,
      "cards",
      chartCopy.cards.filter((card) => card.cardId !== removedNode.cardId)
    );

    // update parent of the deleted node
    const parentNodeId = removedNode.parentNode;

    const parentNodeIndex = chartCopy.cards.findIndex((card) => card.cardId === parentNodeId);
    if (parentNodeIndex < 0) {
      console.log("something went wrong");
      return;
    }
    let filteredNodes;
    if (!isAssistant) {
      filteredNodes = _.get(chartCopy, `cards.${parentNodeIndex}.childNodes`, []).filter((id) => id !== removedNode.cardId);
      _.set(chartCopy, `cards.${parentNodeIndex}.childNodes`, filteredNodes);
    } else {
      filteredNodes = _.get(chartCopy, `cards.${parentNodeIndex}.assistantNodes`, []).filter((id) => id !== removedNode.cardId);
      _.set(chartCopy, `cards.${parentNodeIndex}.assistantNodes`, filteredNodes);
    }

    handleUpdateChartAndAddHistory(chartCopy);
  };

  useEffect(() => {
    if (orgUsersData) {
      const chartPlanId = _.get(chart, "plan.id");
      const filteredUsers = _.get(orgUsersData, "users", []).filter((user) => {
        const userPlanIds = _.get(user, "plan", []).map((plan) => plan.id);
        return _.isNil(chartPlanId) ? true : userPlanIds.includes(chartPlanId);
      });
      setUsers(filteredUsers);
    }
  }, [orgUsersData, chart]);

  const currentYearPlans = plans.filter((plan) => plan.year === org.fiscalYear);

  if (loading || userLoading || planLoading) return <Loading />;
  if (!chart && !loading)
    return (
      <Container style={{ display: "flex", justifyContent: "center" }}>
        <Typography color="primary" variant="body1">
          Unable to access the chart
        </Typography>
      </Container>
    );
  return (
    <>
      <Container maxWidth={false} className={styles.container}>
        {/* header */}
        <div className={styles.header}>
          <div>
            <Typography>
              R&R Chart
              <EditablePlan plan={chart.plan} plans={currentYearPlans} disabled={!isAdmin} handleUpdatePlan={handleUpdatePlan} />
            </Typography>
            <EditableText
              value={chart.name}
              defaultValue={"New Chart"}
              name={"name"}
              placeholder={"Enter Chart Name"}
              handleChange={handleChange}
              handleSubmit={() => handleUpdateChartAndAddHistory(chart)}
              textClassName={styles.headerText}
              inputClassName={styles.headerName}
              disabled={!isAdmin}
            />
            <Typography variant="subtitle2">Last saved {getDateString(chart.updatedAt)}</Typography>
          </div>
          <div>
            <Select value={chart.status} name="status" disabled={!isAdmin} onChange={handleChangeAndUpdate} disableUnderline>
              <MenuItem value="draft">
                <Typography variant="h6">Draft</Typography>
              </MenuItem>
              <MenuItem value="final">
                <Typography variant="h6">Final</Typography>
              </MenuItem>
            </Select>
          </div>
        </div>

        {/* filter & undo & redo & print */}
        <div className={styles.toolbar}>
          <div>
            <Select value={chart.status} onChange={handleDisplayOptionChange} disableUnderline renderValue={() => "Card Details"}>
              <MenuItem value="name">
                <Checkbox checked={displayOptions.name} />
                <ListItemText>
                  <Typography variant="button">Name / Title</Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem value="avatar">
                <Checkbox checked={displayOptions.avatar} />
                <ListItemText>
                  <Typography variant="button">Avatar</Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem value="roles">
                <Checkbox checked={displayOptions.roles} />
                <ListItemText>
                  <Typography variant="button">Roles</Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem value="responsibilities">
                <Checkbox checked={displayOptions.responsibilities} />
                <ListItemText>
                  <Typography variant="button">Responsibilities</Typography>
                </ListItemText>
              </MenuItem>
            </Select>
          </div>
          <div>
            <IconButton
              onClick={() => handleChangeChartHistory(-1)}
              size="medium"
              disabled={!isAdmin || chartHistory.length < 2 || historyIndex === 0}
            >
              <Icon path={mdiUndoVariant} size={1} />
            </IconButton>
            <IconButton
              onClick={() => handleChangeChartHistory(1)}
              size="medium"
              disabled={!isAdmin || chartHistory.length < 2 || historyIndex === chartHistory.length - 1}
            >
              <Icon path={mdiRedoVariant} size={1} />
            </IconButton>
            <IconButton onClick={handleSaveTree} size="medium" style={{ marginLeft: "16px" }} disabled={!isAdmin}>
              <Icon path={mdiPrinter} size={1} />
            </IconButton>
          </div>
        </div>

        {/* tree */}
        <div className={styles.graphContainer} id="treeContainer">
          <TreeGraph
            ref={componentRef}
            cards={chart.cards}
            handleChangeNode={handleChangeNode}
            handleUpdateChartAndAddHistory={handleUpdateChartAndAddHistory}
            handleSubmit={() => handleUpdateChartAndAddHistory(chart)}
            handleAddChildNode={handleAddChildNode}
            handleAddAssistantNode={handleAddAssistantNode}
            handleDeleteLeafNode={handleDeleteLeafNode}
            displayOptions={displayOptions}
            users={users}
            disableEditing={!isAdmin}
          />
        </div>
      </Container>
    </>
  );
};

export default AccountabilityChart;

const GET_CHART = gql`
  query AccountabilityChart_GetChart($id: ID) {
    chart(id: $id) {
      id: _id
      isCompanyChart
      isFavorite
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      status
      organization
      plan {
        id
        departmentName
        color
        shortName
      }
      cards {
        cardId
        parentNode
        childNodes
        assistantNodes
        user {
          id
          profilePicture
          name {
            first
            last
          }
        }
        name
        title
        roles
        responsibilities
      }
    }
  }
`;

const GET_ORGANIZATION_USERS = gql`
  query AccountabilityChart_GetOrganizationUsers($organization: ID!) {
    users(organization: $organization) {
      id
      profilePicture
      name {
        first
        last
      }
      position
      auth
      plan {
        id
        departmentName
        color
        shortName
        sharedPlanId
      }
    }
  }
`;

const GET_DEPARTMENTS = gql`
  query AccountabilityChart_GetDepartments($organization: ID!) {
    plans(organization: $organization, category: "1 year", closed: false) {
      id
      departmentName
      color
      shortName
      year
    }
  }
`;

const UPDATE_CHART = gql`
  ${CHART_FIELDS}
  mutation AccountabilityChart_UpdateChart($id: ID!, $cards: [CardInput!], $name: String, $status: String, $plan: String) {
    updateChart(id: $id, cards: $cards, name: $name, status: $status, plan: $plan) {
      chart {
        ...ChartFields
      }
    }
  }
`;

const getDateString = (date) => {
  const dateObj = new Date(parseInt(date));
  const dateIsToday = isToday(dateObj);
  const formattedDate = format(dateObj, "MMMM dd, yyyy");
  const formattedTime = format(dateObj, "h:mm aa ");

  return dateIsToday ? `today at ${formattedTime}` : `on ${formattedDate} at ${formattedTime}`;
};
