import { gql, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { WEEKLY_TARGET_FIELDS } from "../../../utils/fragments";
import WeeklyTargets from "../../../components/WeeklyTargets/WeeklyTargets";
import Loading from "../../../components/Loading/Loading";
import useCorpPlan from "../../../hooks/useCorpPlan";
import _ from "lodash";

const ScorecardStep = ({ userIds, org, canEdit }) => {
  const variables = { users: userIds, organization: org.id };
  const { subscribeToMore, data, loading } = useQuery(SCORECARD_STEP_QUERY, { variables });
  const { corpForSelectedYear } = useCorpPlan({ fiscalYear: org.fiscalYear, orgId: org.id });
  useEffect(
    () =>
      subscribeToMore({
        document: SCORECARD_STEP_SUBSCRIPTION,
        variables: { users: userIds },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const kpi = subscriptionData.data.weeklyTargetPayload.weeklyTargetMutated;
          const action = subscriptionData.data.weeklyTargetPayload.action;
          let newWeeklyTargets;
          switch (action) {
            case "update":
              newWeeklyTargets = prev.weeklyTargets.map((weeklyTarget) => (weeklyTarget.id === kpi.id ? kpi : weeklyTarget));
              break;
            case "delete":
              newWeeklyTargets = prev.weeklyTargets.filter((weeklyTarget) => weeklyTarget.id !== kpi.id);
              break;
            case "create":
              newWeeklyTargets = [...prev.weeklyTargets, kpi];
              break;
            default:
              break;
          }

          return Object.assign({}, prev, {
            weeklyTargets: newWeeklyTargets,
          });
        },
      }),
    []
  );

  if (loading) return <Loading />;

  return (
    <div>
      <WeeklyTargets
        weeklyTargets={data.weeklyTargets}
        planId={_.get(corpForSelectedYear, "id")}
        plansOrder={org.plansOrder}
        variables={variables}
        range={1}
        canEdit={canEdit}
      />
    </div>
  );
};

export default ScorecardStep;

const SCORECARD_STEP_QUERY = gql`
  ${WEEKLY_TARGET_FIELDS}
  query ScorecardStepQuery($organization: ID!, $users: [ID!]) {
    weeklyTargets(organization: $organization, users: $users) {
      ...WeeklyTargetFields
    }
  }
`;

const SCORECARD_STEP_SUBSCRIPTION = gql`
  ${WEEKLY_TARGET_FIELDS}
  subscription OnScoreCardMutation($users: [ID!]) {
    weeklyTargetPayload(users: $users) {
      weeklyTargetMutated {
        ...WeeklyTargetFields
      }
      action
    }
  }
`;
