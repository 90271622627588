import React from "react";
import styles from "./Splash.module.scss";
import logo from "../../assets/img/vantage90_logo_inline.svg";
import splash from "../../assets/img/vantage90_splash.svg";

const Splash = ({ children, isWide }) => {
  const contentStyles = {
    width: "600px",
    "@media screen and (max-width: 960px)": {
      height: "550px",
    },
  };

  return (
    <div className={styles.container}>
      <div className={styles.splash}>
        <div className={styles.splashOverlay}>
          <img src={logo} alt="Vantage90 Logo" className={styles.img} />
          {/* <Typography variant="h3" className={styles.splashTitle}>
              Welcome to Vantage90
            </Typography> */}
        </div>
        <img src={splash} alt="splash" className={styles.svg} />
      </div>
      <div className={styles.content} style={isWide ? contentStyles : {}}>
        {children}
      </div>
    </div>
  );
};

export default Splash;
