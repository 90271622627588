import React from "react";
import styles from "./WeeklyReview.module.scss";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import useForm from "../../hooks/useForm";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { TimePicker } from "@material-ui/pickers";
import Loading from "../../components/Loading/Loading";
import { getDay } from "../../utils/dates";

import SelectUsers from "../../components/SelectUsers/SelectUsers";
import SelectDepartment from "../../components/SelectDepartment/SelectDepartment";

const initForm = {
  title: null,
  users: [],
  startTime: new Date(),
  frequency: 1,
  plan: "all",
};

const initErrorForm = {
  title: ["required"],
};

const AddMeetingDialog = ({ open, handleClose, requestFetch, params, user, snack }) => {
  const [createMeeting, { loading }] = useMutation(CREATE_MEETING);
  const { data } = useQuery(GET_USERS_AND_DEPARTMENTS, { variables: { organization: params.org } });

  const { form, formErrors, handleChange, handleChangeDate, handleChangeDayOfTheWeek, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });
  const fs = useMediaQuery("(max-width: 600px)");

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const { title, users, startTime, frequency, plan } = form;

    const ok = await createMeeting({
      variables: { organization: params.org, owner: user.id, title, users, startTime, frequency, plan: plan === "all" ? null : plan },
    });
    if (ok.data.createMeeting) {
      snack(`Created "${title}" meeting`);
      requestFetch();
      resetForm(initForm);
      handleClose(false, ok.data.createMeeting)();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose(false)();
        }
      }}
      fullWidth
      fullScreen={fs}
    >
      <DialogTitle>
        <div className={styles.flexSpaceBetween}>
          Create New Meeting
          <div>
            <IconButton onClick={handleClose(false)} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>

      <DialogContent>
        <TextField
          autoFocus
          label="Title"
          name="title"
          fullWidth
          variant="outlined"
          margin="normal"
          value={form.title || ""}
          onChange={handleChange}
          helperText={formErrors.title}
          error={Boolean(formErrors.title)}
        />
        <SelectDepartment
          plans={_.get(data, "plans")}
          name="plan"
          handleChange={handleChange}
          value={form.plan}
          helperText={formErrors.plan}
          error={Boolean(formErrors.plan)}
          showAll
        />
        <SelectUsers
          name="users"
          label="Attendees"
          multiple
          users={_.get(data, "users")}
          handleChange={handleChange}
          values={form.users}
          plan={form.plan}
          allPlans={_.get(data, "plans")}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel variant="outlined">Frequency</InputLabel>
          <Select variant="outlined" label="Frequency" name="frequency" value={form.frequency} onChange={handleChange}>
            <MenuItem value={1}>Every week</MenuItem>
            <MenuItem value={2}>Every 2 weeks</MenuItem>
            <MenuItem value={4}>Every month</MenuItem>
            <MenuItem value={12}>Every 3 months</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel variant="outlined">Day Of The Week</InputLabel>
          <Select
            variant="outlined"
            label="Day Of The Week"
            value={getDay(form.startTime)}
            onChange={handleChangeDayOfTheWeek("startTime")}
          >
            <MenuItem value={0}>Sunday</MenuItem>
            <MenuItem value={1}>Monday</MenuItem>
            <MenuItem value={2}>Tuesday</MenuItem>
            <MenuItem value={3}>Wednesday</MenuItem>
            <MenuItem value={4}>Thursday</MenuItem>
            <MenuItem value={5}>Friday</MenuItem>
            <MenuItem value={6}>Saturday</MenuItem>
          </Select>
        </FormControl>
        <TimePicker
          fullWidth
          variant="inline"
          inputVariant="outlined"
          label="Time"
          margin="normal"
          value={form.startTime || ""}
          onChange={handleChangeDate("startTime")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary" variant="contained" disabled={loading} className={styles.button}>
          {loading ? <Loading size={24} color="#fff" /> : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMeetingDialog;

const CREATE_MEETING = gql`
  mutation AddMeetingDialog_CreateMeeting(
    $organization: ID!
    $owner: ID!
    $title: String!
    $users: [ID!]
    $startTime: String!
    $frequency: Int!
    $plan: ID
  ) {
    createMeeting(
      organization: $organization
      owner: $owner
      title: $title
      users: $users
      startTime: $startTime
      frequency: $frequency
      plan: $plan
    ) {
      id
      title
      plan {
        id
        departmentName
      }
      status
      startTime
      frequency
      owner {
        id
        name {
          first
          last
        }
        profilePicture
      }
      users {
        id
        name {
          first
          last
        }
        profilePicture
      }
      steps {
        name
        alias
        duration
      }
      endTime
      rating
      weeklyTargets {
        id
        value
      }
      rocks {
        id
        value
      }
      todos {
        id
        value
      }
      issues {
        id
        value
      }
    }
  }
`;

const GET_USERS_AND_DEPARTMENTS = gql`
  query AddMeetingDialog_GetUsersDepts($organization: ID!) {
    users(organization: $organization) {
      name {
        first
        last
      }
      profilePicture
      id
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    plans(organization: $organization, category: "1 year", closed: false) {
      id
      departmentName
      sharedPlanId
    }
  }
`;
