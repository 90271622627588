import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import styles from "./Metrics.module.scss";
import { getYear } from "date-fns";
import { mergeCustomizer } from "../../utils/misc";
import PlanPill from "../PlanPill/PlanPill";

function OneYearMetricsList({
  open,
  onClose,
  metrics,
  selectedYear,
  closed,
  onSubmit,
  formMetric,
  plansOrder,
  handleCreateQuarterMetric,
  disableMessage,
}) {
  const [selectedMetric, setSelectedMetric] = useState(formMetric ? formMetric : null);
  const [metricsForSelYear, setMetricsForSelYear] = useState([]);
  const metricsByYear = useMemo(() => {
    const corpMetrics = metrics.filter((metric) => _.get(metric, "plan.departmentName") === "Corporate");
    const nonCorpMetrics = _.difference(metrics, corpMetrics);

    const metricsByYear1 = _.groupBy(corpMetrics, "plan.year");
    const metricsByYear2 = _.groupBy(nonCorpMetrics, "plan.plan.year");
    return _(metricsByYear1)
      .mergeWith(metricsByYear2, mergeCustomizer)
      .map((metrics, year) => ({ year: getYear(parseInt(year)), metrics }))
      .value();
  }, [metrics]);

  useEffect(() => {
    if (metricsByYear) {
      const mtrcsForSelYear = _.get(_.find(metricsByYear, ["year", selectedYear]), "metrics", []);
      const sortedMtrcsForSelYear = _.sortBy(mtrcsForSelYear, [
        function (mtrc) {
          // this preliminary sorting step is required if the list of metrics includes multiple plans
          return plansOrder.indexOf(_.get(mtrc, "plan.sharedPlanId"));
        },
        "number",
      ]);

      setMetricsForSelYear(sortedMtrcsForSelYear);
    }
  }, [metricsByYear, selectedYear, plansOrder]);

  const handleListItemClick = (id) => () => {
    setSelectedMetric((prev) => (id === prev ? null : id));
  };

  const handleSubmit = () => {
    onSubmit(selectedMetric);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {closed && `Closed `}1 Year Metrics ({selectedYear})
      </DialogTitle>
      <DialogContent>
        <List>
          {_.isEmpty(metricsForSelYear) ? (
            <Typography>No metrics for this year</Typography>
          ) : (
            metricsForSelYear.map(({ id, value, number, plan }, idx) => (
              <ListItem
                key={id}
                button
                selected={selectedMetric === id}
                onClick={handleListItemClick(id)}
                className={styles.metricListItem}
              >
                <ListItemText>
                  {!_.isNil(plan?.departmentName) ? <PlanPill plan={plan} /> : <PlanPill plan={null} />}
                  {number}. {value}
                </ListItemText>
              </ListItem>
            ))
          )}
        </List>
      </DialogContent>
      <DialogActions>
        {/* **TODO: The following needs to be changed of after merging the add/edit dialogs for metrics */}
        {/* <Tooltip title={closed ? "year is already closed" : disableMessage ? disableMessage : "Create 1 year metric"}>
          <span style={{ marginRight: "auto" }}>
            <Button onClick={handleCreateQuarterMetric} color="primary" disabled={closed || disableMessage}>
              Create
            </Button>
          </span>
        </Tooltip> */}
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OneYearMetricsList;
