import React from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import styles from "./Error.module.scss";

import { Typography, Button } from "@material-ui/core";

import Splash from "../../components/Splash/Splash";

const Error = ({ title, body }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <Splash>
      <Typography variant="h2" gutterBottom>
        {title}
      </Typography>
      <Typography paragraph>{body}</Typography>
      <div>
        <Button variant="contained" color="primary" className={styles.button} onClick={handleGoBack}>
          Go Back
        </Button>
        <Link to="/">
          <Button variant="outlined" color="primary" className={styles.button}>
            Go Home
          </Button>
        </Link>
      </div>
    </Splash>
  );
};

export default Error;
