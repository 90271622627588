import React, { useState, useEffect, useContext } from "react";
import handleViewport from "react-in-viewport";
import styles from "./Rocks.module.scss";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { TableRow, TableCell, MenuItem, IconButton, Tooltip, Typography } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronUp, mdiChartTimelineVariant, mdiEyeOffOutline, mdiLinkVariantOff } from "@mdi/js";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import useInViewport from "../../hooks/useInViewport";
import UserAvatars from "../UserAvatars/UserAvatars";
import Menu from "../Menu/Menu";
import SuccessCriteria from "../SuccessCriteria/SuccessCriteria";
import Status from "../Status/Status";
import ProgressBar from "../ProgressBar/ProgressBar";
import NotesButton from "../Notes/NotesButton";
import Skeleton from "./Skeleton";
import { formatAs } from "../../utils/dates";
import ConfirmDeletionDialog from "../ConfirmDeletionDialog/ConfirmDeletionDialog";
import useConfirmDelete from "../../hooks/useConfirmDelete";
import PlanPill from "../PlanPill/PlanPill";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const Rock = ({
  inViewport,
  forwardedRef,
  handleAddSCDialog,
  handleAddIssueDialog,
  handleDuplicate,
  showAllSc,
  canEdit,
  quarterDate,
  rock,
  handleEditDialog,
  planId,
}) => {
  const { id, value, index, users, status, successCriterias, objective, plan, hide } = rock;
  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const [showSc, setShowSc] = useState(false);

  // temporary state for sorting
  const [scs, setScs] = useState([]);

  useEffect(() => {
    setScs(successCriterias);
  }, [successCriterias]);

  const [deleteRock] = useMutation(DELETE_ROCK, {
    update(cache, { data: { deleteRock } }) {
      const { deletedRock, deletedSuccessCriterias } = deleteRock;
      const deletedRockId = cache.identify(deletedRock);
      const deletedScIds = deletedSuccessCriterias.map((sc) => cache.identify(sc));
      cache.modify({
        fields: {
          rocks: (existingRocks) => {
            return existingRocks.filter((rockRef) => {
              const rockId = cache.identify(rockRef);
              return rockId !== deletedRockId;
            });
          },
          successCriterias: (existingScs) => {
            return existingScs.filter((scRef) => {
              const scId = cache.identify(scRef);
              return !deletedScIds.includes(scId);
            });
          },
        },
      });
    },
  });

  const [updateSuccessCriteria] = useMutation(UPDATE_SUCCESS_CRITERIA);
  const [updateRockStatus] = useMutation(UPDATE_ROCK_STATUS);
  const hasRendered = useInViewport(inViewport);

  const itemType = "rock";
  const { confirmOpen, handleConfirmOpen, handleDelete } = useConfirmDelete({
    id,
    value,
    itemType,
  });

  const handleUpdateStatus = (newStatus) => async () => {
    try {
      const res = await updateRockStatus({
        variables: {
          id,
          status: newStatus,
        },
      });

      if (res.data.updateRockStatus) {
        snack(`Marked "${value}" as ${newStatus}`);
        requestFetch();
      }
    } catch (err) {
      snack("Failed to update rock status", "error");
    }
  };

  const handleShowSc = () => {
    setShowSc(!showSc);
  };

  const handleDragEnd = async ({ draggableId, destination, source }) => {
    if (_.isEqual(source, destination)) return;

    let newSCs = _.cloneDeep(scs);
    const [srcItem] = newSCs.splice(source.index, 1);
    newSCs.splice(destination.index, 0, srcItem);
    setScs(newSCs);

    const scId = draggableId.split("_")[1];

    try {
      const ok = await updateSuccessCriteria({
        variables: { id: scId, rock: id, rockIndex: destination.index },
      });

      if (ok.data.updateSuccessCriteria) {
        snack("Updated success criteria order");
        requestFetch();
      }
    } catch (err) {
      setScs(successCriterias);
      snack("Failed to update success criteria order", "error");
    }
  };

  useEffect(() => {
    setShowSc(showAllSc);
  }, [showAllSc]);

  if (!hasRendered)
    return (
      <TableRow ref={forwardedRef} className={styles.skeletonRow}>
        <Skeleton />
      </TableRow>
    );

  const isComplete = status === "complete" || (!_.isEmpty(successCriterias) && successCriterias.every((sc) => sc.done));

  return (
    <>
      <TableRow className={styles.noBot} ref={forwardedRef}>
        <TableCell className={styles.firstCell}>
          <UserAvatars users={users} />
        </TableCell>
        <TableCell className={styles.secondCell}>
          <div className={styles.flex}>
            {!_.isNil(plan?.departmentName) ? <PlanPill plan={plan} /> : <PlanPill plan={null} />}
            <div className={isComplete ? styles.complete : undefined}>{value}</div>
            <NotesButton
              id={id}
              model="rock"
              value={value.trim()}
              user={_.get(users, "0") ? _.get(users, "0.id") : null}
              doc={rock}
              tabs={["notes", "issues", "todos"]}
              canEditTodo={canEdit}
              disabled={!canEdit}
              planId={planId}
            />
            {canEdit && (
              <Menu>
                {/* <MenuItem onClick={handleAddIssueDialog(id, "Rock", value.trim(), users[0] ? users[0].id : null)}>Add Issue</MenuItem> */}
                <MenuItem onClick={handleEditDialog(true, rock)}>Edit</MenuItem>
                <MenuItem onClick={handleAddSCDialog(id)}>Add Success Criteria</MenuItem>
                {objective && (
                  <MenuItem onClick={handleDuplicate({ objectiveId: objective.id, index, value, id, successCriterias })}>Copy</MenuItem>
                )}
                <MenuItem onClick={handleConfirmOpen(true)} className={styles.delete}>
                  Delete
                </MenuItem>
              </Menu>
            )}
            {hide && <Icon style={{ marginLeft: "4px", minWidth: "18px" }} path={mdiEyeOffOutline} size={0.75} />}
          </div>
        </TableCell>
        <TableCell align="center">
          <Tooltip
            title={
              objective ? (
                <Typography variant="body2">
                  <span className={styles.label}>
                    Objective: <br />
                  </span>
                  {objective?.value}
                </Typography>
              ) : (
                <Typography variant="body2">No tie in</Typography>
              )
            }
          >
            <Icon path={objective ? mdiChartTimelineVariant : mdiLinkVariantOff} size={1.25} color="rgba(0, 0, 0, 0.54)" />
          </Tooltip>
        </TableCell>
        <TableCell align="center">
          <span className={styles.quarter}>
            {index} {quarterDate && <span className={styles.label}>({formatAs(quarterDate, "MMM d")})</span>}
          </span>
        </TableCell>
        <TableCell align="center">
          <ProgressBar sc={successCriterias} done={isComplete} />
        </TableCell>
        <TableCell>
          <div className={styles.flexCenter}>
            <Status status={status} />
            <Menu icon="arrow">
              <MenuItem onClick={handleUpdateStatus("on track")} disabled={status === "on track"}>
                Mark as on track
              </MenuItem>
              <MenuItem onClick={handleUpdateStatus("off track")} disabled={status === "off track"}>
                Mark as off track
              </MenuItem>
            </Menu>
          </div>
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={handleShowSc}>
            <Icon path={showSc ? mdiChevronUp : mdiChevronDown} size={1} color="rgba(0, 0, 0, 0.54)" />
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan="7">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="SCL" type="successCriteriaList">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {scs &&
                    scs.map((sc, idx) => {
                      if (!showSc) return null;
                      return (
                        <Draggable key={sc.value} draggableId={`SCF_${sc.id}_${sc.value}`} index={idx} isDragDisabled={!canEdit}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{ ...provided.draggableProps.style }}
                              className={styles.containerPadding}
                            >
                              <SuccessCriteria
                                key={sc.id}
                                sc={sc}
                                requestFetch={requestFetch}
                                handleAddIssueDialog={handleAddIssueDialog}
                                snack={snack}
                                showComplete={true}
                                provided={provided}
                                corpPlanId={planId}
                                rockId={id}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                  {_.isEmpty(scs) && showSc && (
                    <Typography variant="body2" className={styles.noSc}>
                      No success criteria. {canEdit && <u onClick={handleAddSCDialog(id)}>Add one</u>}
                    </Typography>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </TableCell>
      </TableRow>

      <ConfirmDeletionDialog
        itemType={itemType}
        value={value}
        confirmOpen={confirmOpen}
        handleConfirmOpen={handleConfirmOpen}
        handleDeletion={handleDelete(deleteRock)}
      />
    </>
  );
};

export default handleViewport(Rock);

const DELETE_ROCK = gql`
  mutation QuarterRocksDeleteRock($id: ID!) {
    deleteRock(id: $id) {
      deletedRock {
        id
      }
      deletedSuccessCriterias {
        id
      }
      updatedObjective {
        id
        rocks {
          id
        }
      }
      updatedPlan {
        id
        rocks {
          id
        }
      }
    }
  }
`;

const UPDATE_ROCK_STATUS = gql`
  mutation ($id: ID!, $status: String!) {
    updateRockStatus(id: $id, status: $status)
  }
`;

const UPDATE_SUCCESS_CRITERIA = gql`
  mutation ($id: ID!, $rock: ID, $rockIndex: Int, $copy: Boolean) {
    updateSuccessCriteria(id: $id, rock: $rock, rockIndex: $rockIndex, copy: $copy)
  }
`;
