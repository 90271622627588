import React from "react";
import { useSelected, useFocused, useSlateStatic, ReactEditor } from "slate-react";
import { Transforms } from "slate";
import Icon from "@mdi/react";
import { IconButton, Button } from "@material-ui/core";
import { mdiLinkOff, mdiOpenInNew } from "@mdi/js";
import { removeLink } from "./functions";
import styles from "./RichText.module.scss";
import { Delete } from "@material-ui/icons";

export const Link = ({ attributes, element, children }) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();

  return (
    <div className={styles.elementLink}>
      <a {...attributes} href={element.href} target="_blank">
        {children}
      </a>
      {selected && focused && (
        <div className={styles.popup} contentEditable={false}>
          <a href={element.href} rel="noreferrer" target="_blank">
            <Icon path={mdiOpenInNew} size={1} />
            <span style={{ wordBreak: "break-all" }}>{element.href}</span>
          </a>
          <IconButton onClick={() => removeLink(editor)} style={{ padding: 0 }}>
            <Icon path={mdiLinkOff} size={1} />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export const Paragraph = ({ style, attributes, children }) => {
  return (
    <div style={style} {...attributes}>
      {children}
    </div>
  );
};

export const Image = ({ attributes, children, element }) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  const selected = useSelected();
  const focused = useFocused();
  return (
    <div {...attributes}>
      {children}
      <div contentEditable={false} style={{ position: "relative" }}>
        <img
          src={element.url}
          style={{
            display: "block",
            maxWidth: "100%",
            maxHeight: "25em",
            boxShadow: selected && focused ? "0 0 0 3px #B4D5FF" : "none",
          }}
        />
        <IconButton
          onMouseDown={(event) => {
            event.preventDefault(); // Prevent the focus from shifting away from the image
            event.stopPropagation(); // Stop the event from bubbling up
          }}
          onClick={() => Transforms.removeNodes(editor, { at: path })}
          style={{
            display: selected && focused ? "inline" : "none",
            position: "absolute",
            top: "0.5em",
            left: "0.5em",
            backgroundColor: "white",
          }}
        >
          <Delete style={{ fontSize: "1em" }} />
        </IconButton>
      </div>
    </div>
  );
};
