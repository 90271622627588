import React from "react";
import _ from "lodash";
import styles from "./Inactive.module.scss";
import { useAuth } from "../../context/authContext";

import { Typography, Button } from "@material-ui/core";

import Splash from "../../components/Splash/Splash";

const Inactive = ({ name }) => {
  const { handleSignout } = useAuth();

  return (
    <Splash>
      <Typography variant="h2" gutterBottom>
        Prohibited
      </Typography>

      <Typography variant="body1" color="error" paragraph>
        {name} has been marked as inactive.
      </Typography>

      <Button margin="normal" fullWidth variant="contained" color="primary" onClick={handleSignout}>
        Sign out
      </Button>
    </Splash>
  );
};

export default Inactive;
