import React, { useState, useContext } from "react";
import _ from "lodash";
import styles from "./SuccessCriteria.module.scss";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { NotesContext } from "../../context/notesContext";
import { ListItem, ListItemIcon, ListItemText, MenuItem, Button } from "@material-ui/core";
import { red, green } from "@material-ui/core/colors";
import Icon from "@mdi/react";
import { mdiDecagram, mdiCheckboxBlankCircle, mdiDrag } from "@mdi/js";
import Menu from "../Menu/Menu";
import { formatAs } from "../../utils/dates";
import { DialogContext } from "../../context/dialogContext";

const SuccessCriteria = ({
  requestFetch,
  handleAddIssueDialog,
  snack,
  showComplete,
  sc = {},
  previewOnly = false,
  provided,
  corpPlanId,
  rockId,
}) => {
  const { id, value, targetDate, done } = sc;

  const { notes } = useContext(NotesContext);
  const { dialog, setDialog } = useContext(DialogContext);

  const [updateSuccessCriteria] = useMutation(UPDATE_SUCCESS_CRITERIA);

  const [deleteSuccessCriteria] = useMutation(DELETE_SUCCESS_CRITERIA, {
    update(cache, { data: { deleteSuccessCriteria } }) {
      const { deletedSuccessCriteria } = deleteSuccessCriteria;
      const deleteScId = cache.identify(deletedSuccessCriteria);
      cache.modify({
        fields: {
          successCriterias: (existingScs) => {
            return existingScs.filter((scRef) => {
              const scId = cache.identify(scRef);
              return scId !== deleteScId;
            });
          },
        },
      });
    },
  });

  const handleEditDialog = () => {
    setDialog({ ...dialog, addSuccessCriteriaDialog: { open: true, successCriterion: sc, planId: corpPlanId, rock: rockId } });
  };

  const handleNotes = () => {
    notes(id, "successCriteria");
  };

  const handleUpdate = async () => {
    try {
      const ok = await updateSuccessCriteria({ variables: { id, done: !done } });

      if (ok.data.updateSuccessCriteria) {
        snack(`Marked "${value}" as ${done ? "incomplete" : "complete"}`);
        requestFetch();
      }
    } catch (err) {
      snack("Failed to update success criteria status", "error");
    }
  };

  const handleDelete = async () => {
    try {
      const ok = await deleteSuccessCriteria({ variables: { id } });

      if (ok.data.deleteSuccessCriteria) {
        snack(`Deleted "${value}" success criteria`);
      }
    } catch (err) {
      snack("Failed to delete success criteria", "error");
    }
  };

  return (
    <div className={styles.container}>
      <ListItem className={styles.cursor}>
        <ListItemIcon className={styles.iconDone}>
          {!previewOnly && (
            <div {...provided.dragHandleProps}>
              <Icon path={mdiDrag} size={1} />
            </div>
          )}
          <Icon path={done ? mdiCheckboxBlankCircle : mdiDecagram} size={1} color={done ? green[400] : red[400]} />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ variant: "body2" }} className={done ? styles.done : undefined}>
          {value} {targetDate && <span style={{ fontWeight: "bold" }}>({formatAs(targetDate, "MMM dd, yyyy")})</span>}
        </ListItemText>
        {!previewOnly && (
          <span className={styles.icon}>
            <div className={styles.flex}>
              {/* {showComplete && <Button onClick={handleUpdate}>Mark as {done ? "Incomplete" : "Complete"}</Button>} */}
              <Menu>
                <MenuItem onClick={handleNotes}>View Notes</MenuItem>
                <MenuItem onClick={handleAddIssueDialog(id, "SuccessCriteria", value.trim())}>Add Issue</MenuItem>
                <MenuItem onClick={handleEditDialog}>Edit</MenuItem>
                {showComplete && <MenuItem onClick={handleUpdate}>Mark as {done ? "Incomplete" : "Complete"}</MenuItem>}
                <MenuItem onClick={handleDelete} className={styles.delete}>
                  Delete
                </MenuItem>
              </Menu>
            </div>
          </span>
        )}
      </ListItem>
    </div>
  );
};

export default SuccessCriteria;

const UPDATE_SUCCESS_CRITERIA = gql`
  mutation ($id: ID!, $done: Boolean!) {
    updateSuccessCriteria(id: $id, done: $done)
  }
`;

const DELETE_SUCCESS_CRITERIA = gql`
  mutation NoDialogDeleteSuccessCriteria($id: ID!) {
    deleteSuccessCriteria(id: $id) {
      deletedSuccessCriteria {
        id
      }
      updatedRock {
        id
        successCriterias {
          id
        }
      }
    }
  }
`;
