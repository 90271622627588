import client from "../apolloClient";
import gql from "graphql-tag";
import _ from "lodash";

export const getUser = (id) => {
  return client.query({
    query: gql`
    query GetUser{
      user(id: "${id}") {
        id
        name {
          first
          last
        }
        email
        auth
        plan{
          id
          departmentName
          closed
          sharedPlanId
        }
        profilePicture
        position
        organization
        organizations
        llmPersonalPrompt
        llmFeedbackPrompts {
          id
          title
          prompt
          access {
            id
            level
          }
        }
        notificationSettings {
          weeklyReminder {
            frequency
            enabled
          }
          meetingReminder {
            frequency
            enabled
          }
        }
      stripeCustomerId,
        sessionId,
        isVerified
        verificationToken
      }
    }
    `,
    fetchPolicy: "no-cache",
  });
};

export const getOneYearObjectives = ({ organization, ids }) => {
  return client.query({
    query: gql`
      {
        objectives(organization: "${organization}" ids: "${ids}") {
          id
          value
        }
      }
    `,
  });
};

export const getNotes = (id, model) => {
  return client.query({
    query: gql`
      query GetNotes{
        ${model}(id: "${id}") {
          notes {
            id
            user {
              name {
                first
                last
              }
            }
            date
            text
            url
            filename
            type
          }
        }
      }
    `,
    fetchPolicy: "no-cache",
  });
};

export const getSubArrayFieldNotes = (id, model, field) => {
  return client.query({
    query: gql`
      query GetSubArrayFieldNotes{
        ${model}(id: "${id}") {
          ${field}{
            notes {
            id
            user {
              name {
                first
                last
              }
            }
            date
            text
            url
            filename
            type
          }
          }
        }
      }
    `,
    fetchPolicy: "no-cache",
  });
};

export const getNestedNotes = (id, model, core) => {
  return client.query({
    query: gql`
      query GetNestedNotes{
        ${model}(id: "${id}" ){
          notes{
            ${core}{
              id
              user{
                name{
                  first
                  last
                }
              }
              date 
              text
              url
              filename
              type
            }
          }
        }
      }
    `,
    fetchPolicy: "no-cache",
  });
};

export const getRockStatus = (id) => {
  return client.query({
    query: gql`
    query{
      rockStatus(id: "${id}")
    }
    `,
    fetchPolicy: "no-cache",
  });
};

export const getTodoTieIn = (id, model) => {
  return client.query({
    query: gql`
    query{
      ${_.camelCase(model)}(id: "${id}"){
        value
      }
    }
    `,
    fetchPolicy: "no-cache",
  });
};

export const signout = () => {
  return client.query({
    query: gql`
      query Signout {
        signout
      }
    `,
    fetchPolicy: "no-cache",
  });
};

export const resetPassword = (id, email) => {
  return client.mutate({
    mutation: gql`
      mutation {
        sendPasswordResetEmail(email: "${email}", _id: "${id}")
      }
    `,
  });
};
