import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, DialogContentText } from "@material-ui/core";
import Loading from "../../components/Loading/Loading";

const CreateYearDialog = ({ open, handleClose, nextPlanYearStart, nextPlanYearRange, organizationId, snack, requestFetch }) => {
  const [confirmText, setConfirmText] = useState();
  const [loading, setLoading] = useState(false);
  const [createNewYear] = useMutation(CREATE_NEW_PLAN);

  const handleChange = (e) => {
    setConfirmText(e.target.value);
  };

  const handleCreateNewYear = async () => {
    setLoading(true);

    try {
      const res = await createNewYear({ variables: { id: organizationId } });
      if (res.data.createFuturePlans) {
        // window.location.reload();
        requestFetch();
        handleClose();
        snack(`Successfully created ${nextPlanYearRange} plan`);
      }
    } catch (err) {
      snack(`Failed to create ${nextPlanYearRange} plan`, "error");
    }

    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={
        loading
          ? null
          : (event, reason) => {
              if (reason !== "backdropClick") {
                handleClose();
              }
            }
      }
      fullWidth
    >
      <DialogTitle>Create {nextPlanYearRange} Plan</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to create plan for year {nextPlanYearRange}, this will NOT close the current year plan, items that need to be copied
          over to the new plan can be done when closing out Quarter 4. This process cannot be undone.
        </DialogContentText>
        <DialogContentText>
          Please type <code>{nextPlanYearStart}</code> to proceed.
        </DialogContentText>
        <TextField variant="outlined" fullWidth value={confirmText || ""} onChange={handleChange} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleCreateNewYear} disabled={confirmText !== nextPlanYearStart || loading}>
          {loading ? <Loading color="#fff" size={24} /> : `Create`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateYearDialog;

const CREATE_NEW_PLAN = gql`
  mutation ($id: ID!) {
    createFuturePlans(id: $id)
  }
`;
