import React from "react";
import _ from "lodash";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, useTheme } from "@material-ui/core";
import Loading from "../Loading/Loading";

export default function ConfirmDeletionDialog({
  itemType,
  value,
  confirmOpen,
  handleConfirmOpen,
  handleDeletion,
  children,
  deleteLoading,
}) {
  const theme = useTheme();

  return (
    <Dialog
      open={confirmOpen}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleConfirmOpen(false)();
        }
      }}
    >
      <DialogTitle>Delete the following {itemType}?</DialogTitle>
      <DialogContent>{_.isEmpty(children) ? <DialogContentText>{value}</DialogContentText> : <>{children}</>}</DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmOpen(false)}>Cancel</Button>
        <Button onClick={handleDeletion} color="primary" autoFocus disabled={deleteLoading}>
          {deleteLoading ? <Loading size={24} color={theme.palette.primary.main} /> : "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
