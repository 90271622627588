import React, { useEffect, useState, useContext, useMemo } from "react";
import styles from "./Objectives.module.scss";
import _, { camelCase, upperFirst } from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { fiscalYearStart, getCurrentQuarter, year } from "../../utils/dates";
import { isAuthed } from "../../utils/authorization";
import { v4 } from "uuid";

import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  MenuItem,
  useMediaQuery,
  TextField,
  TableFooter,
  Backdrop,
  useTheme,
  IconButton,
  Menu,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiPlus, mdiChartTimelineVariant, mdiContentCopy, mdiCursorMove } from "@mdi/js";

import { DialogContext } from "../../context/dialogContext";
import { useAuth } from "../../context/authContext";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
// import { LoadingContext } from "../../context/loadingContext";

import Objective from "./Objective";
import OneYearObjective from "./OneYearObjective";
import RockProgressTotals from "./RockProgressTotals";
import CardTitle from "../CardTitle/CardTitle";
import Loading from "../Loading/Loading";
import useMobileMenu from "../../hooks/useMobileMenu";
import ConfirmDeletionDialog from "../ConfirmDeletionDialog/ConfirmDeletionDialog";
import { useParams } from "react-router-dom";
import { OBJECTIVE_FIELDS } from "../../utils/fragments";
import RockTable from "./RockTable";

const Objectives = ({
  objectives,
  fiscalYear,
  plansOrder = [],
  category,
  corpForSelectedYear,
  planId,
  currentQuarter,
  variables,
  locked,
  nextYearPlanCreated,
  isFuturePlan,
  isCurrentPlan,
}) => {
  const { dialog, setDialog } = useContext(DialogContext);
  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const { auth } = useAuth();
  const { departmentFilter } = useDepartmentFilter();
  const params = useParams();
  // const { getLoading } = useContext(LoadingContext);
  const categoryStr = _.camelCase(category);

  const [rocksFilter, setRocksFilter] = useState(JSON.parse(sessionStorage.getItem(`${categoryStr}Objs.rocksFilter`)) || "hide");
  const [showAllQuarters, setShowAllQuarters] = useState(() => {
    const sessionStorageKey = JSON.parse(sessionStorage.getItem(`${categoryStr}Objs.showAllQuarters`));
    return _.isNil(sessionStorageKey) ? false : sessionStorageKey;
  });
  const [showDescriptions, setShowDescriptions] = useState(() => {
    const sessionStorageKey = JSON.parse(sessionStorage.getItem(`${categoryStr}Objs.showDescriptions`));
    return _.isNil(sessionStorageKey) ? true : sessionStorageKey;
  });
  const [rockScStrategy, setRockScStrategy] = useState(JSON.parse(sessionStorage.getItem(`${categoryStr}Objs.rockScStrategy`)) || "move");
  const [threeYearTieIn, setThreeYearTieIn] = useState(
    JSON.parse(sessionStorage.getItem(`${categoryStr}Objs.threeYearTieIn`)) || "current"
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [processedObjs, setProcessedObjs] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [objectiveToDelete, setObjectiveToDelete] = useState();
  const [rockProgressQuarter, setRockProgressQuarter] = useState(getCurrentQuarter(fiscalYear) - 1);

  const { data: corpPlans } = useQuery(GET_CORP_PLANS, {
    variables: { organization: params.org },
  });

  const [updateRockQuarter] = useMutation(UPDATE_ROCK_QUARTER);
  const [updateObjectiveNumber] = useMutation(UPDATE_OBJECTIVE_NUMBER);
  const [updateObjectiveStatus] = useMutation(UPDATE_OBJECTIVE_STATUS);
  const [updateSuccessCriteria] = useMutation(UPDATE_SUCCESS_CRITERIA);
  const [deleteObjective, { loading: deleteLoading }] = useMutation(DELETE_OBJECTIVE, {
    update(cache, { data: { deleteObjective } }) {
      try {
        const { objective, rocks, successCriterias } = deleteObjective;
        const deletedObjCacheId = cache.identify(objective);
        const deletedRockCacheIds = rocks.map((rock) => cache.identify(rock));
        const deletedScCacheIds = successCriterias.map((sc) => cache.identify(sc));
        // propagate to all objectives query
        cache.modify({
          fields: {
            objectives: (existingObjs) =>
              existingObjs.filter((obj) => {
                const cacheId = cache.identify(obj);
                return cacheId !== deletedObjCacheId;
              }),
          },
        });
        if (!_.isEmpty(deletedRockCacheIds)) {
          // remove rocks from cache
          cache.modify({
            fields: {
              rocks: (existingRocks) =>
                existingRocks.filter((rock) => {
                  const cacheId = cache.identify(rock);
                  return deletedRockCacheIds.indexOf(cacheId) < 0;
                }),
            },
          });
        }
        if (!_.isEmpty(deletedScCacheIds)) {
          // remove success criterias from cache
          cache.modify({
            fields: {
              successCriterias: (existingScs) =>
                existingScs.filter((sc) => {
                  const cacheId = cache.identify(sc);
                  return deletedScCacheIds.indexOf(cacheId) < 0;
                }),
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
  });

  const handleConfirmOpen = (open, objective) => () => {
    if (open) {
      setObjectiveToDelete(objective);
    } else {
      setObjectiveToDelete();
    }
  };

  function toPascalCase(str) {
    return upperFirst(camelCase(str));
  }

  const handleDeleteObjective = () => async () => {
    const { id, value } = objectiveToDelete;

    try {
      const res = await deleteObjective({ variables: { id } });
      let delMutationData = `delete${toPascalCase("objective")}`;

      if (res.data[delMutationData]) {
        snack(`Deleted "${value}" objective`);
        handleConfirmOpen(false)();
      }
    } catch (err) {
      snack("Failed to delete objective", "error");
    }
  };

  const { isMobile, renderMobileMenu } = useMobileMenu();

  const stringifiedPlansOrder = JSON.stringify(plansOrder);

  const handleUpdateStatus = (newStatus, objective) => async () => {
    const { id, value } = objective;

    try {
      const ok = await updateObjectiveStatus({
        variables: {
          id,
          status: newStatus,
        },
      });

      if (ok.data.updateObjectiveStatus) {
        snack(`Marked "${value}" as ${newStatus}`);
      }
    } catch (err) {
      snack("Failed to change objective status", "error");
    }
  };

  const handleEditDialog =
    (open, objective = {}) =>
    () => {
      setDialog({
        ...dialog,
        addObjectiveDialog: { open, objective, category, oneYearCorpPlan: planId, variables },
      });
    };

  const filteredObjectives = useMemo(() => {
    return objectives.filter(filterObjectives);
  }, [objectives, searchTerm]);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (currentQuarter) {
      setRockProgressQuarter(currentQuarter - 1);
    }
  }, [currentQuarter]);

  useEffect(() => {
    if (filteredObjectives) {
      // Need to clone because the objectives > rocks will be modified which will otherwise modify the objectives prop (i.e. anti-pattern)
      const filteredObjsClone = _.cloneDeep(filteredObjectives);
      const objsBySpid = _.groupBy(filteredObjsClone, "plan.sharedPlanId");

      const groupedSortedObjs = plansOrder
        .filter((spid) => Object.keys(objsBySpid).includes(spid))
        .reduce((sortedGroupedObjs, spid) => {
          const sortedObjs = _.sortBy(objsBySpid[spid], ["number"]);

          if (category === "1 year") {
            sortedObjs.forEach((obj) => {
              _.set(obj, "rocks", processQuarterRocks(obj));
            });
          }

          sortedGroupedObjs.push(sortedObjs);
          return sortedGroupedObjs;
        }, []);
      setProcessedObjs(groupedSortedObjs);
    }
  }, [filteredObjectives, stringifiedPlansOrder]);

  // TODO: Might be better off having the rocks for objectives already configured to be a 2D list instead of processing it as such with each render
  function processQuarterRocks(objective) {
    const rocksByIndex = _.groupBy(objective.rocks, "index");
    return [...Array(4).keys()].reduce((quarterArrs, quarterIdx) => {
      quarterArrs.push(_.sortBy(_.get(rocksByIndex, [quarterIdx + 1], []), "number"));
      return quarterArrs;
    }, []);
  }

  function filterObjectives(value) {
    // if it the case that there is no entered search-term
    if (searchTerm === "") {
      return value;
    }

    // if the execution goes past this point, this means either the user entered a search-term
    let userNames = value.users ? value.users.map((user) => `${_.get(user, "name.first", "")} ${_.get(user, "name.last", "")}`) : [];
    let rocks = value.rocks ? value.rocks.map((rock) => rock.value) : [];

    let description = _.get(value, "description");
    description = _.isNull(description) ? "" : description;

    let hasSearchTermMatch =
      _.get(value, "value", "").toLowerCase().includes(searchTerm.toLowerCase()) ||
      _.get(value, "status", "").toLowerCase().includes(searchTerm.toLowerCase()) ||
      description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      userNames.find((user) => user.toLowerCase().includes(searchTerm.toLowerCase())) ||
      rocks.find((rock) => rock.toLowerCase().includes(searchTerm.toLowerCase()));

    if (hasSearchTermMatch) {
      return value;
    }
  }

  const handleOpenDialog = () => {
    setDialog({
      ...dialog,
      addObjectiveDialog: { open: true, category, oneYearCorpPlan: planId, variables },
    });
  };

  const handleOpenAddRockDialog = (id, quarter) => () => {
    setDialog({
      ...dialog,
      addRockDialog: { open: true, quarter, objective: id, planId: _.get(corpForSelectedYear, "id") },
    });
  };

  const handleAddIssueDialog =
    (referenceId = null, referenceModel = null, value = null, user = null) =>
    () => {
      setDialog({
        ...dialog,
        addTodoDialog: {
          open: true,
          category: "issue",
          referenceId,
          referenceModel,
          value,
          user,
          planId: _.get(corpForSelectedYear, "id"),
        },
      });
    };

  // const handleShowAllRocks = () => {
  //   sessionStorage.setItem(`oneYearObjs.showAllRocks`, JSON.stringify(!showAllRocks));
  //   setShowAllRocks((prev) => !prev);
  // };

  // const handleShowMyRocks = () => {
  //   sessionStorage.setItem(`oneYearObjs.showMyRocks`, JSON.stringify(!showMyRocks));
  //   setShowMyRocks((prev) => !prev);
  // };

  // const handleShowAllQuarters = () => {
  //   setShowAllQuarters((prev) => !prev);
  // };

  const handleFilter = ({ val, sessionStorageKey, setFunction } = {}) => {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(val));
    setFunction(val);
  };

  const handleBeforeDragStart = ({ draggableId }) => {
    // locks the table cells' widths when a drag is happening via an inline style
    let tableCells = document.querySelectorAll(`#objectivesTable td`);
    for (let i = 0; i < tableCells.length; i++) {
      tableCells[i].style.width = `${tableCells[i].offsetWidth}px`;
    }
  };

  const handleDragEnd = async ({ draggableId, destination, source }) => {
    // removes the inline style added to lock the table cells' widths upon dragging
    let tableCells = document.querySelectorAll(`#objectivesTable td`);
    for (let i = 0; i < tableCells.length; i++) {
      tableCells[i].removeAttribute("style");
    }

    if (!destination || _.isEqual(source, destination)) return;

    const [dragType, dragId] = draggableId.split("_");
    const [dropType, dropId] = destination.droppableId.split("_");

    if (dragType === dropType) {
      const origObjs = _.cloneDeep(processedObjs);
      let successfulRequest = false;

      if (dragType === "OBJECTIVE") {
        const objGroupIdx = draggableId.split("_")[2];

        const reorderedObjs = _.cloneDeep(processedObjs);
        const objsGroup = _.get(reorderedObjs, [objGroupIdx], []);
        const mappedObjNumber = _.get(objsGroup, [destination.index, "number"], 1);

        const [removed] = objsGroup.splice(source.index, 1);
        objsGroup.splice(destination.index, 0, removed);

        setProcessedObjs(reorderedObjs);

        try {
          const ok = await updateObjectiveNumber({ variables: { id: dragId, number: mappedObjNumber } });
          if (ok.data.updateObjectiveNumber) {
            successfulRequest = true;
            snack(`Moved objective to number ${mappedObjNumber}`);
          }
        } catch (err) {
          setProcessedObjs(origObjs);
          snack("Failed to reorder objective", "error");
        }
      } else if (["ROCK", "SC"].includes(dragType)) {
        // indicates the negative index location of the destination quarter from the destination path after splitting into an array
        const destDropQuarterIdxHelper = {
          ROCK: -1,
          SC: -3,
        };

        const srcDropPath = source.droppableId.split("_")[2];
        const destDropPath = destination.droppableId.split("_")[2];

        const origObjs = _.cloneDeep(processedObjs);
        const reorderObjs = _.cloneDeep(processedObjs);

        const destDropPathArr = destDropPath.split(".");
        const destDropQuarter = parseInt(destDropPathArr[destDropPathArr.length + destDropQuarterIdxHelper[dragType]]) + 1; // + 1 because it is the quarter index

        const srcArr = _.get(reorderObjs, srcDropPath.split("."), []);
        const destArr = _.get(reorderObjs, destDropPathArr, []);

        // only utilized by rock mutation at the moment
        const mappedDestItemNum = dragType === "ROCK" ? _.get(destArr, [destination.index, "number"], destArr.length + 1) : undefined;

        let srcItem,
          isCopy = false;
        if (rockScStrategy === "move" || srcDropPath === destDropPath) {
          [srcItem] = srcArr.splice(source.index, 1);
        } else {
          isCopy = true;
          srcItem = _.cloneDeep(srcArr[source.index]);
          _.set(srcItem, "id", v4()); // temporary id that will be shortly replaced by the actual provided by mongo (safeguard to prevent identical draggable ids)
        }
        destArr.splice(destination.index, 0, srcItem);
        setProcessedObjs(reorderObjs);

        if (dragType === "ROCK") {
          try {
            const res = await updateRockQuarter({
              variables: { id: dragId, index: parseInt(destDropQuarter), objective: dropId, number: mappedDestItemNum, copy: isCopy },
            });

            if (res.data.updateRockLocation) {
              snack(
                srcDropPath === destDropPath
                  ? "Reordered rocks within quarter"
                  : `${isCopy ? "Copied" : "Moved"} rock to quarter ${destDropQuarter}`
              );
              successfulRequest = true;
            }
          } catch (err) {
            setProcessedObjs(origObjs);
            snack(
              srcDropPath === destDropPath
                ? "Failed to reorder rocks within quarter"
                : `Failed to ${isCopy ? "copy" : "move"} rock to quarter`,
              "error"
            );
          }
        } else if (dragType === "SC") {
          try {
            const ok = await updateSuccessCriteria({
              variables: { id: dragId, rock: dropId, rockIndex: destination.index, copy: isCopy },
            });
            if (ok.data.updateSuccessCriteria) {
              snack(
                srcDropPath === destDropPath
                  ? "Reordered success criteria"
                  : `${isCopy ? "Copied" : "Moved"} success criteria to quarter ${destDropQuarter} rock`
              );
              successfulRequest = true;
            }
          } catch (err) {
            setProcessedObjs(origObjs);
            snack(
              srcDropPath === destDropPath
                ? "Failed to reorder success criteria"
                : `Failed to ${isCopy ? "copy" : "move"} success criteria to quarter ${destDropQuarter} rock`,
              "error"
            );
          }
        }
      }

      if (successfulRequest) {
        requestFetch();
      }
    }
  };

  // // NOT USED AT THE MOMENT
  // const getButtons = () => {
  //   if (category === "3 year" && fs) {
  //     const curr = `${year(fiscalYearStart(fiscalYear))} - ${year(fiscalYear, 2)}`;
  //     return (
  //       <Menu button={curr}>
  //         <MenuItem disabled>Other years will go here</MenuItem>
  //       </Menu>
  //     );
  //   }
  // };

  const handleToggleQuarter = (increment) => {
    const newQuarterNumber = rockProgressQuarter + increment;

    if (newQuarterNumber >= 0 && newQuarterNumber < 4) {
      setRockProgressQuarter(newQuarterNumber);
    }
  };

  // need to set min-widths to properly align the nested tables
  const getColGroup = () => {
    if (category === "1 year")
      return (
        <colgroup>
          <col style={{ width: "5%", minWidth: 75 }} />
          <col style={{ width: "10%", minWidth: 140 }} />
          <col style={{ width: "45%", minWidth: 400 }} />
          <col style={{ width: "10%", minWidth: 130 }} />
          <col style={{ width: "15%", minWidth: 250 }} />
          <col style={{ width: "10%", minWidth: 155 }} />
          <col style={{ width: "5%", minWidth: 75 }} />
        </colgroup>
      );
    else
      return (
        <colgroup>
          <col style={{ width: "5%", minWidth: 75 }} />
          <col style={{ width: "10%", minWidth: 140 }} />
          <col style={{ width: "40%", minWidth: 400 }} />
          <col style={{ width: "10%", minWidth: 130 }} />
          <col style={{ width: "10%", minWidth: 130 }} />
          <col style={{ width: "15%", minWidth: 250 }} />
          <col style={{ width: "10%", minWidth: 155 }} />
        </colgroup>
      );
  };

  const isAllDepartments = _.isNil(departmentFilter.id);
  const closedYear = _.get(corpForSelectedYear, "closed");
  // const locked = _.get(corpForSelectedYear, "locked", false);
  const selectedYear = _.get(corpForSelectedYear, "year");
  const isOneYear = category === "1 year";

  return (
    <>
      <Card className={styles.card}>
        <CardTitle vertical color="orange">
          <Icon path={mdiChartTimelineVariant} size={1} color="#fff" className={styles.icon} />
          <Typography variant="h5" className={styles.title}>
            {category} Objectives
          </Typography>
        </CardTitle>

        <CardActions className={styles.cardActions}>
          {/* {closedYear && selectedYear && (
          <Typography variant="h5" style={{ marginRight: "auto", paddingLeft: 10 }}>
            Closed Year({year(selectedYear)})
          </Typography>
        )} */}
          {/* {isAllDepartments && (
            <span className={styles.dndInfo}>{"(Drag and drop only available when filtering by specific department)"}</span>
          )} */}
          {renderMobileMenu(
            <>
              <TextField
                className={styles.searchField}
                label="Search"
                type="search"
                variant="outlined"
                size="small"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
              />
              {/* {category !== "1 year" && <div>{getButtons()}</div>} */}
              {category === "1 year" ? (
                <>
                  <FormControl variant="outlined" size="small" className={styles.filterFormControl}>
                    <InputLabel>Description</InputLabel>
                    <Select
                      value={showDescriptions}
                      label="Description"
                      onChange={(e) =>
                        handleFilter({
                          val: e.target.value,
                          sessionStorageKey: `${categoryStr}Objs.showDescriptions`,
                          setFunction: setShowDescriptions,
                        })
                      }
                      className={styles.filterSelect}
                    >
                      {["show", "hide"].map((str, idx) => {
                        let val = str === "show";
                        return (
                          <MenuItem value={val} key={idx}>
                            {_.startCase(str)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" size="small" className={styles.filterFormControl}>
                    <InputLabel>Quarters</InputLabel>
                    <Select
                      value={showAllQuarters}
                      label="Quarters"
                      onChange={(e) =>
                        handleFilter({
                          val: e.target.value,
                          sessionStorageKey: `${categoryStr}Objs.showAllQuarters`,
                          setFunction: setShowAllQuarters,
                        })
                      }
                      className={styles.filterSelect}
                    >
                      {["all", "single"].map((str, idx) => {
                        let val = str === "all";
                        return (
                          <MenuItem value={val} key={idx}>
                            {_.startCase(str)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" size="small" className={styles.filterFormControl}>
                    <InputLabel>Rocks</InputLabel>
                    <Select
                      value={rocksFilter}
                      label="Rocks"
                      onChange={(e) =>
                        handleFilter({
                          val: e.target.value,
                          sessionStorageKey: `${categoryStr}Objs.rocksFilter`,
                          setFunction: setRocksFilter,
                        })
                      }
                      className={styles.filterSelect}
                    >
                      {["all", "mine", "hide"].map((val, idx) => {
                        return (
                          <MenuItem value={val} key={idx}>
                            {_.startCase(val)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" size="small" className={styles.filterFormControl}>
                    <InputLabel>Interaction</InputLabel>
                    <Select
                      value={rockScStrategy}
                      label="Interaction"
                      onChange={(e) =>
                        handleFilter({
                          val: e.target.value,
                          sessionStorageKey: `${categoryStr}Objs.rockScStrategy`,
                          setFunction: setRockScStrategy,
                        })
                      }
                      className={styles.filterSelect}
                    >
                      {["move", "copy"].map((val, idx) => {
                        return (
                          <MenuItem value={val} key={idx}>
                            {_.startCase(val)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </>
              ) : (
                <>
                  <FormControl variant="outlined" size="small" className={styles.filterFormControl}>
                    <InputLabel>Description</InputLabel>
                    <Select
                      value={showDescriptions}
                      label="Description"
                      onChange={(e) =>
                        handleFilter({
                          val: e.target.value,
                          sessionStorageKey: `${categoryStr}Objs.showDescriptions`,
                          setFunction: setShowDescriptions,
                        })
                      }
                      className={styles.filterSelect}
                    >
                      {["show", "hide"].map((str, idx) => {
                        let val = str === "show";
                        return (
                          <MenuItem value={val} key={idx}>
                            {_.startCase(str)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" size="small" className={styles.filterFormControl}>
                    <InputLabel>Years</InputLabel>
                    <Select
                      value={threeYearTieIn}
                      label="Years"
                      onChange={(e) =>
                        handleFilter({
                          val: e.target.value,
                          sessionStorageKey: `${categoryStr}Objs.threeYearTieIn`,
                          setFunction: setThreeYearTieIn,
                        })
                      }
                      className={styles.filterSelect}
                    >
                      {["all", "current"].map((val, idx) => {
                        return (
                          <MenuItem value={val} key={idx}>
                            {_.startCase(val)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" size="small" className={styles.filterFormControl}>
                    <InputLabel>Quarters</InputLabel>
                    <Select
                      value={showAllQuarters}
                      label="Quarters"
                      onChange={(e) =>
                        handleFilter({
                          val: e.target.value,
                          sessionStorageKey: `${categoryStr}Objs.showAllQuarters`,
                          setFunction: setShowAllQuarters,
                        })
                      }
                      className={styles.filterSelect}
                    >
                      {["all", "single"].map((str, idx) => {
                        let val = str === "all";
                        return (
                          <MenuItem value={val} key={idx}>
                            {_.startCase(str)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </>
              )}
            </>
          )}
          {isMobile ? (
            <IconButton
              onClick={handleOpenDialog}
              className={styles.menuButtonColor}
              color="primary"
              disabled={!isAuthed(auth, "department facilitator")}
            >
              <Icon path={mdiPlus} size={0.75} color="#fff" />
            </IconButton>
          ) : (
            <Button
              startIcon={<Icon path={mdiPlus} size={1} color="#fff" />}
              className={styles.menuButtonColor}
              onClick={handleOpenDialog}
              variant="contained"
              color="primary"
              disabled={!isAuthed(auth, "department facilitator")}
            >
              New Objective
            </Button>
          )}
        </CardActions>

        <CardContent className={styles.cardContent}>
          {!_.isEmpty(objectives) ? (
            <Table size="small" className={styles.table} id="objectivesTable">
              {getColGroup()}
              <TableHead>
                <TableRow>
                  {category === "1 year" ? (
                    <>
                      <TableCell />
                      <TableCell align="center">Accountable</TableCell>
                      <TableCell />
                      <TableCell align="center">3 Year Tie In</TableCell>
                      <TableCell align="center">Rock Progress{showAllQuarters ? "" : ` (Q${rockProgressQuarter + 1})`}</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell className={styles.lastCell} />
                    </>
                  ) : (
                    <>
                      <TableCell />
                      <TableCell align="center">Accountable</TableCell>
                      <TableCell />
                      {/* <TableCell align="center">      // unsure what this was for?
                              {_.isNil(planId) ? (category === "my" ? "Objectives" : "3 Year") : "1 Year"} Tie In
                            </TableCell> */}
                      <TableCell align="center">1 Year Objectives</TableCell>
                      <TableCell align="center">3 Year Metrics</TableCell>
                      <TableCell align="center">Rock Progress{showAllQuarters ? "" : ` (Q${rockProgressQuarter + 1})`}</TableCell>
                      <TableCell align="center">Status</TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <DragDropContext onDragEnd={handleDragEnd} onBeforeDragStart={handleBeforeDragStart}>
                {processedObjs.map((objsArr, outerIdx) => {
                  const spid = _.get(objsArr, ["0", "plan", "sharedPlanId"]);
                  return (
                    <Droppable key={spid} droppableId={`OBJECTIVE_${spid}`} type={`objective_${spid}`}>
                      {(provided, snapshot) => (
                        <TableBody key={outerIdx} ref={provided.innerRef} {...provided.droppableProps}>
                          {objsArr.map((objective, innerIdx) => {
                            return (
                              <Draggable
                                key={objective.id}
                                draggableId={`OBJECTIVE_${objective.id}_${outerIdx}`}
                                index={innerIdx}
                                isDragDisabled={!isAuthed(auth, "department facilitator") || locked}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <TableRow
                                      {...provided.draggableProps}
                                      ref={provided.innerRef}
                                      style={{ ...provided.draggableProps.style }}
                                      className={styles.tableRow}
                                    >
                                      <TableCell colSpan="100%" className={styles.noPadding}>
                                        <Table className={styles.table} style={{ width: "100%" }} size="small">
                                          {getColGroup()}
                                          <TableBody>
                                            {isOneYear ? (
                                              <OneYearObjective
                                                key={objective.id}
                                                fiscalYear={fiscalYear}
                                                currentQuarter={currentQuarter}
                                                objective={objective}
                                                handleOpenDialog={handleOpenAddRockDialog}
                                                handleAddIssueDialog={handleAddIssueDialog}
                                                category={category}
                                                showAllRocks={
                                                  rocksFilter === "all" || rocksFilter === "mine" || rocksFilter === "department"
                                                }
                                                showMyRocks={rocksFilter === "mine"}
                                                showDepartmentRocks={rocksFilter === "department"}
                                                canEdit={isAuthed(auth, "department facilitator") && !locked}
                                                objPath={`${outerIdx}.${innerIdx}`}
                                                rockScStrategy={rockScStrategy}
                                                plansOrder={plansOrder}
                                                closedYear={closedYear}
                                                rockProgressQuarter={rockProgressQuarter}
                                                showAllQuarters={showAllQuarters}
                                                handleToggleQuarter={handleToggleQuarter}
                                                showDescription={showDescriptions}
                                                corpForSelectedYear={corpForSelectedYear}
                                                handleEditDialog={handleEditDialog}
                                                handleUpdateStatus={handleUpdateStatus}
                                                handleConfirmOpen={handleConfirmOpen}
                                                provided={provided}
                                                nextYearPlanCreated={nextYearPlanCreated}
                                                isFuturePlan={isFuturePlan}
                                                isCurrentPlan={isCurrentPlan}
                                              />
                                            ) : (
                                              <Objective
                                                objective={objective}
                                                fiscalYear={fiscalYear}
                                                corpPlans={corpPlans}
                                                category={category}
                                                provided={provided}
                                                canEdit={isAuthed(auth, "department facilitator") && !locked}
                                                handleAddIssueDialog={handleAddIssueDialog}
                                                threeYearTieIn={threeYearTieIn}
                                                objPath={`${outerIdx}.${innerIdx}`}
                                                plansOrder={plansOrder}
                                                rockProgressQuarter={rockProgressQuarter}
                                                showAllQuarters={showAllQuarters}
                                                handleToggleQuarter={handleToggleQuarter}
                                                showDescription={showDescriptions}
                                                corpForSelectedYear={corpForSelectedYear}
                                                handleEditDialog={handleEditDialog}
                                                handleUpdateStatus={handleUpdateStatus}
                                                handleConfirmOpen={handleConfirmOpen}
                                              />
                                            )}
                                          </TableBody>
                                        </Table>
                                      </TableCell>
                                    </TableRow>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </TableBody>
                      )}
                    </Droppable>
                  );
                })}
              </DragDropContext>
              <TableFooter className={styles.footer}>
                <RockProgressTotals
                  objectives={filteredObjectives}
                  fiscalYear={fiscalYear}
                  category={category}
                  threeYearTieIn={threeYearTieIn}
                  quarterNumber={rockProgressQuarter}
                  showAllQuarters={showAllQuarters}
                  handleToggleQuarter={handleToggleQuarter}
                />
              </TableFooter>
              {/* <Backdrop open={getLoading(["objectives"])} className={styles.backdrop}>
                    <div className={styles.loadingContainer}>
                      <Loading />
                    </div>
                  </Backdrop> */}
            </Table>
          ) : (
            <Typography variant="body1" align="center">
              Nothing to show
              <br />
              {isAuthed(auth, "department facilitator") && (
                <Button
                  color="primary"
                  onClick={handleOpenDialog}
                  style={{ fontStyle: "italic" }}
                  disabled={!isAuthed(auth, "department facilitator") || closedYear}
                >
                  Add an objective
                </Button>
              )}
            </Typography>
          )}
          <RockTable organization={params.org} fiscalYear={fiscalYear} planId={planId} />
        </CardContent>
      </Card>
      {/* {!_.isEmpty(selectedObjective) && (
        <EditDialog
          open={editDialog}
          handleClose={handleEditDialog(false)}
          objective={selectedObjective}
          category={category}
          snack={snack}
          corpForSelectedYear={corpForSelectedYear}
          planId={planId}
        />
      )} */}
      <ConfirmDeletionDialog
        itemType={"objective"}
        value={_.get(objectiveToDelete, "value", "")}
        confirmOpen={!_.isEmpty(objectiveToDelete)}
        handleConfirmOpen={handleConfirmOpen}
        handleDeletion={handleDeleteObjective}
        deleteLoading={deleteLoading}
      />
    </>
  );
};

export default Objectives;

const GET_CORP_PLANS = gql`
  query Objectives_GetCorpPlans($organization: ID!) {
    plans(organization: $organization, departmentName: "Corporate", category: "1 year") {
      id
      year
      closed
    }
  }
`;

const UPDATE_ROCK_QUARTER = gql`
  mutation ($id: ID!, $index: Int!, $objective: ID!, $number: Int!, $copy: Boolean!) {
    updateRockLocation(id: $id, index: $index, objective: $objective, number: $number, copy: $copy)
  }
`;

const UPDATE_OBJECTIVE_NUMBER = gql`
  mutation ($id: ID!, $number: Int!) {
    updateObjectiveNumber(id: $id, number: $number) {
      objective {
        id
        number
      }
      objectives {
        id
        number
      }
    }
  }
`;

const UPDATE_OBJECTIVE_STATUS = gql`
  ${OBJECTIVE_FIELDS}
  mutation ($id: ID!, $status: String!) {
    updateObjectiveStatus(id: $id, status: $status) {
      objective {
        ...ObjectiveFields
      }
    }
  }
`;

const UPDATE_SUCCESS_CRITERIA = gql`
  mutation ($id: ID!, $rock: ID, $rockIndex: Int, $copy: Boolean) {
    updateSuccessCriteria(id: $id, rock: $rock, rockIndex: $rockIndex, copy: $copy)
  }
`;

const DELETE_OBJECTIVE = gql`
  mutation ($id: ID!) {
    deleteObjective(id: $id) {
      objective {
        id
      }
      plan {
        id
        objectives
      }
      objectives {
        id
        objectives
      }
      otherObjectives {
        id
        number
      }
      metrics {
        id
        objectives
      }
      rocks {
        id
      }
      successCriterias {
        id
      }
    }
  }
`;
