import React, { useState } from "react";
import _ from "lodash";
import styles from "./Signin.module.scss";
import useForm from "../../hooks/useForm";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { Typography, TextField, Button } from "@material-ui/core";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import Version from "../../components/Version/Version";

import { Link } from "react-router-dom";
import Splash from "../../components/Splash/Splash";

const SIGNIN_USER = gql`
  mutation Signin($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      accessToken
    }
  }
`;

const initForm = { email: null, password: null };

const Signin = ({ authenticate }) => {
  const [forgotPasswordDialog, setForgotPasswordDialog] = useState(false);
  const [error, setError] = useState();

  const [signIn, { loading }] = useMutation(SIGNIN_USER, {
    onCompleted: (data) => {
      const accessToken = _.get(data, "signin.accessToken");
      authenticate(accessToken);
    },
    onError: (err) => {
      setError(err); // need onError to catch Apollo Client errors or else crash occurs
    },
  });

  const { form, handleChange } = useForm({ initForm });

  const handleForgotPasswordDialog = (open) => () => {
    setForgotPasswordDialog(open);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = form;
    signIn({
      variables: {
        email,
        password,
      },
    });
  };

  return (
    <>
      <Splash>
        <Typography variant="h2" gutterBottom>
          Sign In
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoFocus
            value={form.email || ""}
            onChange={handleChange}
          />
          <TextField
            type="password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            value={form.password || ""}
            onChange={handleChange}
          />
          {error && (
            <Typography variant="body1" color="error" paragraph>
              {error.message}
            </Typography>
          )}
          <Button type="submit" margin="normal" fullWidth variant="contained" color="primary" disabled={loading}>
            {loading ? "Signing In..." : "Sign In"}
          </Button>
          <Button color="primary" margin="normal" onClick={handleForgotPasswordDialog(true)} disabled={loading}>
            Forgot password?
          </Button>
        </form>
        <Typography>
          New User? <Link to="/signup">Sign up here</Link>
        </Typography>
        <Version className={styles.version} />
        <div className={styles.linkContainer}>
          <Link to="/pricing" target="_blank" rel="noopener noreferrer" className={styles.link}>
            PRICING
          </Link>
          <Link to="/privacy" target="_blank" rel="noopener noreferrer" className={styles.link}>
            PRIVACY
          </Link>
          <Link to="/security" target="_blank" rel="noopener noreferrer" className={styles.link}>
            SECURITY
          </Link>
          <Link to="/terms" target="_blank" rel="noopener noreferrer" className={styles.link}>
            TERMS
          </Link>
        </div>
      </Splash>
      <ForgotPasswordDialog open={forgotPasswordDialog} handleClose={handleForgotPasswordDialog(false)} />
    </>
  );
};

export default Signin;
