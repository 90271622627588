import React, { useEffect, useContext, useState } from "react";
import styles from "./AddEditMetricDialog.module.scss";
import _, { valuesIn } from "lodash";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import useForm from "../../hooks/useForm";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { METRIC_FIELDS } from "../../utils/fragments";
import { GET_METRICS, GET_ONE_YEAR_METRICS, GET_ONE_YEAR_PLAN, GET_THREE_YEAR_METRICS, GET_WEEKLY_TARGETS } from "../../utils/query";
import { getYear } from "date-fns";
import { year } from "../../utils/dates";
import { useAuth } from "../../context/authContext";
import { isAuthed } from "../../utils/authorization";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
import { calcQuarterMetricTotal, recursiveMergeCustomizer, removeFormattedNum } from "../../utils/misc";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  useMediaQuery,
  Chip,
  FormControlLabel,
  Switch,
  Typography,
  FormControl,
  FormLabel,
  FormHelperText,
  RadioGroup,
  Radio,
  Select,
  InputLabel,
  MenuItem,
  Collapse,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HelpIcon from "@material-ui/icons/HelpOutlineRounded";

import Icon from "@mdi/react";
import { mdiClose, mdiLinkVariant, mdiRefresh } from "@mdi/js";
import { blueGrey, deepPurple, red } from "@material-ui/core/colors";

import Loading from "../Loading/Loading";
import VariableDialog from "../VariableDialog/VariableDialog";
import SyntaxDialog from "../VariableDialog/SyntaxDialog";
import OneYearMetricsList from "../Metrics/OneYearMetricsList";
import Distribution from "../Metrics/Distribution";
import ThreeYearMetricsList from "../Metrics/ThreeYearMetricsList";
import WeeklyTargetsList from "../Metrics/WeeklyTargetsList";
import NumberFormatCustom from "../NumberFormat/NumberFormat";
import NewSelectDepartment from "../SelectDepartment/NewSelectDepartment";
import NewSelectObjectives from "../SelectObjectives/NewSelectObjectives";
import NewSelectUsers from "../SelectUsers/NewSelectUsers";

// const initForm = {
//   value: null,
//   users: [],
//   cumulative: false,
//   calculateTotal: true,
//   year: [
//     [{ value: "Y1" }, { value: "" }, { value: "" }],
//     [{ value: "Y2" }, { value: "" }, { value: "" }],
//     [{ value: "Y3" }, { value: "" }, { value: "" }],
//   ],
//   quarter: [
//     [{ value: "Q1" }, { value: "" }, { value: "" }],
//     [{ value: "Q2" }, { value: "" }, { value: "" }],
//     [{ value: "Q3" }, { value: "" }, { value: "" }],
//     [{ value: "Q4" }, { value: "" }, { value: "" }],
//     [{ value: "Total" }, { value: "" }, { value: "" }],
//   ],
//   unit: null,
//   plan: null,
//   comparator: "gte",
//   comparisonInfo: {
//     target: "",
//     targetMin: "",
//     targetMax: "",
//     comparand: "",
//   },
//   enableFormula: false,
//   formula: null,
//   formulaScope: [],
//   objectives: [],
//   weeklyTarget: null,
// };

const AddEditMetricDialog = ({ dialog, setDialog, requestFetch, fetch, params, snack }) => {
  const fs = useMediaQuery("(max-width: 600px)");

  const { departmentFilter } = useDepartmentFilter();
  const deptFilterSpid = _.get(departmentFilter, "sharedPlanId");

  const metricDialog = _.get(dialog, "addMetricDialog", {});
  const {
    open,
    metric,
    category,
    yearOne,
    planId: corpPlan, // this will be 3 year corp plan if creating a 3 year metric
    variables,
  } = metricDialog;

  const isCreate = _.isNil(metric);

  const yearOneInt = parseInt(yearOne);
  const YEARS = { Y1: getYear(yearOneInt), Y2: getYear(yearOneInt) + 1, Y3: getYear(yearOneInt) + 2 };

  const [close, setClose] = useState(true);

  const [oneYearMetrics, setOneYearMetrics] = useState([]);
  const [oneYearMetricsListOpen, setOneYearMetricsListOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMeasurableIdx, setSelectedMeasurableIdx] = useState();
  const [distributionOpen, setDistributionOpen] = useState(false);

  const [threeYearMetrics, setThreeYearMetrics] = useState([]);
  const [threeYearMetricsListOpen, setThreeYearMetricsListOpen] = useState(false);

  const [weeklyTargets, setWeeklyTargets] = useState([]);
  const [weeklyTargetsListOpen, setWeeklyTargetsListOpen] = useState(false);

  const [variableDialogOpen, setVariableDialogOpen] = useState(false);
  const [formulaScopeIdx, setFormulaScopeIdx] = useState(null);
  const [syntaxDialogOpen, setSyntaxDialogOpen] = useState(false);

  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery(GET_DATA, {
    variables: {
      organization: params.org,
      planCategory: { year: "3 year", quarter: "1 year" }[category],
      category,
      sharedPlanId: deptFilterSpid,
      oneYearCorpPlan: corpPlan,
      closed: _.isNil(corpPlan) ? false : null,
    },
  });

  const [getOneYearMetrics, { data: oneYearMetricsData }] = useLazyQuery(GET_ONE_YEAR_METRICS);
  const [getOneYearPlan, { data: oneYearPlanData, refetch: oneYearPlansRefetch }] = useLazyQuery(GET_ONE_YEAR_PLAN);
  const [getThreeYearMetrics, { data: threeYearMetricsData }] = useLazyQuery(GET_THREE_YEAR_METRICS);
  const [getWeeklyTargets, { data: weeklyTargetsData }] = useLazyQuery(GET_WEEKLY_TARGETS);

  const [updateMetricMeasurables] = useMutation(UPDATE_METRIC_MEASURABLES);
  const [updateMetric] = useMutation(UPDATE_METRIC);
  const [createMetric, { loading: mutationLoading }] = useMutation(CREATE_METRIC, {
    update(cache, { data: { createMetric } }) {
      try {
        if (_.isNil(variables)) {
          throw new Error("variable cannot be null or undefined");
        }
        const { metric, oneYearMetric } = createMetric;
        let variablesArr1 = [variables];
        if (_.get(metric, "category") === "quarter") {
          // update the metric list
          const existingData = cache.readQuery({
            query: GET_ONE_YEAR_METRICS,
            variables: {
              organization: params.org,
              sharedPlanId: deptFilterSpid,
            },
          });
          if (existingData) {
            cache.writeQuery({
              query: GET_ONE_YEAR_METRICS,
              variables: {
                organization: params.org,
                sharedPlanId: deptFilterSpid,
              },
              data: {
                metrics: [metric, ...existingData.metrics],
              },
            });
          }
        }
        if (!(_.get(metric, "category") === "quarter" && category === "year")) {
          const departmentSharedPlanId = variables.sharedPlanId;
          if (_.isNil(departmentSharedPlanId)) {
            variablesArr1.push({
              organization: variables.organization,
              planId: variables.planId,
              sharedPlanId: _.get(metric, "plan.sharedPlanId"),
            });
          } else {
            variablesArr1.push({ organization: variables.organization, planId: variables.planId, sharedPlanId: null });
          }
          for (const variables of variablesArr1) {
            const existingData = cache.readQuery({
              query: GET_METRICS,
              variables,
            });
            if (existingData) {
              cache.writeQuery({
                query: GET_METRICS,
                variables,
                data: {
                  metrics: [metric, ...existingData.metrics],
                },
              });
            }
          }

          if (oneYearMetric) {
            // find corpPlan variable
            const corpPlanId = _.get(oneYearMetric, "plan.plan.id")
              ? _.get(oneYearMetric, "plan.plan.id")
              : _.get(oneYearMetric, "plan.id");
            const departmentSharedPlanId = _.get(oneYearMetric, "plan.sharedPlanId");

            const variablesArr2 = [
              { organization: variables.organization, planId: corpPlanId, sharedPlanId: null }, // All department query
              { organization: variables.organization, planId: corpPlanId, sharedPlanId: departmentSharedPlanId }, // Specific department query
            ];

            for (const oneYearGetMetricVariable of variablesArr2) {
              const existingOneYearMetricData = cache.readQuery({
                query: GET_METRICS,
                variables: oneYearGetMetricVariable,
              });
              // only write to cache if the one year query exist
              if (existingOneYearMetricData) {
                cache.writeQuery({
                  query: GET_METRICS,
                  variables: oneYearGetMetricVariable,
                  data: {
                    metrics: [oneYearMetric, ...existingOneYearMetricData.metrics],
                  },
                });
              }
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
  });

  const shapeComparisonInfo = (comparator, comparisonInfo) => {
    let compInfo = null;
    if (["delta", "btw", "percent"].includes(comparator)) {
      compInfo =
        comparator === "btw" ? _.omit(comparisonInfo, ["target", "comparand"]) : _.omit(comparisonInfo, ["targetMin", "targetMax"]);
    }
    return compInfo;
  };

  const shapeMeasurables = (category, cumulative, measurables) => {
    let measurablesCopy = _.cloneDeep(measurables);
    if (category === "quarter" && cumulative) {
      measurablesCopy[4][1].value = measurablesCopy[3][1].value; //Year total is the same as the 4th quarter total
      measurablesCopy[4][2].value = "";
    }
    return measurablesCopy;
  };

  const schema = Yup.object().shape({
    value: Yup.string().required("Value is required"),
    plan: Yup.string().required("Department is required"),
    comparator: Yup.string().required("Comparator is required"),
    comparisonInfo: Yup.object({
      target: Yup.string()
        .nullable()
        .when("$comparator", ([comparator], schema) =>
          ["delta", "percent"].includes(comparator) ? schema.required(`${_.capitalize(comparator)} is required`) : schema
        ),
      comparand: Yup.string()
        .nullable()
        .when("$comparator", ([comparator], schema) =>
          ["delta", "percent"].includes(comparator) ? schema.required("Comparand is required") : schema
        ),
      targetMin: Yup.string()
        .nullable()
        .when("$comparator", ([comparator], schema) => (comparator === "btw" ? schema.required("Min value is required") : schema)),
      targetMax: Yup.string()
        .nullable()
        .when("$comparator", ([comparator], schema) => (comparator === "btw" ? schema.required("Max value is required") : schema)),
    }).nullable(),
    measurables: Yup.array().of(
      Yup.array().of(
        Yup.object({
          value: Yup.string(),
        })
      )
    ),
    enableFormula: Yup.boolean().nullable(),
    formula: Yup.string()
      .nullable()
      .when("enableFormula", {
        is: true,
        then: (schema) => schema.required("Formula is required"),
        otherwise: (schema) => schema,
      }),
    formulaScope: Yup.array().of(
      Yup.object({
        varType: Yup.string().required("Variable type is required"),
        value: Yup.string().required("Value type is required"),
      })
    ),
    objectives: Yup.array().of(Yup.string().required("Objective is required")),
    unit: Yup.string().nullable(),
    users: Yup.array().of(Yup.string().required("User is required")),
    cumulative: Yup.boolean(),
    calculateTotal: Yup.boolean(),
    weeklyTarget: Yup.string().nullable(),
    threeYearMetric: Yup.string().nullable(),
  });

  const getInitialValues = ({ metric, category, data, metricDialog }) => {
    const { users, unit, value } = metricDialog;
    const longestMetricMeasurable = _.get(data, "longestMetricMeasurable"); //for 3 year only

    let initVals = {
      value: value || "",
      unit: unit || null,
      users: users || [],
      plan: "",
      comparator: "gte",
      comparisonInfo: {
        target: "",
        targetMin: "",
        targetMax: "",
        comparand: "",
      },
      measurables:
        category === "year"
          ? [
              ...getExtraMeasurableYears(longestMetricMeasurable),
              [{ value: "Y1" }, { value: "" }, { value: "" }],
              [{ value: "Y2" }, { value: "" }, { value: "" }],
              [{ value: "Y3" }, { value: "" }, { value: "" }],
            ]
          : [
              [{ value: "Q1" }, { value: "" }, { value: "" }],
              [{ value: "Q2" }, { value: "" }, { value: "" }],
              [{ value: "Q3" }, { value: "" }, { value: "" }],
              [{ value: "Q4" }, { value: "" }, { value: "" }],
              [{ value: "Total" }, { value: "" }, { value: "" }],
            ],
      enableFormula: false,
      formula: null,
      formulaScope: [],
      objectives: [],
      cumulative: false,
      calculateTotal: true,
      weeklyTarget: null,
      threeYearMetric: null,
    };

    if (!_.isNil(metric)) {
      initVals = _.mergeWith({}, initVals, metric, (objVal, srcVal) => recursiveMergeCustomizer(srcVal));
    }

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues({ metric, category, data, metricDialog }),
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const {
        value,
        plan,
        comparator,
        comparisonInfo,
        measurables,
        enableFormula,
        formula,
        formulaScope,
        objectives,
        unit,
        users,
        cumulative,
        calculateTotal,
        weeklyTarget,
        threeYearMetric,
      } = values;

      const compInfoToSubmit = shapeComparisonInfo(comparator, comparisonInfo);
      const measurablesToSubmit = shapeMeasurables(category, cumulative, measurables);

      try {
        let res;
        if (isCreate) {
          res = await createMetric({
            variables: {
              organization: params.org,
              plan,
              objectives,
              category,
              value,
              users,
              comparator,
              comparisonInfo: compInfoToSubmit,
              measurables: measurablesToSubmit,
              unit,
              cumulative,
              calculateTotal,
              enableFormula,
              formula,
              formulaScope,
              weeklyTarget,
              threeYearMetric,
            },
          });
        } else {
          res = await updateMetric({
            variables: {
              id: metric.id,
              plan,
              objectives,
              value,
              users,
              comparator,
              comparisonInfo: compInfoToSubmit,
              measurables: measurablesToSubmit,
              unit,
              cumulative,
              calculateTotal,
              enableFormula,
              formula,
              formulaScope,
              weeklyTarget,
              threeYearMetric,
            },
          });
        }

        if (res) {
          snack(`${isCreate ? "Created" : "Updated"} "${value}" metric`);
          if (close) {
            handleClose();
          } else {
            resetForm();
          }
        }
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
    enableReinitialize: true,
  });

  const { values, errors, touched, handleSubmit: handleSubmitFormik, isSubmitting, getFieldProps, setFieldValue, resetForm } = formik;

  const handleSubmit = (close = true) => {
    setClose(close);
    handleSubmitFormik();
  };

  const updateTotal = (valIdxArr = []) => {
    const { calculateTotal, measurables } = values;

    if (category === "quarter" && calculateTotal) {
      for (const idx of valIdxArr) {
        const value = calcQuarterMetricTotal(measurables, idx);
        setFieldValue(`measurables.4.${idx}.value`, value.toString());
      }
    }
  };

  const handleComparator = (event, newComparator) => {
    // enforce value set
    if (newComparator !== null) {
      setFieldValue("comparator", newComparator);
    }
  };

  const handleChangeAuto = () => {
    setFieldValue("calculateTotal", !values.calculateTotal);
  };

  const handleClose = () => {
    setDialog({ ...dialog, addMetricDialog: { open: false } });
  };

  const handleOpenOneYearMetrics = (year, measurableIdx) => () => {
    setSelectedYear(year);
    setSelectedMeasurableIdx(measurableIdx);
    setOneYearMetricsListOpen(true);
  };

  const handleLinkOneYearMetric = (metricId) => {
    const measurableClone = _.cloneDeep(_.get(values, `measurables.${selectedMeasurableIdx}.2`));
    const newMeasurable = _.merge({}, measurableClone, { reference: metricId });

    setFieldValue(`measurables.${selectedMeasurableIdx}.2`, newMeasurable);
    setOneYearMetricsListOpen(false);
  };

  const handleOpenThreeYearMetricsList = () => {
    setThreeYearMetricsListOpen(true);
    const plans = _.get(data, "plans");
    const selectedPlan = _.find(plans, (plan) => plan.id === values.plan);
    if (selectedPlan) {
      const planYear = year(_.get(selectedPlan, "year"));
      setSelectedYear(planYear);
    }
  };

  const handleLinkThreeYearMetric = (metricId) => {
    setFieldValue("threeYearMetric", metricId);
    setThreeYearMetricsListOpen(false);
  };

  const handleOpenDistribution = (year, measurableIdx) => () => {
    setSelectedYear(year);
    setSelectedMeasurableIdx(measurableIdx);
    setDistributionOpen(true);
  };

  const handleChangeDistribution = (distribution) => {
    const measurableClone = _.cloneDeep(_.get(values, ["measurables", selectedMeasurableIdx.toString(), "1"]));
    const newMeasurable = _.merge({}, measurableClone, { distribution });

    setFieldValue(`measurables.${selectedMeasurableIdx}.1`, newMeasurable);
    setDistributionOpen(false);
  };

  const handleLinkWeeklyTarget = (wtId) => {
    setFieldValue("weeklyTarget", wtId);
    setWeeklyTargetsListOpen(false);
  };

  /*
  // TODO: Works but needs to be rethought after merging add and edit dialogs for metrics
  // (Creating a 3 year metric already creates a 1 year metric with similar field values so this seems redundant)
  const handleCreateQuarterMetric = async () => {
    const category = "quarter";
    const { value, users, comparator, comparisonInfo, measurables, unit, weeklyTarget } = getInitialValues({
      metricDialog: { value: values.value },
      category,
    });

    const compInfoToSubmit = shapeComparisonInfo(comparator, comparisonInfo);
    const measurablesToSubmit = shapeMeasurables(category, cumulative, measurables);

    try {
      const res = await createMetric({
        variables: {
          organization: params.org,
          plan: _.get(oneYearPlanData, "plan.id"),
          category,
          value,
          users,
          comparator,
          comparisonInfo: compInfoToSubmit,
          measurables: measurablesToSubmit,
          unit,
          weeklyTarget,
        },
      });

      if (res) {
        snack(`Created 1 year metric, "${value}"`);
      }
    } catch (err) {
      snack("Failed to create 1 year metric", "error");
    }
  };
  */

  /*
  const handleSubmit =
    ({ close, category }) =>
    async () => {
      if (!validateForm()) return;

      const {
        value,
        unit,
        users,
        plan,
        objectives,
        cumulative,
        calculateTotal,
        comparator,
        comparisonInfo,
        enableFormula,
        formula,
        formulaScope,
        weeklyTarget,
        threeYearMetric,
      } = form;

      let measurables = [];
      if (category === "year") measurables = form.year;
      else if (category === "quarter") {
        const { quarter } = form;
        if (cumulative) {
          quarter[4][1].value = quarter[3][1].value.toString(); //Year total is the same as the 4th quarter total
        }
        measurables = quarter;
      }

      measurables.forEach((m) => {
        m[1].value = removeFormattedNum(m[1].value);
      });

      let compInfo = null;
      if (["delta", "btw", "percent"].includes(comparator)) {
        compInfo =
          comparator === "btw" ? _.omit(comparisonInfo, ["target", "comparand"]) : _.omit(comparisonInfo, ["targetMin", "targetMax"]);
      }

      try {
        const res = await createMetric({
          variables: {
            organization: params.org,
            plan,
            objectives,
            category,
            value,
            users,
            comparator,
            comparisonInfo: compInfo,
            measurables,
            unit,
            cumulative,
            calculateTotal,
            enableFormula,
            formula,
            formulaScope,
            weeklyTarget,
            threeYearMetric,
          },
        });

        if (res) {
          snack(`Created "${value}" metric`);
          // requestFetch();
          resetForm({ ...initForm, plan });
          if (close) {
            handleClose();
          }
        }
      } catch (err) {
        snack("Failed to create metric", "error");
      }
    };
    */

  useEffect(() => {
    if (category === "year") {
      getOneYearMetrics({
        variables: {
          organization: params.org,
          sharedPlanId: deptFilterSpid,
        },
      });
    }
  }, [category]);

  useEffect(() => {
    if (oneYearMetricsData) {
      setOneYearMetrics(_.get(oneYearMetricsData, "metrics", []));
    }
  }, [oneYearMetricsData]);

  useEffect(() => {
    if (category === "quarter") {
      getThreeYearMetrics({
        variables: {
          organization: params.org,
          sharedPlanId: deptFilterSpid,
        },
      });
      getWeeklyTargets({
        variables: {
          organization: params.org,
          sharedPlanId: deptFilterSpid,
        },
      });
    }
  }, [category]);

  useEffect(() => {
    // Looks to be for when creating a new 1-year metric during 3-year metric create/edit
    if (category === "year") {
      const plans = _.get(data, "plans", []);
      const threeYearPlan = _.find(plans, { id: values.plan });
      getOneYearPlan({
        variables: {
          organization: params.org,
          sharedPlanId: _.get(threeYearPlan, "sharedPlanId"),
        },
      });
    }
  }, [category, data, values.plan]);

  useEffect(() => {
    if (threeYearMetricsData) {
      setThreeYearMetrics(_.get(threeYearMetricsData, "metrics", []));
    }
  }, [threeYearMetricsData]);

  useEffect(() => {
    if (weeklyTargetsData) {
      setWeeklyTargets(_.get(weeklyTargetsData, "weeklyTargets", []));
    }
  }, [weeklyTargetsData]);

  useEffect(() => {
    const handler = setTimeout(() => {
      // Code to run after the debounce delay
      if (category && values.calculateTotal) {
        updateTotal(isCreate ? [1] : [1, 2]);
      }
    }, 300); // 300ms delay

    // Cleanup function to clear the timer if dependencies change before the timeout
    return () => {
      clearTimeout(handler);
    };
  }, [category, isCreate, values.calculateTotal, values.measurables]);

  useEffect(() => {
    const plans = _.get(data, "plans", []);
    if (open && isCreate && deptFilterSpid) {
      const planWithSameSharedPlanId = _.find(plans, (plan) => plan.sharedPlanId === deptFilterSpid);
      setFieldValue("plan", _.get(planWithSameSharedPlanId, "id", null));
    }
  }, [data, open, isCreate, deptFilterSpid]);

  useEffect(() => {
    if (open) {
      refetch();
    } else {
      resetForm();
    }
  }, [fetch, open]);

  if (queryLoading) return null;

  const metrics = (_.get(data, "metrics") || []).filter((metric) => {
    // filter by corp plan selection
    if (corpPlan) {
      const metricCorpPlanId = _.get(metric, "plan.plan.id", _.get(metric, "plan.id"));
      return metricCorpPlanId === corpPlan;
    }
    return true;
  });
  const metricsById = _.keyBy(metrics, "id");

  const numOfvariables = _.get(values, "formulaScope.length");
  const plansOrder = _.get(data, "organization.plansOrder", []);

  const selectedWeeklyTarget = weeklyTargets.find((wt) => wt.id === values.weeklyTarget);
  const selectedThreeYearMetric = threeYearMetrics.find((m) => m.id === values.threeYearMetric);

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        fullWidth
        fullScreen={fs}
      >
        <DialogTitle>
          <div className={styles.title}>
            {isCreate ? "Create New" : "Edit"} {category === "year" ? "3" : "1"} Year Metric
            <div>
              <IconButton onClick={handleClose} size="small">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Metric Details"
            {...getFieldProps("value")}
            error={Boolean(touched.value && errors.value)}
            helperText={touched.value && errors.value}
          />
          <NewSelectDepartment
            name="plan"
            plans={_.get(data, "plans")}
            formik={formik}
            helperText="Which department does this metric belong to?"
            disabled={!_.isNil(deptFilterSpid)}
          />
          {category === "year" && (
            <NewSelectObjectives
              name="objectives"
              multiple={true}
              objectives={_.get(data, "objectives", null)}
              category={"1 year"}
              helperText="Which 3 year objectives are tied to this metric?"
              plansOrder={plansOrder}
              formik={formik}
            />
          )}
          {category === "quarter" && (
            <>
              <ToggleButtonGroup
                exclusive
                value={values.cumulative}
                onChange={() => setFieldValue("cumulative", !values.cumulative)}
                className={styles.flexCenter}
              >
                <ToggleButton value={false}>
                  <Tooltip title="Each entry is the total from that quarter">
                    <div>Add values</div>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value={true}>
                  <Tooltip title="Each entry includes the totals of previous quarters">
                    <div>Don't add values</div>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
              {!values.cumulative && (
                <Typography align="center">
                  <Button
                    variant={values.calculateTotal ? "contained" : "outlined"}
                    onClick={handleChangeAuto}
                    color="primary"
                    className={styles.auto}
                    disableElevation
                  >
                    Auto Totals
                  </Button>
                </Typography>
              )}
            </>
          )}
          <NewSelectUsers
            name="users"
            multiple={true}
            users={_.get(data, "users")}
            handleResetField={() => setFieldValue("users", [])}
            helperText="Who is accountable for this metric?"
            plan={values.plan}
            allPlans={_.get(data, "plans")}
            formik={formik}
          />
          <div className={styles.modelButtons}>
            <ToggleButtonGroup exclusive value={values.comparator} onChange={handleComparator}>
              <ToggleButton value="lt">
                <span className={styles.toggleText}>&#60;</span>
              </ToggleButton>
              <ToggleButton value="lte">
                <span className={styles.toggleText}>&#8804;</span>
              </ToggleButton>
              <ToggleButton value="eq">
                <span className={styles.toggleText}>=</span>
              </ToggleButton>
              <ToggleButton value="gte">
                <span className={styles.toggleText}>&#8805;</span>
              </ToggleButton>
              <ToggleButton value="gt">
                <span className={styles.toggleText}>&#62;</span>
              </ToggleButton>
              <ToggleButton value="btw">
                <span className={styles.toggleText}>{"a<x<b"}</span>
              </ToggleButton>
              <ToggleButton value="percent">
                <span className={styles.toggleText}>%</span>
              </ToggleButton>
              <ToggleButton value="delta">
                <span className={styles.toggleText}>&Delta;</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <Collapse timeout="auto" in={["btw", "percent", "delta"].includes(values.comparator)}>
            <div style={{ display: "flex", gap: 16 }}>
              {values.comparator === "btw" ? (
                <>
                  <TextField
                    label="Min"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    {...getFieldProps("comparisonInfo.targetMin")}
                    error={Boolean(_.get(touched, "comparisonInfo.targetMin") && _.get(errors, "comparisonInfo.targetMin"))}
                    helperText={_.get(touched, "comparisonInfo.targetMin") && _.get(errors, "comparisonInfo.targetMin")}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Max"
                    margin="normal"
                    variant="outlined"
                    {...getFieldProps("comparisonInfo.targetMax")}
                    error={Boolean(_.get(touched, "comparisonInfo.targetMax") && _.get(errors, "comparisonInfo.targetMax"))}
                    helperText={_.get(touched, "comparisonInfo.targetMax") && _.get(errors, "comparisonInfo.targetMax")}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </>
              ) : (
                <>
                  <TextField
                    label={_.capitalize(values.comparator)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    {...getFieldProps("comparisonInfo.target")}
                    error={Boolean(_.get(touched, "comparisonInfo.target") && _.get(errors, "comparisonInfo.target"))}
                    helperText={_.get(touched, "comparisonInfo.target") && _.get(errors, "comparisonInfo.target")}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                  <TextField
                    select
                    label="Comparand"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    {...getFieldProps("comparisonInfo.comparand")}
                    error={Boolean(_.get(touched, "comparisonInfo.comparand") && _.get(errors, "comparisonInfo.comparand"))}
                    helperText={_.get(touched, "comparisonInfo.comparand") && _.get(errors, "comparisonInfo.comparand")}
                  >
                    <MenuItem value={"previous"}>Previous actual</MenuItem>
                    <MenuItem value={"projected"}>Current projected</MenuItem>
                  </TextField>
                </>
              )}
            </div>
          </Collapse>
          <FormControlLabel
            control={
              <Switch
                size="small"
                name="enableFormula"
                checked={!!values.enableFormula}
                onChange={(e) => setFieldValue("enableFormula", e.target.checked)}
              />
            }
            label="Enable Formula"
            style={{ marginLeft: -7 }}
          />

          {values.enableFormula && (
            <div className={styles.formulaInput} style={{ marginBottom: 8 }}>
              <TextField
                fullWidth
                margin="dense"
                variant="filled"
                placeholder="Formula (e.g. (v0 + 100)/v2)"
                {...getFieldProps("formula")}
                error={Boolean(touched.formula && errors.formula)}
                helperText={touched.formula && errors.formula}
                InputProps={{
                  endAdornment: (
                    <>
                      <Tooltip title="Add Variable">
                        <IconButton onClick={() => setVariableDialogOpen(true)}>
                          <AddCircleIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Help">
                        <IconButton onClick={() => setSyntaxDialogOpen(true)}>
                          <HelpIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ),
                }}
              />
              {values.formulaScope.map((scope, idx) => {
                const { varType, value: scopeValue } = scope;
                const displayName = varType === "number" ? scopeValue : _.get(metricsById, `${scopeValue}.value`, "");
                return (
                  <Chip
                    key={idx}
                    variant="outlined"
                    size="small"
                    style={{ marginRight: "4px" }}
                    label={
                      <span>
                        <span style={{ fontWeight: "bold" }}>{`v${idx}: `}</span>
                        {`${displayName}`}
                      </span>
                    }
                    onDelete={() => {
                      const newScope = values.formulaScope.filter((scope, index) => index !== idx);
                      setFieldValue("formulaScope", newScope);
                    }}
                    onClick={() => {
                      setVariableDialogOpen(true);
                      setFormulaScopeIdx(idx);
                    }}
                  />
                );
              })}
            </div>
          )}

          {category === "quarter" && !values.enableFormula && (
            <div className={styles.flex}>
              <div className={styles.field}>
                <div className={styles.flex}>
                  <span className={styles.buttonText}>
                    {!_.isEmpty(values.threeYearMetric) ? (
                      <>
                        Using <span style={{ fontWeight: "bold" }}>{_.get(selectedThreeYearMetric, "value", "")}</span>{" "}
                      </>
                    ) : (
                      <>Use 3 Year Metric</>
                    )}
                  </span>{" "}
                  <IconButton
                    onClick={() =>
                      !_.isEmpty(values.threeYearMetric) ? handleLinkThreeYearMetric(null) : handleOpenThreeYearMetricsList()
                    }
                  >
                    <Icon
                      size={1}
                      path={!_.isEmpty(values.threeYearMetric) ? mdiClose : mdiLinkVariant}
                      color={!_.isEmpty(values.threeYearMetric) ? red[400] : blueGrey[400]}
                    />
                  </IconButton>
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.flex}>
                  <span className={styles.buttonText}>
                    {!_.isEmpty(values.weeklyTarget) ? (
                      <>
                        Using KPI <span style={{ fontWeight: "bold" }}>{_.get(selectedWeeklyTarget, "value", "")}</span>
                      </>
                    ) : (
                      <>Use KPI</>
                    )}
                  </span>{" "}
                  <IconButton
                    onClick={() => (!_.isEmpty(values.weeklyTarget) ? handleLinkWeeklyTarget(null) : setWeeklyTargetsListOpen(true))}
                  >
                    <Icon
                      size={1}
                      path={!_.isEmpty(values.weeklyTarget) ? mdiClose : mdiLinkVariant}
                      color={!_.isEmpty(values.weeklyTarget) ? red[400] : blueGrey[400]}
                    />
                  </IconButton>
                </div>
              </div>
              <span className={styles.textFields}></span>
            </div>
          )}
          {values.measurables.map(([name, projected, actual], i) => {
            const isTotal = name.value === "Total";
            const year = _.get(YEARS, name.value, parseInt(name.value));
            const showLinkBtn = year <= getYear(yearOneInt) && !values.enableFormula;
            const reference = _.get(actual, "reference");
            const hasReference = !_.isNil(reference);
            const referenceObj = oneYearMetrics.find((m) => m.id === reference);

            if (isTotal && values.cumulative) return null;
            return (
              <div className={styles.flex} key={i}>
                <TextField
                  label={`${name.value} Projected Value`}
                  variant="outlined"
                  margin="normal"
                  className={styles.textFields}
                  disabled={(isTotal && values.calculateTotal) || values.enableFormula || values.threeYearMetric}
                  {...getFieldProps(`measurables.${i}.1.value`)}
                  value={values.enableFormula ? "TBD" : projected.value}
                  error={Boolean(_.get(touched, `measurables.${i}.1.value`) && _.get(errors, `measurables.${i}.1.value`))}
                  helperText={_.get(touched, `measurables.${i}.1.value`) && _.get(errors, `measurables.${i}.1.value`)}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: category === "year" && (
                      <IconButton onClick={handleOpenDistribution(year, i)}>
                        <Icon path={mdiRefresh} size={1} />
                      </IconButton>
                    ),
                  }}
                />
                {!values.enableFormula && ((category === "year" && hasReference) || (category === "quarter" && values.weeklyTarget)) ? (
                  <span className={styles.referenceText} style={{ maxWidth: isCreate ? "150px" : "none" }}>
                    Data pulled from{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {category === "quarter" ? _.get(selectedWeeklyTarget, "value", "") : _.get(referenceObj, "value", "")}
                    </span>
                  </span>
                ) : (
                  <>
                    {!isCreate && (
                      <TextField
                        label={`${name.value} Actual Value`}
                        variant="outlined"
                        margin="normal"
                        className={styles.textFields}
                        disabled={
                          (isTotal && values.calculateTotal) || hasReference || values.enableFormula || !_.isEmpty(values.weeklyTargets)
                        }
                        {...getFieldProps(`measurables.${i}.2.value`)}
                        value={values.enableFormula ? "TBD" : actual.value}
                        error={Boolean(_.get(touched, `measurables.${i}.2.value`) && _.get(errors, `measurables.${i}.2.value`))}
                        helperText={_.get(touched, `measurables.${i}.2.value`) && _.get(errors, `measurables.${i}.2.value`)}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    )}
                    <TextField
                      label="Unit"
                      variant="outlined"
                      margin="normal"
                      {...getFieldProps("unit")}
                      className={styles.unit}
                      disabled={isTotal && values.calculateTotal}
                      InputLabelProps={{
                        shrink: !_.isEmpty(values.unit),
                      }}
                    />
                  </>
                )}
                {category === "year" && (
                  <Tooltip title="Link 1 year metric">
                    <IconButton
                      onClick={handleOpenOneYearMetrics(year, i)}
                      style={{ visibility: showLinkBtn ? "visible" : "hidden" }}
                      disabled={!showLinkBtn}
                    >
                      <Icon path={mdiLinkVariant} size={1} color={hasReference ? deepPurple[400] : blueGrey[400]} />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          {isCreate && (
            <Button
              onClick={() => handleSubmit(false)}
              color="primary"
              variant="outlined"
              disabled={mutationLoading}
              className={styles.button}
            >
              {mutationLoading ? <Loading size={24} color="#fff" /> : "Create & Add Another"}
            </Button>
          )}
          <Button
            onClick={() => handleSubmit(true)}
            color="primary"
            variant="contained"
            disabled={mutationLoading}
            className={styles.button}
          >
            {mutationLoading ? <Loading size={24} color="#fff" /> : isCreate ? "Create" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
      {oneYearMetricsListOpen && (
        <OneYearMetricsList
          open={oneYearMetricsListOpen}
          onClose={() => setOneYearMetricsListOpen(false)}
          metrics={oneYearMetrics}
          selectedYear={selectedYear}
          closed={selectedYear < getYear(yearOneInt)}
          onSubmit={handleLinkOneYearMetric}
          formMetric={_.get(values, `measurables.${selectedMeasurableIdx}.2.reference`)}
          plansOrder={plansOrder}
          // handleCreateQuarterMetric={handleCreateQuarterMetric}
          // disableMessage={(() => {
          //   const missingArr = [];
          //   if (_.isEmpty(values.value)) missingArr.push("Metric Details");
          //   if (_.isEmpty(values.plan)) missingArr.push("Department");
          //   if (_.isEmpty(missingArr)) return;

          //   return "Please fill out the following field(s): " + missingArr.join(", ");
          // })()}
        />
      )}
      {distributionOpen && (
        <Distribution
          open={distributionOpen}
          onClose={() => setDistributionOpen(false)}
          selectedYear={selectedYear}
          handleSubmit={handleChangeDistribution}
          distribution={_.get(values, `measurables.${selectedMeasurableIdx}.1.distribution`)}
        />
      )}
      {threeYearMetricsListOpen && (
        <ThreeYearMetricsList
          open={threeYearMetricsListOpen}
          onClose={() => setThreeYearMetricsListOpen(false)}
          metrics={threeYearMetrics}
          onSubmit={handleLinkThreeYearMetric}
          formMetric={_.get(values, "threeYearMetric")}
          selectedYear={selectedYear}
          updateMeasurables={updateMetricMeasurables}
          snack={snack}
        />
      )}
      {weeklyTargetsListOpen && (
        <WeeklyTargetsList
          open={weeklyTargetsListOpen}
          onClose={() => setWeeklyTargetsListOpen(false)}
          weeklyTargets={weeklyTargets}
          onSubmit={handleLinkWeeklyTarget}
          formWeeklyTarget={_.get(values, "weeklyTarget")}
        />
      )}
      <VariableDialog
        open={variableDialogOpen}
        handleClose={() => {
          setVariableDialogOpen(false);
          setFormulaScopeIdx(null);
        }}
        handleChangeVariable={(value) =>
          setFieldValue(`formulaScope.${!_.isNil(formulaScopeIdx) ? formulaScopeIdx : numOfvariables}`, value)
        }
        formulaScope={_.get(values, `formulaScope.${!_.isNil(formulaScopeIdx) ? formulaScopeIdx : numOfvariables}`, {
          varType: "reference",
          value: null,
        })}
        list={metrics}
        listName={"Metric"}
        createMode={_.isNil(formulaScopeIdx)}
      />
      <SyntaxDialog open={syntaxDialogOpen} handleClose={() => setSyntaxDialogOpen(false)} />
    </>
  );
};

export default AddEditMetricDialog;

const GET_DATA = gql`
  query AddMetricDialog_GetData(
    $organization: ID!
    $category: String
    $planCategory: String
    $sharedPlanId: ID
    $oneYearCorpPlan: ID
    $closed: Boolean
  ) {
    organization(id: $organization) {
      id
      plansOrder
    }

    users(organization: $organization) {
      id
      name {
        first
        last
      }
      profilePicture
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    plans(organization: $organization, category: $planCategory, oneYearCorpPlan: $oneYearCorpPlan, closed: $closed) {
      id
      departmentName
      sharedPlanId
      year
    }

    metrics(organization: $organization, category: $category, sharedPlanId: $sharedPlanId) {
      id
      value
      measurables {
        value
        notes {
          id
        }
        reference {
          id
        }
      }
      unit
      cumulative
      calculateTotal
      enableFormula
      formula
      formulaScope {
        varType
        value
      }
      status
      plan {
        id
        plan {
          id
        }
      }
    }

    weeklyTargets(organization: $organization, sharedPlanId: $sharedPlanId, oneYearCorpPlan: $oneYearCorpPlan) {
      id: _id
      value
    }

    threeYearMetrics: metrics(organization: $organization, sharedPlanId: $sharedPlanId, category: "year") {
      id
      category
      value
    }

    longestMetricMeasurable(org: $organization) {
      measurables {
        value
        notes
        reference
      }
    }

    objectives(organization: $organization, archived: false, category: "3 year", sharedPlanId: $sharedPlanId) {
      id
      value
      category
      number
      plan {
        id
        departmentName
        color
        shortName
        sharedPlanId
      }
    }
  }
`;

const CREATE_METRIC = gql`
  ${METRIC_FIELDS}
  mutation CreateOneYearMetric(
    $value: String!
    $comparator: String!
    $comparisonInfo: ComparisonInfoInput
    $measurables: [[MetricMeasurableInput]]
    $unit: String
    $organization: ID!
    $plan: ID!
    $objectives: [ID!]
    $category: String!
    $users: [ID!]
    $cumulative: Boolean
    $calculateTotal: Boolean
    $enableFormula: Boolean
    $formula: String
    $formulaScope: [FormulaVariableInput!]
    $weeklyTarget: ID
    $threeYearMetric: ID
  ) {
    createMetric(
      value: $value
      comparator: $comparator
      comparisonInfo: $comparisonInfo
      measurables: $measurables
      unit: $unit
      organization: $organization
      plan: $plan
      objectives: $objectives
      category: $category
      users: $users
      cumulative: $cumulative
      calculateTotal: $calculateTotal
      enableFormula: $enableFormula
      formula: $formula
      formulaScope: $formulaScope
      weeklyTarget: $weeklyTarget
      threeYearMetric: $threeYearMetric
    ) {
      metric {
        ...MetricFields
      }
      plans {
        id
        metrics
      }
      oneYearMetric {
        ...MetricFields
      }
      metrics {
        id
        weeklyTarget
        threeYearMetric
        measurables {
          value
          notes {
            id
          }
          reference {
            id
            calculateTotal
            measurables {
              value
            }
          }
          distribution
        }
      }
      weeklyTargets {
        id: _id
        oneYearMetric
      }
    }
  }
`;

const UPDATE_METRIC = gql`
  ${METRIC_FIELDS}
  mutation MetricsEditDialog_UpdateMetric(
    $id: ID!
    $value: String!
    $unit: String
    $users: [ID!]
    $plan: ID!
    $objectives: [ID!]
    $comparator: String!
    $comparisonInfo: ComparisonInfoInput
    $measurables: [[MetricMeasurableInput]]
    $cumulative: Boolean
    $calculateTotal: Boolean
    $enableFormula: Boolean
    $formula: String
    $formulaScope: [FormulaVariableInput!]
    $weeklyTarget: ID
    $threeYearMetric: ID
  ) {
    updateMetric(
      id: $id
      value: $value
      unit: $unit
      comparator: $comparator
      comparisonInfo: $comparisonInfo
      measurables: $measurables
      users: $users
      plan: $plan
      objectives: $objectives
      cumulative: $cumulative
      calculateTotal: $calculateTotal
      enableFormula: $enableFormula
      formula: $formula
      formulaScope: $formulaScope
      weeklyTarget: $weeklyTarget
      threeYearMetric: $threeYearMetric
    ) {
      metric {
        ...MetricFields
      }
      metrics {
        id
        number
        weeklyTarget
        threeYearMetric
        measurables {
          value
          notes {
            id
          }
          reference {
            id
            calculateTotal
            measurables {
              value
            }
          }
          distribution
        }
      }
      plans {
        id
        metrics
      }
      objectives {
        id
        metrics
      }
      weeklyTargets {
        id: _id
        oneYearMetric
      }
    }
  }
`;

const UPDATE_METRIC_MEASURABLES = gql`
  ${METRIC_FIELDS}
  mutation MetricsAddDialog_UpdateMetric($id: ID!, $measurables: [[MetricMeasurableInput]]!) {
    updateMetricMeasurables(id: $id, measurables: $measurables) {
      metric {
        ...MetricFields
      }
      metrics {
        id
        number
        weeklyTarget
        threeYearMetric
        measurables {
          value
          notes {
            id
          }
          reference {
            id
            calculateTotal
            measurables {
              value
            }
          }
          distribution
        }
      }
      plans {
        id
        metrics
      }
      objectives {
        id
        metrics
      }
      weeklyTargets {
        id: _id
        oneYearMetric
      }
    }
  }
`;

// const formatInitialForm = (initForm, longestMetricMeasurable, category) => {
//   // add extra year columns
//   if (_.isNil(longestMetricMeasurable) || category === "quarter") {
//     return initForm;
//   }
//   let newInitForm = { ...initForm };
//   const measurables = longestMetricMeasurable.measurables;
//   const measurableLength = measurables.length;

//   // if other metric contains more than 3 year columns, add the years to initForm
//   let closedMeasurables = [];
//   if (measurableLength > 3) {
//     measurables.forEach((year, idx) => {
//       if (measurableLength - idx > 3) {
//         const yearName = year[0].value;
//         closedMeasurables.push([{ value: yearName }, { value: "" }, { value: "" }]);
//       }
//     });

//     newInitForm.year = [...closedMeasurables, ...newInitForm.year];
//   }

//   return newInitForm;
// };

const getExtraMeasurableYears = (longestMetricMeasurable) => {
  // add extra year columns
  const measurables = _.get(longestMetricMeasurable, "measurables", []);
  const measurableLength = measurables.length;

  // if other metric contains more than 3 year columns, add the years to initForm
  let extraMeasurables = [];
  if (measurableLength > 3) {
    measurables.forEach((year, idx) => {
      if (measurableLength - idx > 3) {
        const yearName = year[0].value;
        extraMeasurables.push([{ value: yearName }, { value: "" }, { value: "" }]);
      }
    });
  }

  return extraMeasurables;
};
